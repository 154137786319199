import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from 'react-router-dom';
import DashboardHeader from "./DashboardHeader"; 
import DashboardFooter from "./DashboardFooter"; 
import ReactPaginate from 'react-paginate';
import { AuthContext } from "../context/AuthProvider";
import { SiteContext } from '../context/site';
import { StoreContext } from '../context/store';
import { AutoComplete, Select  } from 'antd';
import Filters from "./Filters";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import '../dashboard.css';
import DashboardModalProductsDetails from "./DashboardModalProductsDetails"; 
import DashboardModalProductsDelete from "./DashboardModalProductsDelete"; 
import DashboardModalCategoryDelete from "./DashboardModalCategoryDelete"; 

const DashboardProductListDev = () => {
				
	const { 
		productListDev, 
		productGetDev, 
		filePath, 
		getLastUpdate, 
		productCreate, 
		productUpdate, 
		getCategories, 
		getSubCategories, 
		productGetSizes, 
		productGetImages,
		productGetDescriptions,
		getImageBlob,
		blobToBase64
	}  = useContext( SiteContext );
	
	const [ productCount, setProductCount ] = useState( [] ); 

	const [ allProducts, setAllProducts ] = useState( [] );

	const [ selectedProductName, setSelectedProductName ]  	= useState( '' );
	const [ selectedProductId, setSelectedProductId ]  		= useState( '' );
	const [ selectedProductImage, setSelectedProductImage ] = useState( '' );
	const [ selectedProductCategory, setSelectedProductCategory ] = useState( '' );
	const [ selectedProductSubCategory , setSelectedProductSubCategory ] 	= useState( '' );
	const [ selectedProductColor , setSelectedProductColor ] 				= useState( '' );
	const [ selectedProductDescription , setSelectedProductDescription ] 	= useState( '' );
	const [ selectedProductActivated , setSelectedProductActivated ] 	= useState( '' );

	const [ catSubCats, setCatSubCats ] =  useState( [] );
	const [ selectedCategory, setSelectedCategory ] =  useState( [] );

	const [ autoCompleteOptions, setAutoCompleteOptions ] = useState( [] );
	const [ autoCompleteInputValue, setAutoCompleteInputValue ] = useState( '' );
	const [ autoCompleteSelectedOption, setAutoCompleteSelectedOption ] = useState('');
	
	const navigate = useNavigate();
	
	const autoCompleteOnSelect = ( data, option ) => {

		setAutoCompleteSelectedOption( option );
		setAutoCompleteInputValue( option.label );

		const product = allProducts.filter( e => e.productId == data )[ 0 ];

		setSelectedProductId ( product.productId );
		setSelectedProductName( product.productName );
		setSelectedProductImage( product.productImageUrl );
		setSelectedProductCategory( product.category );
		setSelectedProductSubCategory( product.subCategory );
		setSelectedProductColor( product.productColor );
		setSelectedProductDescription( product.productLineDescription );
		setSelectedProductActivated( product.productActived );

		navigate( '/product/edit/dev/?productId=' + product.productId )
		// setOpenDashboardModalProductsDetails( true );

	};
	const autoCompleteInputChange = ( data, option ) => {
		setAutoCompleteInputValue( data );
		setAutoCompleteSelectedOption( option ); // to remove selected option when user types  something wich doesn't match with any option
	};

	// 
	useEffect( () => {
		// 
		const getAll  = async () => {
			
			const all = await productListDev();	
			// const allActive = all.filter( ( x, y ) => !x.productActivated ); 
			setAllProducts( all );
			setProductCount( all.length );
console.log( 'all[ 0 ]', all[ 0 ] )
			// Auto cmoplet
			const autoCompleteData = all.map( ( e ) => ( 
				{ 
					label: e.productName, 
					value: e.productId,
					description: e.productLineDescription
				} 
			) );
			setAutoCompleteOptions( autoCompleteData );

			// Categories
			const categoriesUniques = Array();
			
			const categories = all.map( ( e ) => { 
				const obj = { 
					category: e.category, 
					subCategory: e.subCategory,
				}
				if ( !categoriesUniques.some( f => f.category === obj.category && f.subCategory === obj.subCategory ) ) {
					categoriesUniques.push( obj );
				}
			})
// console.log( categoriesUniques );
			setCatSubCats( categoriesUniques );
		}
		getAll();

	}, [] );

	// 
	const [currentPage, setCurrentPage] = useState(0);
	const PER_PAGE = 50;
	const offset = currentPage * PER_PAGE;
	const currentPageData = allProducts
		.slice(offset, offset + PER_PAGE);
	const pageCount = Math.ceil(allProducts.length / PER_PAGE);
	const imageURLPrefixe = 'https://237usa.diamta.com/public/uploads/products/';
	function handlePageClick({ selected: selectedPage }) {
		setCurrentPage(selectedPage);
	}

	// update
	const { getUser } = useContext( AuthContext );
	const updateSite = async ( itemId ) => {
		// get the product data
		const product = allProducts.filter( product => product.id == itemId )[ 0 ];
console.log( '>>> product: ', product );		
		const itemDescriptions = await productGetDescriptions( product.id );	
console.log( 'itemDescriptions: ', itemDescriptions );
		const productDescriptions = itemDescriptions.map( description => ({
			descName: description.description
		}))
console.log( 'productDescriptions: ', productDescriptions );
		const itemImages = await productGetImages( product.id );
console.log( 'itemImages: ', itemImages );		
		const productImages = itemImages.map( itemImage => imageURLPrefixe + itemImage.path );
console.log( 'productImages: ', productImages );
		const itemSizes = await productGetSizes( product.id );
console.log( 'itemSizes: ', itemSizes );
		const productSizes = itemSizes.map( itemSize => ({
			sizeName: itemSize.size,
			sizeQuantity:  Number( itemSize.quantity ),
		}))
console.log( 'productSizes: ', productSizes );
		// check if the product exists on production server
		const token = getUser().accessToken;
		const exist = await getLastUpdate( itemId );
console.log( 'exist', exist );	
		const categories = await getCategories();
console.log( 'categories', categories );
		const category = categories.filter( category => category.categoryId == product.category )[0].categoryName;
console.log( 'category', category );	
		const subcategories = await getSubCategories( product.category );
console.log( 'subcategories', subcategories );
		const subcategory = subcategories.filter( subcategorie => subcategorie.subCategoryId == product.subCategory )[0].subCategoryName;
console.log( 'subcategory', subcategory );	
		const dataObj = {
			productName: product.productName,
			productColor: product.productColor,
			productLineDescription: product.productLineDescription,
			productSizeOrder: '1', // Number( product.productSizeOrder ),
			brand: product.brand,
			productPriceBuy: Number( product.productPriceBuy ),
			productPriceBenefit: Number( product.productPriceBenefit ),
			productTax: 1, //Number( product.productTax ),
			productQuantity: Number( product.productQuantity ),
			initialProductQuantity: Number( product.initialProductQuantity ),
			category: category,
			subCategory: subcategory,
			subSubCategory: product.subSubCategory,
			productDescription: productDescriptions,
			productSize: productSizes,
		}
		
		const productImageUrl = imageURLPrefixe + product.productImageUrl
console.log( 'productImageUrl', productImageUrl );
		const productImageBinary = await getImageBlob( productImageUrl );
console.log( 'productImageBinary', productImageBinary );
		// const productImagesBinary = productImages.map( async ( productImageUrl ) => await getImageBlob( productImageUrl ) );
		const productImagesBinary = Array();
		for( const productImage of productImages ){
			const productImageBinary = await getImageBlob( productImage );
			productImagesBinary.push( productImageBinary );
		}
console.log( 'productImagesBinary', productImagesBinary );	
		const data = {
			dto: dataObj,
 			productImageUrl: productImageBinary,
			productImages: productImagesBinary,
		}
		
console.log( 'data', data );	
		if( exist ){	// update
			const rep = await productUpdate( token, data );
		}
		else{			// create
			// const rep = await productCreate( token, data );
			const rep = await productCreate( token, dataObj, productImageBinary, productImagesBinary );
		}
	}
	
	// build products 
	const BuildItems = () => {
		return (
			currentPageData.map( ( product, index ) => 
				
				<div key  = { index }  className="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6 " >
	
					<tbody>
						<tr>
							<td className="crop">
								<Link 
									style		= {{ cursor: 'pointer' }}
									to 			= { '/dashboard/product/edit/dev/?productId=' + product.id }
								>
									<img 
										src={ product.productImageUrl ? 
											filePath + 'uploads/products/' + product.productImageUrl : 
											filePath + 'uploads/products/defaultProductImages.png' } 
									/>
								</Link>
							</td>
							<td>
								<Link 
									style		= {{ cursor: 'pointer' }}
									to 			= { '/dashboard/product/edit/dev/?productId=' + product.id }
								>
									{ product.productName }
								</Link>
								<br/>
								<span>Status: { product.status }</span> 
								<br/>
								<button onClick={ e => updateSite( product.id ) }>Mettre en ligne</button> 
							</td>
						</tr>
						<tr><td>&nbsp;</td></tr>
					</tbody>
				</div>
				
			)
		)
	}


	return (
		<>
			
			<DashboardHeader />

			<div className="ps-layout--shop" >
                <div className="ps-layout__right">
                    <div className="ps-block--shop-features">
                        <div className="ps-block__header">
                            <h3>Liste des Produits ( { productCount } )</h3>
                        </div>
                    </div>
                    <div className="ps-shopping ps-tab-root" >
                        
                        <div className="ps-tabs" >
                            <div className="ps-tab active" id="tab-1">
                                <div className="ps-shopping-product">
                                    <div className="row" style={{ margingLeft: '2%' }}>
										<BuildItems /> 
                                    </div>
									<div className="ps-pagination">
									<ReactPaginate
										previousLabel={"← Précédent"}
										nextLabel={"Suivant →"}
										pageCount={pageCount}
										onPageChange={handlePageClick}
										containerClassName={"pagination"}
										previousLinkClassName={"pagination__link"}
										nextLinkClassName={"pagination__link"}
										disabledClassName={"pagination__link--disabled"}
										activeClassName={"pagination_active"}
									/>
                                </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

			<DashboardFooter />
		</>
	);
};

export default DashboardProductListDev;
