import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import { StoreContext } from '../context/store';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

const FilterByBrandTextInput = ( params ) => {

	// context custom hook
	const { brandList, brandFiltered } 	= useContext( StoreContext );
	
	const[ brands, setBrands ]  = useState( [] ); 
  
	const handleOnSearch = (string, results) => {
		// onSearch will have as the first callback parameter
		// the string searched and for the second the results.
		console.log(string, results)
	}

	const handleOnHover = (result) => {
		// the item hovered
		console.log(result)
	}

	const handleOnSelect = (item) => {
		// the item selected
		brandFiltered( [ item.name ] );
		console.log(item)
	}

	const handleOnFocus = () => {
		console.log('Focused')
	}

	const formatResult = (item) => {
		return (
			<>
				<span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
			</>
		)
	}

	// display all
	const handleClickAll = () => {
		brandFiltered();
	}

	const styling = {
		backgroundColor: 		'black',
		borderRadius: 			'0px',
		backgroundColor: 		'white',
		height: 				'34px',
		hoverBackgroundColor: 	'#eee',
		iconColor: 				'#fcb800',
		lineColor: 				'#fcb800',
		boxShadow: 				'',
	}
	
	useEffect(() => {
		setBrands ( brandList.map( ( brand, key ) => ( { id: key, name: brand } ) ) );
	}, [ brandList ]); // Include cartItems as a dependency here

	return (
		<>
			<form className="ps-form--widget-search" action="do_action" method="get">
				<ReactSearchAutocomplete
					items			= { brands }
					onSearch		= { handleOnSearch }
					onHover			= { handleOnHover }
					onSelect		= { handleOnSelect }
					onFocus			= { handleOnFocus }
					autoFocus
					formatResult	= { formatResult }
					styling			= { styling }
					onClear			= { handleClickAll }
				/>
			</form>
		</>
	);
};

export default FilterByBrandTextInput;
