import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation, useSearchParams } from 'react-router-dom';
import DashboardHeader from "./DashboardHeader"; 
import DashboardFooter from "./DashboardFooter"; 
import ReactPaginate from 'react-paginate';
import { AuthContext } from "../context/AuthProvider";
import { SiteContext } from '../context/site';
import { StoreContext } from '../context/store';
import { AutoComplete, Select,   } from 'antd';
import Filters from "./Filters";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import DashboardAddProductDescriptionModal from './DashboardAddProductDescriptionModal';
import DashboardAddProductSizeModal from './DashboardAddProductSizeModal';
import DashboardModifProductDescriptionModal from './DashboardModifProductDescriptionModal';
import DashboardModifProductSizeModal from './DashboardModifProductSizeModal';

import { Space, Spin, Button, notification, message, Popconfirm, Radio, Flex, DatePicker, Image, Upload } from 'antd';
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
	LoadingOutlined,
	InboxOutlined, 
	QuestionCircleOutlined
} from '@ant-design/icons';


const DashboardProductEdit = () => {
	
	const [ url, setUrl ] = useState( window.document.location );

	// page title
	const [ pageTitle, setPageTitle ] 	= useState( 'Ajouter un produit' );

	// status
	const [ statusList, setStatusList ] = useState( [ { label: 'en cours', value: 'en cours' }, { label: 'terminé', value: 'terminé' } ] )

	// get the project id in query params
	const [searchParams, setSearchParams] = useSearchParams();
	const [ productId, setProductId ] = useState( searchParams.get( 'productId' ) ? searchParams.get( 'productId' ) : 0 );

	// all products
	const { getAllProducts }  = useContext( StoreContext );
	const [ allProducts, setAllProducts ] = useState( [] );

	// product
	const [ product, setProduct ] 		= useState( {} );
	const [ pageType, setPageType ] 	= useState( '' );

	// Set descriptions
	const { description, setDescription } 	= useContext( SiteContext );
	const { descriptions, setDescriptions, productGetDescriptions, productGetSizes, productGetImages } = useContext( SiteContext );

	// Set sizes
	const { size, setSize } = useContext( SiteContext );
	const { sizes, setSizes } = useContext( SiteContext );
	const { productGetDev, productRemove } = useContext( SiteContext );
	
	// File
	const { filePath } 	= useContext( SiteContext );

	// Save
	const { productSave, productSaveDev } = useContext( SiteContext );

	// Name
	const [ name, setName ] = useState( '' );
	const handleChangeName = ( e ) => {
		const data = e.target.value;
		setName( data );
	}

	// Product Image
	const [ displayProductImage, setDisplayProductImage ] 	= useState( 'none' );
	const[ productImageUrl, setProductImageUrl ] 			= useState( '' );

	// Color
	const [ color, setColor ] = useState( '' );
	const handleChangeColor = ( e ) => {
		const data = e.target.value;
		setColor( data );
	}

	// Line description
	const [ lineDescription, setLineDescription ] = useState( '' );
	const handleChangeLineDescription = ( e ) => {
		const data = e.target.value;
		setLineDescription( data );
	}

	// Brand
	const [ brand, setBrand ] = useState( '' );
	const handleChangeBrand = ( e ) => {
		const data = e.target.value;
		setBrand( data );
	}

	// Price
	const [ price, setPrice ] = useState( '' );
	const handleChangePrice = ( e ) => {
		const data = e.target.value;
		setPrice( data );
	}

	// Price buy
	const [ priceBuy, setPriceBuy ] = useState( '' );
	const handleChangePriceBuy = ( e ) => {
		const data = e.target.value;
		setPriceBuy( data );
	}

	// Price benefit
	const [ priceBenefit, setPriceBenefit ] = useState( '' );
	const handleChangePriceBenefit = ( e ) => {
		const data = e.target.value;
		setPriceBenefit( data );
	}

	// Initial quantity
	const [ initialQuantity, setInitialQuantity ] = useState( '' );
	const handleChangeInitialQuantity  = ( e ) => {
		const data = e.target.value;
		setInitialQuantity( data );
	}

	// Quantity
	const [ quantity, setQuantity ] = useState( '' );
	const handleChangeQuantity  = ( e ) => {
		const data = e.target.value;
		setQuantity( data );
	}

	// File uploader
	const { Dragger } = Upload;
	const [ uploading, setUploading ] = useState(false);
	const [ fileList, setFileList ] = useState([]);

	// Product Files
	const [ productFiles, setProductFiles ] = useState( [] );

	// catSubCats
	// const [ catSubCats, setCatSubCats ] =  useState( [] );
	// const [ selectedCatSubCats, setSelectedCatSubCats ] =  useState( [] );
	
	// Category
	const { getCategories, getSubCategories } 	= useContext( SiteContext );
	const [ categories, setCategories ] 				=  useState( [] );
	const [ subCategories, setSubCategories ] 			=  useState( [] );
	const [ selectedCategory, setSelectedCategory ] =  useState( '' );
	const [ selectedSubCategory, setSelectedSubCategory ] =  useState( '' );
	
	// File
	const props = {
		listType: 'picture',
		fileList: fileList,
		multiple: true,
		beforeUpload : ()=> {
			/* update state here */
			return false; 
		},
		onChange(info) {
			info.file.status = 'success';
			let newFileList = [...info.fileList];
			setFileList( newFileList );
			console.log(info.file, info.fileList);

			// if (status == 'success') {
				// let newFileList = [...info.fileList];
				// setFileList( newFileList );
				// console.log(info.file, info.fileList);
			// }
			// if (status === 'done') {
				// message.success(`${info.file.name} file uploaded successfully.`);
			// } 
			// else if (status === 'error') {
				// message.error(`${info.file.name} file upload failed.`);
			// }
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files);
		},
	};

	// Category
	const handleChangeCategory = async ( catId ) => {
		// get sub categories for the selected category
		const subcat = await getSubCategories( catId )
		setSubCategories( subcat );
		
		setSelectedCategory( catId );
	}

	// SubCategory
	const handleChangeSubCategory = ( subCatId ) => {
		// get categories
		setSelectedSubCategory( subCatId );
	}

	// Product images
	const [ selectedProductImage, setSelectedProductImage ] = useState( '' );
	const handleChangeProductImage = ( url ) => {		
		setSelectedProductImage( url );
		setProductImageUrl( url );		//
	}

	// Product creation status
	const [ selectedProductStatus, setSelectedProductStatus ] = useState( 'en cours' );
	const handleChangeSelectedProductStatus = ( status ) => {
		setSelectedProductStatus( status )
	}

	// Empty descriptions list
	const handleClickDeleteAllDescriptions  = () => {
		setDescription( {} );
		setDescriptions( [] )
	}

	// Empty sizes list
	const handleClickDeleteAllSizes  = () => {
		setSize( {} );
		setSizes( [] )
	}
	
	// Delete the Product
	const navigate = useNavigate();
	const handleClickDeleteProduct = async () => {
		const rep = await productRemove( productId );
		if( !rep ){
			message.error( rep );
			return
		}
		else{
			message.success( 'Produit supprimé' );
			
		}
		
		navigate( '/dashboard/product/list/dev' );
	}
	
	

	// get the product data if edit
	useEffect( () => {
		
		// morning cleaning
		handleClickDeleteAllDescriptions();
		handleClickDeleteAllSizes();

		// page type
		setPageType( productId ? 'edit' : 'new' );

		// categories 
		const getCats  = async () => {
			const cats = await getCategories();
			setCategories( cats );
		}
		getCats();

	}, [ url ] );

	//
	const getProductData = async () => {
		const data = await productGetDev( productId );
		if( !data && pageType == 'edit' ){
			message.error( 'Produit non trouvé' );
			return;
		}

		if( pageType == 'edit' )
			productSetter( data )
	}

	// 
	useEffect( () => {

		//Product update
		if( pageType == 'edit' ){
			// Page title
			setPageTitle( 'Modifier un produit' )
			
			// get produvt data
			getProductData();
		}
		else{
			setPageTitle( 'Ajouter un produit' )
			productCleaner()
		}

	}, [ pageType ] );


	const productSetter = async( data ) => {

		setProduct( data ); 
		
		// line description
		setLineDescription( data.productLineDescription );
		
		// get product desctiption
		const productGetDescriptionsData = async () => {
			const data = await productGetDescriptions( productId );	
			const dataObj = data.map( 
				v => ({ 
					text 	: v.description,
					id		: v.id
				}) );
			setDescriptions( dataObj )
		}
		productGetDescriptionsData();

		// Name
		setName( data.productName );
		
		// Status
		setSelectedProductStatus( data.status );
		
		// Color
		setColor( data.productColor )

		// Brand
		setBrand( data.brand );

		// Price
		setPrice( data.productPriceBuy );

		// Price buy
		setPriceBuy( data.productPriceBuy );

		// Price benefit
		setPriceBenefit( data.productPriceBenefit );

		// Initial quantity
		setInitialQuantity( data.initialProductQuantity );
		
		// Quantity
		setQuantity( data.productQuantity );
		
		// sizes
		const productGetSizesData = async () => {
			const data = await productGetSizes( productId );
			const dataObj = data.map( 
				v => ({ 
					label 		: v.size, 
					quantity 	: v.quantity,
					id 			: v.id,
				}) )
			setSizes( dataObj )
		}
		productGetSizesData();

		// Images
		const productGetImagesData = async () => {
			const data = await productGetImages( productId );
			
			if( data ){
				const files = data.map ( ( file, key ) => ( {
					"uid": "rc-upload-" + file.id + "-" + key,
					"id": file.id,
					"lastModified": 1712629873826,
					"lastModifiedDate": file.dateCreated,
					"name": file.name,
					// "size": file.size,
					// "type": file.extension,
					"path": file.path,
					"url": filePath + 'uploads/products/' + file.path,
					"percent": 100,
					"originFileObj": {
						"uid": "rc-upload-" + file.id + "-" + key,	// fake
					},
					"status": "done"
				}) );

				setFileList( files );		// for files uploader
				setProductFiles( files );	// a copy 
			}
		}
		await productGetImagesData();

		// Product images

		setDisplayProductImage( 'block' )		// Display
		setProductImageUrl( data.productImageUrl ? data.productImageUrl : 'defaultProductImages.png' );
		setSelectedProductImage( data.productImageUrl );
		
		// Category
		setSelectedCategory( data.category );
		
		// Sub category
		
		// get sub categories for the selected category
		const subcats = await getSubCategories( data.category );
	
		// const subcat = subcats.filter( v => v.subCategoryId == data.subCategory )[0];
		setSubCategories( subcats );
		setSelectedSubCategory( data.subCategory );
	}

	// 
	const productCleaner = () => {
		// Name
		setName( '' );

		// Color
		setColor( '' )

		// Brand
		setBrand( '' );

		// Price
		setPrice( '' );

		// Price buy
		setPriceBuy( '' );

		// Price benefit
		setPriceBenefit( '' );

		// Initial quantity
		setInitialQuantity( '' );
		
		// Quantity
		setQuantity( '' );
		
		// sizes
		setSizes( [] );

		// descriptions
		setDescriptions( [] );

		// Files
		setFileList( [] );	// for files uploader
		setProductFiles( [] ); 	// to keep a copy

		// Category
		setSelectedCategory( '' );
		
		// Subcat
		setSelectedSubCategory( '' );
	}

	// Build categories list
	const BuildCategories = () => {
		return (
			<Select
				defaultValue = { selectedCategory ? selectedCategory: "Choisir une categorie" }
				style = {{ width: '300px' }}
				onChange = {(value) => {
					handleChangeCategory(value)
				}}
				options 		= {
					categories.map( ( cat, index ) => {	
						return { value: cat.categoryId, label: cat.categoryName }
					})
				}
			/>
		)
	}
	
	// Build sub categories list
	const BuildSubCategories = () => {
		return (
			<Select
				defaultValue = { selectedSubCategory ? selectedSubCategory: "Choisir une sous categorie" }
				style = {{ width: '300px' }}
				onChange = {(value) => {
					handleChangeSubCategory(value)
				}}
				options 		= {
					subCategories.map( ( cat, index ) => {	
						return { value: cat.subCategoryId, label: cat.subCategoryName }
					})
				}
			/>
		)
	}
	
	// Build descriptions
	const BuildDescriptions = () => {
		return(
			descriptions.map( ( description, key ) =>
				<button 
					type			= "button" 
					className		= "btn btn-dark" 
					data-toggle		= "modal" 
					data-target		= "#descriptionEditModal"
					onClick			= { e => setDescription( description ) }
					index			= { key }
				>
					{ description.text }
				</button>
			)
		)
	}
	
	// Build sizes
	const BuildSizes = () => {
		return(
			sizes.map( ( size, key ) =>
				<button 
					type			= "button" 
					className		= "btn btn-dark" 
					data-toggle		= "modal" 
					data-target		= "#sizeEditModal"
					onClick			= { e => setSize( size ) }
					index			= { key }
				>
					{ size.label } - { size.quantity }
				</button>
			)
		)
	}

	// build default image selection
	const BuildImagesSelect = () => {
		return (
			<Select
				defaultValue = { selectedProductImage ? selectedProductImage: "Choisir l'image principale du produit" }
				style = {{ width: '300px' }}
				onChange = {(value) => {
					handleChangeProductImage(value) 
				}}
				options 		= {
					fileList.map( ( file, index ) => {	
						return { value: file.path, label: file.name }
					})
				}
			/>
		)
	}

	// build default image selection
	const BuildStatusSelect = () => {
		return (
			<Select
				defaultValue = { selectedProductStatus ? selectedProductStatus: "" }
				style = {{ width: '300px' }}
				onChange = {(value) => {
					handleChangeSelectedProductStatus(value) 
				}}
				options 		= {
					statusList.map( ( status, index ) => {	
						return { value: status.value, label: status.label }
					})
				}
			/>
		)
	}

	// Save
	const { getUser } = useContext( AuthContext );
	const token = getUser().accessToken;
	const handleClickSave = async ( e ) => {
		e.preventDefault();
		// line description
		if( !lineDescription ){
			const messageText = 'Taper la description linéaire';
			message.error( messageText );
			return;
		}

		// status


		// descriptions
		if( !descriptions.length ){
			const messageText = 'Ajouter une description';
			message.error( messageText );
			return;
		}

		// Name
		if( name == '' ){
			const messageText = 'Taper le nom du produit';
			message.error( messageText );
			return;
		}

		// Color
		if( color == '' ){
			const messageText = 'Taper la couleur';
			message.error( messageText );
			return;
		}

		// Brand
		if( brand  == '' ){
			const messageText = 'Taper la marque';
			message.error( messageText );
			return;
		}

		// Price
		if( price  == '' ){
			const messageText = 'Taper le prix';
			message.error( messageText );
			return;
		}

		// Price buy
		if( priceBuy == '' ){
			const messageText = 'Taper la couleur';
			message.error( messageText );
			return;
		}

		// Price benefit
		if( priceBenefit == '' ){
			const messageText = 'Taper le benefice';
			message.error( messageText );
			return;
		}

		// Initial quantity
		if( initialQuantity == '' ){
			const messageText = 'Taper la quantité initiale';
			message.error( messageText );
			return;
		}

		// Quantity
		if( quantity == '' ){
			const messageText = 'Taper la quantité';
			message.error( messageText );
			return;
		}
		
		// Sizes
		if( !sizes.length ){
			const messageText = 'Taper une taille';
			message.error( messageText );
			return;
		}
		
		// Category
		if( !selectedCategory ){
			const messageText = 'Selectionner une categorie';
			message.error( messageText );
			return;
		}
		
		// Sub Category
		if( !selectedSubCategory ){
			const messageText = 'Selectionner une sous categorie';
			message.error( messageText );
			return;
		}
		
		// Files
		if( !fileList.length ){
			const messageText = 'Ajouter une image';
			message.error( messageText );
			return;
		}

		// Descriptions
		const productDescription = descriptions.map( ( v, k ) => ({ 
			descName: 	v.text,
		}));
			
		// sizes
		const productSize = sizes.map( ( v, k ) => ({ 
			sizeName: 		v.label,
			sizeQuantity: 	v.quantity,
		}));
			
		// files
		const productImages = fileList.map( ( file, key ) => ( {
			"imageName": file.name,
		}));

		// list of files to delete on the server
		var filesToDelete = [];
		if( pageType == 'edit' ){
			const filesUIDList 	= fileList.map( e => e.uid );

			filesToDelete = productFiles.filter( e => !filesUIDList.includes( e.uid ) ).map( f => f.id );
		}


		// Send data
		const data = {
			"productId": pageType == 'edit' ? productId : '',
			"productName": name,
			"productStatus": selectedProductStatus,
			"productColor": color,
			"productImageUrl": selectedProductImage,
			"productNewImageUrl": productImages[ 0 ].imageName,
			"productLineDescription": lineDescription,
			"productSizeOrder": "string",
			"brand": brand,
			"productPrice": price,
			"productPriceBuy": priceBuy,
			"productPriceBenefit": priceBenefit,
			"productTax": 0,
			"productQuantity": quantity,
			"initialProductQuantity": initialQuantity,
			"category": selectedCategory,
			"subCategory": selectedSubCategory,
			"subSubCategory": "string",
			"productDescription": JSON.stringify( productDescription ) ,
			"productSize": JSON.stringify( productSize ),
			"filesToDelete": filesToDelete,
		}

		// const rep = await productSave( data, pageType, token );
		
		const rep = await productSaveDev( data, fileList ); 
		
		if( !rep ){
			message.error( 'Erreur' );
			return
		}
		else{
			message.success( 'Produit enregistré' );

			await getProductData();
			
		}
		
		if( pageType != 'edit' )
			navigate( '/dashboard/product/list/dev' );
	}



	// JSX
	return (
		<>

			<DashboardHeader />

			<DashboardAddProductDescriptionModal />
			<DashboardAddProductSizeModal />
			<DashboardModifProductDescriptionModal />
			<DashboardModifProductSizeModal />

			<div className="ps-layout--shop">
                <div className="ps-layout__right">
                    <div className="ps-block--shop-features">
                        <div className="ps-block__header">
                            <h3> { pageTitle }</h3>
                        </div>
                    </div>
                    <div className="ps-shopping ps-tab-root" style={{ marginLeft: '3%' }}>
                       <form action="#">
							<p>&nbsp;</p>
							<p>Nom de l'article</p>
                            <div className="form-group">
                                <input 
									type		= "text" 
									className	= "form-control bg-transparent" 
									placeholder	= "Nom de l'article"
									value		= { name }
									onChange	= { e => handleChangeName( e ) }
								/>
                            </div>
							<p>&nbsp;</p>
							<p>Status</p>
                            <div className="form-group">
                                <BuildStatusSelect/>
                            </div>
							<p>&nbsp;</p>
							<div style={{ display: displayProductImage }} >
								<p>Image principale </p>
								<div className="form-group">
									<BuildImagesSelect/>&nbsp;
									<img style = {{ width: '400px' }} title = "Product image" src = { filePath + 'uploads/products/' + productImageUrl } /> 
								</div>
								<p>&nbsp;</p>
							</div>
							<p>Couleur</p>
							<div className="form-group">
                                <input 
									type		= "text" 
									className	= "form-control bg-transparent" 
									placeholder	= "Couleur de l'article"
									value		= { color }
									onChange	= { e => handleChangeColor( e ) }
								/>
                            </div>
							<p>&nbsp;</p>
							<p>Description lineaire</p>
							<div className="form-group">
                                <input 
									type		= "text" 
									className	= "form-control bg-transparent" 
									placeholder	= "Description lineaire"
									value		= { lineDescription }
									onChange	= { e => handleChangeLineDescription( e ) }
								/>
                            </div>
							<p>&nbsp;</p>
							<p>Marque</p>
							<div className="form-group">
                                <input 
									type		= "text" 
									className	= "form-control bg-transparent" 
									placeholder	= "Marque"
									value		= { brand }
									onChange	= { e => handleChangeBrand( e ) }
								/>
                            </div>
							<p>&nbsp;</p>
							<p>Prix</p>
							<div className="form-group">
                                <input 
									type		= "text" 
									className	= "form-control bg-transparent" 
									placeholder	= "Prix"
									value		= { price }
									onChange	= { e => handleChangePrice( e ) }
								/>
                            </div>
							<p>&nbsp;</p>
							<p>Prix d'achat</p>
							<div className="form-group">
                                <input 
									type		= "text" 
									className	= "form-control bg-transparent" 
									placeholder	= "Prix d'achat"
									value		= { priceBuy }
									onChange	= { e => handleChangePriceBuy( e ) }
								/>
                            </div>
							<p>&nbsp;</p>
							<p>Benefice</p>
							<div className="form-group">
                                <input 
									type		= "text" 
									className	= "form-control bg-transparent" 
									placeholder	= "Benefice"
									value		= { priceBenefit }
									onChange	= { e => handleChangePriceBenefit( e ) }
								/>
                            </div>
							<p>&nbsp;</p>
							<p>Quantité initiale</p>
							<div className="form-group">
                                <input 
									type		= "text" 
									className	= "form-control bg-transparent" 
									placeholder	= "Quantité initiale"
									value		= { initialQuantity }
									onChange	= { e => handleChangeInitialQuantity( e ) }
								/>
                            </div>
							<p>&nbsp;</p>
							<p>Quantité</p>
							<div className="form-group">
                                <input 
									type		= "text" 
									className	= "form-control bg-transparent" 
									placeholder	= "Quantité"
									value		= { quantity }
									onChange	= { e => handleChangeQuantity( e ) }
								/>
                            </div>
							<p>&nbsp;</p>
							<p>Categorie</p>
							<div className="form-group">
                                <BuildCategories/>
                            </div>
							<p>&nbsp;</p>
							<p>Sous Categorie</p>
							<div className="form-group">
                                <BuildSubCategories/>
                            </div>
							<p>&nbsp;</p>
							<p>Descriptions</p>
							<div className="form-group">
								<button 
									type			= "button" 
									className		= "btn btn-success" 
									data-toggle		= "modal" 
									data-target		= "#descriptionAddModal"
									style			= {{ padding: '2px 18px' }}
								>
									<i className='fa fa-plus' ></i>&nbsp;Ajouter une description
								</button>
								<div><BuildDescriptions /></div>
								<Popconfirm
									title		= 'Suprimer toutes les Descriptions'
									description	= 'Voulez-vous supprimer toutes les Descriptions?'
												onConfirm	= { handleClickDeleteAllDescriptions }
												okText		= 'Oui'
												cancelText	= 'Non'
												
											>
												<button 
														type			= "button" 
														className		= "btn btn-info" 
														style			= {{ padding: '2px 18px' }}
												>
														<span className="btn-icon-right">
															<i className="fa fa-trash"></i>
														</span>&nbsp;
														Supprimer toutes les descriptions
														
												</button>
								</Popconfirm>
							</div>
							<p>&nbsp;</p>
							<p>Tailles et Quantités</p>
							<div className="form-group">
								<button 
									type			= "button" 
									className		= "btn btn-success" 
									data-toggle		= "modal" 
									data-target		= "#sizeAddModal"
									style			= {{ padding: '2px 18px' }}
								>
									<i className='fa fa-plus' ></i>&nbsp;Ajouter une taille
								</button>
								<div><BuildSizes /></div>
								<Popconfirm
									title		= 'Suprimer toutes les Tailles'
									description	= 'Voulez-vous supprimer toutes les tailles?'
												onConfirm	= { handleClickDeleteAllSizes }
												okText		= 'Yes'
												cancelText	= 'No'
												
											>
												<button 
														type			= "button" 
														className		= "btn btn-info" 
														style			= {{ padding: '2px 18px' }}
												>
														<span className="btn-icon-right">
															<i className="fa fa-trash"></i>
														</span>&nbsp;
														Supprimer toutes les tailles
												</button>
								</Popconfirm>
							</div>
							<p>&nbsp;</p>
							<p>Images</p>
							<div className="form-group">
								<div className="fallback">
									<Dragger {...props}>
										<p className="ant-upload-drag-icon">
											<InboxOutlined />
										</p>
										<p className="ant-upload-text">Click or drag file to this area to upload</p>
										<p className="ant-upload-hint">
											Support for a single or bulk upload. Strictly prohibited from uploading company data or other
											banned files.
										</p>
									</Dragger>

								</div>
								
                            </div>
							<p>&nbsp;</p>
							<div className="form-group">
								<button
									onClick	= { e => handleClickSave( e ) }
									style = {{ width: '100%', height: '50px' }}
									className = "btn btn-success"
								>
									Enregistrer
								</button>
								<Popconfirm
									title		= 'Supprimer ce Produit'
									description	= 'Attention: voulez-vous vraiment supprimer ce produit?'
												onConfirm	= { handleClickDeleteProduct }
												okText		= 'Oui'
												cancelText	= 'Non'
												
											>
												<button 
														type			= "button" 
														className		= "btn btn-alert" 
														style			= {{ padding: '2px 18px' }}
												>
														<span className="btn-icon-right">
															<i className="fa fa-trash"></i>
														</span>&nbsp;
														<span style={{ color: 'red' }}>
															Supprimer ce produit
														</span>
												</button>
								</Popconfirm>
                            </div>
						</form>
                    </div>
                </div>
            </div>

			<DashboardFooter />
		</>
	);
};

export default DashboardProductEdit;
