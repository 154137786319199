import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import Currency from './Currency';
import { StoreContext } from '../context/store';
import '../priceSliderOverrides.css';

const FilterByPriceSlider = ( params ) => {

	// context custom hook
	const { highestPrice, priceRange } 	= useContext( StoreContext );
	const [ minPrice, setMinPrice ] 		= useState( 0 );
	const [ maxPrice, setMaxPrice ] 		= useState( 0 );

	// on slider change
	const sliderChange = ( value ) => {
		setMinPrice( value[ 0 ] );
		setMaxPrice( value[ 1 ] );
		setTimeout( ( priceRange( value ), 2000 ) );
		
	}
	
	useEffect(() => {
		if( !maxPrice )
			setMaxPrice( highestPrice )
	}, [ highestPrice ]); // Include cartItems as a dependency here

	const beautyPrice = ( price ) => {
		return price.toString().replace( /(?=(?:.{3})*$)/g, ' ' )
	}

	return (
		<>
			<p className="ps-slider__meta">
				<RangeSlider 
					min 			= { 0 }
					max 			= { highestPrice }
					step 			= { highestPrice / 20 }
					// value 			= { [ minPrice, maxPrice / 2 ] }
					onInput			= { ( value, userInteraction ) => sliderChange( value ) }
				/>
			</p>
			<br />
			Prix de <span className="">{ beautyPrice( minPrice ) }</span><Currency /> à <span className="">{ beautyPrice( maxPrice ) }</span><Currency />
		</>
	);
};

export default FilterByPriceSlider;
