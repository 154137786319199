import React, { useState, useEffect, useContext } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ShoppingPageItemSimple from "./ShoppingPageItemSimple";
import { StoreContext } from '../context/store';


const ShopStore = ( params ) => {
	const storeName 	= params.params.name;
	const title 		= params.params.title;
	const path			= params.params.path;

	// custom hook to get data
	const { getAStoreProducts } = useContext( StoreContext );
	const [ stores, setStores ] = useState( [] );

	// set product badge
	const getBadge = ( productPrice, productPriceSale ) => {
		const rep = ( ( productPriceSale - productPrice )/productPriceSale ) * 100;
		
		if( isNaN( rep ) || rep == 'Infinity' || rep == '-Infinity' )
			return ''
		
		return	'-' + Math.trunc( rep ) + '%'
	}
	
	const BuildItems = () => {
		return (
			stores.map(( product, index ) => 
				<ShoppingPageItemSimple 
					key  = { index } 
					item = {{
						name: 			product.productName, 
						price: 			product.productPrice.toString().replace(/(?=(?:.{3})*$)/g, ' '),
						priceOld:		product.productPriceSale ? product.productPriceSale.toString().replace(/(?=(?:.{3})*$)/g, ' ') : '',
						description: 	product.productLineDescription, 
						picture: 		product.productImageUrl ? product.productImageUrl : product.productImage[0],
						pictures: 		product.productImage,
						badge:			getBadge( product.productPrice, product.productPriceSale ),
						brand:			product.brand,
						productDescription: product.productDescription,
						productId: 		product.productId,
						productColor: 	product.productColor,
						productSize:	product.productSize,
						store:			storeName,
						category:		null,
						categories: 	null,
						categoryPath:	null,
					}}
				/>
			)
		)
	}
	
	//
	useEffect( () => {
		// set the products state
		const getProducts = async () => {
			const storeProducts = await getAStoreProducts( storeName );
// console.log( 'storeProducts', storeProducts );
			setStores ( storeProducts );
		}
		getProducts();
	}, [ title ] );
	
	return (
		<>
		    <div className="ps-section__header">
                <h3>{ title }</h3>
            </div>
            <div className="ps-section__content" style={{paddingTop: '5px'}}>
                <OwlCarousel 
					className		= 'owl-theme' 
					loop margin		= { 2 }  
					nav 			= { true }
					items			= { 10 }
					responsiveClass	= { true }
					responsive		= {
																				{ 
											0: {
												items:2
											},
											600: {
												items:3
											},
											1000: {
												items:5
											}
										}
									}
				>
					<BuildItems /> 
                </OwlCarousel>
					
			</div>
		</>
	);
};

export default ShopStore;
