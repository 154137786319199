import React, { useState, useEffect, useContext } from "react";
import { Modal } from 'react-responsive-modal';
import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';
import Currency from './Currency'
import { useNavigate, Link } from 'react-router-dom';

const MySpaceCommandsModal = ( params ) => {
	// Sets the moment instance to use.
	Moment.globalMoment = moment;
	// Set the locale for every react-moment instance to French.
	Moment.globalLocale = 'fr';
console.log( params );
	const orderCode 	= params.params.orderCode;
	const createdDate 	= params.params.createdDate;
	const modeRetrait 	= params.params.deliveryTypePrice ? 1 : 2; // 1 : livraison, 2 : récuperation
	const lastName 		= params.params.person.lastName;
	const firstName 	= params.params.person.firstName;
	const email		 	= params.params.person.email;
	const phone			= params.params.person.phoneNumber;
	const orderPhone	= params.params.orderPhoneNumber;
	const orderStatus	= params.params.orderStatus;
	const name	 		= lastName + ' ' + firstName;
	const deliveryAddress	= params.params.deliveryTypePrice ? params.params.userOrderAddress : '';
	const partnerInfo	= !params.params.deliveryTypePrice ? params.params.recupLocation.partners[0] : '';
	const products 		= params.params.products;

console.log( partnerInfo );

	const BuildProduct = () => {
		return(
			products.map( ( product, key  ) =>
				<tr>
					<td><a target="_blank" href={ 'https://www.237usa.com/images/products/' + product.productImage[0].imageName }><img src={ 'https://www.237usa.com/images/products/' + product.productImage[0].imageName } alt="" width="50" /></a></td>
					<td>
						<Link>{ product.productName }</Link><br/>
						Couleur: { product.productColor }<br/>
						Taille: { product.productSizeOrder }<br/>
						
					</td>
					<td>
						Prix unitaire: { product.productPrice.toString().replace(/(?=(?:.{3})*$)/g, ' ').trim() }<Currency /><br/>
						Quantité: { product.productQuantitySell }<br/>
						Total: <b>{ ( product.productPrice * product.productQuantitySell ).toString().replace(/(?=(?:.{3})*$)/g, ' ').trim() }<Currency /> </b>
					</td>
				</tr>
			)
		)
	}
	return (
		<>
		<div class="ps-vendor-dashboard pro">
        <div class="container">
            <div class="ps-section__header">
                <h3>Commande # { orderCode}</h3>
                <p>Date: <Moment locale='fr' format='DD MMM yyyy, hh:mm' >{ createdDate }</Moment></p>
				<p>
				{ 
					orderStatus.orderStatusName == 'NON-PAYER'
					?
						<span style={{ color: 'red' }}>&nbsp;Non payé</span>
					:
						<span style={{ color: 'green' }}><i className="icon-check"></i>&nbsp;Payé</span>
				}
				</p>
            </div>
            <div class="ps-section__content">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                        <figure class="ps-block--vendor-status">
                            <figcaption>Détails de la Commande</figcaption>
                            <table class="table ps-table ps-table--vendor">
                                <thead>
                                    <tr>
                                        <th>Client</th>
                                        <th>
										{ 
											modeRetrait == 1 ? 
												<>Adresse de Livraison</> 
											: 
												<>Lieu de Recupération</> 
										}
										</th>
                                        <th>Paiement</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{ name }
                                            <p>{ email }</p>
											<p>{ phone }</p>
                                        </td>
                                        <td>
										{ 
											modeRetrait == 1 ? 
												deliveryAddress 
											: 
											<>
											{ partnerInfo.partnerAlias }<br/>
											{ partnerInfo.partnerAddress }<br/>
											Téléphone: { partnerInfo.partnerPhoneNumber}
											</>
										}
                                        </td>
                                        <td>
                                            { orderPhone }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </figure>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 ">
                        <figure class="ps-block--vendor-status">
                            <figcaption>Produits Commandés</figcaption>
                            <table class="table ps-table ps-table--vendor">
                                <thead>
                                    <tr>
                                        <th><i class="icon-picture"></i></th>
                                        <th>Détails</th>
                                        <th>Prix</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <BuildProduct />
                                </tbody>
                            </table>
                        </figure>
                    </div>
                </div>
            </div>
        </div>
    </div>
		</>
	);
};

export default MySpaceCommandsModal;
