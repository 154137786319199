import React, { useState, useEffect, useContext } from "react";
import Currency from "./Currency";
import ImageGallery from "react-image-gallery";
import { CartContext } from '../context/cart';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import ShopItemColors from "./ShopItemColors";

const PartnerModal = ( items ) => {

console.log( 'items', items );

	const partner		= items.params.partner;
	const partnerName 	= partner.partnerAlias;
	const firstName 	= partner.partnerFirstName;
    const lastName 		= partner.partnerLastName;
	const phone 		= partner.partnerPhoneNumber;
	const avatar		= partner.partnerAvatar;
	const photo			= partner.partnerPhoto;
	const locationImabe = partner.partnerLocationImage;
	const googleMap		= partner.partnerGoogleMapLink;
	const dayOfWeek		= partner.dayOfWeek;
	const timeOfWeek	= partner.timeOfWeek;
	const capacity		= partner.partnerCapacity;
	const address		= partner.partnerAddress;
	const descriptions	= partner.partnerDescription;
	const offerName		= partner.pertnerOffer.pertnerOfferName;
	const offerImages	= partner.pertnerOffer.partnerOfferImages;

	// Build partner Image
	const imageGalerie = () => {
		const arr = Array();
		for( const picture of offerImages ){
			const obj = {};
			obj[ 'original' ] 	= 'https://www.237usa.com/images/partner/product-partner/' + picture.partnerOfferImageName;
			obj[ 'thumbnail' ] 	= 'https://www.237usa.com/images/partner/product-partner/' + picture.partnerOfferImageName;
			
			arr.push( obj );		
		}
		
		return arr;
	}	
	const imageGaleriePictures = imageGalerie();

	// Build Description's lines
	const BuildDescription = () => {
		return(
			descriptions.map( ( description, index ) =>
				<li key = { index }>
					{ description }
				</li>
			)
		)
	}
	
	// handle click voir page
	const navigate = useNavigate();
	
	useEffect(() => {
	}, []);

	return (
		<>
        
    <div className="ps-page--blog customModal">
        <div className="ps-post--detail">
            <div className="ps-post__header" style={{ paddingBottom:'15px', }}>
                <div className="container">
                    <h1>{ partnerName } </h1>
					<p><i className="icon-address"></i> { address }</p>
                    <p><i className="icon-phone"></i> { phone }</p>
					<p><i className="icon-date"></i> { dayOfWeek }</p>
					<p><i className="icon-clock"></i> { timeOfWeek }</p>
                </div>
            </div>
            <div className="container" style={{ padding:'5px' }}>
                <div className="ps-post__content"  style={{ paddingTop:'15px', }}>
                    <ImageGallery 
						items			= { imageGaleriePictures } 
						showThumbnails 	= { false }
					/>
                </div>
                <div className="ps-post__content" style={{ paddingTop: '15px', }}>
                    <blockquote className="ps-blockquote">
                        <p style={{ fontSize: '16px' }}>
							<BuildDescription />
						</p>
                    </blockquote>
                </div>
                
            </div>
        </div>
        <div className="ps-block--author" style={{display: 'flex' }}>
            <div className="ps-block__thumbnail" style={{ textAlign:'center', }}> 
				<img src={ 'https://www.237usa.com/images/partner/avatar/' + avatar } alt="" />
				<br/><i>{ firstName } { lastName } <br/>Manager, <br/>{ partnerName }</i>
			</div>
            <div className="ps-block__content" style={{ paddingTop:'0px', }}>
                Hello! 
				<br/>Merci d'avoir choisi <b>{ partnerName }</b> pour récuperer votre achat en ligne.
				<br/>Achetez vos produits made in USA sur <b><a href='https://237usa.com'>237usa.com</a></b> et recuperez vos achats dans notre établissement <b>en 24h</b>!
				<br/>La récupération est <b>gratuite et sécurisée</b>.
				<br/>Nous vous attendons à <b>{ address }</b>
				<br/>de <b>{ dayOfWeek }, { timeOfWeek }</b>.
				<br/>Téléphone:  <b>+237 { phone }</b>
				<br/>Bon shopping!
            </div>
        </div>
    </div>
		</>
	);
};

export default PartnerModal;
