import React, { useState, useEffect, useContext } from "react";

import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
} from '@ant-design/icons';
import { Button, Divider, notification, Space } from 'antd';
import { CartContext } from '../context/cart';
import { Radio } from 'antd';

const ShopItemQuantityCart = ( params ) => {
	
	
	const cartItem							= params.params;	
	const [ quantity, setQuantity ] 		= useState( cartItem.product.quantity );
	const minQuantity 						= 1;
	const productName						= cartItem.product.product.name;
	const itemSize						= cartItem.product.product.size;
	const itemSizes							= cartItem.product.product.itemSizes;
	
	// Get product quantity for the selected size
	const getSizeQuantity = ( ) => {
		const sizeQty 	= itemSizes.find( ( size ) =>
			size.label == itemSize
		)
		return sizeQty != null ? sizeQty.value : 1;
	}
	
	const maxQuantity	= getSizeQuantity();	// will b set wen a size will be chosen

	// Antd notifications
	const [ api, contextHolder ] = notification.useNotification();
	const openNotificationErrorMaxQuantity = ( message ) => {		// Error max quantity
		notification.warning({
			message: `${message}`,
			duration: 3,
			description: 'C\'était la dernière pièce en stock du produit ' + productName + ' taille ' + itemSize + '.',
			placement: 'topRight',
		});
	};
	const openNotificationErrorNoSize = ( message ) => {			// Error no size selected
		notification.error({
			message: `${message}`,
			duration: 3,
			description: 'Veuillez d\'abord choisir une taille',
			placement: 'topRight',
		});
	};
		
	// add quantity
	const { addToCart } = useContext( CartContext );
	const handleClickQtyUp = async() => {
		// test if we have this quantity for this product size
		if( quantity >= maxQuantity ) {		
			openNotificationErrorMaxQuantity( 'Fini!' )
			return
		}
		// update the cart
		cartItem.quantity = quantity + 1;
		cartItem.product = cartItem.product.product;	// Todo:
		await addToCart( cartItem );
		// set quantity
		setQuantity( quantity + 1 );
	}
	
	// remove quantity
	const handleClickQtyDown = async() => {
		if( quantity <= 1 ) {
			return
		}
		// update the cart
		cartItem.quantity = quantity - 1;
		cartItem.product = cartItem.product.product;	// Todo:
		await addToCart( cartItem );
		// set quantity
		setQuantity( quantity - 1 );
		
	}
	
	useEffect(() => {
	}, []);
	// return the jsx code
	return (
		<div className="form-group--number">
			<button className="up" onClick={ handleClickQtyUp }><i className="fa fa-plus"></i></button>
			<button className="down" onClick={ handleClickQtyDown }><i className="fa fa-minus"></i></button>
			<input id='itemQuantity' className="form-control" type="text" placeholder={ quantity } />
		</div>
	);
};

export default ShopItemQuantityCart;
