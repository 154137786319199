import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import AllRoutes from "./AllRoutes";
import $ from 'jquery'; 
import Breadcrumb from "./Breadcrumb";
import ShopCart from "./ShopCart";
import SecuredPagesAuth from "./SecuredPagesAuth";
import SearchAProduct from './SearchAProduct';

const Header = () => {
	


	
	const categories = AllRoutes.getAllCategories();
	const selections = AllRoutes.getAllSelections();
	const [ selectedSearchOption, setSelectedSearchOption ] = useState('');

	// Build the shop Selection menu
	const BuildShopSelectionLinks = ( selections ) => {
		const selectionsArr = Object.values( selections.params );	// Convert indexed Obj to Array
		return (
			selectionsArr.map( ( selection, index ) => 
				<li key = {index}> 
					<Link to = { '/' + selection[0].path } > 
						{ selection[0].selection } 
					</Link>
				</li>
			)
		)
	}

	// Build the Shop Category Links 
	const BuildShopCategoryLinks = ( category ) => {
		category = Object.values( category );

		return(
			category[0].map( ( cat, index ) => 
				<li key={ index }>
					<Link to = { '/' + cat.path } > 
						{ cat.title }
					</Link>
				</li>		
			)
		)
	}
	
	// Helper: capitalize
	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
	// Build the shop Category menu
	const BuildShopCategory = () => {
		const cats = Object.values( categories );
		return (
			cats.map( ( category, index ) => 
				!category[0].category.includes( '/' ) 
				&&
				<div key={ index } className="mega-menu__column">
					<h4>
						<Link 
							to={ '/categories/' + category[0].category }
						>
							<b>{ capitalizeFirstLetter( category[0].category ) }</b>
						</Link>
						<span className="sub-toggle"></span>
					</h4>
					<ul className="mega-menu__list">
						<BuildShopCategoryLinks category = { category } />
					</ul>
				</div>
			)
		)
	}
	
	// Build select
	const BuildSearch = () => {
		const cats = Object.values( categories );
		return (
			cats.map( ( category, index ) => 
				<option 
					key={index} 
					value={category[0].category} 
				>
					{category[0].category}
				</option>
			)
		)
	}
	
	const navigate = useNavigate();
	const handleSelectSearch = ( event ) => {
		const term = event.target.value;
		const URL = '/categories/' + term;
		setSelectedSearchOption( term );
		
		navigate( URL )
	}
	
	useEffect(() => {
		// sticky header
		const stickyHeader = () => {
			var header = $('.header'),
				scrollPosition = 0,
				checkpoint = 50;
			header.each(function() {
				if ($(this).data('sticky') === true) {
					var el = $(this);
					$(window).scroll(function() {
						var currentPosition = $(this).scrollTop();
						if (currentPosition > checkpoint) {
							el.addClass('header--sticky');
						} else {
							el.removeClass('header--sticky');
						}
					});
				}
			});
		}		
		stickyHeader()	
	}, []);
	
	return (
		<>
		<SecuredPagesAuth />
		<header className="header header--1" data-sticky="true">
			
				<div className="header__top">
				<div className="ps-container">
                <div className="header__left">
                    <div className="menu--product-categories">
                        <div className="menu__toggle"><i className="icon-menu"></i><span> Nos Sélections</span></div>
                        <div className="menu__content">
                            <ul className="menu--dropdown">
								<BuildShopSelectionLinks params={ selections } />
								<li><Link to='/selections'>Voir tout</Link></li>
							</ul>
                        </div>
                    </div>
						<Link className="ps-logo" to="/accueil">
							<img src="img/logo_light.png" alt="" />
					</Link>
                </div>
                <div className="header__center">
                    <form className="ps-form--quick-search" action="index.html" method="get">
                        <div className="form-group--icon"><i className="icon-chevron-down"></i>
                            <select 
								className 	= "form-control"
								value		= { selectedSearchOption } 
								onChange	= { handleSelectSearch } 
							>
								<option value='All' defaultValue="defaultValue">Tout</option>
								<BuildSearch />
                            </select>
                        </div>
						<SearchAProduct />
                    </form>
                </div>
                <div className="header__right">
                    <ShopCart />
                </div>
            </div>
        </div>
        <nav className="navigation">
            <div className="ps-container">
                <div className="navigation__left">
                    <div className="menu--product-categories">
                        <div className="menu__toggle"><i className="icon-menu"></i><span> Nos Sélections</span></div>
                        <div className="menu__content">
						<ul className="menu--dropdown">
							<BuildShopSelectionLinks params={ selections } />
							<li><Link to='/selections'>Voir tout</Link></li>
						</ul>
                        </div>
                    </div>
                </div>
                <div className="navigation__right">
                    <ul className="menu">
                        <li className="menu-item-has-children has-mega-menu">
							<Link to='/categories'>Catégories</Link><span className="sub-toggle"></span>
                            <div className="mega-menu">
								<BuildShopCategory />
                            </div>
                        </li>
						<li className="menu-item-has-children has-mega-menu">
							<Link to='/boutiques'>Boutiques</Link><span className="sub-toggle"></span>
                            <div className="mega-menu" style={{ width: '250px' }}>
								<ul className="mega-menu__list">
									<li><Link to='/boutiques/levis'>Levis | Signature</Link></li>
									<li><Link to='/boutiques/reason'>Reason</Link></li>
									<li><Link to='/boutiques/us-polo-assn'>U.S. Polo Assn</Link></li>
									<li><Link to='/boutiques'>Voir tout</Link></li>
								</ul>
                            </div>
                        </li>
						<li className="menu-item-has-children has-mega-menu">
							<Link to='/boutiques'>Soldes</Link><span className="sub-toggle"></span>
                            <div className="mega-menu" style={{ width: '250px' }}>
								<ul className="mega-menu__list">
									<li><Link to='/categories/soldes/soldes-pour-femmes'>Soldes pour Femmes</Link></li>
									<li><Link to='/categories/soldes/soldes-pour-hommes'>Soldes pour Homme</Link></li>
									<li><Link to='/categories/soldes/soldes-maison'>Soldes Maison</Link></li>
									<li><Link to='/categories/soldes/soldes-electronique'>Soldes Electronique</Link></li>
									<li><Link to='/categories/soldes'>Voir tout</Link></li>
								</ul>
                            </div>
                        </li>
                    </ul>
                    <ul className="navigation__extra">
                        <li>
                            <div className="ps-dropdown">
							<Link to='/blog'>Comment ça marche</Link><span className="sub-toggle"></span>
                                <ul className="ps-dropdown-menu">
									<li><Link to='/blog/comment-commander'>Comment commander</Link></li>
									<li><Link to='/blog/comment-payer'>Comment Payer</Link></li>
									<li><Link to='/blog/comment-recevoir-ses-achats'>Livraison et Recupération</Link></li>
									<li><Link to='/faq'>FAQ</Link></li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <Link to='/contactez-nous'>Contactez-nous</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
		<Breadcrumb />
    </header>
    <header className="header header--mobile" data-sticky="true">
        <div className="navigation--mobile">
            <div className="navigation__left"><Link className="ps-logo" to="/accueil"><img src="img/logo_light.png" alt="" /></Link></div>
            <div className="navigation__right">
                <div className="header__actions">
                    <div className="ps-block--user-header">
                        <ShopCart />
                    </div>
                </div>
            </div>
        </div>
        <Breadcrumb />
    </header>
	
		</>
	);
};

export default Header;
