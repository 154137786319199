import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link,  } from "react-router-dom";
import PasswordForgetRestore from "./PasswordForgetRestore";
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import { AuthContext } from "../context/AuthProvider";
import { Form, Input, Select } from 'antd';
import { Space, Spin, Button, notification, message, Popconfirm } from 'antd';
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
	LoadingOutlined
} from '@ant-design/icons';

import useDidMountEffect from './useDidMountEffect';

const PasswordForgetValidation = ( params ) => {
	
	// spin
	const [ passwordForgetValidationSpin, setPasswordForgetValidationSpin ] = useState( 'none' );
	
	// user email
	const { getUser }	= useContext( AuthContext );
	const email = getUser().email;

	// code
	const [ code, setCode ] = useState( '' );
	const [ codeError, setCodeError ] = useState( false );
	const handleChangeCode = ( e ) => {	
		setCode( e.target.value );
		// realtime validation for error messages
		if( !e.target.value ){
			setCodeError( 'Le code de validation doit avoir 5 chiffres.' );
		}
		else if( !codeRegex.test( e.target.value ) ){
			setCodeError( 'Le code de validation doit avoir 5 chiffres' );
		}
		else{
			setCodeError( false )
		}
	}

	// Get fetch definition
	async function getData( url, data, method ) {
		const response = await fetch( url, {
			method: method, // *GET, POST, PUT, DELETE, etc.
			// mode: "no-cors", // no-cors, *cors, same-origin
			headers: {
				"Content-Type": "application/json",
				// 'Content-Type': 'application/x-www-form-urlencoded',
			},
			// body: JSON.stringify( data ), // body data type must match "Content-Type" header
		});

		return response; // parses JSON response into native JavaScript objects
	}
	
	// Validation
	const navigate 	= useNavigate();
	const codeRegex = /^\d{5}$/;
	const handleClickCodeVerification = async ( event ) => {
		
		event.preventDefault();
		
		// spin
		setPasswordForgetValidationSpin( 'block' );

		// reset all errors
		resetAllErrors();

		// shop validation registration email
		if( !codeRegex.test( code ) ){
			setPasswordForgetValidationSpin( 'none' );
			message.error( 'Le code de validation doit avoir 5 chiffres' );
			setCodeError( 'Le code de validation doit avoir 5 chiffres' );
			return
		}
		
		// code verification
		const verificationURL = 'https://allkakou.com/api/authentication/confirmRecovery?codePassword=' + code +'';
		const method 	= 'GET';
		const data 		= {}
		const resp = await getData( verificationURL, data, method );

console.log( resp );

		if( resp.status == 404 ){
			setPasswordForgetValidationSpin( 'none' );
			const errorMsg = 'Code non trouvé';
			message.error( errorMsg );	
			setCodeError( errorMsg );	
			return;
		}

		// Redirect to password restore
		navigate( '/connexion/mot-de-passe-oublie/restaurer' );

	}
	
	// code resend
	const handleClickCodeNotReceived = ( event ) => {
		const verificationURL = 'https://allkakou.com/api/authentication/generate-new-code?emailNewCode=' + email + '&typeCode=PasswordRecovery';
		const resp = getData( verificationURL ).then( 
			( data ) => {
				
				if( data.status !== 200 ){
					setPasswordForgetValidationSpin( 'none' );
					message.error( data.status );	
					
					return;
				}
				
				message.info( 'Un nouveau code a été envoyé dans votre boite e-mail ' + email + '.' );
				
				// const gotoURL = '/connexion/mot-de-passe-oublie/validation';
			}
		);
	}
	
	const [form] = Form.useForm();
	
	// reset all the error messages
	const resetAllErrors = () => {
		setCodeError( false );
	}
	
	const cancel = ()  => {
	};
	
	// run after the first render
	useDidMountEffect(() => {
        form.validateFields();
    },  [ codeError ] );

	useEffect(() => {
		setPasswordForgetValidationSpin( 'none' );
	}, []);


	return (
		<>
			<Header />
				<div className="container">
				<div class="ps-section__header" style={{textAlign:'center', marginTop: '25px'}}>
                    <h3>Réinitialiser votre Mot de Passe</h3>
                </div>
					<Form 
						style 		= {{ paddingTop: '25px' }} 
						className 	= "ps-form--account ps-tab-root" 
						action 	= "" 
						method 	= "get"
						layout 	= "vertical"
						form	= {form}
					>
						
						<div className="ps-tabs">
							<div className="">
								<div className="ps-form__content">
									<h5>Entrez le code qui vous a été envoyé à votre boite e-mail {email}</h5>
									<div className="form-group">
										<Form.Item
											name  = "Code"
											rules={[
												{
													message: codeError,
													validator: ( value ) => {
														if ( codeError === false ) {
															return Promise.resolve();
														} 
														else {
															return Promise.reject( codeError );
														}
													}
												}
											]}
										>
											<Input 
												value={code} 
												onChange={handleChangeCode}
												className="form-control" 
												type="text" 
												placeholder="Code" 
											/>
										</Form.Item>
									</div>
									
									<div className="form-group submtit">
										<button 
											className="ps-btn ps-btn--fullwidth"
											onClick={handleClickCodeVerification}
										>
											<Space>
												<Spin
													indicator={
														<LoadingOutlined
															style={{
																fontSize: 		20,
																marginRight: 	'10px',
																display:		passwordForgetValidationSpin
															}}
															spin
														/>
													}
												/>
											</Space>
											Confirmer
										</button>
										<Popconfirm
											title="Envoyer un nouveau code"
											description={ 'Voulez-vous envoyer un nouveau code à l\'adresse email ' + email + '?'}
											onConfirm={ () => handleClickCodeNotReceived() } 
											onCancel={cancel}
											okText="Oui"
											cancelText="Non"
										>
											<a className="ps-product__remove" style={{ cursor: 'pointer' }} >
												Je n'ai pas reçu de code.
											</a>
										</Popconfirm>
									</div>
									<p>&nbsp;</p>
								</div>
							</div>
						</div>
					</Form>
				</div>
				<Newsletter />
            
			<Footer />
		</>
	);
};

export default PasswordForgetValidation;
