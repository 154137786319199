import React, { useState, useEffect, useContext } from "react";
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
} from '@ant-design/icons';
import { Button, Divider, notification, message, Space } from 'antd';
import ShopBtnAddToCart from './ShopBtnAddToCart'
import { CartContext } from '../context/cart';
import { Radio } from 'antd';

const RadioButton 	= Radio.Button;
const RadioGroup 	= Radio.Group;

const ShopItemSize = ( params ) => {
	const cartItem							= params.productSizes.cartItem;	
	
	// console.log( '--------------- *****************',  params );
	
	const productSizes 						= cartItem.product.productSizes;
	const [ quantity, setQuantity ] 		= useState( null );
	const [ productSize, setProductSize ] 	= useState( '' );
	cartItem.product.size 					= productSize;	// 
	cartItem.quantity 						= quantity;
	const { isItemInCart, clearCart } 		= useContext( CartContext );
	const minQuantity 						= 1;
	const [ maxQuantity, setMaxQuantity ] 	= useState( 0 );	// will b update wen prodduct size will be chosen
	
	// Selection changes
	const onChange 	= async ( { target: { value } } ) => {
		// set  product selected size
		await setProductSize( value );
		// get the quantity for the selected size
		const maxValue = getSizeQuantity( value );
	
		const minValue = maxValue == 0 ? 0 : 1;
		await setMaxQuantity( maxValue );
		// set selected product size's quantity
		const inCart = await isItemInCart( cartItem );
		if( inCart ){	// if the product exists in the cart for the selected size, get the quantity
			const sizeQuantity = await inCart.quantity <= maxValue ? inCart.quantity : maxQuantity;
			setQuantity( sizeQuantity );
		}
		else{
			// set  product selected size's quantity
			setQuantity( minValue );
		}
	};
	
// 	console.log( '--------------- *****************  ShopItemQuantity ***************************' );

	// Get product quantity of the selected size
	const getSizeQuantity = ( productSize ) => {
		//if( !productSize )
		//	return 1;
		const sizes 	= cartItem.product.itemSizes;

		const sizeQty 	= sizes.find( ( size ) =>
			size.label == productSize
		)

		return sizeQty.value
	}
	
	// Antd notifications
	notification.config({
		placement: 'bottomRight',
		top: 150,
	});

	const openNotificationErrorMaxQuantity = ( message ) => {		// Error max quantity
		notification.warning({
			message: `${message}`,
			duration: 6,
			description: 'C\'était la dernière pièce en stock du produit ' + cartItem.product.name + ' taille ' + productSize + '.',
			placement: 'topRight',
		});
	};

	const openNotificationErrorSoldOut = ( message ) => {		// Error max quantity
		notification.warning({
			message: `${message}`,
			duration: 9,
			description: 'Le produit ' + cartItem.product.name + ' taille ' + productSize + ' a été sollicité par beaucoup il est actuelement en rupture de stock.',
			placement: 'topRight',
		});
	};

	
	const openNotificationErrorNoSize = ( message ) => {			// Error no size selected
		notification.error({
			message: `${message}`,
			duration: 3,
			description: 'Veuillez d\'abord choisir une taille',
			placement: 'topRight',
		});
	};


	// add quantity
	const handleClickQtyUp = () => {
		if( !productSize ) {
			openNotificationErrorNoSize( 'Attention' )
			return
		}
		
		if( maxQuantity == 0  ) {		
			openNotificationErrorSoldOut( 'Stock épuisé!' )
			return
		}
		
		if( quantity >= maxQuantity ) {		
			openNotificationErrorMaxQuantity( 'Fini!' )
			return
		}
		setQuantity( quantity + 1 );
	}
	
	// remove quantity
	const handleClickQtyDown = () => {
		if( quantity <= minQuantity ) {
			return
		}
		if( !productSize ) {
			openNotificationErrorNoSize( 'Attention' )
			return
		}
		setQuantity( quantity - 1 );
		
	}
	
	useEffect(() => {

	}, []);
	// return the jsx code
	return (
        <div>
			<figcaption>Choisir une taille</figcaption>
			<RadioGroup 
				size 		= 'large'
				options		= { productSizes }
				onChange	= { onChange }
				value		= { productSize }
				optionType	= 'button'
				buttonStyle	= 'solid'
			/>

			<div className="ps-product__btn-group">
                <figure>
                    <figcaption>Choisir une quantité</figcaption>
					<div className="form-group--number">
						<button className="up" onClick={ handleClickQtyUp }><i className="fa fa-plus"></i></button>
						<button className="down" onClick={ handleClickQtyDown }><i className="fa fa-minus"></i></button>
						<input 
							id			= 'itemQuantity' 
							className	= 'form-control' 
							type		= 'text' 
							placeholder	={ quantity } 
						/>
					</div>
					<ShopBtnAddToCart params =
						{{
							selectedSize: 	productSize, 
							cartItem:		cartItem,
							quantity:		quantity,
						}}
					/>
                </figure>             
            </div>
        </div>
	);
};

export default ShopItemSize;
