import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import { AuthContext } from "../context/AuthProvider";
import { SiteContext } from "../context/site";
import { Form, Input, Select } from 'antd';
import { Space, Spin, Button, notification, message, Popconfirm } from 'antd';
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
	LoadingOutlined
} from '@ant-design/icons';

import useDidMountEffect from './useDidMountEffect';

const PasswordForgetRestore = ( params ) => {
	
	// user email
	const { getUser, isValidPassword }	= useContext( AuthContext );
	const email = getUser().email;
	
	// mot de passe
	const [ motDePasse, setMotDePasse ] = useState( '' );
	const [ motDePasseError, setMotDePasseError ] = useState( false );
	const handleChangeMotDePasse = ( e ) => {	
		setMotDePasse( e.target.value );
		if( !e.target.value ){
			setMotDePasseError( 'Saisir un mot de passe.' );
		}
		else if( isValidPassword( e.target.value ) !== true ) {
			setMotDePasseError( 'Le mot de passe doit avoir 6 à 100 caractères, lettres majuscules et minuscules et au moins un chiffre.' );
		}
		else{
			setMotDePasseError( false );
		}
	}
	
	// password forget restore spin
	const [ passwordForgetRestoreSpin, setPasswordForgetRestoreSpin ] = useState( 'none' );
	
	// confirmer mot de passe
	const [ confirmerMotDePasse, setConfirmerMotDePasse ] = useState( '' );
	const [ confirmerMotDePasseError, setConfirmerMotDePasseError ] = useState( false );
	const handleChangeConfirmerMotDePasse = ( e ) => {	
		setConfirmerMotDePasse( e.target.value );
		
		if( motDePasse != e.target.value ) {
			setConfirmerMotDePasseError( 'Les mots de passe doivent être identiques.' );
		}
		else{
			setConfirmerMotDePasseError( false );
		}
	}
	
	// const regexPasswordValidation = [\d+a-z+A-Z+!*\-*\+*&*é*à*ç*è*\'*\(*\)*ç*]{5,100}
	
	// post data definition
	async function postData( url, data, method ) {
		const response = await fetch( url, {
			method: "POST", // *GET, POST, PUT, DELETE, etc.
			// mode: "no-cors", // no-cors, *cors, same-origin
			headers: {
				"Content-Type": "application/json",
				// 'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: JSON.stringify( data ), // body data type must match "Content-Type" header
		});
		
		if( response.status !== 200 ){
			return false
		}
		else{
			return true; // parses JSON response into native JavaScript objects
		}
		
	}
	
	const navigate 	= useNavigate();
	const handleClickPasswordRestore = async ( event ) => {
		event.preventDefault();
		
		setPasswordForgetRestoreSpin( 'block' );
		
		resetAllErrors();
		
		// validation connexion email
		if( !motDePasse ){
			setPasswordForgetRestoreSpin( 'none' );
			message.error( 'Saisir un mot de passe.' );
			setMotDePasseError( 'Saisir un mot de passe.' );
			return
		}
		// validate password
		if( isValidPassword( motDePasse ) !== true ) {
			setPasswordForgetRestoreSpin( 'none' );
			message.error( 'Le mot de passe doit avoir 6 à 100 caractères, lettres majuscules et minuscules et au moins un chiffre.', [6] );
			setMotDePasseError( 'Le mot de passe doit avoir 6 à 100 caractères, lettres majuscules et minuscules et au moins un chiffre.' )
			return
		}
		// validate password repeat
		if( motDePasse != confirmerMotDePasse ) {
			setPasswordForgetRestoreSpin( 'none' );
			message.error( 'Les mots de passe doivent être identiques.' );
			setConfirmerMotDePasseError( 'Les mots de passe doivent être identiques.' );
			return
		}

		// restore the password
		const passwordRecoverApiURL = 'https://allkakou.com/api/authentication/recover-password-up';
		const passwordRecoverData = {
			id: '',
			username: '',
			password: confirmerMotDePasse,
			email: email
		};
		
		const method = 'POST';
		
		const resp = await postData( passwordRecoverApiURL, passwordRecoverData, method );
		if( !resp ){
			setPasswordForgetRestoreSpin( 'none' );
			message.error( 'Vérifier votre connexion internet svp.' );	
			return;
		}

		setPasswordForgetRestoreSpin( 'none' );
		message.success( 'Votre mot de passe a été modifié.' );
				
		// Login redirect
		const gotoUrl = '/connexion';
		navigate( gotoUrl );

	}
	
	const [form] = Form.useForm();
	
	// reset all the error messages
	const resetAllErrors = () => {
		setMotDePasseError( false );
		setConfirmerMotDePasseError( false );
	}
	
	// run after the first render
	useDidMountEffect(() => {
        form.validateFields();
    },  [ motDePasseError ] );
	useDidMountEffect(() => {
        form.validateFields();
    },  [ confirmerMotDePasseError ] );

	useEffect(() => {
		setPasswordForgetRestoreSpin( 'none' );
	}, []);
	
	return (
		<>
			<Header />
				<div className="container">
				<div class="ps-section__header" style={{textAlign:'center', marginTop: '25px'}}>
                    <h3>Restauration de votre Mot de Passe</h3>
                </div>
				<Form 
						style 		= {{ paddingTop: '25px' }} 
						className 	= "ps-form--account ps-tab-root" 
						action 	= "" 
						method 	= "get"
						layout 	= "vertical"
						form	= {form}
				>
                    <div className="ps-tabs">
						<div className="ps-form__content">
							<h5>Taper votre nouveau Mot de Passe</h5>
							<div className="form-group">
								<Form.Item
									name  = "motDePasse"
									rules={[
										{
											message: motDePasseError,
											validator: ( value ) => {
												if ( motDePasseError === false ) {
													return Promise.resolve();
												} 
												else {
													return Promise.reject( motDePasseError );
												}
											}
										}
									]}
								>
									<Input 
										value={motDePasse} 
										onChange={handleChangeMotDePasse}
										className="form-control" 
										type="password" 
										placeholder="Nouveau Mot de Passe" 
									/>
								</Form.Item>
							</div>
							<div className="form-group form-forgot">
								<Form.Item
										name  = "motDePasseConfirmation"
										rules={[
											{
												message: confirmerMotDePasseError,
												validator: ( value ) => {
													if ( confirmerMotDePasseError === false ) {
														return Promise.resolve();
													} 
													else {
														return Promise.reject( confirmerMotDePasseError );
													}
												}
											}
										]}
									>
										<Input 
											value={confirmerMotDePasse}
											className="form-control" 
											type="password" 
											placeholder="Confirmer le Mot de passe" 
											onChange={handleChangeConfirmerMotDePasse}
										/>
								</Form.Item>
							</div>
							
							<div className="form-group submtit">
                                    <button 
										className="ps-btn ps-btn--fullwidth"
										onClick={handleClickPasswordRestore}
									>
										<Space>
											<Spin
												indicator={
													<LoadingOutlined
														style={{
															fontSize: 		20,
															marginRight: 	'10px',
															display:		passwordForgetRestoreSpin
														}}
														spin
													/>
												}
											/>
										</Space>
										Modifier le Mot de Passe
									</button>
                                </div>
                            </div>
							<p>&nbsp;</p>
                    </div>
                </Form>
				<Newsletter />
            </div>
			<Footer />
		</>
	);
};

export default PasswordForgetRestore;
