// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from "./reportWebVitals";
import { CartProvider } from "./context/cart"
import { AuthProvider } from "./context/AuthProvider";
import { SiteProvider } from "./context/site";
import { StoreProvider } from "./context/store";
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<AuthProvider>
			<SiteProvider>
				<StoreProvider>
					<CartProvider>
						<App />
					</CartProvider>
				</StoreProvider>
			</SiteProvider>
		</AuthProvider>
	</React.StrictMode>
);


reportWebVitals();
