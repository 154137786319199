import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';

const Shops = ( params ) => {

	
	return (
		<>
			<div className="ps-home-ads">
           <div className="ps-promotions">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
						<Link 
							title		= "visiter la boutique U.S Polo ASSN" 
							className	= "ps-collection" 
							to			= "/boutiques/us-polo-assn"
						>
							<img src="img/brand/uspolo_570x210.png" alt="" />
						</Link>
                    </div>
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
						<Link 
								title		= "visiter la boutique Reason" 
								className	= "ps-collection" 
								to			= "/boutiques/reason"
							>
								<img src="img/brand/reason_570x210.jpg" alt="" />
						</Link>
                    </div>
                </div>
				<br />
				<div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
						<Link 
								title		= "visiter la boutique Levi Strauss" 
								className	= "ps-collection" 
								to			= "/boutiques/levis"
							>
								<img src="img/brand/levis_570x210.png" alt="" />
						</Link>
                    </div>
                </div>
            </div>
        </div>
        </div>
		</>
	);
};

export default Shops;
