import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import BlogMenu from './BlogMenu';
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import SocialBar from "./SocialBar";
import useDidMountEffect from './useDidMountEffect';
import { SiteContext } from '../context/site';
import { Form, Space, Spin, Button, notification, message, Popconfirm, Select, Radio, Flex, DatePicker } from 'antd';

const ContactPage = ( params ) => {

	// name
	const [ name, setName ] = useState( '' );
	const [ nameError, setNameError ] = useState( false );
	const handleChangeName = ( e ) => {
		const data = e.target.value;
		setNameError( '' );
		setName( data )
	}

	// email
	const [ email, setEmail ] 		= useState( '' );
	const [ emailError, setEmailError ] = useState( false );
	const handleChangeEmail = ( e ) => {
		const data = e.target.value;
		setEmailError( '' );
		setEmail( data )
	}

	// subject
	const [ subject, setSubject ] 	= useState( '' );
	const [ subjectError, setSubjectError ] = useState( false );
	const handleChangeSubject = ( e ) => {
		const data = e.target.value;
		setSubjectError( '' );
		setSubject( data )
	}

	// messageText
	const [ messageText, setMessageText ] 	= useState( '' );
	const [ messageTextError, setMessageTextError ] = useState( false );
	const handleChangeMessageText = ( e ) => {
		const data = e.target.value;
		setMessageTextError( '' );
		setMessageText( data )
	}

	// send
	const handleClickSend = async() => {
		// name
		if( name == '' ){
			const errorMsg = 'Tapez votre nom';
			setNameError( errorMsg );
			return
		}
		else if( name.length < 3 ){
			const errorMsg = 'Nom invalide';
			setNameError( errorMsg );
			return
		}

		// email
		const regexEmailValidation = /^[a-zA-Z0-9. _-]+@[a-zA-Z0-9. -]+\.[a-zA-Z]{2,4}$/; 
		if( email == '' ){
			const errorMsg = 'Tapez votre adresse email';
			setEmailError( errorMsg );
			return
		}
		else if( !regexEmailValidation.test( email ) ){
			const errorMsg = 'Adresse email invalide';
			setEmailError( errorMsg );
			return
		}

		// subject
		if( subject == '' ){
			const errorMsg = 'Tapez le sujet du mesage';
			setSubjectError( errorMsg );
			return
		}
		else if( subject.length < 3 ){
			const errorMsg = 'Subject invalide';
			setSubjectError( errorMsg );
			return
		}

		// messageText
		if( messageText == '' ){
			const errorMsg = 'Tapez un messageText';
			setMessageTextError( errorMsg );
			return
		}
		else if( messageText.length < 5 ){
			const errorMsg = 'Votre message n\'est pas  invalide';
			setMessageTextError( errorMsg );
			return
		}
		
		const rep = await send();		
	}

	// Errors
	const [form] = Form.useForm();	

	// run after the first render
	useDidMountEffect(() => {
        form.validateFields();
    },  [ nameError ] );
	useDidMountEffect(() => {
        form.validateFields();
    },  [ emailError ] );
	useDidMountEffect(() => {
        form.validateFields();
    },  [ subjectError ] );
	useDidMountEffect(() => {
        form.validateFields();
    },  [ messageTextError ] );

	// send Email
	const [ countMailSent, setCountMailSent ] = useState( 0 );
	const { sendEmail } = useContext( SiteContext );
	const html 	= "------- Nom: " + name + "        " + "------- Email: " + email + "        " + "------- Subject: " + subject + "        " + "------- MessageText: " + messageText;
	
	const send = async () => {
		if( countMailSent >= 3 ){
			const errorText = 'Vous ne pouvez plus envoyer de mail. Reesayez plus tard!';
			message.error( errorText )
			return;
		}
		
		const rep = await sendEmail( html );
		console.log( 'Rep', rep );
		if( rep == true )
			message.success( 'Votre courriel a bien été reçu, nous vous repondrons bientôt!' )
		else
			message.error( 'Erreur. Veuillez ressayer.' )

		const count = countMailSent + 1;
		setCountMailSent( count );
	}

	return (
		<>
			<Header />
				<div className="ps-page--single" id="contact-us">

					<div className="ps-contact-info">
						<div className="container">
							<div className="ps-section__header">
								<h3>Contactez-nous pour toute vos questions</h3>
							</div>
							<div className="ps-section__content">
								<div className="row">
									<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 ">
										<div className="ps-block--contact-info">
											<h4>Contact Direct</h4>
											<p><a href="#">info@237usa.com</a><span>+237 699 97 29 41</span></p>
										</div>
									</div>
									<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 ">
										<div className="ps-block--contact-info">
											<h4>Quartier Général</h4>
											<p><span>3383 Gandy Street Syracuse, NY 13202</span></p>
										</div>
									</div>
									<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 ">
										<div className="ps-block--contact-info">
											<h4>Travaillez avec nous</h4>
											<p><span>Envoyer vos CV à:</span><a href="#">career@237usa.com</a></p>
										</div>
									</div>
									<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 ">
										<div className="ps-block--contact-info">
											<h4>Service client</h4>
											<p><a href="#">serviceclient@237usa.com</a><span>+237 699 97 29 41</span></p>
										</div>
									</div>
									<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 ">
										<div className="ps-block--contact-info">
											<h4>Media / Presse</h4>
											<p><a href="#">media@237usa.com</a><span>+237 699 97 29 41</span></p>
										</div>
									</div>
									<div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 ">
										<div className="ps-block--contact-info">
											<h4>Points de recupération partenaires</h4>
											<p><a href="#">partenariat@237usa.com</a><span>+237 699 97 29 41</span></p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="ps-contact-form">
						<div className="container">
							<Form 
								className="ps-form--contact-us" 
								action="index.html" 
								method="get"
								form	= {form}
							>
								<h3>Contactez-nous</h3>
								<div className="row">
									<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
										<div className="form-group">
											<Form.Item
												name  = "name"
												
												rules = {[
													{
														messageText: nameError,
														validator: ( value ) => {
															if ( nameError === false ) {
																return Promise.resolve();
															} 
															else {
																return Promise.reject( nameError );
															}
														}
													}
												]}
											>
												<input 
													value 		= { name }
													onChange 	= { handleChangeName }
													className	= "form-control" 
													type		= "text" 
													placeholder	= "Nom *"
												/>
											</Form.Item>
										</div>
									</div>
									<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 ">
										<div className="form-group">
											<Form.Item
												name  = "email"
												
												rules = {[
													{
														messageText: emailError,
														validator: ( value ) => {
															if ( emailError === false ) {
																return Promise.resolve();
															} 
															else {
																return Promise.reject( emailError );
															}
														}
													}
												]}
											>
												<input 
													value 		= { email }
													onChange 	= { handleChangeEmail }
													className="form-control" 
													type="email" 
													placeholder="Email *" 
												/>
											</Form.Item>
										</div>
									</div>
									<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
										<div className="form-group">
										<Form.Item
											name  = "subject"
											
											rules = {[
												{
													messageText: subjectError,
													validator: ( value ) => {
														if ( subjectError === false ) {
															return Promise.resolve();
														} 
														else {
															return Promise.reject( subjectError );
														}
													}
												}
											]}
										>
											<input
												value 		= { subject }
												onChange 	= { handleChangeSubject }
												className	="form-control" 
												type		="text" 
												placeholder	= "Sujet *" 
											/>
										</Form.Item>
										</div>
									</div>
									<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
										<div className="form-group">
											<Form.Item
												name  = "messageText"
												rules = {[
													{
														messageText: messageTextError,
														validator: ( value ) => {
															if ( messageTextError === false ) {
																return Promise.resolve();
															} 
															else {
																return Promise.reject( messageTextError );
															}
														}
													}
												]}
											>
												<textarea 
													value 		= { messageText }
													onChange 	= { handleChangeMessageText }
													className	= "form-control" 
													rows		= "5" 
													placeholder	= "MessageText *" 
												>
												</textarea>
											</Form.Item>
										</div>
									</div>
								</div>
								<div>
									
									<button 
										className 	= "ps-btn"
										onClick 	= { handleClickSend } 
									>
										Envoyer
									</button>
								</div>
							</Form>
						</div>
					</div>
				</div>	
			<Newsletter />
			<Footer />
		</>
	);
};

export default ContactPage;
