import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import Newsletter from "./Newsletter";
import ShopSelection from "./ShopSelection";
import ShopCategoriesList from "./ShopCategoriesList";
import ShopCategory from "./ShopCategory";
import Header from "./Header";
import Footer from "./Footer";

const ShopSelections = ( params ) => {
	return (
		<>
		<Header/>
		<div id="homepage-1">
        <div class="ps-banner--organic">
			<br />
			<img src="../img/testBan02.jpg" alt="" /></div>
		<div className="ps-product-list ps-clothings">
            <p></p>
        </div>
		<div className="ps-product-list ps-clothings">
            <div className="ps-container">
                <ShopSelection params= {{
					name: 	'nouveau produits',
					title: 	'Arrivages récents',
				}} />
            </div>
        </div>
        <div className="ps-product-list ps-clothings">
            <div className="ps-container">
                <ShopSelection params= {{
					name: 	'sac a dos',
					title: 	'Sac à dos, prix spécial rentrée',
				}} />
            </div>
        </div>
        <div className="ps-product-list ps-clothings">
            <div className="ps-container">
                <ShopSelection params= {{
					name: 	'derniere chance',
					title: 	'Dernière chance, plus q\'une pièce',
				}} />
            </div>
        </div>

		<div className="ps-product-list ps-clothings">
            <div className="ps-container">
				<ShopSelection params = {{
					name: 	'US Jeans',
					title: 	'Sélection de Jeans originaux US',
				}}/>
            </div>
        </div>
			
		</div>
		<Newsletter />
		<Footer />
		</>
	);
};

export default ShopSelections;
