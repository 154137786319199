import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import { StoreContext } from '../context/store';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

const FilterByColorButton = ( params ) => {

	// context custom hook
	const { colorList, colorFiltered, getColorCode } = useContext( StoreContext );

	const[ colors, setColors ]  = useState( [] ); 
	// build colors filter
	const BuildColorFilter = () => {
		return 	colors.map( ( color, index ) => 
		<div className='row' >
			<div className='row' style	= {{ marginLeft: '6%', cursor: 'pointer' }} onClick = { () => handleClickColor ( color.originalName ) }>
				{ color.colorName }
			</div>
		</div>
		)
	}

	// handle color click
	const handleClickColor = ( originalColorName ) => {	
		colorFiltered( originalColorName );
	}

	// display all
	const handleClickAll = () => {
		colorFiltered();
	}

	useEffect( () => {
		// remove duplicates
		var myColors 		= [];
		var myUniqueColors 	= [];
		const getColorInfo = async() => {
			const data = await getColorCode( colorList );

			data.map( ( colorObj ) => {

				if( !myColors.includes( colorObj.originalName ) ){
					myUniqueColors.push( colorObj );
					myColors.push( colorObj.originalName )
				}
			})
			setColors ( myUniqueColors );
		}
		getColorInfo();

// console.log( 'myColors', myColors );
// console.log( 'myUniqueColors', myUniqueColors );

		
	}, [ colorList ] ); 

	return (
		<>
			<BuildColorFilter />
			<p><Link onClick={ handleClickAll }>Toutes les couleurs</Link></p>
		</>
	);
};

export default FilterByColorButton;
