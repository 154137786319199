import React, { useState, useEffect, useContext } from "react";
import { CartContext } from '../context/cart';
import { SiteContext } from '../context/site';
import { useNavigate, useLocation } from 'react-router-dom';
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import Currency from "./Currency";
import { Link } from "react-router-dom";

import MySpaceMenu from './MySpaceMenu';
import image01 from '../images/vendor-1.png';
import image02 from '../images/vendor-2.png';
import image03 from '../images/vendor-3.png';
import { AuthContext } from "../context/AuthProvider";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import MySpaceAdresseAddModal from './MySpaceAdresseAddModal';
import { Form, Input, Select } from 'antd';
import type { PopconfirmProps } from 'antd';
import { Space, Spin, Button, notification, message, Popconfirm } from 'antd';
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
	LoadingOutlined
} from '@ant-design/icons';

import useDidMountEffect from './useDidMountEffect';

const MySpaceAdresse = () => {
	
	//
	const { createUserAdresse, updateUserAdresse, getUserAdresses, getVilles, deleteUserAdresse }  = useContext( SiteContext );
	const { getUser }	= useContext( AuthContext );
	const userId = getUser().id;
	
	// 
	const [ adresses, setAdresses ] = useState( [] );
	const [ villes, setVilles ] 	= useState( [] );
	const [ quarter, setQuarter ] 	= useState( '' );

	//
	const [ countUserAdresses, setCountUserAdresses ] = useState( 0 );

	const navigate 			= useNavigate();

	const handleClickDelete = async( adresseId ) => {
		const obj = { 
			adresseId: adresseId,
		}
		const rep = await deleteUserAdresse( obj );
		
		if( !rep ){
			message.error( 'Adresse non trouvée' );
			return;
		}
		
		// get the user adresses
		const data = await getUserAdresses( userId );
		setAdresses( data );
		setCountUserAdresses( data.length );

	}

	const cancel: PopconfirmProps['onCancel'] = (e) => {
		console.log( 'Click on No' );
	};

	const MesAdresses = () => {
			return (
				adresses.map(
					( useradresse, key ) =>
						<tr key={key}>
							<td>
								{ useradresse.ville }
							</td>
							<td>
								{ useradresse.quartier }
							</td>
							<td>
								{ useradresse.description }
							</td>
							<td>
								<Button 
									index			= { 'editer' }
									title   		= 'Editer'
									data-adressid	= { useradresse.id }
									onClick 		= { ( e ) => handleClickAddAdresse( useradresse.id ) }
								>
									<i className="fa fa-pencil"></i>
								</Button>&nbsp;&nbsp;

								<Popconfirm
									title		= "Supprimer"
									description	= "Voulez-vous vraiment supprimer cette adresse?"
									onConfirm	= { ( e ) => handleClickDelete( useradresse.id ) }
									onCancel	= { cancel }
									okText		= "Oui"
									cancelText	= "Non"
								>
									<Button 
										title='Suprimer'
										index	= { 'suprimer' }
									>
										<i className="fa fa-trash-o text-danger"></i>
									</Button>
								</Popconfirm>
							</td>
						</tr>
				)
			)
		
	}

	// Modal
	const [ openAdresseModal, setOpenAdresseModal ] = useState(false);
	const onOpenAdresseModal  		= () => setOpenAdresseModal(true);
	const onCloseAdresseModal 		= async () => {
		const data = await getUserAdresses( userId );
		setAdresses( data );
		setCountUserAdresses( data.length );
		
		setOpenAdresseModal(false)
	};

	// Click to add / edit adresse
	const [ adresse, setAdresse ] = useState( {} );	// new
	const handleClickAddAdresse = async ( adressid ) => {

		if( !adressid && countUserAdresses >= 3 ){
			message.error( 'Vous avez atteint le nombre maximum d\'adresses.' );
			return;
		}
		
		if( !adressid ){
			setAdresse( {} );
		}
		
		if( adressid ){
			const data = await adresses.filter( e => e.id == adressid  );
			setAdresse( data[0] );
		}
		
		setOpenAdresseModal(true);
	}

	useEffect( () => {
		// get the villes
		const getVillesData = async () => {
			const data = await getVilles();
			setVilles( data );
		}
		getVillesData();  
		
		// get the user adresses
		const getAdresses = async ( userId ) => {
			const data = await getUserAdresses( userId );
			setAdresses( data );
			setCountUserAdresses( data.length );
		}
		getAdresses( userId );  
		
	}, [] );
	
	return (
	<>
		
		<Modal open={ openAdresseModal } onClose={ onCloseAdresseModal } center>
			<MySpaceAdresseAddModal params =
				{{
					userId: userId,
					villes: villes,
					adresse: adresse
				}}
			/>
		</Modal>
		
		<Header />
		<main className="ps-page--my-account">
        <section className="ps-section--account">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="ps-section__left">
                            <MySpaceMenu />
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="ps-section__right">
                            <div className="container" style={{minWidth: '0px'}}>
                <div className="ps-section__header">
					<h3>Vos Adresses de Livraison </h3>
					<p>Achetez et faites vous livrer plus rapidement en créant une ou plusieur adresses de livraison.</p>
                    <p>
						<button 
							index			= { 'ajouter' }
							className 		= "ps-btn ps-btn--fullwidth"
							data-adressid	= {0}
							onClick   		= { ( e ) => handleClickAddAdresse( 0 ) }
						>
							<i className="fa fa-plus"></i>&nbsp;Ajouter une Adresse
						</button>
					</p>
					{ countUserAdresses ?  
						<table class="table" >
                                            <thead>
                                                <tr>
                                                    <th><b>Ville</b></th>
                                                    <th><b>Quartier</b></th>
                                                    <th><b>Details</b></th>
													<th>&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <MesAdresses />
                                            </tbody>
						</table>
					:
						<p>
						Aucune adresse enregistrée.
						</p>
					}
					
                </div>
            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
		<Newsletter />
    </main>
	<Footer />
		</>
	);
};

export default MySpaceAdresse;
