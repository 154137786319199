import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import BlogMenu from './BlogMenu';
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import SocialBar from "./SocialBar";
const BlogPage = ( params ) => {

	
	return (
		<>
		<Header />

        <div class="ps-faqs">
            <div class="container">
                <div class="ps-section__header">
                    <h1>Questions Fréquement Posées</h1>
                </div>
                <div class="ps-section__content">
                    <div class="table-responsive">
                        <table class="table ps-table--faqs">
                            <tbody>
                                <tr>
                                    <td class="heading" rowspan="1">
                                        <h4>VOS COMMANDES</h4>
                                    </td>
                                    <td class="question"> Peut on utiliser un smartphone pour commander?</td>
                                    <td>Oui, vous pouvez acceder à votre boutique en lighe avec un ordinateurs ou un téléphone portable.</td>
                                </tr>
                                <tr>
                                    <td class="question">
										<h4>PAIEMENT</h4>
									</td>
                                    <td>Quel méthodes de paiement sont acceptée?</td>
									<td>Nous acceptons les paiements Orange Money et MTN Mobile Money.</td>
                                </tr>
                                <tr>
                                    <td class="heading" rowspan="2">
                                        <h4>LIVRAISON</h4>
                                    </td>
                                    <td class="question"> Comment rentrer en pocesion de ses achats effectués en ligne?</td>
                                    <td>Sur la page de Commande, vous ouvez choisir le mode de recupération de vos achats: la livraison ou la recupération. Vous pouvez appeler le numéro d'assistance à tout moment: +237 699 97 29 41.</td>
                                </tr>
                                <tr>
                                    <td class="question">Apres combien de temps mes achats seront-ils dispobibles?</td>
                                    <td>Le lendemain de votre achat, vous pourrez passer dans le point de récupération choisis pour retirer votre colis. Si vous avez choisis la livraison, un coursier passera vous livré à la date et au lieu convenu ç partir de 24h apres avoir passé votre commande.</td>
                                </tr>
                            </tbody>
							< SocialBar />
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="ps-call-to-action">
            <div class="container">
                <h3>Une question? <Link href="/contactez-nous"> Contactez-nous</Link></h3>
            </div>
        </div>
    
	<Newsletter />
	<Footer />
		</>
	);
};

export default BlogPage;
