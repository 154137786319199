import React, { useState, useEffect, useContext } from "react";
import { CartContext } from '../context/cart';
import { SiteContext } from '../context/site'; 
import Currency from "./Currency";
import MenuLogin from './MenuLogin';
import ProductCartCount from "./ProductCartCount";
import "../cartOverrides.css";
import { Button, message, Popconfirm } from 'antd';
import { useNavigate, Link, useLocation  } from 'react-router-dom';

const ShopCart = () => {
	
	const { cartItems, getCartTotal, removeFromCart, clearCart, getCartProductCount } = useContext( CartContext );
	const { siteURL } = useContext( SiteContext );
	
	const products 		= cartItems;
	const cartTotal 	= getCartTotal();
	const total 		= cartTotal.toString().replace(/(?=(?:.{3})*$)/g, ' ').trim();
	const productTotal 	= getCartProductCount();
	const [ showVider, setShowVider ] = useState( getCartProductCount );
	
	// Antd
	const confirmRemove = ( product )  => {
		removeFromCart( product );
		message.success( 'Un article a été supprimé de votre panier' );
	};
	const confirmRemoveAll = ()  => {
		clearCart();
		message.success( 'Votre panier a été vidé!' );
	};
	const cancel = ()  => {
	};
	
	// click on the cart button
	const navigate = useNavigate();
	const handleClickPanier = () => {
		if( !productTotal )
			message.info( 'Votre panier est vide. Bon shopping!' );
		else
			navigate( '/panier' );
			
	}

	const BuildCartProduct = () => {
		return (
			products.map( ( product, index ) =>
				<div key={ index } className="ps-product--cart-mobile">
					<div className="ps-product__thumbnail">
						<Link to={ product.product.path } >
							<img src={ siteURL + '/images/products/' + product.product.picture } alt="" />
						</Link>
					</div>
					<div className="ps-product__content">
						<Popconfirm
							title="Supprimer un produit"
							description={ 'Voulez vous supprimer ' + product.product.name + ', taille ' + product.product.size + ' de votre panier?'}
							onConfirm={ () => confirmRemove( product ) } 
							onCancel={cancel}
							okText="Oui"
							cancelText="Non"
						>
							<a className="ps-product__remove" style={{ cursor: 'pointer' }} >
								<i className="icon-cross"></i>
							</a>
						</Popconfirm>
						<Link to={ product.product.path }>
							{ product.product.name }
						</Link>
						<p>
							<small>
								Size: { product.product.size }
							</small>
							<br/>
							<small>
								{ product.quantity } x { product.product.price }< Currency />
							</small>
						</p>
					</div>
				</div>
			)
		)
	}
	
	return (
		<>
			<div className="header__actions">&nbsp;
                        <div className="ps-cart--mini">
							<Link className="header__extra" onClick= { e => handleClickPanier() } >
								<i className="icon-bag2"></i>
								<span>
									<i><ProductCartCount /></i>
								</span>
							</Link>
						{ productTotal != 0 
							?
                            <div className="ps-cart__content">
                                <div className="ps-cart__items">
                                    <BuildCartProduct />
                                </div>
								<div className="ps-cart__footer">
									<h3>Total:<strong>{ total }<Currency /></strong></h3>
									<figure>
										<Popconfirm
											title="Vider tout le panier"
											description="Voulez vous vider votre panier?"
											onConfirm={ () => confirmRemoveAll() } 
											onCancel={ () => cancel() }
											okText="Oui"
											cancelText="Non"
										>
											<a style={{ color:'white' }} className="ps-btn ps-btn--black">Vider</a>&nbsp;
											
										</Popconfirm>
										<Link to='/commander' style={{ color:'white' }} className="ps-btn" >
											Commander
										</Link>
									</figure>
								</div>
                            </div>
							:
							<div className="ps-cart__content">
								<div className="ps-cart__footer">
									<span>Panier vide. Achettez dans notre boutique.</span>
								</div>
							</div>
						}
                        </div>
                        <MenuLogin />
            </div>
		</>
	);
};

export default ShopCart;
