import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';

import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import SocialBar from "./SocialBar";
import ShopSelection from "./ShopSelection";

const NewsletterThankYou = ( params ) => {
	
	return (
		<>
			<Header />
				<div class="ps-page--404">
						<div class="ps-section__content">
							<h3>Merci pour votre Abonnement!</h3>
						</div>
						<div className="ps-product-list ps-clothings">
								<div className="ps-container">
									<ShopSelection params= {{
										name: 	'sac a dos',
										title: 	'Sac à dos, prix spécial rentrée',
									}} />
								</div>
							</div>
				</div>
			<Newsletter />
			<Footer />
		</>
	);
};

export default NewsletterThankYou;
