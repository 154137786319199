import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import BlogMenu from './BlogMenu';
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import SocialBar from "./SocialBar";

import { Form, Input, Select } from 'antd';

const BlogPage = ( params ) => {

	
	return (
		<>
		<Header />
			<div className="ps-page--blog" style={{ marginTop: '25px' }}>
        <div className="container">
            <div className="ps-blog--sidebar">
                <div className="ps-blog__left">
                    <div className="ps-post--detail sidebar">
                        <div className="ps-post__header">
                            <h1>Bienvenue sur 237usa.com</h1>
                            <p>17 Décembre 2023 / par Jane, 237usa.com / dans <a href="#">Technologies</a></p>
                        </div>
                        <div className="ps-post__content">
                            <h4>L'Afrique devrait dépasser le demi-milliard d'utilisateurs de commerce électronique d'ici 2025, ce qui ont montré un taux de croissance annuel composé (TCAC) stable de 17 % des consommateurs en ligne pour le marché.</h4>
                            <p>Les méthodes de paiement par carte de débit représentent 10 % de la population, tandis que les taux de possession de cartes de crédit sont faibles, avec une moyenne de 2 % pour l'ensemble du continent. Les paiements en ligne restent donc un défi permanent pour les entreprises souhaitant cibler les consommateurs de commerce électronique sur les marchés africains. Les produits de mode et d'électronique génèrent les revenus de vente les plus élevés parmi les acheteurs en ligne sur les marchés africains, les produits de mode devant atteindre 13,4 milliards de dollars de ventes et les produits électroniques devant atteindre 11,2 milliards de dollars de ventes annuelles d'ici 2025.</p>
                            <blockquote className="ps-blockquote">
                                <p>“L'Afrique est en tête de la génération de trafic Web sur appareils mobiles, avec 69 % de son trafic Web total constitué d'utilisateurs Internet mobiles en 2021 et devrait être presque exclusivement un marché basé sur mobile d'ici 2040.”</p><span className="ps-blockquote__author">trade.gov/rise-ecommerce-africa</span>
                            </blockquote>
                            <p>Selon l’Administration du commerce international, d’ici 2025, un demi-milliard de personnes en Afrique seront des utilisateurs du commerce électronique. Et selon la CNUCED, le commerce numérique pourrait ajouter 180 milliards de dollars au PIB de la région d’ici la même année. Cette croissance est tirée par l’expansion de l’Internet mobile. </p>
                            <div className="row"> </div>
                            <h4>La GSMA prévoit que 61 % des connexions en Afrique se feront sur les smartphones d'ici 2025 (33 % sur la 4G). </h4>
                            <p>L’adoption croissante des services financiers numériques, principalement de l’argent mobile, contribue également à la croissance du commerce électronique.</p>
                            
                            <p><strong>Portefeuille virtuel </strong>Un portefeuille virtuel qui conserve l'argent pour les transactions sur un appareil mobile est appelé portefeuille mobile. Il n'existe actuellement que deux fournisseurs d'argent mobile (portefeuille) dans le système de paiement numérique du Cameroun : MTN et Orange.</p>
                            <h4>Le paiement à la livraison</h4>
                            <p>Le paiement à la livraison, c'est lorsqu'un acheteur paie des biens ou des services une fois qu'ils sont reçus. En revanche, le paiement à l'avance se produit lorsque le paiement est effectué avant l'expédition des biens ou des services, par exemple dans le cadre d'une transaction de crédit de commerce électronique.</p>
                        </div>
                        <div className="ps-post__footer">
                            <SocialBar />
                        </div>
                    </div>
                </div>
                <div className="ps-blog__right">
                    <BlogMenu />
                </div>
            </div>
        </div>
    </div>
	<Newsletter />
	<Footer />
		</>
	);
};

export default BlogPage;
