import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';

const BlogMenu = ( params ) => {
	
	return (
		<>
			<aside className="widget widget--blog widget--categories">
                        <h3 className="widget__title">Comment ça marche</h3>
                        <div className="widget__content">
                            <ul>
								<li><Link to='/blog/comment-commander'>Comment commander</Link></li>
								<li><Link to='/blog/comment-payer'>Comment Payer</Link></li>
								<li><Link to='/blog/comment-recevoir-ses-achats'>Livraison et Recupération</Link></li>
								<li><Link to='/faq'>FAQ</Link></li>
                            </ul>
                        </div>
                    </aside>
                    
		</>
	);
};

export default BlogMenu;
