import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import Newsletter from "./Newsletter";
import ShopLeftMenu from "./ShopLeftMenu";
import ShoppingPageItemSimple from './ShoppingPageItemSimple';
import Header from "./Header"; 
import Footer from "./Footer"; 
import Filters from "./Filters";
import ReactPaginate from 'react-paginate';
import { StoreContext } from '../context/store';
import '../paginateOverrides.css'
		
const ShoppingPage = ( params ) => {
	
	// current link
	const location 			= useLocation();
	const currentLink 		= location.pathname;
	const rechercheResult	= currentLink.includes( 'recherche' ) ? true : false;
	const rechercheName		= !rechercheResult ? '' : currentLink.split( '/' )[ currentLink.split( '/' ).length - 1 ]
	const selectionName 	= params.params.selection;
	const categoryName		= params.params.category;
	const pageName			= params.params.pageName ? params.params.pageName : rechercheResult ? 'Résulat de votre recherche': '';
	const categoryPath		= params.params.path;
	const categories		= params.params.categories.val;	// all categories to build the left menu

	// loading message
	const [ loadingMessage, setLoadingMessage ] = useState( 'veuillez patienter ...' )
	const [ displayLoadingMessage, setDisplayLoadingMessage ] = useState( 'none' );
	
	// custom hook to get data
	const { productList, getACategoryProducts, getASelectionProducts } 	= useContext( StoreContext );
	
	// set product badge
	const getBadge = ( productPrice, productPriceSale ) => {
		const rep = ( ( productPriceSale - productPrice )/productPriceSale ) * 100;
		
		if( isNaN( rep ) || rep == 'Infinity' || rep == '-Infinity' )
			return ''
		
		return	'-' + Math.trunc( rep ) + '%'
	}
	
	// paginate
	const thumburl = 'foo.jpg';
	const [currentPage, setCurrentPage] = useState(0);
	const PER_PAGE = 24;
	const offset = currentPage * PER_PAGE;
	const currentPageData = productList
		.slice(offset, offset + PER_PAGE);

	const pageCount = Math.ceil(productList.length / PER_PAGE);
	
	function handlePageClick({ selected: selectedPage }) {
		setCurrentPage(selectedPage);
		window.scrollTo( 0, 0 );
	}
// console.log( 'productList', productList );
// console.log( 'currentPageData', currentPageData );

	// build products 
	const BuildItems = () => {
		return (
			currentPageData.map( ( product, index ) => 
				<div key  = { index }  className="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6 ">
					<ShoppingPageItemSimple 
						key  = { index } 
						item = {{
							name: 			product.productName, 
							price: 			product.productPrice.toString().replace(/(?=(?:.{3})*$)/g, ' '),
							priceOld:		product.productPriceSale ? product.productPriceSale.toString().replace(/(?=(?:.{3})*$)/g, ' ') : '',
							description: 	product.productLineDescription, 
							picture: 		product.productImageUrl ? product.productImageUrl : product.productImage[0],
							pictures: 		product.productImage,
							badge:			getBadge( product.productPrice, product.productPriceSale ),
							brand:			product.brand,
							productDescription: product.productDescription,
							productId: 		product.productId,
							productColor: 	product.productColor,
							productSize:	product.productSize,
							selection:		selectionName,
							category:		categoryName,
							recherche:		rechercheName.replace( /%20/g, ' ' ),
							categories: 	categories,
							categoryPath:	categoryPath,
							
						}}
					/>
				</div>
			)
		)
	}
	
	//
	useEffect( () => {
		// get the products
		setDisplayLoadingMessage( 'block' );
		const getProducts = async () => {
			const products =  selectionName == null ?
				await getACategoryProducts( categoryName ):
				await getASelectionProducts( selectionName );
				setDisplayLoadingMessage( 'none' );
			// setProducts ( products );
		}
		getProducts();
	}, [ params.params.pageName ] );
	
	useEffect( () => {
		setCurrentPage( 0 );
	}, [ productList ] );

	
	return (
		<>
		<Header/>
		<div className="ps-panel--sidebar" id="cart-mobile">
        <div className="ps-panel__header">
            <h3>Shopping Cart</h3>
        </div>
        
    </div>

    <div className="ps-page--shop">
        <div className="ps-container">
            <div className="">
                &nbsp;
            </div>
            
            <div className="ps-layout--shop">
                <ShopLeftMenu params = { categories } />
                <div className="ps-layout__right">
                    <div className="ps-block--shop-features">
                        <div className="ps-block__header">
                            <h3>{ pageName }</h3>
                        </div>
                        
                    </div>
                    
                    <div className="ps-shopping ps-tab-root">
                        <div className="ps-shopping__header">
                            <p><strong>{ productList.length }</strong> Produits trouvés </p>
                            <div className="ps-shopping__actions">
								<Filters />
                            </div>
                        </div>
                        <div className="ps-tabs">
                            <div className="ps-tab active" id="tab-1">
                                <div className="ps-shopping-product">
									<div className="row" style={{ display: displayLoadingMessage, marginTop: '20px', paddingLeft: '45%' }}>
										<span>{ loadingMessage }</span>
									</div>
                                    <div className="row">
										<BuildItems /> 
                                    </div>
                                </div>
                                <div className="ps-pagination">
									<ReactPaginate
										previousLabel={"← Précédent"}
										nextLabel={"Suivant →"}
										pageCount={pageCount}
										onPageChange={handlePageClick}
										containerClassName={"pagination"}
										previousLinkClassName={"pagination__link"}
										nextLinkClassName={"pagination__link"}
										disabledClassName={"pagination__link--disabled"}
										activeClassName={"pagination_active"}
									/>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <Newsletter />
	<Footer />
		</>
	);
};

export default ShoppingPage;
