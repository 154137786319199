import React, { useState, useEffect, useContext } from "react";
import Currency from "./Currency";
import ProductModalAddToCart from "./ProductModalAddToCart";
import { Modal } from 'react-responsive-modal';
import { SiteContext } from '../context/site';
import { useNavigate, Link, useLocation  } from 'react-router-dom';

import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
} from '@ant-design/icons';
import { Button, Divider, notification, Space } from 'antd';
	
const ProductStickyHeader = ( params ) => {

	const name 			= params.params.name;
	const category 		= params.params.category;
	const picture 		= params.params.picture;
	const price 		= params.params.price;
	const price_old		= params.params.price_old;
	const description 	= params.params.description;
	const cartItem		= params.params.cartItem;

	const { siteURL } 	= useContext( SiteContext );

	// ProductModalOverview state
	const [ openProductModalOverview, setOpenProductModalOverview ] = useState(false);
	const onOpenProductModalOverview  = () => setOpenProductModalOverview(true);
	const onCloseProductModalOverview = () => setOpenProductModalOverview(false);

	// Antd
	const [ api, contextHolder ] = notification.useNotification();
	const openNotification = ( message ) => {
		notification.success({
			message: `${message}`,
			duration: 3,
			description: "Ce produit a été ajouté à vote panier.",
			placement: 'topRight'
		});
	};

	// ProductModalAddToCart state
	const [ openProductModalAddToCart, setOpenProductModalAddToCart ] = useState(false);
	const onOpenProductModalAddToCart  = () => setOpenProductModalAddToCart(true);
	const onCloseProductModalAddToCart = () => setOpenProductModalAddToCart(false);

	// Handle click top
	const handleClickTop = ( e ) => {
		e.preventDefault();
		window.scrollTo(0, 0);
	}

	return (
		<>
		<Modal open={ openProductModalAddToCart } onClose={ onCloseProductModalAddToCart } center>
			<h2>{ name }</h2>
			<ProductModalAddToCart params=
				{{
					cartItem: cartItem,
				}}
			/>
		</Modal>
		
		<header className="header header--product" data-sticky="true" >
        <nav className="navigation" style={{ zIndex: 1000 }}>
            <div className="container">
                <article className="ps-product--header-sticky">
                    <div className="ps-product__thumbnail">
						<Link 
							className 	= "ps-product__title" 
							onClick		= { ( e ) => handleClickTop( e ) }
						>
							<img src={ siteURL + '/images/products/' + picture } alt="" />
						</Link>
					</div>
                    <div className="ps-product__wrapper">
                        <div className="ps-product__content">
							<Link 
								className 	= "ps-product__title" 
								onClick		= { ( e ) => handleClickTop( e ) }
							>
								{ name }
							</Link>
                        </div>
                        <div className="ps-product__shopping">
							<span className="ps-product__price"><span>{ price }<Currency /></span>
							{ price_old &&
								<del>{ price_old }<Currency /></del>
							}
							</span>
								<a style={{ cursor: 'pointer', }} onClick={ onOpenProductModalAddToCart } title="Ajouter">
									<i className="icon-bag2"></i>&nbsp;Ajouter au panier
								</a>
						</div>
                    </div>
                </article>
            </div>
        </nav>
    </header>
	
		</>
	);
};

export default ProductStickyHeader;
