import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { AuthContext } from "../context/AuthProvider";
import { HashLink  } from 'react-router-hash-link';
const MenuLogin = () => {
	
	const { isAuthenticated, logOut } = useContext( AuthContext );
	
	const [ authenticated, setAuthenticated ] = useState( isAuthenticated() );

	const navigate 	= useNavigate();
	const handleClickDeconnexion = () => {
		logOut();
		setAuthenticated( false );
		const fallback	= '/connexion';
		navigate( fallback );
	}
	
	return (
		<>
			<div className="ps-block--user-header">
                <div className="ps-block__left"><i className="icon-user"></i></div>
                <div className="ps-block__right">
					{
						authenticated === false
						?
							<Link 
								to="/connexion"
							>
								Connexion
							</Link>
						:
							<Link onClick={ handleClickDeconnexion }>
								Déconnexion
							</Link>
					}
					<Link 
						to="/connexion"
					>
						Inscription
					</Link>
				</div>
            </div>
		</>
	);
};

export default MenuLogin;
