import React from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import SearchAProduct from './SearchAProduct';
import AllRoutes from "./AllRoutes";
import { StoreContext } from '../context/store';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

import '../accordionMenuOverrides.css';

import '../footerMobileMenuOverrides.css';

const ShopMobileMenu = ( params ) => {

	// get products list
	const { productList } = useContext( StoreContext );

	// adding active states for menu boutons
	const [ actives, setActives ] 					= useState( new Array( 4 ).fill( '' ) );
	const [ toggleActive, setToggleActive ] 		= useState( new Array( 2 ).fill( '' ) );
	const [ displayMegaMenu, setDisplayMegaMenu ] 	= useState( new Array( 2 ).fill( 'none' ) );	// content for the toggle menu
	
	const [ activeOverlay, setActiveOverlay ] 	= useState( '' );


	const categories = Object.values( params.params )
	const selections = AllRoutes.getAllSelections();

	// get all root Categories
	const checkIfSubcatRegex = /\//;
	const rootCategoriesAll = categories.reduce( ( total, subcategories ) =>  { total.push( subcategories.filter( subcategory => !checkIfSubcatRegex.test( subcategory.category ) ) ); return total; } , [] ); 	
	const rootCategories = rootCategoriesAll.filter( category => category.length ).map( elt => elt[ 0 ] ); 
	
	// navigation
	const navigate = useNavigate();
	
	const BuildAccordionItems = ( params ) => {
		var accordionCategories = [];
		
		if ( !params.params )
			accordionCategories = rootCategories;
		else
			accordionCategories = GetACategorySubcat( params.params, categories ); 

		// handle accordion's button click
		
		const handleAccordionMenuClick = ( path ) => {		
			// close mobile manu
			const newActives = actives.map( e => e = '' );
			setActives( newActives );
			// close overlay
			setActiveOverlay( '' );
			// navigate
			navigate( '/' + path )
		}

		return(
			accordionCategories.map( ( cat, index ) =>
				<AccordionItem>
					{
						GetACategorySubcat( cat.category, categories ).length
							?
								<AccordionItemHeading>
									<AccordionItemButton>
										{ cat.title }
									</AccordionItemButton>
								</AccordionItemHeading>	
							:
									<p style={{ marginTop:'5%', marginLeft:'5%' }}>
										<a style={{ cursor: 'pointer' }} onClick={ ( e ) => handleAccordionMenuClick( cat.path ) }>
											{ cat.title }
										</a>
									</p>
					}
					<AccordionItemPanel>
					{
						GetACategorySubcat( cat.category, categories ).length 
							&&
								<BuildAccordionItems params={ cat.category } />
					}
					</AccordionItemPanel>
				</AccordionItem>		
			)
		)
	}
	
	
	// list a category sub  categories
	const GetACategorySubcat = ( category, categories ) => {
		const re = new RegExp( "(" + category + "\\/)([^\\/]*)$" ); 	//  first degre subcategory
		const subCategoriesWithMultiple = categories.reduce( ( total, subcategories ) => { total.push( subcategories.filter( subcategory => re.test( subcategory.category ) ) ); return total; }, [] ); 
		
		const subCategories = subCategoriesWithMultiple.reduce( ( total, subcategories ) => { return( subcategories.length > total.length ? subcategories : total ) }, [] );

		return subCategories;
	}

	// handle menu click
	const handleClickMenu = ( e, index ) => {

		e.preventDefault();

		
		// check if already active
		const isactive = actives[ index ];

		// reset active states
		const newActives = actives.map( e => e = '' );

		// exit mobile menu if already active
		if( isactive ){
			setActives( newActives );
			setActiveOverlay( '' );
			return;
		}

		// set new active states
		newActives[ index ] = 'active';
		setActives( newActives );
		

		
		// activate overlay
		setActiveOverlay( 'active' );
		
		if( index == 1 ) // Todo: dynamic
			window.open( "https://wa.me/+237699972941", '_blank' )
		
		if( index == 3 ){
			setActiveOverlay( '' );
			navigate( "/connexion" )
		}
	}

	// handle menu's toggle button click
	const handleClickMenuToggle = ( e, index ) => {
		e.preventDefault();
		// get the current element state
		const state = toggleActive[ index ];
		// reset active states
		const newActives  = toggleActive.map( e => e = '' );
		const newDisplays = [ ...newActives ];
		// set element's new state
		const newState 			= !state ? 'active' : '';
		const newStateDisplay 	= !state ? 'block'  : 'none';
		newActives[ index ] 	= newState;	
		setToggleActive( newActives );
		// content for the menu
		newDisplays[ index ] = newStateDisplay;
		setDisplayMegaMenu( newDisplays );
	}

	// handle overlay click
	const handleClickOverlay = () => {
		// close mobile manu
		const newActives = actives.map( e => e = '' );
		setActives( newActives );
		// close overlay
		setActiveOverlay( '' );
	}

	// Build the shop Selection menu
	const BuildShopSelectionLinks = ( selections ) => {
		const selectionsArr = Object.values( selections.params );	// Convert indexed Obj to Array
		return (
			selectionsArr.map( ( selection, index ) => 
				<li key = {index}> 
					<Link to = { '/' + selection[0].path } > 
						{ selection[0].selection } 
					</Link>
				</li>
			)
		)
	}

	useEffect( () => {
	}, [ ] );
	
	return (
		<>
		<div className={ "ps-site-overlay " + activeOverlay } onClick = { handleClickOverlay } ></div>
			<div className="navigation--list">
				<div className="navigation__content">
					<a 
						className={ "navigation__item " + actives[ 0 ] } 
						// href="#menu-mobile"
						onClick = { ( e ) => handleClickMenu( e, 0 ) }
						style={{ cursor: 'pointer' }}
					>
						<i className="icon-menu"></i>
							<span> 
								Menu
							</span>
					</a>
					<a 
						className={ "navigation__item " + actives[ 2 ] } 
						// href="#search-sidebar"
						onClick = { ( e ) => handleClickMenu( e, 2 ) }
						style={{ cursor: 'pointer' }}
					>
						<i className="icon-magnifier"></i>
						<span>Trouver</span>
					</a>
					<a 
						className={ "navigation__item " + actives[ 1 ] } 
						onClick = { ( e ) => handleClickMenu( e, 1 ) }
						style={{ cursor: 'pointer' }}
					>
						<i className="fa fa-whatsapp"></i>
						<span> 
							WhatsApp
						</span>
					</a>
					<a 
						className	= { "navigation__item " + actives[ 3 ] } 
						onClick 	= { ( e ) => handleClickMenu( e, 3 ) }
						style		= { { cursor: 'pointer' } }
					>
						<i className="fa fa-user"></i>
						<span>
							Connexion
						</span>
					</a>
				</div>
			</div>
			<div className={ "ps-panel--sidebar " + actives[ 2 ] } id="search-sidebar">
				<div className="ps-panel__header">
            
                    <form className="ps-form--quick-search" action="index.html" method="get">
                        
						<SearchAProduct />
                    </form>
					
				</div>
				<div className="navigation__content"></div>
			</div>
			<div className={ "ps-panel--sidebar " + actives[ 0 ] } id="menu-mobile" >
				<div className="ps-panel__header">
					<h3>Menu</h3>
				</div>
				<div className="ps-panel__content">
					<ul className="menu--mobile">
						<li className="menu-item-has-children has-mega-menu">
							<Link to='/accueil'>Accueil</Link>
						</li>
						<li className="menu-item-has-children has-mega-menu">
							<Link>Catégories</Link>
							<span 
								className	= { "sub-toggle " + toggleActive[ 0 ] } 
								onClick 	= { ( e ) => handleClickMenuToggle( e, 0 ) }
							>
							</span>
							<div className={ 'mega-menu' } style={{ display: displayMegaMenu[ 0 ] }} >
								<div className="mega-menu__column">
									<div className="ps-layout__left">
										<aside className="widget widget_shop">
											<ul className="ps-list--categories">
												<Accordion allowMultipleExpanded={true} allowZeroExpanded={true} >
													<BuildAccordionItems params={ false } />
												</Accordion>
											</ul>
										</aside>
									</div>
								</div>
							</div>
						</li>
						<li className="menu-item-has-children has-mega-menu">
							<Link>Séléctions</Link>
							<span 
								className	= { "sub-toggle " + toggleActive[ 1 ] } 
								onClick 	= { ( e ) => handleClickMenuToggle( e, 1 ) }
							>
							</span>
							<div className={ 'mega-menu' } style={{ display: displayMegaMenu[ 1 ] }} >
								<div className="mega-menu__column">
									<div className="ps-layout__left">
										<aside className="widget widget_shop">
											<ul className="ps-list--categories">
												<BuildShopSelectionLinks params={ selections } />
												<li><Link to='/selections'>Voir tout</Link></li>
											</ul>
										</aside>
									</div>
								</div>
							</div>
						</li>
						<li className="menu-item-has-children has-mega-menu">
							<Link >Boutiques</Link>
							<span 
								className 	= { "sub-toggle " + toggleActive[ 2 ] } 
								onClick 	= { ( e ) => handleClickMenuToggle( e, 2 ) }
							>
							</span>
							<div className={ 'mega-menu' }  style={{ display: displayMegaMenu[ 2 ] }}>
								<div className="mega-menu__column">
									<div className="ps-layout__left">
										<aside className="widget widget_shop">
											<ul className="ps-list--categories">
												<li><Link to='/boutiques/levis'>Levis | Signature</Link></li>
												<li><Link to='/boutiques/reason'>Reason</Link></li>
												<li><Link to='/boutiques/us-polo-assn'>U.S. Polo Assn</Link></li>
												<li><Link to='/boutiques'>Voir tout</Link></li>
											</ul>
										</aside>
									</div>
								</div>
							</div>
						</li>
						<li className="menu-item-has-children has-mega-menu">
							<Link >Blog</Link>
							<span 
								className 	= { "sub-toggle " + toggleActive[ 3 ] } 
								onClick 	= { ( e ) => handleClickMenuToggle( e, 3 ) }
							>
							</span>
							<div className={ 'mega-menu' }  style={{ display: displayMegaMenu[ 3 ] }}>
								<div className="mega-menu__column">
									<div className="ps-layout__left">
										<aside className="widget widget_shop">
											<ul className="ps-list--categories">
												<li>
													<Link to="/blog/comment-commander">Comment commander</Link>
												</li>
												<li>
													<Link to="/blog/comment-payer">Comment Payer</Link>
												</li>
												<li>
													<Link to="/blog/comment-recevoir-ses-achats">Livraison et Recupération</Link>
												</li>
												<li>
													<Link to="/faq">FAQ</Link>
												</li>
											</ul>
										</aside>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div className={ "ps-panel--sidebar " + actives[ 1 ] } id="navigation-mobile">
				<div className="ps-panel__header">
					<h3>WhatsApp</h3>
				</div>
				
			</div>
		</>
	);
};

export default ShopMobileMenu;
