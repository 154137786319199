import React, { useState, useEffect, useContext } from "react";
import Currency from "./Currency";
import ImageGallery from "react-image-gallery";
import { CartContext } from '../context/cart';
import { Link } from "react-router-dom";
import ShopItemColors from "./ShopItemColors";
import { AuthContext } from '../context/AuthProvider';
import { SiteContext } from '../context/site';
import { Space, Spin, Button, notification, message, Popconfirm, Select, Radio, Flex, DatePicker } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
	LoadingOutlined
} from '@ant-design/icons';

const DashboardModalCategoryDelete = ( params ) => {
	const { getUser } = useContext( AuthContext );
		
	const { getSoldoutProduct, deactivateProduct, removeProduct, removeLocalProduct } = useContext( SiteContext );
	const [ countProducts, setCountProducts ] 	= useState( '' );
	const allProducts = params.params.allProducts;
	const [ categoryToDelete, setCategoryToDelete ]	= useState( params.params.category );
	const [ toDeleteProducts, setToDeleteProducts ]	= useState( [] );
	// Deactivate a product
	const token = getUser().accessToken;
	const handleClickDelete = ( mode ) => {
		toDeleteProducts.map( async ( product, y )=> {
			// const product 	= allProducts.filter( ( v, k ) => v.productId == x.productId )[ 0 ];
			
			var rep = '';
			
			// server
			if( product ){
				const category 		= product.category;
				const subCategory	= product.subCategory;
				const productName	= product.productName;
				const productColor	= product.productColor;
			
				// if( !product.productActived )
				// 	return;

				// Deactivate
				const data = {
				category 		: category,
					subCategory		: subCategory,
					productName		: productName,
					productColor	: productColor,
				}
				
				
				if( mode == 2 ){
					rep = await removeProduct( data, token );
				}
				else{
					rep = await deactivateProduct( data, token )
				}
			}
			else {
				console.log( 'Product not found' );
			}


			const messageTxt = mode == 2 ? 'Produits supprimés' : 'Produits désactivés'
			message.success( messageTxt )
		})
	}

	const BuildProducts = () => {
		return( 
			toDeleteProducts.map ( ( product, index ) => 
				<p key = { index } >
					{ product.productId };
				</p>
			)
		)
	}

	const getCategoryProducts = ( categoryToDelete ) => {
		const productsToDelete = allProducts.filter( x => x.category == categoryToDelete.category && x.subCategory == categoryToDelete.subCategory );
		return productsToDelete;
	}

	// get the items to delete
	useEffect( () => {
		const toDelete = async () => {
			const productsToDelete = await getCategoryProducts( categoryToDelete );
			setToDeleteProducts( productsToDelete );
			setCountProducts( productsToDelete.length );
		}
		toDelete();
	}, [] );

	return (
		<>      
			<p>&nbsp;</p>
			<p>Desactivé / Supprimer tous les produits de la categorie <b>{ categoryToDelete.category } / { categoryToDelete.subCategory }</b></p>
			<p>Nombre de produits à supprimer: { countProducts } </p>
			<p>
				<button
					onClick={ (e) => handleClickDelete( 1 ) } // desactiver
				>
					Desactiver les articles
				</button>&nbsp;
				<button
					style={{ backgroundColor: 'red', color: 'white' }}
					onClick={ (e) => handleClickDelete( 2 ) } // supprimer
				>
					Supprimer les articles
				</button>
			</p>
			<p>&nbsp;</p>
			<BuildProducts/>
			
		</>
	);
};

export default DashboardModalCategoryDelete;
