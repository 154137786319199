import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import BlogMenu from './BlogMenu';
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import SocialBar from "./SocialBar";
const BlogPageOrder = ( params ) => {

	
	return (
		<>
		<Header />
			<div className="ps-page--blog" style={{ marginTop: '25px' }}>
        <div className="container">
            <div className="ps-blog--sidebar">
                <div className="ps-blog__left">
                    <div className="ps-post--detail sidebar">
                        <div className="ps-post__header">
                            <h1>Commander sur 237usa.com</h1>
                            <p>17 Décembre 2023 / par Jane, 237usa.com / dans <Link to='/blog'>Comment ça marche</Link></p>
                        </div>
                        <div className="ps-post__content">
                            <h4>Passer une commande sur 237usa est tres facile et se fait en quelques étapes simples.</h4>
							<ul>
								<li>
									Ouvrez le site <Link href='/index'>237usa.com</Link> dans votre navigateur avec votre ordinateur ou votre téléphone portable. Vous trouverez des centaines d'articles, tous d'origines Americaine pour <Link to='/boutiques/categories/femmes'>femmes</Link>, <Link to='/boutiques/categories/hommes'>hommes</Link> ou pour la <Link to='/boutiques/categories/maison'>maison</Link> dans des catégories variées tel que <Link to='/boutiques/categories/hommes/chaussures'>chaussures</Link>, <Link to='/boutiques/categories/femmes/cosmetiques'>cosmétiques</Link>, <Link to='/boutiques/categories/femmes/sacs'>sac</Link>, <Link to='/boutiques/categories/femmes/cosmetiques/parfums'>parfums</Link>, <Link to='/boutiques/categories/hommes/jeans'>jeans</Link>, <Link to='/boutiques/categories/hommes/jeans'>chemises</Link>, ...
								</li>
								<li>
									En un clic, ajoutez votre article dans votre panier, apres avoir choisi la taille et la quantité souhaité. Vous pouvez voir le total de vos achats à tout moment et ajouter ou supprimer des articles
								</li>
								<li>
									Lorsque vous avez fini votre shopping, cliquez sur le bouton commander de votre panier pour commander.
								</li>
								<li>
									Sur la page de Commander, finaliser votre achat en précisant 
									<ul>
										<li>
											un numéro de téléphone pour votre paiement mobile
										</li> 
										<li>
											Un mode de reception: <Link to='/blog/comment-recevoir-ses-achats'>la livraison ou la recupération</Link>. 
										</li>
									</ul>
								</li>
								<li>
									Recevoir votre achat selon le mode de reception choisi et payer avec le numéro de paiement mobile fourni pendant la commande.
								</li>
							</ul>
						</div>
                        <div className="ps-post__footer">
                            <SocialBar />
                        </div>
                    </div>
                </div>
                <div className="ps-blog__right">
                    <BlogMenu />
                </div>
            </div>
        </div>
    </div>
	<Newsletter />
	<Footer />
		</>
	);
};

export default BlogPageOrder;
