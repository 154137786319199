import React, { useState, useEffect, useContext } from "react";
import Currency from "./Currency";
import ImageGallery from "react-image-gallery";
import { CartContext } from '../context/cart';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import ShopItemColors from "./ShopItemColors";
import { SiteContext } from '../context/site';

const ProductModalOverview = ( items ) => {
	const name				= items.params.name;
	const productId			= items.params.productId;
	const productSizeTab 	= items.params.productSizes;
	const cartItem	 		= items.params.cartItem;
	const price				= items.params.price;
	const price_old			= items.params.price_old;
	const descriptions		= items.params.descriptions;
	const pictures			= items.params.pictures;
	const productColors		= items.params.productColors;
	const path				= items.params.path;
	const description		= items.params.description;

	// Build product Image
	const { siteURL } 	= useContext( SiteContext );
	const imageGalerie = () => {
		const arr = Array();
		for( const picture of pictures ){
			const obj = {};
			obj[ 'original' ] 	= siteURL + '/images/products/' + picture.imageName;
			obj[ 'thumbnail' ] 	= siteURL + '/images/products/' + picture.imageName;
			
			arr.push( obj );		
		}
		
		return arr;
	}	
	const imageGaleriePictures = imageGalerie();

	// Build Description's lines
	const BuildDescriptions = () => {
		return(
			descriptions.map( ( description, index ) =>
				<li key = { index }>
					{ description.descName }
				</li>
			)
		)
	}
	
	// handle click voir page
	const navigate = useNavigate();
		
	const handleClickVoirPage = () => {
		navigate ( path );
	}
	
	useEffect(() => {
	}, []);

	return (
		<>      
				<p>&nbsp;</p>		
                <article className="ps-product--detail ps-product--fullwidth">
                    <div className="ps-product__header">
							<ImageGallery 
								items			= { imageGaleriePictures } 
								showThumbnails 	= { false }
							/>
							<p>&nbsp;</p>
							<ShopItemColors params = { productColors } />
                        </div>
                        <div className="ps-product__info">
                            <h1>{ description }</h1>
                            
                            <h4 className="ps-product__price">{ price }<Currency /> – { price_old }<Currency /></h4>
                            <div className="ps-product__desc">
								<BuildDescriptions />
                            </div>
							<a onClick={ handleClickVoirPage } style={{color:'white'}} title="Voir la page du produit" className="ps-btn ps-btn--black">Voir la page du produit</a>
						</div>
                </article>
				<p>&nbsp;</p>
		</>
	);
};

export default ProductModalOverview;
