import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import { SiteContext } from "../context/site";
import '../modalOverrides.css';

import { Space, Spin, Button, notification, message, Popconfirm } from 'antd';
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
	LoadingOutlined
} from '@ant-design/icons';

import { Form, Input, Select } from 'antd';

import useDidMountEffect from './useDidMountEffect';

const MySpaceAdresseAddModal = ( params ) => {
	
	// Villes
	const villes 		= params.params.villes;
	const [ villeOptions, setVilleOptions ] = useState( [] );
	const [ ville, setVille ] = useState( '' );
	const handleChangeVille = async( value ) => {
		const villeId = value;
		setQuartiers( [] );
		setQuartierSpin( 'block' );
		const villeQuartiers = await getVilleQuartiers( villeId );
		setQuartierSpin( 'none' );
		setQuartiers( villeQuartiers );
		setVille( villeId );
		setVilleError( false );
	}

	// Quartiers
	const [ quartierSpin, setQuartierSpin ] = useState( 'none' );
	const [ quartiers, setQuartiers ] = useState( [] );
	const [ quartierOptions, setQuartierOptions ] = useState( [] );
	const [ quartier, setQuartier ] = useState( '' );
	const handleChangeQuartier = ( value ) => {
		const quartierId = value;
		setQuartier( quartierId );
		setQuartierError( false );
	}
	const { getVilleQuartiers }  = useContext( SiteContext );

	// Description
	const [ description, setDescription ] = useState( '' );
	const validateDescription = () => { return description.length > 3 ? true : false };
	const handleChangeDescription = ( e ) => {
		setDescription( e.target.value );
		const val = validateDescription();
		if( !val )
			setDescriptionError( 'Description insuffisante' );
		else
			setDescriptionError( false );
	} 
	
	// Adresses
	const adresse = params.params.adresse;

	const type 	= adresse.id == null ? 1 : 2; // 1 -> new, 2 -> edit


	
	// Ajouter
	const [ buttonSpin, setButtonSpin ] = useState( 'none' );
	const [ ajouterError, setAjouterError ] = useState( false );
	const handleClickAjouter = async() => {
		//
		setAjouterError( false );
		// validation
		if( !ville ){
			const error = 'Vous devez selectioner une ville';
			message.error( error );	
			setVilleError( error );	
			
			return
		}
		else if( !quartier ){
			const error = 'Vous devez selectioner un quartier';
			message.error( error );	
			setQuartierError( error );
			return
		}
		else if( !description ){
			const error = 'Des précisions sur votre de votre emplacement';
			message.error( error );	
			setDescriptionError( error );
			return
		}
		
		const val = validateDescription();
		if( !val ){
			const error = 'Description insuffisante';
			message.error( error );	
			setDescriptionError( error );
			return
		}
		
		const data = {
			description : 	description,
			quartierId : 	quartier,
			villeId : 		ville,
			userId : 		userId
		}
		
		// spiner
		setButtonSpin( 'block' );
		
		// save
		const error = 'Vous avez deja enregistré cette adresse.'; // Todo: get other possible errors
		if( type == 1 ){	// new
			const rep = await createUserAdresse( data );
			if( !rep ){
				
				message.error( error );
				setAjouterError( error );
				setButtonSpin( 'none' );
				return
			}
		}
		if( type == 2 ){	// modif
			const rep = await updateUserAdresse( { ... data, adresseId: adresse.id } );
			if( !rep ){
				message.error( error );
				setAjouterError( error );
				setButtonSpin( 'none' );
				return
			}
		}
		
		setButtonSpin( 'none' );
		openNotificationContactSuccess( 'Succes' );
		
		document.getElementsByClassName( "react-responsive-modal-closeButton" )[0].click();
		
	}
	const { createUserAdresse, updateUserAdresse }  = useContext( SiteContext );
	
	// User
	const userId =  params.params.userId;

	// Form title
	const title = type == 1 ? 'Ajouter une adresse' : 'Modifier une adresse';

	// Notifications
	const openNotificationContactSuccess = ( message ) => {			// Error no size selected
		notification.success({
			message: `${message}`,
			duration: 2,
			description: type == 1 ? 'Adresse ajoutée!' : 'Adresse modifiée!',
			placement: 'topRight',
			top: 200
		});
	};

	// Form and form's Errors
	const [form] = Form.useForm();
	const [ villeError, setVilleError ] 			= useState( false );
	const [ quartierError, setQuartierError ] 		= useState( false );
	const [ descriptionError, setDescriptionError ] = useState( false );
	const { TextArea } = Input;
	// form errors states to run after the first render
	useDidMountEffect(() => {
        form.validateFields();
    },  [ villeError ] );
	useDidMountEffect(() => {
        form.validateFields();
    },  [ quartierError ] );
	useDidMountEffect(() => {
        form.validateFields();
    },  [ descriptionError ] );
	useDidMountEffect(() => {
        form.validateFields();
    },  [ ajouterError ] );

	// Effects
	useEffect(() => {
		const options = villes.map( ( ville, key ) =>
			({ 
				value: ville.id, 
				label: ville.name 
			})
		);
		setVilleOptions( options );
	}, [villes] );
	
	useEffect(() => {
		const options = quartiers.map( ( quartier, key ) =>
			({ 
				value: quartier.id, 
				label: quartier.name 
			})
		);
		setQuartierOptions( options );
	}, [quartiers] );

	useEffect(() => {
		if( type == 2 ){
			setVille( adresse.villeId );
			setQuartier( adresse.quartierId );
			setDescription( adresse.description );
		}
	}, [type] );
	
	return (

		<div className="modal-content">
			<Form 
				style={{
					width: '90%',
				}}
				className	= "ps-form--checkout"
				action 	= "" 
				method 	= "get"
				layout 	= "vertical"
				form	= {form}
			>
				<div className="modal-header">
					<h5 className="modal-title" id="contactModalLabel">{ title }</h5>
					<button id="closeModal" type="button" className="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div className="modal-body">
								
					<div className="form-group">
						<label for="villes" className="col-form-label">Votre ville</label>
						<Form.Item
							name  = "villes"
							rules={[
								{
									message: villeError,
									validator: ( value ) => {
										if ( villeError === false ) {
											return Promise.resolve();
										} 
										else {
											return Promise.reject( villeError );
										}
									}
								}
							]}
						>
							<Select 
								defaultValue	= { adresse != null ? adresse.ville : '' }
								onChange 		= { handleChangeVille }
								options 		= { villeOptions }
							/>
						</Form.Item>
					</div>
					<div className="form-group">
						<label for="edit-recipient-name" className="col-form-label">Votre quartier</label>&nbsp;
							<Space>
								<Spin
									indicator={
										<LoadingOutlined
											style={{
												fontSize: 		20,
												marginRight: 	'10px',
												display:		quartierSpin,
												color: 			'wheat',
											}}
											spin
										/>
									}
								/>
							</Space>
						<Form.Item
							name  = "quartiers"
							rules = {[
								{
									message: quartierError,
									validator: ( value ) => {
										if ( quartierError === false ) {
											return Promise.resolve();
										} 
										else {
											return Promise.reject( quartierError );
										}
									}
								}
							]}
						>
							<Select 
								defaultValue	= { adresse != null ? adresse.quartier : '' }
								onChange 		= { (e) => handleChangeQuartier(e) }
								options 		= { quartierOptions } 
							/>
						</Form.Item>
							</div>
						<div className="form-group">
										<label for="description" className="col-form-label">Details:</label>
						<Form.Item
							name  = "description"
							rules={[
								{
									message: descriptionError, validator: ( value ) => {
										if ( descriptionError === false ) {
											return Promise.resolve();
										} 
										else {
											return Promise.reject( descriptionError );
										}
									}
								}
							]}
						>
							<TextArea 
								rows			= {2}
								defaultValue 	= { adresse != null ? adresse.description : '' }
								value 			= { description }
								onChange 		= { ( e ) => handleChangeDescription( e ) }
								type			= "text" 
								className		= "form-control" 
								id				= "description" 
								placeholder 	= "Rue, bloc, immeuble, porte ... aidez-nous à vous trouver." 
							/>
						</Form.Item>
									</div>
								
							</div>
							<div className="modal-footer">
							<Form.Item
								name  = "ajouter"
								rules={[
													{
														message: ajouterError,
														validator: ( value ) => {
															if ( ajouterError === false ) {
																return Promise.resolve();
															} 
															else {
																return Promise.reject( ajouterError );
															}
														}
													}
								]}
							>
								<button 
									type		= "button" 
									className	= "btn btn-success"
									onClick 	= { handleClickAjouter }
								>
									{ type == 1 ? 'Ajouter l\'adresse' : 'Modifier l\'adresse' } &nbsp;
									<Space>
										<Spin
											indicator={
												<LoadingOutlined
													style={{
														fontSize: 		20,
														marginRight: 	'10px',
														display:		buttonSpin,
														color: 			'wheat',
													}}
													spin
												/>
											}
										/>
									</Space>
								</button>
							</Form.Item>
						</div>
			</Form>
		</div>

	);
};

export default MySpaceAdresseAddModal;
