import React from "react";
import { Link } from "react-router-dom";
import AllRoutes from "./AllRoutes";
import ShopMobileMenu from "./ShopMobileMenu";

const Footer = () => {
	return (
		<>
		<footer className="ps-footer">
			<div className="ps-container">
				&copy; 2024 237usa.com Management Boards
            </div>
		</footer>
		</>
	);
};

export default Footer;
