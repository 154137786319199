import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import { SiteContext } from "../context/site";
import '../modalOverrides.css';

import { Space, Spin, Button, notification, message, Popconfirm } from 'antd';
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
	LoadingOutlined
} from '@ant-design/icons';

const DashboardModifProductDescriptionModal = ( params ) => {
	
	// Set descriptions
	const { description, setDescription } 	= useContext( SiteContext );
	const { descriptions, setDescriptions } = useContext( SiteContext );

	// Description text
	const [ text, setText ] = useState( '' );
	const handleChangeText = ( e ) => {	
		setText( e.target.value );
	}

	// Contact uid
	const [ uid, setUid ] = useState( '' );

	// Save Description
	const handleClickDescription = ( ) => {
		

		// const rd = Math.floor(Math.random() * 1000000000);
		// const newDescription = {
			// text: text,
			// uid: description.uid,
		// }
		
		var toUpdate = {};
		descriptions.map( e => {
			if( e.id ){		// description existing in the database
				if( e.id == uid )
					toUpdate = e
			} 
			if ( e.uid ){	// newly created description
				if( e.uid == uid )
					toUpdate = e
			} 
		})

		toUpdate.text = text;
		
		const newDescriptions = descriptions.map( e => e.id == uid ? toUpdate : e )
		// contacts.push( newContact );
		setDescription( toUpdate );
		setDescriptions( newDescriptions );
		
		
		// const conts = descriptions;
		// conts.push( newDescription );
		// setDescriptions( conts );

// console.log( 'conts', conts );

		message.success( 'Description Modifiée' );

		document.getElementsByClassName( 'closeModal' )[0].click(); // Todo: react way
	}

	// delete description
	const handleClickDeleteDescription = () => {
		deleteADescription();
		message.success( 'Description suprimée' )
		
		window.document.getElementsByClassName( 'closeModal' )[0].click(); // Todo: react way
	}
	
	// Delete current contact
	const deleteADescription = () => {
		var toDelete = '';
		descriptions.map( e => {
			if( e.id ){		// contact existing in the database
				if( e.id == uid ){
					toDelete = e;
					setDescriptions( descriptions.filter( e => e.id != toDelete.id ) );
				}
			} 
			if ( e.uid ){	// newly created contact
				if( e.uid == uid ){
					toDelete = e;
					setDescriptions( descriptions.filter( e => e.uid != toDelete.uid ) );
				}
			} 
		})
	}
	

	useEffect(() => {
// console.log( 'description ---> ', description );
		setText( description.text );
		setUid( description.id ? description.id : description.uid ); // newly created contact doesn't have id but uid
	}, [ description ] );

	return (
		<>
			<div className="bootstrap-modal">
				<div className="modal fade" id="descriptionEditModal" tabindex="-1" role="dialog" aria-textledby="descriptionEditModal" aria-hidden="true">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="descriptionAddModalText">Modifier une description</h5>
								<button id="closeModal" type="button" className="close" data-dismiss="modal" aria-text="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form>
									<div className="form-group">
										<text for="recipient-name" className="col-form-text">Text de la description:</text>
										<textarea 
											value		= { text }
											onChange	= {  e => handleChangeText( e ) }
											style		= {{ width : '98%' }}
										/>
									</div>
								</form>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-info closeModal" data-dismiss="modal">Fermer</button>
								<Popconfirm
									title		= 'Supprimer une Description'
									description	= 'êtes-vous  sûr de vouloir retirer cete description de la liste?'
									onConfirm	= { handleClickDeleteDescription }
									okText		= 'Oui'
									cancelText	= 'Non'
								>
									<button 
										type		= "button" 
										classNameName	= "btn btn-danger"
									>
										Supprimer
									</button>
								</Popconfirm >
								<button 
									type		= "button" 
									className	= "btn btn-success"
									onClick = { e => handleClickDescription( e ) }
								>
									Enregistrer
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DashboardModifProductDescriptionModal;
