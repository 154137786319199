import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import { SiteContext } from "../context/site";
import '../modalOverrides.css';

import { Space, Spin, Button, notification, message, Popconfirm } from 'antd';
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
	LoadingOutlined
} from '@ant-design/icons';

const DashboardAddProductDescriptionModal = ( params ) => {

	var newDescription = {};
	
	const openNotificationDescriptionSuccess = ( message ) => {			// Error no size selected
		notification.success({
			message: `${message}`,
			duration: 3,
			description: 'Description ajoutée!',
			placement: 'topRight',
		});
	};

	// set description
	const { description, setDescription } = useContext( SiteContext );
	const { descriptions, setDescriptions } = useContext( SiteContext );

	// Description text
	const [ text, setText ] = useState( '' );
	const handleChangeText = ( e ) => {	
		setText( e.target.value );
	}

	//
	
	// Add Description
	const handleClickDescription = () => {
		// check if description already exist

		// Description validation
		const descriptionTextCheck = isValideDescriptionText();
		if( descriptionTextCheck !== true ){
			message.error( 'Description invalide' );
			return;
		}
		
		// duplicate verification
		const check = descriptions.filter( e => e.text == text );
console.log( 'check', check );
		if( check.length ){
			message.error( 'Cette description existe déja' );
			window.document.getElementById( 'closeModal' ).click(); // Todo: react way
			return;
		}
			
		const rd = Math.floor(Math.random() * 1000000000);
		const newDescription = {
			text: text,
			uid: 'create_' + rd,
		}

		setDescription( newDescription );
		const conts = descriptions;
		conts.push( newDescription );
		setDescriptions( conts );

console.log( 'conts', conts );

		openNotificationDescriptionSuccess( 'Success' );
		
		window.document.getElementById( 'closeModal' ).click(); // Todo: react way
	}
	
	
	// Text validation
	const regexTextValidation = /^[a-zA-Z0-9. _-]+@[a-zA-Z0-9. -]+\.[a-zA-Z]{2,4}$/; 
	const isValideDescriptionText = () => {
		if( text.length > 1 )
			return true;
		else
			return false;
	}
	

	// useEffect(() => {
	// console.log( 'newDescription', newDescription );
		// if( newDescription.text == null )
			// return

		// setDescription( newDescription );
	// }, [ newDescription ] );

	return (
		<>
			<div className="bootstrap-modal">
				<div className="modal fade" id="descriptionAddModal" tabindex="-1" role="dialog" aria-labelledby="descriptionAddModalLabel" aria-hidden="true">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="descriptionAddModalLabel">Ajouter une Description</h5>
								<button id="closeModal" type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form>
									<div className="form-group">
										<label for="recipient-name" className="col-form-label">Description du produit:</label>
										<input 
											value 		= { text }
											onChange 	= { e => handleChangeText( e ) }
											type 		= "text" 
											className		= "form-control" 
											id			= "recipient-name" 
										/>
									</div>
								</form>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
								<button 
									type	= "button" 
									className	= "btn btn-success"
									onClick = { e => handleClickDescription( e ) }
								>
									Save
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DashboardAddProductDescriptionModal;
