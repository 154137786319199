import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import { SiteContext } from "../context/site";
import '../modalOverrides.css';

import { Space, Spin, Button, notification, message, Popconfirm } from 'antd';
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
	LoadingOutlined
} from '@ant-design/icons';

const DashboardModifProductSizeModal = ( params ) => {

	
	// Set sizes
	const { size, setSize } 	= useContext( SiteContext );
	const { sizes, setSizes } 	= useContext( SiteContext );

	// Size label
	const [ label, setLabel ] = useState( '' );
	const handleChangeLabel = ( e ) => {	
		setLabel( e.target.value );
	}

	// Size value
	const [ quantity, setQuantity ] = useState( '' );
	const handleChangeQuantity = ( e ) => {	
		const number = e.target.value;
		if( isNaN( number ) ){
			message.error( 'Taper un chiffre' );
			return;
		}
		setQuantity( number );
	}

	const [ uid, setUid ] = useState( '' );

	// Save Size
	const handleClickSize = async () => {
		// Size validation
		const labelError = 'Taper le label de la taille';

		if( !label ){
			message.error( labelError );
			return;
		}

		// Quantity validaton
		const quantityError = 'Taper la quantité de la taille';

		if( !quantity ){
			message.error( quantityError );
			return;
		}

		var toUpdate = {};
		sizes.map( e => {
			if( e.id ){		// contact existing in the database
				if( e.id == uid )
					toUpdate = e
			} 
			if ( e.uid ){	// newly created contact
				if( e.uid == uid )
					toUpdate = e
			} 
		})

		toUpdate.label 		= label;
		toUpdate.quantity  	= quantity;
		
		const newSizes = sizes.map( e => e.id == uid ? toUpdate : e )
		// contacts.push( newContact );
		setSize( toUpdate );
		setSizes( newSizes );

		message.success( 'Taille Modifiée' );

		window.document.getElementsByClassName( "modalCloseTop" )[0].click(); // Todo: react way
		
	}

	// Delete current contact
	const deleteASize = () => {
		var toDelete = '';
		sizes.map( e => {
			if( e.id ){		// contact existing in the database
				if( e.id == uid ){
					toDelete = e;
					setSizes( sizes.filter( e => e.id != toDelete.id ) );
				}
			} 
			if ( e.uid ){	// newly created contact
				if( e.uid == uid ){
					toDelete = e;
					setSizes( sizes.filter( e => e.uid != toDelete.uid ) );
				}
			} 
		})
	}


	// delete size
	const handleClickDeleteSize = () => {
		deleteASize();
		message.success( 'Taille supprimée!' )
		window.document.getElementsByClassName( "modalCloseTop" )[0].click(); // Todo: react way
	}

	useEffect(() => {
		setQuantity( size.quantity );
		setLabel( size.label );
		setUid( size.id ? size.id : size.uid ); // newly created contact doesn't have id but uid
// console.log( 'size', size );
	}, [ size ] );

	return (
		<>
			<div className="bootstrap-modal">
				<div className="modal fade" id="sizeEditModal" tabindex="-1" role="dialog" aria-labelledby="sizeEditModal" aria-hidden="true">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="descriptionAddModalLabel">Modifier une taille</h5>
								<button id="closeModal" type="button" className="close modalCloseTop" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form>
									<div className="form-group">
										<label for="recipient-name" className="col-form-label">Label de la taille:</label>
										<input 
											value 		= { label }
											onChange 	= { e => handleChangeLabel( e ) }
											type 		= "text" 
											className		= "form-control" 
											id			= "recipient-name" 
										/>
									</div>
									<div className="form-group">
										<label for="recipient-name" className="col-form-label">Quantité:</label>
										<input 
											value 		= { quantity }
											onChange 	= { e => handleChangeQuantity( e ) }
											type 		= "text" 
											className		= "form-control" 
											id			= "recipient-name" 
										/>
									</div>
								</form>
							</div>
							<div className="modal-footer">
								<button type="button" className="modalClose btn btn-info" data-dismiss="modal">Fermer</button>
								<Popconfirm
									title		= 'Supprimer une Taille'
									description	= 'Êtes-vous sûr de vouloir retirer cete taille de la liste?'
									onConfirm	= { handleClickDeleteSize }
									okText		= 'Oui'
									cancelText	= 'Non'
								>
									<button 
										type		= "button" 
										className	= "btn btn-danger"
									>
										Supprimer
									</button>
								</Popconfirm >
								<button 
									type	= "button" 
									className	= "btn btn-success"
									onClick = { e => handleClickSize( e ) }
								>
									Enregistrer
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DashboardModifProductSizeModal;
