import React, { useState, useEffect, useContext } from "react";
import Currency from "./Currency";
import { useNavigate, useLocation } from 'react-router-dom';
import Badge from "./Badge";
import ProductModalOverview from "./ProductModalOverview";
import ProductModalAddToCart from "./ProductModalAddToCart";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { SiteContext } from '../context/site';

import {
  RadiusBottomleftOutlined,
  RadiusBottomrightOutlined,
  RadiusUpleftOutlined,
  RadiusUprightOutlined,
} from '@ant-design/icons';
import { Button, Divider, notification, Space } from 'antd';

const ShoppingPageItemSimple = ( val ) => {
	
	const [ error, setError ] = useState(null);
  
	const name					= val.item.name;
	const price 				= val != null && val.item != null ? val.item.price : 0;
	const price_old				= val != null && val.item != null ? val.item.priceOld : 0;
	const description 			= val != null && val.item != null ? val.item.description : '';
	const badge					= val != null && val.item != null ? val.item.badge : '';
	const brand					= val != null && val.item != null ? val.item.brand : '';
	const productId 			= val.item.productId;
	const productColors			= val.item.productColor;
	const initialQuantity		= val.item.initialProductQuantity;
	const productQuantity		= val.item.productQuantity;
	const specifications 		= val.item.productSize;	// Todo: rename productSize to specification on server
	const productSize			= val.item.productSize;	
	const selectionName			= val.item.selection;
	const categoryName			= val.item.category;
	const storeName				= val.item.store ? val.item.store : '';
	const rechercheName			= val.item.recherche;
	const currentPath			= val.item.categoryPath;	// Todo: rename categoryPath to currentPath 
	const picture 				= val.item.picture;
	const pictures 				= val.item.pictures;
	const productDescription 	= val.item.productDescription != null ? val.item.productDescription : [];
// console.log( 'name', name );
	// Antd
	const [ api, contextHolder ] = notification.useNotification();
	const openNotification = ( message ) => {
		notification.success({
			message: `${message}`,
			duration: 3,
			description: "Ce produit a été ajouté à vote panier.",
			placement: 'topRight'
		});
	};

	// Description's param for the URL 
	const descriptionStringTab = productDescription.map( ( description, index ) =>
		description.descName
	)	
	const descriptionString = '&productDescription=' + descriptionStringTab.join( '&productDescription=' );

	// Pictures's param for the URL 
	const { siteURL } 	= useContext( SiteContext );
	const serverPicturesConst = siteURL + '/images/products/';
	const pictureStringTab = pictures.map( ( picture, index ) =>
			picture.imageName
	)	
	const pictureString = '&pictures=' + pictureStringTab.join( '&pictures=' );

	// Product size
	const productSizeStringTab = productSize.map( ( size, index ) =>
		size.sizeName + '~' + size.sizeQuantity
	)

	const productSizeString = '&productSize=' + productSizeStringTab.join( '&productSize=' );

	// Specifications param for the URL 

	const sizesListTab =  specifications.map( ( specification ) => specification.sizeName );

	// const specificationStringTab = specifications.map( ( specification, index ) =>
		// specification.sizeName + '~' + specification.sizeQuantity
	// )	
	const sizesListString = '&sizesList=' + sizesListTab.join( ', ' );
	// Product URL
	const navigate 	= useNavigate();
	
	//window.location.href = '/product/article?&name=' + name + '&price=' + price + '&price_old=' + price_old + '&description=' + description + '&badge=' + badge + '&category=' + categoryName + '&brand=' + brand + descriptionString + pictureString + '&productColors=' + productColors + '&productId=' + productId + specificationString + '&selectionName=' + selectionName + '&categoryName=' + categoryName + '&currentPath=' + currentPath ;
		
	const pathType = ( () => {
		if( categoryName && categoryName !== null  ) 
			return 'categories/' + categoryName + '/article?type=category&typeName=' + categoryName;
		else if( selectionName && selectionName !== null )
			return 'selections/' + selectionName + '/article?type=selection&typeName=' + selectionName;
		else if( storeName && storeName !== null )
			return 'boutiques/' + storeName + '/article?type=store&typeName=' + storeName;
		else if( rechercheName && rechercheName !== null )
			return 'recherche/' + rechercheName + '/article?type=recherche&typeName=' + rechercheName;
	} )()

// console.log( 'pathType', pathType );

	const productLink = '/' + pathType + '&name=' + name + '&productId=' + productId;
	
	const handleClickProduct = () => {
		navigate ( productLink );
		// navigate(0);	
	};

	// Data for the modal product page
	// Product size Arrays for the modal
	const productSizes 	= Array();
	const itemSizes 	= Array();

	for( var size of productSize ){
		// for Ant design radiobox
		const obj01 = {};
		obj01[ 'label' ] = size.sizeName;
		obj01[ 'value' ] = size.sizeName;
		productSizes.push( obj01 );
		// to retrieve the quantity for selected size
		const obj02 = {};
		obj02[ 'label' ] = size.sizeName;
		obj02[ 'value' ] = size.sizeQuantity;
		itemSizes.push( obj02 );	
	}

	// A cart item
	const product =	{
		id: 			productId,
		name: 			name,
		price: 			price,
		picture: 		picture,
		size: 			'',
		productSizes:	productSizes,
		itemSizes:		itemSizes,
		path:			productLink,
	};
	const cartItem = {
		product:	product,
		quantity:	1 	
	}
	
	// ProductOverviewModal state
	const [ openProductModalOverview, setOpenProductModalOverview ] = useState(false);
	const onOpenProductModalOverview  = () => setOpenProductModalOverview(true);
	const onCloseProductModalOverview = () => setOpenProductModalOverview(false);

	// ProductAddToCartModal state
	const [ openProductModalAddToCart, setOpenProductModalAddToCart ] = useState(false);
	const onOpenProductModalAddToCart  = () => setOpenProductModalAddToCart(true);
	const onCloseProductModalAddToCart = () => setOpenProductModalAddToCart(false);

	useEffect(() => {
		// Rating
		
	}, []);
	
	return (
		<>
		<Modal open={ openProductModalOverview } onClose={ onCloseProductModalOverview } center>
			<h2>{ name }</h2>
			<ProductModalOverview params=
			{{
				productSizes: 	productSize,
				name: 			name,
				cartItem: 		cartItem,
				price:			price,
				price_old:		price_old,
				descriptions:	productDescription,
				pictures:		pictures,
				productId:		productId,
				productColors:	productColors,
				description:	description,
				path:			productLink,
			}}
		/>
		</Modal>

		<Modal open={ openProductModalAddToCart } onClose={ onCloseProductModalAddToCart } center>
			<h2>{ name }</h2>
			<ProductModalAddToCart params=
			{{
				cartItem: cartItem,
			}}
		/>
		</Modal>
                <div className="ps-product">
                    <div className="ps-product__thumbnail">
						<a style={{ cursor:'pointer' }} onClick={ handleClickProduct }>
							<img src={ serverPicturesConst + picture } alt="" />
						</a>
                        <Badge badge= { badge } />
                        <ul className="ps-product__actions">
							<li>&nbsp;</li>
							<li>
							<Space>
								<a 
									style={{ cursor: 'pointer' }} 
									onClick={ onOpenProductModalAddToCart } 
									title="Ajouter"
								>
									<i className="icon-bag2"></i>
								</a>
							</Space>
							</li>
							<li>
								<a 
									style={{ cursor: 'pointer' }} 
									onClick={ onOpenProductModalOverview } 
									title="Apperçu"
								>
									<i className="icon-eye"></i>
								</a>
							</li>
							<li>&nbsp;</li>
                        </ul>
                    </div>
                    <div className="ps-product__container"><a style={{cursor:'pointer'}} onClick={ handleClickProduct } className="ps-product__vendor" >{ name }</a>
                        <div className="ps-product__content"><a style={{cursor:'pointer'}} className="ps-product__title" onClick={ handleClickProduct } >{ description }</a>
							<div className="ps-product__rating">
							</div>
							<p className="ps-product__price sale">{ price }<Currency /> &nbsp;
							{ 
								price_old 
								&&
								<del>
									{price_old} <Currency />
								</del>
							}
							</p>
                        </div>
                        <div className="ps-product__content hover">
							<p className="ps-product__price sale">
								{ price }<Currency /> &nbsp;
							{ 
								price_old 
								&&
								<del>
									{price_old} <Currency />
								</del>
							}
							</p>
                        </div>
                    </div>
                </div>
				
				
		</>
	);
};

export default ShoppingPageItemSimple;
