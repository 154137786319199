// Filename - "./components/Navbar.js

import React from "react";
const Currency = () => {
	return (
		<>F</>
	);
};

export default Currency;
