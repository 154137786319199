import React from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import FilterByBrandCheckBoxes from './FilterByBrandCheckBoxes'
import FilterByBrandTextInput from './FilterByBrandTextInput'
import FilterByPriceSlider from './FilterByPriceSlider'
import FilterBySizeButton from './FilterBySizeButton'
import FilterByColorButton from './FilterByColorButton'
import { StoreContext } from '../context/store';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

import '../accordionMenuOverrides.css';

const ShopLeftMenu = ( params ) => {
	
	// get products list
	const { productList } = useContext( StoreContext );

// console.log( 'productList', productList );

	// context custom hook
	const categories = Object.values( params.params )

// console.log( categories ) ;

	// get all root Categories
	const checkIfSubcatRegex = /\//;
	const rootCategoriesAll = categories.reduce( ( total, subcategories ) =>  { total.push( subcategories.filter( subcategory => !checkIfSubcatRegex.test( subcategory.category ) ) ); return total; } , [] ); 	
	var rootCategories = rootCategoriesAll.filter( category => category.length ).map( elt => elt[ 0 ] ); 

	// remove some categories
	const categoriesToNotDisplay = [ 'soldes' ];
	const rep = rootCategories.filter( category => !categoriesToNotDisplay.includes( category.category ) );
	rootCategories = rep;

	// curent path
	const uselocation = useLocation();
	const path = uselocation.pathname;
// console.log( 'rootCategories', rootCategories );
// console.log( 'path', path );
// console.log( 'rootCategories.filter', rootCategories.filter( rootCatObj => rootCatObj.path == path ) );
	const BuildAccordionItems = ( params ) => {
		var accordionCategories = [];
		
		if ( !params.params )
			accordionCategories = rootCategories;
		else
			accordionCategories = GetACategorySubcat( params.params, categories ); 


		return(
			accordionCategories.map( ( cat, index ) =>
				<AccordionItem>
					{
						GetACategorySubcat( cat.category, categories ).length
							?
								<AccordionItemHeading>
									<AccordionItemButton>
										{ cat.title }
									</AccordionItemButton>
								</AccordionItemHeading>	
							:
									<p style={{ marginTop:'5%', marginLeft:'5%' }}>
										<Link to = { '/' +  cat.path } >
											{ cat.title }
										</Link>
									</p>
					}
					<AccordionItemPanel>
					{
						GetACategorySubcat( cat.category, categories ).length 
							&&
								<BuildAccordionItems params={ cat.category } />
					}
					</AccordionItemPanel>
				</AccordionItem>		
			)
		)
	}
	
	// list a category sub  categories
	const GetACategorySubcat = ( category, categories ) => {
		const re = new RegExp( "(" + category + "\\/)([^\\/]*)$" ); 	//  first degre subcategory
		const subCategoriesWithMultiple = categories.reduce( ( total, subcategories ) => { total.push( subcategories.filter( subcategory => re.test( subcategory.category ) ) ); return total; }, [] ); 
		
		const subCategories = subCategoriesWithMultiple.reduce( ( total, subcategories ) => { return( subcategories.length > total.length ? subcategories : total ) }, [] );

		return subCategories;
	}


	useEffect( () => {
			
	}, [] );
	
	return (
		<>
			<div className="ps-layout__left">
                    <aside className="widget widget_shop">
                        <h4 className="widget-title">Catégories</h4>
                        <ul className="ps-list--categories">
							<Accordion allowMultipleExpanded={true} allowZeroExpanded={true} >
								<BuildAccordionItems params={ false } />
							</Accordion>
                        </ul>
                    </aside>
					{ 
						( productList != null && 
						  rootCategories.filter( rootCatObj => '/' + rootCatObj.path == path ).length == 0 &&
						  path != '/categories'
						) &&
							<aside className="widget widget_shop">
								<h4 className="widget-title">A Vos Marques</h4>
								<FilterByBrandTextInput />
								<figure className="ps-custom-scrollbar" data-height="250">
									<FilterByBrandCheckBoxes />
								</figure>
								<figure>
									<h4 className="widget-title">Sélection par Prix</h4>
									<FilterByPriceSlider />
								</figure>
								<figure>
									<h4 className="widget-title">Sélection par Couleur</h4>
									<FilterByColorButton />
								</figure>
								<figure className="sizes">
									<h4 className="widget-title">Sélection Par taille</h4>
									<FilterBySizeButton />
								</figure>
							</aside>
					}
                </div>
		</>
	);
};

export default ShopLeftMenu;
