import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';

import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import SocialBar from "./SocialBar";


const NotFoundPage = ( params ) => {

	
	return (
		<>
			<Header />
				<div class="ps-page--404">
					<div class="container">
						<div class="ps-section__content"><img src="img/404.jpg" alt="" />
							<h3>ohh! la page n'a pas été trouvée</h3>
							<p>Il semble que nous ne trouvions pas ce que vous cherchez. <br/>Peut-être que revenir à la page d'accueil va vous aider <br/><Link to="/accueil"> Accueil</Link></p>
						</div>
					</div>
				</div>
			<Newsletter />
			<Footer />
		</>
	);
};

export default NotFoundPage;
