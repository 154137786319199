import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { AuthContext } from "../context/AuthProvider";

const Breadcrumb = () => {

	const { isAuthenticated, logOut } = useContext( AuthContext );

	const location = useLocation();
	var pagePath = decodeURIComponent( location.pathname );
	while( pagePath.charAt(0) === '/' ){ // get ride of the begining slash
		pagePath = pagePath.substring(1);
	}

	const pagePathTab 	= pagePath.split( '/' );
	var paths 			= Array();
	var path_prev 		= '';
	var product			= '';
	for( var path of pagePathTab ){
		const pathObj 	= {};
		path = path.replace( /\s/g, '-' );

		if( path == 'article' ){ 
			const searchParams = new URLSearchParams( window.location.search );
			product = searchParams.get( 'name' );
			
			pathObj[ 'pathName' ] 	= product;
			pathObj[ 'path' ] 		= ( path_prev ) ? path_prev + '/' + path : path;
			paths.push( pathObj );
		}
		else{
			pathObj[ 'pathName' ] 	= path;
			pathObj[ 'path' ] 		= ( path_prev ) ? path_prev + '/' + path : path;
			
			paths.push( pathObj );
		}	
		
		path_prev += ( path_prev ) ? '/' + path : path;
		
	}
	
	const pathClean = paths.filter( e => e.path != 'accueil' );

	// build each breadcrumb part url;
	const BuildItems = ( ) => {	// Todo: get the base URL dynamicaly
		return (
			pathClean.map(( apath, index ) => 
			index < pathClean.length - 1
			?
				<li key={index}>
					<Link 
						to 		= { '/' + apath.path }
						style	= {{cursor: 'pointer'}} 
						title	= { apath.pathName } 
					>
						{ apath.pathName } 
					</Link>
				</li>
			:
				<li key={index}>
					{ apath.pathName } 
				</li>	
			)
		)
	}


	return (
		<>
			<div className="ps-breadcrumb row">
				<div className="" style={{ width: '80%', paddingLeft: '5%' }}>
					<ul className="breadcrumb">
						<Link 
							to 		= '/accueil' 
							style	= {{ cursor: 'pointer' }} 
							title	= 'Accueil'
						>
							Accueil 
						</Link> 
						<BuildItems /> 
					</ul>
				</div>
				<div style={{ width: '20%', textAlign: 'right', paddingRight: '4%' }}>
					{ isAuthenticated() &&  
						<Link to='/mon-espace' ><i class='fa fa-user' ></i>&nbsp;Mon espace</Link>
					}
				</div>
			</div>
		</>
	);
};

export default Breadcrumb;
