import React, { useState, useEffect, useContext } from "react";

import Newsletter from "./Newsletter";
import ShopLeftMenu from "./ShopLeftMenu";
import ShopCategoryItem from "./ShopCategoryItem";
import Header from "./Header"; 
import Footer from "./Footer"; 
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import { StoreContext } from '../context/store';

const ShopCategories = ( params ) => {

	// clear product list
	const { clearProductList, productList } = useContext( StoreContext );

	const categoriesObjArr 	= Object.values( params.params.categories );
	const categories 		= Object.values( categoriesObjArr[0] );

// alert( categories.length )
	const [ total, setTotal ] = useState( categories.length );
	
	const location = useLocation();
	const { pathname, search } = location;
	const currentLink 		= pathname.replace(/^\//, '');
	
	// buil a category's sub categories
	const BuildCategories = ( params ) => {
		var addValue = 1;
		const subCategories = params.subCategories;
		return (
			subCategories.map(( subCategory, index ) => 
				(
					subCategory.path != currentLink 
					?
						<>
							<ShopCategoryItem 
								key  = { index } 
								item = {{
									category: 	subCategory.title,
									path: 		subCategory.path,
									categories: categories,
									imageSrc: 	subCategory.imageUrl,
								}}
							/>
							{ setTotal( addValue++ ) } 
						</>
					:
					''
				)
			)
		)
	}

	// get all root Categories
	const checkIfSubcatRegex = /\//;
	const rootCategoriesAll = categories.reduce( ( total, subcategories ) =>  { total.push( subcategories.filter( subcategory => !checkIfSubcatRegex.test( subcategory.category ) ) ); return total; } , [] ); 	
	var rootCategories = rootCategoriesAll.filter( category => category.length ).map( elt => elt[ 0 ] ); 

	// check if this link is a root categories link ( not a sub category )
	const checkIfSubcatPathRegex = /(.+\/)+/;
	const checkIfSubcatPath = checkIfSubcatPathRegex.test( currentLink );

	// remove some categories
	const categoriesToNotDisplay = [ 'soldes' ];
	const rep = rootCategories.filter( category => !categoriesToNotDisplay.includes( category.category ) );
	rootCategories = rep;

	// B the category with it subcategories
	const BuildItems = () => {
// console.log( 'currentLink', currentLink );
// console.log( 'checkIfSubcatPathRegex j', checkIfSubcatPath );
		
		return (
			checkIfSubcatPath
			?
				categories.map(( subCategories, index ) => 
					(
						subCategories[0].path == currentLink
						&& 
						<BuildCategories 
							key  			= { index }
							subCategories 	= { subCategories }
						/>
					)
				)
			:
				rootCategories.map(( subCategories, index ) => 
					(
						<BuildCategories 
							key  			= { index }
							subCategories 	= { [ subCategories ] }
						/>
					)
				)
		)
	}	
 

	// setTotal
	const pageName 		= params.params.pageName;
	const path			= params.params.path;
	const category		= params.params.category;
	useEffect( () => {
		if( !checkIfSubcatPath ){
			setTotal( rootCategories.length )
		}
		
	}, [ rootCategories ] );
	
	// clear products list
	useEffect( () => {
		if( productList.length ){
			clearProductList();
		}
	}, [ productList ] );
	
	
	return (
		<>
		<Header/>
			
				<div className="ps-panel--sidebar" id="cart-mobile">
        <div className="ps-panel__header">
            <h3>Shopping Cart</h3>
        </div>
        
    </div>
	
    <div className="ps-page--shop">
        <div className="ps-container">
            <div className="">
                &nbsp;
            </div>
            
            <div className="ps-layout--shop">
                <ShopLeftMenu params = { categories }/>
                <div className="ps-layout__right">
                    <div className="ps-block--shop-features">
                        <div className="ps-block__header">
                            <h3>{ pageName }</h3>
                        </div>
                        
                    </div>
                    
                    <div className="ps-shopping ps-tab-root">
                        <div className="ps-shopping__header">
                            <p><strong>{ total }</strong> Catégories trouvées</p>
                            <div className="ps-shopping__actions">
                                <div className="ps-shopping__view">
                                    
                                </div>
                            </div>
                        </div>
                        <div className="ps-tabs">
                            <div className="ps-tab active" id="tab-1">
                                <div className="ps-shopping-product">
                                    <div className="row">
                                    <BuildItems /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Newsletter />
	<Footer />

		</>
	);
};

export default ShopCategories;
