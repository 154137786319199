import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { Button, notification, message, Popconfirm } from 'antd';
import PartnerModal from "./PartnerModal";
import modalOverrides from "../modalOverrides.css";
import addressModalOverrides from "../addressModalOverrides.css";
import { CartContext } from '../context/cart';
import { StoreContext } from '../context/store';
import { SiteContext } from '../context/site';

import MySpaceAdresseAddModal from './MySpaceAdresseAddModal';
import Currency from "./Currency";
import imageLivraison from '../images/milestone-3.png';
import { AuthContext } from "../context/AuthProvider";
import { HashLink  } from 'react-router-hash-link';
import { Form, Input, Select } from 'antd';
import '../cascadeOverrides.css';

import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
} from '@ant-design/icons';

import type { CascaderProps } from 'antd';
import { Cascader } from 'antd';

import useDidMountEffect from './useDidMountEffect';

// const recuperations =
// [
    // {
        // "recupLocationId": "097ea7b7-ed1b-4244-861e-7e187e4044ab",
        // "location": "New Bell",
        // "city": "Douala",
        // "partners": [
            // {
                // "partnerAddress": "Ngangue, New Bell, Douala",
                // "partnerDescription": [
                    // "Situé pas loin du petit marché Ngangue. Le Salon de Coiffure chez Guy est partenaire de 237usa.com.",
                    // "Le salon fournit les services suivants: Coupe de cheveux régulière, Coupe de cheveux + Barbe, Rasage à la serviette chaude, Garniture de barbe, Les sourcils, Fondus and Shampooing.",
                    // "Parmi les installations de cet établissement figurent une connexion Wi-Fi gratuite pour les clients",
                    // "L'établissement est entierement climatisé pour la grande satisfaction de ses clients."
                // ],
                // "partnerFirstName": "Guy",
                // "partnerLastName": "Rene",
                // "partnerAlias": "Salon de Coiffure - Chez Guy",
                // "partnerPhoneNumber": "699972941",
                // "partnerAvatar": "photo-salon-coiffure-chez-guy.png",
                // "partnerPhoto": "avatar-salon-coiffure-chez-guy.jpeg",
                // "partnerLocationImage": "map-salon-coiffure-chez-guy.png",
                // "partnerGoogleMapLink": "https://www.google.com/maps/place/4%C2%B001'24.1%22N+9%C2%B042'36.4%22E/@4.023777,9.708612,18z/data=!4m5!3m4!1s0x0:0x83f7ec02a771ac2c!8m2!3d4.02336!4d9.7101",
                // "dayOfWeek": "Lundi-Samedi",
                // "timeOfWeek": "9h-21h",
                // "partnerCapacity": 30,
                // "pertnerOffer": {
                    // "pertnerOfferName": "Promotion",
                    // "partnerOfferImages": [
                        // {
                            // "partnerOfferImageName": "salon-coiffure-chez-guy1.jpeg"
                        // },
                        // {
                            // "partnerOfferImageName": "salon-coiffure-chez-guy2.jpeg"
                        // },
                        // {
                            // "partnerOfferImageName": "salon-coiffure-chez-guy3.jpeg"
                        // },
                        // {
                            // "partnerOfferImageName": "salon-coiffure-chez-guy4.jpeg"
                        // },
                        // {
                            // "partnerOfferImageName": "salon-coiffure-chez-guy5.jpeg"
                        // }
                    // ]
                // },
                // "partnerActived": true
            // }
        // ]
    // },
	// {
		// "recupLocationId": "444747-485545",
		// "location": "Japoma",
		// "city": "Douala",
		// "partners": [
            // {
                // "partnerAddress": "Bafana II, Japoma, Douala",
                // "partnerDescription": [
                    // "Immeuble Bafana II.",
                    // "Fourniture de connexion internet illimité.",
                // ],
                // "partnerFirstName": "Kamdem",
                // "partnerLastName": "Jean",
                // "partnerAlias": "Immeuble Bafana II",
                // "partnerPhoneNumber": "699972941",
                // "partnerAvatar": "",
                // "partnerPhoto": "",
                // "partnerLocationImage": "",
                // "partnerGoogleMapLink": "https://www.google.com/maps/place/4%C2%B001'24.1%22N+9%C2%B042'36.4%22E/@4.023777,9.708612,18z/data=!4m5!3m4!1s0x0:0x83f7ec02a771ac2c!8m2!3d4.02336!4d9.7101",
                // "dayOfWeek": "Lundi-Samedi",
                // "timeOfWeek": "9h-21h",
                // "partnerCapacity": 30,
                // "pertnerOffer": {
                    // "pertnerOfferName": "Wifi",
                    // "partnerOfferImages": [
                        // {
                            // "partnerOfferImageName": "salon-coiffure-chez-guy1.jpeg"
                        // },
                    // ]
                // },
                // "partnerActived": true
            // },
			// {
                // "partnerAddress": "Erica Barbapapa, Japoma, Douala",
                // "partnerDescription": [
                    // "Japoma Cocotier",
                    // "Barbapapa, Bonbon.",
                // ],
                // "partnerFirstName": "Erica",
                // "partnerLastName": "Erica",
                // "partnerAlias": "Erica Barbapapa",
                // "partnerPhoneNumber": "699972941",
                // "partnerAvatar": "",
                // "partnerPhoto": "",
                // "partnerLocationImage": "",
                // "partnerGoogleMapLink": "https://www.google.com/maps/place/4%C2%B001'24.1%22N+9%C2%B042'36.4%22E/@4.023777,9.708612,18z/data=!4m5!3m4!1s0x0:0x83f7ec02a771ac2c!8m2!3d4.02336!4d9.7101",
                // "dayOfWeek": "Lundi-Samedi",
                // "timeOfWeek": "9h-21h",
                // "partnerCapacity": 30,
                // "pertnerOffer": {
                    // "pertnerOfferName": "Wifi",
                    // "partnerOfferImages": [
                        // {
                            // "partnerOfferImageName": "salon-coiffure-chez-guy1.jpeg"
                        // },
                    // ]
                // },
                // "partnerActived": true
            // },
        // ]
    // }
// ]



const Order = () => {
	// const { setReferrer } = useContext( SiteContext );
	const [ partnerButtonDisplay, setPartnerButtonDisplay ] = useState( 'none' );
	const { 
		partnersForRetrieval, 
		sendOrder, 
		createUserAdresse, 
		updateUserAdresse, 
		getUserAdresses, 
		getVilles, 
		deleteUserAdresse 
	}   = useContext( SiteContext ); // used in use effect

	const { checkCoupon }   = useContext( StoreContext ); // used in use effect

	const [ partners, setPartners ] 			= useState( [] ); // useState( recuperations );
	const [ partnerAlias, setPartnerAlias ] 	= useState( '' ); // to be displayed on the partner button
	const [ partner, setPartner ] 	= useState( [] ); 	// a partner states
	const [ cities, setCities ] 	= useState( [] ); 	// cities states for cascade


	// Build Antd Cascade data
	const getCities = () => {
		return(
			partners.map( ( obj, index ) => 
				(
					{
						value: obj.city,
						label: obj.city,
						children: getCityLocations( obj.city ),
						key: index,
					}
				)
			)
		)
	}
	// Get a city locations
	const getCityLocations = ( city ) => {
		return(
			partners.map( ( obj, index ) => 
				obj.city == city 
				&&
					(	
						{
							value: obj.location,
							label: obj.location,
							children: getLocationPartners( obj.location ),
							key: index,
						}
					)
			)
		)
	}

	// Get a location partners
	const getLocationPartners = ( location ) => {
		const res = partners.map( ( obj, index ) => 
			obj.location == location
			&&
				obj.partners.map( ( partner, key ) => 
					(
						{
							value: JSON.stringify( partner ),
							label: partner.partnerAlias,
							key: key
						}
					)
				)
		)
		return res.filter( el => el )[0];	// remove array elements == false or empty and return the inner array
	}

	// Remove duplicates
	function removeDuplicateCities( arr ){	
		const newArr = Array();
		for( const elt of arr ){
			const city = elt.value;
			if( !newArr.length )
				newArr.push( elt ) 
			else
				for( const ele of newArr ){
					if ( city != ele.value )
						newArr.push( elt ) 
				}
		}
		return newArr;
	}

	const citiesAll = getCities();

	// form error - recup
	const [ recupError, setRecupError ] = useState( false );
	
	// active - livraison
	const [ activeLivraison, setActiveLivraison ] = useState( 'none' );
	const [ activeLivraisonStyle, setActiveLivraisonStyle ] = useState( {} );
	
	
	// active - recuperation
	const [ activeRecuperation, setActiveRecuperation ] = useState( 'blobk' );
	const [ activeRecuperationStyle, setActiveRecuperationStyle ] = useState( { backgroundColor: '#fcb800' } );
	
	
	// user info
	const { getUser }	= useContext( AuthContext );
	
	const [ email, setEmail ] 			= useState( getUser() != null ? getUser().email : '' );	
	const [ firstName, setFirstName ] 	= useState( getUser() != null ? getUser().firstName : '' );	
	const [ lastName, setLastName ] 	= useState( getUser() != null ? getUser().lastName : '' );	
	const [ userId, setUserId ] 		= useState( getUser() != null ? getUser().id : '' );

	const name = firstName + ' ' + lastName;

	// Partner: select the location
	
	// interface Option {
		// value: array;
		// label: string;
		// children?: Option[];
	// }

	// const options: Option[] = cities;
	const onChangePlace = ( value ) => {
// console.log( 'value', value );
		const partnerJson 				= value[ value.length -1 ];
		const city 						= value[ 0 ];
		const partnerLocation 			= value[ 1 ];
		const partnerObj 				= JSON.parse( partnerJson );
		partnerObj[ 'city' ]			= city;
		partnerObj[ 'partnerLocation' ]	= partnerLocation;
		setPartner( partnerObj );
		setPartnerButtonDisplay( 'block' )
		
		resetAllErrors( false );

	};


	// Partner modal
	const [ openPartnerModal, setOpenPartnerModal ] = useState(false);
	const onOpenPartnerModal  = () => setOpenPartnerModal(true);
	const onClosePartnerModal = () => setOpenPartnerModal(false);
	const handleClickPartner = () => {
		setOpenPartnerModal(true);
	}
	
	// Product Recap
	const { cartItems, getCartTotal, removeFromCart, clearCart, getCartProductCount } = useContext( CartContext );
	const [ livraison, setLivraison ] = useState( '0' );
	const priceTotal 		= ( livraison.replace( /\s/g, '' )*1 + getCartTotal() ).toString().replace(/(?=(?:.{3})*$)/g, ' ').trim();
	const numberOffItems	= getCartProductCount();
	const [ modeLivraison, setModeLivraison ] 	= useState( 2 );	// 1 = livraison, 2 = repuperation
	const [ pluriel, setPluriel ] = useState( numberOffItems > 1 ? 's' : '' );
	
	// Form validation: coupon
	const [ coupon, setCoupon ] = useState( '' );
	const [ couponValidationState, setCouponValidationState ] = useState( '' );
	const [ couponError, setCouponError ]= useState(false);
	
	const onChangeCoupon = ( e ) => {	// get coupon
		setCoupon( e.target.value );
	}
	const couponValidation = async () => {	// coupon validation
		if( coupon == '' )
			return true
		const rep = await checkCoupon( coupon );
		
		return rep;
	}

	// Form validation: phone
	const [phoneNumberError, setPhoneNumberError]  = useState( false );
	const [ phone, setPhone ] = useState( '' );
	const onChangePhone = ( e ) => {	// get phone
		const reg = /^[+0-9\s]+$/g;
		setPhone( e.target.value );
		if( e.target.value && !reg.test( e.target.value ) ){
			const error = 'Caractere non permis';
			message.error( error );
			setPhoneNumberError( error );
		}
		else{
			setPhoneNumberError( false );
		}
	}

	const phoneNumberValidation = () => {
		if( !phone ){
			const error = 'Saisissez ci-dessus le numéro Orange Money ou MTN Mobile Money que vous allez utiliser pour payer.';
			setPhoneNumberError( error );
			return error;
		}
		const phoneCleaned = phone.replace( /\s/g, '' ).replace( /^\+/g, '' ).replace( /^237/, '' )	// remove all spaces
		const reg = /^6\d{8}$/;
		if( !reg.test( phoneCleaned ) ){
			const error = 'Numéro de paiement mobile incorrect.';
			setPhoneNumberError( error );
			return error;
		}
		return true;
	}
	// Form validation: delivery info
	// ville
	const [ ville, setVille ] = useState( '' );
	const [ villeValidationState, setVilleValidationState ] = useState( '' );
	const [ villeError, setVilleError ] = useState( false );
	const onChangeVille = ( e ) => {	// get ville
		setVille( e.target.value );
	}
	const villeValidation = () => {
		return ville ? true : false;
	}
	// quartier
	const [ quartier, setQuartier ] = useState( '' );
	const [ quartierValidationState, setQuartierValidationState ] = useState( '' );
	const [ quartierError, setQuartierError ] = useState( false );
	const onChangeQuartier = ( e ) => {	// get quartier
		setQuartier( e.target.value );
	}
	const quartierValidation = () => {
		return quartier ? true : false;
	}
	// repere
	const [ repere, setRepere ] = useState( '' );
	const [ repereError, setRepereError ] = useState( false );
	const [ repereValidationState, setRepereValidationState ] = useState( '' );
	const onChangeRepere = ( e ) => {	// get repere
		setRepere( e.target.value );
	}
	const repereValidation = () => {
		return repere ? true : false;
	}
	// phoneContact
	const [ phoneContact, setPhoneContact ] = useState( '' );
	const [ phoneContactValidationState, setPhoneContactValidationState ] = useState( '' );
	const [ phoneContactError, setPhoneContactError ] = useState( false );
	const onChangePhoneContact = ( e ) => {	// get phoneContact
		const reg = /^[+0-9\s]+$/g;
		setPhoneContact( e.target.value );
		if( e.target.value && !reg.test( e.target.value ) ){
			const error = 'Caractere non permis'
			message.error( error );
			setPhoneContactError( error );
		}
		else{
			setPhoneContactError( false );
		}
		
	}
	const phoneContactValidation = () => {
		if( !phoneContact )
			return 'Taper un numéro de téléphone pour votre livraison';
		const reg = /\d{9}/;
		if( !reg.test( phoneContact ) )
			return 'Numéro de téléphone pour la livraison incorrect.';
		return true;
	}
	
	// change mode de livraison
	const changeModeLivraison = ( mode ) => {
		setModeLivraison( mode );
		resetAllErrors();
	}
	
	// checks icons
	const [ checkLivraison, setCheckLivraison ] = useState( 'none' );
	const [ checkRecuperation, setCheckRecuperation ] = useState( 'block' );

	// Form sending
	const navigate = useNavigate();
	const handleClickEnvoyer = async ( event ) => {
		event.preventDefault();
		if( !cartItems.length ){
			message.error( 'Panier vide!' );
			return;
		}		

		// phone validation
		const res = phoneNumberValidation();
		if( res !== true ){
			message.error( res );
			return;
		}

		// delivery validation
		if( modeLivraison == 1 ){	// Livraison
			// if( !villeValidation() ){
				// const error = 'Saisisez votre ville de livraison';
				// message.error( error );
				// setVilleError( error );
				// return;
			// }
			// else if( !quartierValidation() ){
				// const error = 'Saisisez votre quartier de livraison';
				// message.error( error );
				// setQuartierError( error );
				// return;
			// }
			// else if( !repereValidation() ){
				// const error = 'Indiquez un point de repère ou un numéro de domicile';
				// message.error( error );
				// setRepereError( error );
				// return;
			// }
			// else {
			//	const res = phoneContactValidation();	
			//	if( res !== true ){
			//		message.error( 'Erreur: ' + res );
			//		setPhoneContactError( res );
			//		return;
			//	}
			// }
			
			if( !selectedAdresse || selectedAdresse == defaultAdresseText ){
				const error = 'Vous devez choisir une adresse de livraison.';
				message.error( 'Erreur: ' + error );
				setAdresseError( error );
				return;
			}

			const phoneContactErreur = phoneContactValidation();	
			if( phoneContactErreur !== true ){
				const error = phoneContactErreur;
				message.error( 'Erreur: ' + error );
				setPhoneContactError( error );
				return;
			}
		}

		// Recup validation
		if( modeLivraison == 2 ){	// recuperation
			if( !partner.partnerAlias ){
				const error = 'Sélectioner un point de recupération partenaire';
				message.error( error );
				setRecupError( error );
				return;
			}
		}
		
		
		// coupon validation
		const validCoupon = await couponValidation();
		if( !validCoupon ){
			const error = 'Coupon de réduction incorrect';
			message.error( error );
			setCouponError( error );

			return;
		}

		// sending
		const orderAddress = modeLivraison != 1 ? '' : 'Ville: ' + ville + ' -- Quartier: ' + quartier + ' -- Repere: ' + repere + ' -- Phone: ' + phoneContact + '';
		const cartProductsArr = cartItems.map( ( elt ) => ({ 
				'productId' 			: elt.product.id, 
				'productQuantitySell'	: elt.quantity,
				'productTotalPrice'		: ( elt.quantity * elt.product.price.replace( /\s/g, '' ) ).toString(),
				'productSizeOrder'		: elt.product.size,
		}))

		const orderObj = {	// Todo: add partner city and location to the partner data server side
			'deliveryType': modeLivraison == 1 ? 1000 : 0,
			"personEmail": email,
			"partnerCity":  modeLivraison == 1 ? '' : partner.city,	
			"partnerLocation": modeLivraison == 1 ? '' : partner.partnerLocation,
			"discountCode": coupon,
			"partnerPhoneNumber": modeLivraison == 1 ? '' : partner.partnerPhoneNumber.replace(/ /g,''),
			"products": cartProductsArr,
			"userPaymentPhoneNumber": phone,
			"userOrderAddress": modeLivraison == 1 ? selectedAdresse + ' -- phone: ' + phoneContact : '',
		}
 console.log( 'orderObj', orderObj );
		// Post order data
		const resp = await sendOrder( orderObj );
//console.log( 'resp', resp );
		if( resp == false ){
			message.error( 'Vérifier votre connexion internet svp.' );	
			return;
		}
		
		
		// clear the user's cart
		await clearCart();
		
		message.success( 'Commande placée.' );
		
		// Login redirect
		// const currentPage 	= location.pathname;
		// await setReferrer( currentPage );
		
		const gotoUrl = '/commander/merci';
		navigate( gotoUrl );

	}

	const [form] = Form.useForm();	


	// user address
	useEffect( () => {
		// set the products state
		const getPartnersForRetrieval = async () => {
			const rep = await partnersForRetrieval();
console.log( 'partnersForRetrieval', rep );
			setPartners ( rep );
		}
		getPartnersForRetrieval();  // uncomment to get production data
		
		if( cities.length == 0 ){
			setCities( removeDuplicateCities( citiesAll ) )
		}
		
	}, [ cities ] );




	// ---------------------------------- User adresse ---------------------------------- //
	const [ countUserAdresses, setCountUserAdresses ] = useState( 0 );
	const [ adresses, setAdresses ] = useState( [] );
	const defaultAdresseText = countUserAdresses ? 'Sélectioner une de vos adresse' : '';
	const [ selectedAdresse, setSelectedAdresse ] = useState( defaultAdresseText );
	const [ villes, setVilles ] 	= useState( [] );
	const [ quarter, setQuarter ] 	= useState( '' );
	const [ adresseError, setAdresseError ] 	= useState( false );
	const [ adresseOptions, setAdresseOptions ] 	= useState( '' );
	
	const handleChangeAdresse = async ( adressid ) => {
		const adresseTab = await adresses.filter( e => e.id == adressid  );
		const adresse = adresseTab[ 0 ];

		const texte   = adresse.ville + ' - ' +  adresse.quartier + ' - ' + adresse.description;
		setSelectedAdresse( texte );
	}
	
	
	useEffect( () => {
		
		// get the villes
		const getVillesData = async () => {
			const data = await getVilles();
			setVilles( data );
		}
		getVillesData();  
		
		// get the user adresses
		const getAdresses = async ( userId ) => {
			const data = await getUserAdresses( userId );
			setAdresses( data );
			setCountUserAdresses( data.length );
		}
		getAdresses( userId );  
		
	}, [] );

	useEffect(() => {
		// set options
		const options = adresses.map( ( adresse, key ) =>
			({ 
				value: adresse.id, 
				label: adresse.ville + ' - ' +  adresse.quartier + ' - ' + adresse.description,
			})
		);
		setAdresseOptions( options );
		
		// set default value
		if( adresses.length ){
			const adresse = adresses[ 0 ];
			const texte = adresse.ville + ' - ' +  adresse.quartier + ' - ' + adresse.description;

			setSelectedAdresse( texte );
		}
		
	}, [adresses] );


	// Modal
	const [ openAdresseModal, setOpenAdresseModal ] = useState(false);
	const onOpenAdresseModal  		= () => setOpenAdresseModal(true);
	const onCloseAdresseModal 		= async () => {
		const data = await getUserAdresses( userId );
		setAdresses( data );
		setCountUserAdresses( data.length );
		
		setOpenAdresseModal(false)
	};

	// Click to add / edit adresse
	const handleClickAddAdresse = async () => {
		setOpenAdresseModal(true);
	}

	// ---------------------------------- End User adresse ---------------------------------- //

	// reset all form errors
	const resetAllErrors = () =>{
		setRecupError( false );
	}

	// run after the first render
	useDidMountEffect(() => {
        form.validateFields();
    },  [ phoneNumberError ] );
	useDidMountEffect(() => {
        form.validateFields();
    },  [ recupError ] );
	useDidMountEffect(() => {
        form.validateFields();
    },  [ villeError ] );
	useDidMountEffect(() => {
        form.validateFields();
    }, [ quartierError ] );
	useDidMountEffect(() => {
        form.validateFields();
    }, [ repereError ] );
	useDidMountEffect(() => {
        form.validateFields();
    }, [ phoneContactError ] );
	useDidMountEffect(() => {
        form.validateFields();
    }, [ couponError ] );
	useDidMountEffect(() => {
        form.validateFields();
    }, [ adresseError ] );

	return (
		<>
			<Modal open={ openPartnerModal } onClose={ onClosePartnerModal } center>
				<PartnerModal params=
				{{
					partner: partner,
				}}
				/>
			</Modal>
			<Modal open={ openAdresseModal } onClose={ onCloseAdresseModal } center>
				<MySpaceAdresseAddModal params =
					{{
						userId: userId,
						villes: villes,
						adresse: {},
					}}
				/>
			</Modal>
			<Header />
			<main className="ps-page--my-account">
			
			<section className="ps-section--account ps-checkout">
				<div className="container">
					<div className="ps-section__header">
						<h3>Commander</h3>
						Finaliser votre commande avec votre <b>numéro de téléphone </b>pour le paiement mobile, et votre <b>mode de restitution préféré</b>  pour votre colis.
					</div>
					<div className="ps-section__content">
						<Form 
							style 		= {{ paddingTop: '25px' }} 
							className	="ps-form--checkout"
							action 	= "" 
							method 	= "get"
							layout 	= "vertical"
							form	= {form}
						>
							<div className="ps-form__content">
								<div className="row">
									<div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 ">
										<div className="ps-block--shipping" >
											<h4>Saisir un numéro de téléphone pour le Paiement Mobile</h4>
											<div className="ps-block__panel">
												<figure>
													<small style={{ maxWidth: '25em' }}>
														Orange Money ou MTN MoMo
													</small>
													<strong> 
														<Form.Item
															name  = "phoneNumber"
															style = {{ marginBottom: '0px' }}
															rules = {[
																{
																	message: phoneNumberError,
																	validator: ( value ) => {
																		if ( phoneNumberError === false ) {
																			return Promise.resolve();
																		} 
																		else {
																			return Promise.reject( phoneNumberError );
																		}
																	}
																}
															]}
														>
															<Input 
																value		= { phone } 
																onChange	= { onChangePhone }
																className	= "form-control" 
																type		= "text" 
																placeholder	= "6xx xx xx xx"
															/>
														</Form.Item>
													</strong>
												</figure>
											</div>
											<h4>Choisir un mode de Restitution pour votre achat</h4>
											<div className="ps-block--payment-method">
												<ul className="ps-tab-list">
													<li >
														<a 
															style={{...activeRecuperationStyle, cursor:'pointer'}} 
															onClick = { 
																() => { 
																		setLivraison( '0' ); 
																		changeModeLivraison( 2 );
																		setActiveLivraison( 'none' );
																		setActiveRecuperation( 'block' );
																		setCheckLivraison( 'none' );
																		setCheckRecuperation( 'block' );
																		setActiveLivraisonStyle( {} );
																		setActiveRecuperationStyle( {backgroundColor: '#fcb800'} );
																} 
															} 
															className="ps-btn ps-btn--sm">
															<i className='fa fa-check' style={{ display: checkRecuperation, color: 'white' }}></i>&nbsp;	Vous passez recuperer gratuitement
														</a>
													</li>
													<li>
														ou &nbsp;<a 
															style={{...activeLivraisonStyle, cursor:'pointer'}} 
															onClick = { 
																() => { 
																	setLivraison( '1 000' ); 
																	changeModeLivraison( 1 );
																	setActiveLivraison( 'block' );
																	setActiveRecuperation( 'none' );
																	setCheckLivraison( 'block' );
																	setCheckRecuperation( 'none' );
																	setActiveLivraisonStyle( {backgroundColor: '#fcb800'} );
																	setActiveRecuperationStyle( {} );
																} 
															} 
															className="ps-btn ps-btn--sm" >
															<i className='fa fa-check' style={{ display: checkLivraison, color: 'white' }}></i>&nbsp;on vient vous livrer, à partir de 1 000<Currency />
														</a>
													</li>
												</ul>
												<div className="ps-tabs">
													<div style={{ display: activeLivraison }}>
													
															<figcaption>
															Sélectioner une de vos Adresse de Livraison
																<Form.Item
																	style = {{ 
																		display: countUserAdresses ? 'block' : 'none',
																		marginBottom: '0px',
																	}}
																	name  = "adresses"
																	rules={[
																		{
																			message: adresseError,
																			validator: ( value ) => {
																				if ( adresseError === false ) {
																					return Promise.resolve();
																				} 
																				else {
																					return Promise.reject( adresseError );
																				}
																			}
																		}
																	]}
																>
																	<Select 
																		defaultValue	= { selectedAdresse }
																		value			= { selectedAdresse }
																		onChange 		= { ( e ) => handleChangeAdresse ( e ) }
																		options 		= { adresseOptions }
																	/>
																	<br/>ou
																</Form.Item>
															</figcaption>  
															<figcaption>
																<Form.Item
																	name  = "ajouter"
																	rules={[
																						{
																							message: adresseError,
																							validator: ( value ) => {
																								if ( adresseError === false ) {
																									return Promise.resolve();
																								} 
																								else {
																									return Promise.reject( adresseError );
																								}
																							}
																						}
																	]}
																>
																	<button 
																		className 		= "ps-btn ps-btn--fullwidth"
																		onClick   		= { ( e ) => handleClickAddAdresse( e ) }
																	>
																		<i className="fa fa-plus"></i>&nbsp;Ajouter une Adresse
																	</button>
																</Form.Item>
															</figcaption>
														<div style={{ display: 'none' }}>	
															<div className="form-group">
																<label>Ville</label>
																<Form.Item
																	name  = "Ville"
																	rules={[
																		{
																			message: villeError,
																			validator: ( value ) => {
																				if ( villeError === false ) {
																					return Promise.resolve();
																				} 
																				else {
																					return Promise.reject( villeError );
																				}
																			}
																		}
																	]}
																>
																	<Input onChange={ onChangeVille } className="form-control" type="text" placeholder="" />
																</Form.Item>
															</div>
															<div className="form-group">
																<label>Quartier</label>
																<Form.Item
																	name  = "Quartier"
																	rules={[
																		{
																			message: quartierError,
																			validator: ( value ) => {
																				if ( quartierError === false ) {
																					return Promise.resolve();
																				} 
																				else {
																					return Promise.reject( quartierError );
																				}
																			}
																		}
																	]}
																>
																	<Input onChange={ onChangeQuartier } className="form-control" type="text" placeholder="" />
																</Form.Item>
															</div>
															<div className="form-group">
																<label>Point de repère ou Numéro du domicile </label>
																<Form.Item
																	name  = "Repere"
																	rules={[
																		{
																			message: repereError,
																			validator: ( value ) => {
																				if ( repereError === false ) {
																					return Promise.resolve();
																				} 
																				else {
																					return Promise.reject( repereError );
																				}
																			}
																		}
																	]}
																>
																	<Input onChange={ onChangeRepere } className="form-control" type="text" placeholder="" />
																</Form.Item>
															</div>
														</div>
															<div className="row" style={{ marginTop: '20px' }}>
																<div className="col-6">
																	<div className="form-group">
																		<label
																			style = {{ 
																				marginBottom: '0px',
																			}}
																		>Numéro de téléphone pour la livraison</label>
																		<Form.Item
																			name  = "phoneLivraison"
																			rules={[
																				{
																					message: phoneContactError,
																					validator: ( value ) => {
																						if ( phoneContactError === false ) {
																							return Promise.resolve();
																						} 
																						else {
																							return Promise.reject( phoneContactError );
																						}
																					}
																				}
																			]}
																		>
																			<Input value={ phoneContact } onChange={ onChangePhoneContact } className="form-control" type="text" placeholder="6xx xx xx xx" />
																		</Form.Item>
																	</div>
																</div>
															</div>
													</div>
													<div style={{ display: activeRecuperation }}>
														<figcaption>Sélectionner un point de recupération partenaire pres de chez vous</figcaption>
														<Form.Item
															name  = "Recup"
															rules={[
																{
																	message: recupError,
																	validator: ( value ) => {
																		if ( recupError === false ) {
																			return Promise.resolve();
																		} 
																		else {
																			return Promise.reject( recupError );
																		}
																	}
																}
															]}
														>
															<Cascader 
																allowClear	= { false } 
																options		= { cities } 
																onChange	= { onChangePlace } 
																placeholder	= "Selectionner" 
															/>
														</Form.Item >
														<div className="ps-block__content stikypan" style={{ display: partnerButtonDisplay }}>
															<a title='Détails du partenaire' className='ps-btn ps-btn--sm' style={{ marginTop: '10px', color: 'white', backgroundColor: 'black' }} onClick={handleClickPartner }>Détails</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">
										<div className="ps-block--checkout-order">
											<div className="ps-block__content" style={{ backgroundColor: 'ps-block__content', paddingTop: '5px', paddingBottom: '20px' }}>
												<figure className="ps-block__items">
													<a>
														<strong>Recapitulatif et commande{ pluriel }</strong>
													</a>
												</figure>
												<figure className="ps-block__items">
													<a>
														<strong>Nombre d'article{ pluriel }</strong>
														<span>
															<small>
																{ numberOffItems }
															</small>
														</span>
													</a>
												</figure>
												<figure className="ps-block__items">
													<a>
														{ 
															modeLivraison == 1 
															? 
																<strong>Livraison</strong> 
															: 
																<strong>Recupération</strong> 
														}
														<span>
															<small>
																{ livraison } <Currency />
															</small>
														</span>
													</a>
												</figure>
												<figure className="ps-block__items">
													<a>
														<strong>Total</strong>
														<span>
															<small>
																<b>{ priceTotal } <Currency /></b>
															</small>
														</span>
													</a>
												</figure>
												<h4>Coupon de réduction</h4>
												<div className="ps-block__panel">
												<figure><small>Saisissez un code</small>
													<strong> 
														<Form.Item
															name  = "Code"
															rules={[
																{
																	message: couponError,
																	validator: ( value ) => {
																		if ( couponError === false ) {
																			return Promise.resolve();
																		} 
																		else {
																			return Promise.reject( couponError );
																		}
																	}
																}
															]}
														>
															<Input onChange={ onChangeCoupon } className="form-control" type="text" placeholder="BXXDCF" />
														</Form.Item>
													</strong> 
													
												</figure>
												<div>
													<button onClick={ handleClickEnvoyer } className="ps-btn ps-btn--fullwidth"><i className='fa fa-send'></i>&nbsp;Envoyer votre commande</button>
												</div>
												</div>
											</div>
											
										</div>
									
									</div>
									
								</div>
							</div>
						</Form>
					</div>
				</div>
			</section>
		</main>
			<Footer />
		</>
	);
};

export default Order;
