import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import { SiteContext } from "../context/site";

import { Space, Spin, Button, notification, message, Popconfirm } from 'antd';
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
	LoadingOutlined
} from '@ant-design/icons';

const Newsletter = () => {
	
	// custom hook context/site
	const { newletterSubscription } = useContext( SiteContext );

	// waiting spin
	const [ newsletterSpin, setNewsletterSpin ] = useState( 'none' );

	// email state
	const [ email, setEmail ] = useState( '' );
	
	// email input
	const handleChangeEmail = ( e ) => {
		setEmail( e.target.value )
	}
	
	// sen data
	const thankYou 		= '/newsletter/merci'
	const emailRegex 	= /^[a-zA-Z0-9. _-]+@[a-zA-Z0-9. -]+\.[a-zA-Z]{2,4}$/; 
	const navigate 		= useNavigate()
	const handleClickEnvoyer = async ( e ) => {
		e.preventDefault();
		
		if( !emailRegex.test( email ) ){
			message.error( 'Adresse email incorrect. Veuillez recommencer.' )
			return;
		}
		const sent = await newletterSubscription( email );

		if( !sent ){
			message.error( 'Une erreur est survenue. Veuillez recommencer.' );
			return;
		}
			
		navigate( thankYou );
	}
	
	return (
		<>
		<div className="ps-newsletter">
            <div className="ps-container">
                <form className="ps-form--newsletter" action="do_action" method="post">
                    <div className="row">
                        <div className="col-xl-5 col-lg-12 col-md-12 col-sm-12 col-12 ">
                            <div className="ps-form__left">
                                <h3>Newsletter</h3>
                                <p>Abonnez-vous et restez informé de toutes les bonnes affaires.</p>
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-12 col-md-12 col-sm-12 col-12 ">
                            <div className="ps-form__right">
                                <div className="form-group--nest">
                                    <input 
										className	= "form-control" 
										type		= "email" 
										placeholder	= "Adresse email" 
										value		= { email }
										onChange 	= { handleChangeEmail }
									/>
                                    <button 
										className	= "ps-btn"
										onClick		= { ( e ) => handleClickEnvoyer( e ) }
									>
										<Space>
											<Spin
												indicator={
													<LoadingOutlined
														style={{
															fontSize: 		20,
															marginRight: 	'10px',
															display:		newsletterSpin
														}}
														spin
													/>
												}
											/>
										</Space>
										Envoyer
									</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
		</>
	);
};

export default Newsletter;
