import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';


const ShopCategoryItem = ( val ) => {
	const category 		= val.item.category;
	const path 			= val.item.path;
	const imageSrc		= val.item.imageSrc;
	const categories 	= val.item.categories;

	const navigate = useNavigate();

	const handleClickItem = () => {
		navigate ( '/' + path, {
			params: {
				selection:	'',
				categoryName:	category,
				categoryPath:	path,
				selectionName: '',
				categories: categories,
			}
		});
		// navigate(0);
	}
	
	// helper
	function capitalizeFirstLetter( string ) {
		if( !string || string === null )
			return
		
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
	
	return (
		<>
			<div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-6 ">
                <div className="ps-block--category">
					<a className="ps-block__overlay" onClick={ handleClickItem } style={{ cursor:'pointer' }} ></a>
					<img src={imageSrc} alt="" />
					
                    <p>{ capitalizeFirstLetter( category ) }</p>
                </div>
            </div>
		</>
	);
};

export default ShopCategoryItem;
