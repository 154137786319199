import React, { useState, useEffect, useContext } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ShoppingPageItemSimple from './ShoppingPageItemSimple.js';
import { StoreContext } from '../context/store';
import { useNavigate } from 'react-router-dom';


const ShopCategory = ( params ) => {

	const categoryName		= params.params.category;
	const displayedName 	= params.params.displayedName;
	const limit 			= params.params.limit;
	const categoryPath		= params.params.path;
	const categories		= params.params.categories;
	const selectionName		= params.params.selectionName ? params.params.selectionName : '';

	const { getACategoryProducts } 	= useContext( StoreContext );
	const[ products, setProducts ] = useState( [] );
		
	const navigate 			= useNavigate();
	const handleClickVoirTout = () => {
		navigate ( '/' + categoryPath, {
			params: {
				pageName: 	displayedName,
				path:		categoryPath,
				category:	categoryName,
				categories:	categories,
				selectionName: selectionName,
			}
		});
		//navigate(0);
	}

	// set product badge
	const getBadge = ( productPrice, productPriceSale ) => {
		const rep = ( ( productPriceSale - productPrice )/productPriceSale ) * 100;
		
		if( isNaN( rep ) || rep == 'Infinity' || rep == '-Infinity' )
			return ''
		
		return	'-' + Math.trunc( rep ) + '%'
	}

	const BuildItems = () => {
		return (
			products.map(( product, index ) => 
					<ShoppingPageItemSimple 
						key  = { index } 
						item = {{
							name: 			product.productName, 
							price: 			product.productPrice.toString().replace(/(?=(?:.{3})*$)/g, ' '),
							priceOld:		product.productPriceSale ? product.productPriceSale.toString().replace(/(?=(?:.{3})*$)/g, ' ') : '',
							description: 	product.productLineDescription, 
							picture: 		product.productImageUrl ? product.productImageUrl : product.productImage[0],
							pictures: 		product.productImage,
							badge:			getBadge( product.productPrice, product.productPriceSale ),
							brand:			product.brand,
							productDescription: product.productDescription,
							productId: 		product.productId,
							productColor: 	product.productColor,
							productSize:	product.productSize,
							selection:		selectionName,
							category:		categoryName,
							categories: 	categories,
							categoryPath:	categoryPath,
						}}
					/>
			)
		)
	}

	//
	useEffect( () => {
		// set the products state
		const getProducts = async () => {
			const categoryProducts = await getACategoryProducts( categoryName );
			setProducts ( categoryProducts );
		}
		getProducts();
	}, [ params.params.category ] );

	return (
		<>
			<div className="ps-section__header">
				<h3>{ displayedName }</h3>
                <ul className="ps-section__links">
					<li><a style={{cursor: 'pointer'}} onClick={ handleClickVoirTout } >Voir tout</a></li>
                </ul>
            </div>
            <div className="ps-section__content" style={{paddingTop: '5px'}}>
                <OwlCarousel 
					className		= 'owl-theme' 
					loop margin		= { 2 }  
					nav 			= { true }
					navigation		= { false }
					items			= { 10 }
					margin			= { 20 }
					autoHeight 		= { true }
					responsiveClass	= { true }
					responsive		= {
										{ 
											0: {
												items:2
											},
											600: {
												items:3
											},
											1000: {
												items:5
											}
										}
									}
				>
					<BuildItems />
                </OwlCarousel>
            </div>
		</>
	);
};

export default ShopCategory;
