import React, { useState, useEffect, useContext } from "react";
import { CartContext } from '../context/cart';

const ProductCartCount = () => {
	
	const { getCartProductCount } 	= useContext( CartContext );
	const cartProductCount 			= getCartProductCount();


	return (
		<>
		{ cartProductCount }
		</>
	);
};

export default ProductCartCount;
