import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import { AuthContext } from "../context/AuthProvider";
import { SiteContext } from "../context/site";
import { HashLink  } from 'react-router-hash-link';
import { Form, Input, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Space, Spin, Button, notification, message, Popconfirm } from 'antd';
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
	LoadingOutlined
} from '@ant-design/icons';

import useDidMountEffect from './useDidMountEffect';

import '../loginOverrides.css';

const Login = () => {
	
	const { isValidPassword }	= useContext( AuthContext );
	
	// active - login
	const [ activeLogin, setActiveLogin ] = useState( 'block' );
	const [ activeLoginStyle, setActiveLoginStyle ] = useState( { color: 'red' } );
	
	// active - register
	const [ activeRegister, setActiveRegister ] 			= useState( 'none' );
	const [ activeRegisterStyle, setActiveRegisterStyle ] 	= useState( {} );
	
	// login email
	const [ connexionEmail, setConnexionEmail ] = useState( '' );
	const [ connexionEmailError, setConnexionEmailError ] = useState( false );
	const handleChangeConnexionEmail = ( e ) => {
		// display the typed data
		setConnexionEmail( e.target.value );
		// realtime validation for error messages
		if( !e.target.value ){
			setConnexionEmailError( 'Saisir une adresse email svp.' );
		}
		else if( !regexEmailValidation.test( e.target.value ) ) {
			setConnexionEmailError( 'Adresse email non valide.' );
		}
		else{
			setConnexionEmailError( false )
		}
	}
	
	// login password
	const [ connexionPassword, setConnexionPassword ] = useState( '' );
	const [ connexionPasswordError, setConnexionPasswordError ] = useState( false );
	const handleChangeConnexionPassword = ( e ) => {	
		// display the typed data
		setConnexionPassword( e.target.value );
		// realtime validation for error messages
		if( e.target.value.length < 7 || e.target.value.length > 100 ) {
			setConnexionPasswordError( 'Votre mot de passe est incorrect.' )
		}
		else{
			setConnexionPasswordError( false )
		}
	}
	
	// forgot password
	const [ passwordForgot, setPasswordForgot ] = useState( '' );
	const handleChangePasswordForgot = ( e ) => {	
		setPasswordForgot( e.target.value );
	}
	
	// subscribe email
	const [ subscribeEmail, setSubscribeEmail ] = useState( '' );
	const [ subscribeEmailError, setSubscribeEmailError ] = useState( false );
	const handleChangeSubscribeEmail = ( e ) => {	
		// display the typed data
		setSubscribeEmail( e.target.value );
		// realtime validation for error messages
		if( !e.target.value ){
			setSubscribeEmailError( 'Saisir une adresse email svp.' );
		}
		else if( !regexEmailValidation.test( e.target.value ) ) {
			setSubscribeEmailError( 'Adresse email non valide.' );
		}
		else{
			setSubscribeEmailError( false )
		}
	}
	
	// subscribe password
	const [ subscribePassword, setSubscribePassword ] = useState( '' );
	const [ subscribePasswordError, setSubscribePasswordError ] = useState( false );
	const handleChangeSubscribePassword = ( e ) => {
		// display the typed data
		setSubscribePassword( e.target.value );
		// realtime validation for error messages
		if( !e.target.value ){
			setSubscribePasswordError( 'Saisir un mot de passe svp.' );
		}
		else if( isValidPassword( e.target.value ) !== true ) {
			setSubscribePasswordError( 'Le mot de passe doit avoir 6 à 100 caractères, lettres majuscules et minuscules et au moins un chiffre.' );
		}
		else{
			setSubscribePasswordError( false );
		}
		
	}
	
	// subscribe nom
	const [ subscribeNom, setSubscribeNom ] = useState( '' );
	const [ subscribeNomError, setSubscribeNomError ] = useState( false );
	const handleChangeSubscribeNom = ( e ) => {	
		// display the typed data
		setSubscribeNom( e.target.value );
		// realtime validation for error messages
		if( !e.target.value ){
			setSubscribeNomError( 'Saisir votre nom.' );
		}
		else{
			setSubscribeNomError( false );
		}
	}

	// subscribe prenom
	const [ subscribePrenom, setSubscribePrenom ] = useState( '' );
	const [ subscribePrenomError, setSubscribePrenomError ] = useState( false );
	const handleChangeSubscribePrenom = ( e ) => {	
		// display the typed data
		setSubscribePrenom( e.target.value );
		// realtime validation for error messages
		if( !e.target.value ){
			setSubscribePrenomError( 'Saisir votre prénom.' );
		}
		else{
			setSubscribePrenomError( false );
		}
	}

	// subscribe checkbox
	const [ subscribeCheckbox, setSubscribeCheckbox ] = useState( '' );
	const changeSubscribeCheckbox = ( e ) => {
		if( subscribeCheckbox )
			setSubscribeCheckbox( '' )
		else
			setSubscribeCheckbox( 'checked' )
	}

	// Regex
	const regexEmailValidation = /^[a-zA-Z0-9. _-]+@[a-zA-Z0-9. -]+\.[a-zA-Z]{2,4}$/; 
	// const regexPasswordValidation = /(\d+[a-zA-Z!\*\-\+&éàçè\'\(\)ç]*){6,100}/
	
	// Connexion
	const { logIn }	= useContext( AuthContext );
	const { getReferrer }	= useContext( SiteContext );

	// waiting spin
	const [ loginSpin, setLoginSpin ] 			= useState( 'none' );
	const [ subscribeSpin, setSubscribeSpin ] 	= useState( 'none' );
	
	// post data definition
	async function postData( url, data, method ) {
		const response = await fetch( url, {
			method: "POST", // *GET, POST, PUT, DELETE, etc.
			// mode: "no-cors", // no-cors, *cors, same-origin
			headers: {
				"Content-Type": "application/json",
				// 'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: JSON.stringify( data ), // body data type must match "Content-Type" header
		});
		return response.json(); // parses JSON response into native JavaScript objects
	}
	
	const navigate 	= useNavigate();
	
	const handleClickLogin = ( event ) => {
		event.preventDefault();
		setActiveRegister( 'none' );
		setActiveLogin( 'block' );
		setActiveRegisterStyle( {} );
		setActiveLoginStyle( { color: 'red' } );
	}
	
	const handleClickRegister = ( event ) => {
		event.preventDefault();
		setActiveLogin( 'none' );
		setActiveRegister( 'block' )
		setActiveLoginStyle( {} );
		setActiveRegisterStyle( { color: 'red' } )
	}
	
	const handleClickConnexion = ( event ) => {
		
		event.preventDefault();
		
		// spin
		setLoginSpin( 'block' );
		
		// reset all errors
		resetAllErrors();

		// validation connexion email
		if( !connexionEmail ){
			setLoginSpin( 'none' );
			message.error( 'Saisir une adresse email svp.' );
			setConnexionEmailError( 'Saisir une adresse email svp.' );
			// form.validateFields();
			return
		}
		if( !regexEmailValidation.test( connexionEmail ) ) {
			setLoginSpin( 'none' );
			message.error( 'Adresse email non valide.' );
			setConnexionEmailError( 'Adresse email non valide.' );
			// form.validateFields();
			return
		}
		// validate password
		if( connexionPassword.length < 7 || connexionPassword.length > 100 ) {
			setLoginSpin( 'none' );
			message.error( 'Votre mot de passe est incorrect.' );
			setConnexionPasswordError( 'Votre mot de passe est incorrect.' );
			// form.validateFields();
			return
		}
		// login
		const loginApiURL = 'https://allkakou.com/api/authentication/sign-in';
		const loginData = {
			password: connexionPassword,
			email: connexionEmail
		};
		const method = 'POST';
		
		const resp = postData( loginApiURL, loginData, method ).then( 
			( data ) => {
				// console.log( '**** data', data ); // JSON data parsed by `data.json()` call

				if( data.accessToken == null ){
					const status = data.status;
					if( status == 'NOT_FOUND' ){
						message.error( 'Il n\'y a pas de compte associé à cette e-mail.' );
						setConnexionEmailError( 'Il n\'y a pas de compte associé à cette e-mail.' );
						// form.validateFields();
					}
					else if( status == 401 ){
						message.error( 'L\'adresse email ou le mot de passe n\'est pas valide.' );
						setConnexionEmailError( 'L\'adresse email ou le mot de passe n\'est pas valide.' );
						// form.validateFields();						
					}
					setLoginSpin( 'none' );
					return;
				}
				setConnexionEmailError( false );
				logIn( data );
				
				// Login redirect			
				const referrer  = getReferrer() != null ? getReferrer() : '';
				const loginPath	= '/connexion';
				const fallback	= '/accueil';
				const gotoUrl = !referrer ? fallback : referrer;
				navigate( gotoUrl );

				console.log( 'Loged in!' )
			}
		);
	}

	// Registration
	const handleClickRegistration = ( event ) => {
		
		event.preventDefault();
		
		// spin
		setSubscribeSpin( 'block' );
		
		// reset all errors
		resetAllErrors();
		
		// validation registration email
		if( !subscribeNom ){
			message.error( 'Saisir votre nom svp.' );
			setSubscribeNomError( 'Saisir votre nom svp.' );
			// form.validateFields();
			setSubscribeSpin( 'none' );
			return
		}
		if( !subscribePrenom ){
			message.error( 'Saisir votre prénom svp.' );
			setSubscribePrenomError( 'Saisir prénom nom svp.' );
			// form.validateFields();
			setSubscribeSpin( 'none' );
			return
		}
		if( !subscribeEmail ){
			message.error( 'Saisir une adresse email svp.' );
			setSubscribeEmailError( 'Saisir une adresse email svp.' );
			// form.validateFields();
			setSubscribeSpin( 'none' );
			return
		}
		if( !regexEmailValidation.test( subscribeEmail ) ) {
			message.error( 'Adresse email non valide.' );
			setSubscribeEmailError( 'Adresse email non valide.' );
			// form.validateFields();
			setSubscribeSpin( 'none' );
			return
		}
		// validation registration password
		if( !subscribePassword ){
			message.error( 'Saisir un mot de passe svp.' );
			setSubscribePasswordError( 'Saisir un mot de passe svp.' );
			// form.validateFields();
			setSubscribeSpin( 'none' );
			return
		}
		if( isValidPassword( subscribePassword ) !== true ) {
			message.error( 'Le mot de passe doit avoir 6 à 100 caractères, lettres majuscules et minuscules et au moins un chiffre.', [6] );
			setSubscribePasswordError( 'Le mot de passe doit avoir 6 à 100 caractères, lettres majuscules et minuscules et au moins un chiffre.' );
			// form.validateFields();
			setSubscribeSpin( 'none' );
			return
		}
		
	// Spin
		
		// subscribe
		const signupApiURL = 'https://allkakou.com/api/authentication/sign-up';
		const method = 'POST';
		const subscribeData = {
			id: 			'',
			username: 		'',
			password: 		subscribePassword,
			email: 			subscribeEmail,
			newPassword: 	'',
			firstName: 		subscribeNom,
			lastName: 		subscribePrenom,
			newsLetter:		subscribeCheckbox == 'checked' ? true : false,
			role:			'ROLE_USER'
		};

		const resp = postData( signupApiURL, subscribeData, method ).then( 
			( data ) => {
				console.log( '**** data', data ); // JSON data parsed by `data.json()` call

				if( data.personId == null ){
					const error_message = data.message == 'The person already exist.' ? 'L\'utilisateur existe déja.' : data.message;
					message.error( error_message );
					
					return;
				}
				
				// keep user info
				logIn( data )
				
				// goto validation
				const validationPath	= '/connexion/email-validation';
				
				navigate( validationPath );
			}
		)
	}
	
	const [form] = Form.useForm();
	
	useEffect(() => {
		setSubscribeSpin( 'none' );
		setLoginSpin( 'none' )
	}, []);

	// useEffect(() => {
		// form.validateFields();
	// }, [ connexionEmailError, connexionPasswordError, subscribeEmailError, subscribePasswordError, subscribeNomError, subscribePrenomError ] );

	// reset all the error messages
	const resetAllErrors = () => {
		setConnexionEmailError( false );
		setConnexionPasswordError( false );
		setSubscribeEmailError( false );
		setSubscribePasswordError( false );
		setSubscribeNomError( false );
		setSubscribePrenomError( false );
	}
	

	// run after the first render
	useDidMountEffect(() => {
        form.validateFields();
    },  [ connexionEmailError ] );
	useDidMountEffect(() => {
        form.validateFields();
    },  [ connexionPasswordError ] );
	useDidMountEffect(() => {
        form.validateFields();
    },  [ subscribeEmailError ] );
	useDidMountEffect(() => {
        form.validateFields();
    },  [ subscribePasswordError ] );
	useDidMountEffect(() => {
        form.validateFields();
    },  [ subscribeNomError ] );
	useDidMountEffect(() => {
        form.validateFields();
    },  [ subscribePrenomError ] );
	
	return (
		<>
			<Header />
				<div className="container">
                <Form 
					style 		= {{ paddingTop: '25px' }} 
					className 	= "ps-form--account ps-tab-root" 
					action 	= "" 
					method 	= "get"
					layout 	= "vertical"
					form	= {form}
				>
                    <ul className="ps-tab-list">
                        <li>
							<Link 
								onClick={handleClickLogin}
							>
								<span style={ activeLoginStyle }>Connexion</span>
							</Link>
						</li>
                        <li>
							<Link 
								onClick={handleClickRegister}
							>
								<span style={ activeRegisterStyle }>Inscription</span>
							</Link>
						</li>
                    </ul>
                    <div className="">
                        <div style={{ display: activeLogin }} >
                            <div className="ps-form__content">
                                <h5>Connectez-vous</h5>
                                <div className="form-group">
								<Form.Item
									name  = "Email"
									rules={[
										{
											message: connexionEmailError,
											validator: ( value ) => {
												if ( connexionEmailError === false ) {
													return Promise.resolve();
												} 
												else {
													return Promise.reject( connexionEmailError );
												}
											}
										}
									]}
								>
								<Input 
									value={connexionEmail} 
									onChange={handleChangeConnexionEmail}
									className="form-control" 
									type="text" 
									placeholder="Email" 
								/>
								</Form.Item>
                                </div>
                                <div className="form-group form-forgot">
									<Form.Item
										name  = "Password"
										rules={[
											{
												message: connexionPasswordError,
												validator: ( value ) => {
													if ( connexionPasswordError === false ) {
														return Promise.resolve();
													} 
													else {
														return Promise.reject( connexionPasswordError );
													}
												}
											}
										]}
									>
										<input 
											value={connexionPassword}
											className="form-control" 
											type="password" 
											placeholder="Mot de passe" 
											onChange={handleChangeConnexionPassword}
										/>
									</Form.Item>
                                </div>
								<Link to='/connexion/mot-de-passe-oublie'>
									Mot de passe oublié?
								</Link>
								<br/><br/>
                                <div className="form-group">
                                    <div className="ps-checkbox">
                                        <input 
											onChange= {handleChangePasswordForgot}
											value = {passwordForgot}
											className="form-control" 
											type="checkbox" 
											id="remember-me" 
											name="remember-me" 
										/>
                                        <label for="remember-me">Se souvenir de moi</label>
                                    </div>
                                </div>
                                <div className="form-group submtit">
                                    <p>&nbsp;</p>
									<button 
										className="ps-btn ps-btn--fullwidth"
										onClick={handleClickConnexion}
									>
										<Space>
											<Spin
												indicator={
													<LoadingOutlined
														style={{
															fontSize: 		20,
															marginRight: 	'10px',
															display:		loginSpin
														}}
														spin
													/>
												}
											/>
										</Space>
										Connexion
									</button>
									<p>&nbsp;</p>
                                </div>
                            </div>
                        </div>
							<div style={{ display: activeRegister }} >
                            <div className="ps-form__content">
                                <h5>Créer un compte</h5>
								<div className="form-group" style={{display: 'flex', marginBottom: '0px' }}>
									<div className="form-group" >
										<Form.Item
											name  = "Name"
											rules={[
												{
													message: subscribeNomError,
													validator: ( value ) => {
														if ( subscribeNomError === false ) {
															return Promise.resolve();
														} 
														else {
															return Promise.reject( subscribeNomError );
														}
													}
												}
											]}
										>
											<Input 
												onChange= {handleChangeSubscribeNom}
												value = {subscribeNom}
												className="form-control" 
												type="text" 
												placeholder="Nom" 
											/>
										</Form.Item>
									</div>
									&nbsp;
									<div className="form-group">
										<Form.Item
											name  = "Prenom"
											rules={[
												{
													message: subscribePrenomError,
													validator: ( value ) => {
														if ( subscribePrenomError === false ) {
															return Promise.resolve();
														} 
														else {
															return Promise.reject( subscribePrenomError );
														}
													}
												}
											]}
										>
											<Input 
												onChange= {handleChangeSubscribePrenom}
												value = {subscribePrenom}
												className="form-control" 
												type="text" 
												placeholder="Prénom" 
											/>
										</Form.Item>
									</div>
								</div>
                                <div className="form-group">
									<Form.Item
										name  = "Subscribe Email"
										rules = {[
											{
												message: subscribeEmailError,
												validator: ( value ) => {
													if ( subscribeEmailError === false ) {
														return Promise.resolve();
													} 
													else {
														return Promise.reject( subscribeEmailError );
													}
												}
											}
										]}
									>
										<Input 
											onChange= {handleChangeSubscribeEmail}
											value = {subscribeEmail}
											className="form-control" 
											type="text" 
											placeholder="Adresse E-mail" 
										/>
									</Form.Item>
                                </div>
                                <div className="form-group">
									<Form.Item
										name  = "Password subscribe"
										rules={[
											{
												message: subscribePasswordError,
												validator: ( value ) => {
													if ( subscribePasswordError === false ) {
														return Promise.resolve();
													} 
													else {
														return Promise.reject( subscribePasswordError );
													}
												}
											}
										]}
									>
										<Input 
											onChange= {handleChangeSubscribePassword}
											value = {subscribePassword}
											className="form-control" 
											type="password" 
											placeholder="Mot de passe" 
										/>
									</Form.Item>
                                </div>
								<div className="form-group">
									<div className="ps-checkbox" >
										<input 
											onChange= {changeSubscribeCheckbox}
											checked = {subscribeCheckbox}
											className="" 
											type="checkbox" 
											id = "chk"
										/>
										<label for="chk" >Recevoir de l'inspiration, des promotions marketing et des mises à jour par e-mail</label>
									</div>
                                </div>
                                <div className="form-group submtit">
									<p>&nbsp;</p>
                                    < button 
										className="ps-btn ps-btn--fullwidth"
										onClick={handleClickRegistration}
									>
										<Space>
											<Spin
												indicator={
													<LoadingOutlined
														style={{
															fontSize: 		20,
															marginRight: 	'10px',
															display:		subscribeSpin
														}}
														spin
													/>
												}
											/>
										</Space>
										Créer mon compte
									</button>
									<p>&nbsp;</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
				
            </div>
			<Newsletter />
			<Footer />
		</>
	);
};

export default Login;
