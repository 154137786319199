import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import { StoreContext } from '../context/store';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

const Filters = ( params ) => {

	// context custom hook
	const { reorderByProductNameAZ, reorderByProductNameZA, reorderByPriceUp, reorderByPriceDown } = useContext( StoreContext );
	
	const [ selectedOption, setSelectedOption ] = useState('');

	const handleSelectFilterByName = ( event ) => {

		setSelectedOption( event.target.text );
		const type = event.target.value;
		if( type == 'AZ' )
			reorderByProductNameAZ();
		else if( type == 'ZA' )
			reorderByProductNameZA();
		else if( type == 'PriceUp' )
			reorderByPriceUp();
		else if( type == 'PriceDown' )
			reorderByPriceDown();
	}

	useEffect( () => {
	}, [] ); // Include cartItems as a dependency here

	return (
		<> 
			<select 
				value				= { selectedOption } 
				onChange			= { handleSelectFilterByName } 
				className			= "ps-select" 
				data-placeholder	= "Sort Items"
			>
				<option value = '' >Trier par </option>
				<option value = { 'AZ' } >Nom de produit: de A à Z</option>
				<option value = { 'ZA' } >Nom de produit: de Z à A</option>
				<option value = { 'PriceUp' } >Prix: du plus petit au plus grand</option>
				<option value = { 'PriceDown' } >Prix: du plus grand au plus petit</option>
			</select>
		</>
	);
};

export default Filters;
