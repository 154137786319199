import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import BlogMenu from './BlogMenu';
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import SocialBar from "./SocialBar";
const BlogPage = ( params ) => {

	
	return (
		<>
		<Header />
			<div class="ps-page--single" id="about-us">
        <div class="ps-about-intro">
            <div class="container">
                <div class="ps-section__header">
                    <h4>Bienvenue sur 237 USA</h4>
                    <h3>Nous vous apportons des produits d'origine américaine pour vous habiller et vous équiper à bas prix. </h3>
					<p>Nous livrons des articles de bonne qualité à des centaines d'acheteurs de toutes classes sociales tout en créant des opportunités économiques pour tous.</p>
                    <p>237 USA a commencé avec une vision simple où la technologie peut être combinée avec une conception inspirante pour aider les gens à accéder facilement et durablement à des articles authentique et fiable.</p>
					<p>Nous fournissons un service centré sur l'humain d'où notre engagement à choisir, trier et mettre à votre disposition des articles de qualités au plus près de chez vous.</p>
                </div>
                <div class="ps-section__content">
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6 ">
                            <div class="ps-block--icon-box"><i class="icon-cube"></i>
                                <h4>5000</h4>
                                <p>Articles à vendre</p>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6 ">
                            <div class="ps-block--icon-box"><i class="icon-store"></i>
                                <h4>10</h4>
                                <p>Points de recupération</p>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6 ">
                            <div class="ps-block--icon-box"><i class="icon-bag2"></i>
                                <h4>250</h4>
                                <p>Achetteurs actifs</p>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6 ">
                            <div class="ps-block--icon-box"><i class="icon-cash-dollar"></i>
                                <h4>1500</h4>
                                <p>Promotions en cours</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
	<Newsletter />
	<Footer />
		</>
	);
};

export default BlogPage;
