import React, { useState, useEffect, useContext } from "react";
import { StoreContext } from '../context/store';
const ShopItemColors = ( params ) => {

	const { getColorCode } 	= useContext( StoreContext );
	const colorStr 			= params.params;
	const colors 			= colorStr.split( '/' );
	const [ s, setS ] 		= useState( colors.length > 1 ? 's' : '' );
	
	const BuildColors = () => {
		
		const colorsUpdated = getColorCode( colors );

		// return the jsx code
		return(
			colorsUpdated.map( ( color, index ) =>
				<div key={ index } >
					{ color[ 'colorName' ] }
				</div>
			)
		)
	}

	return (
		<>
			<div className="ps-product__variations">
                <figure>
                    <figcaption>Couleur{ s } du produit</figcaption>
                    <BuildColors />
                </figure>
            </div>
		</>
	);
};

export default ShopItemColors;
