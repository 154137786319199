import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from 'react-router-dom';
import DashboardHeader from "./DashboardHeader"; 
import DashboardFooter from "./DashboardFooter"; 
import ReactPaginate from 'react-paginate';
import { AuthContext } from "../context/AuthProvider";
import { SiteContext } from '../context/site';
import { StoreContext } from '../context/store';
import { AutoComplete, Select  } from 'antd';
import Filters from "./Filters";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import DashboardModalProductsDetails from "./DashboardModalProductsDetails"; 
import DashboardModalProductsDelete from "./DashboardModalProductsDelete"; 
import DashboardModalCategoryDelete from "./DashboardModalCategoryDelete"; 

const DashboardProductList = () => {

	const { getAllProducts }  = useContext( StoreContext );
	const [ productCount, setProductCount ] = useState( [] );

	const [ allProducts, setAllProducts ] = useState( [] );

	const [ selectedProduct, setSelectedProduct ]  	= useState( {} );
	const [ selectedProductName, setSelectedProductName ]  	= useState( '' );
	const [ selectedProductId, setSelectedProductId ]  		= useState( '' );
	const [ selectedProductImage, setSelectedProductImage ] = useState( '' );
	const [ selectedProductCategory, setSelectedProductCategory ] = useState( '' );
	const [ selectedProductSubCategory , setSelectedProductSubCategory ] 	= useState( '' );
	const [ selectedProductColor , setSelectedProductColor ] 				= useState( '' );
	const [ selectedProductDescription , setSelectedProductDescription ] 	= useState( '' );
	const [ selectedProductActivated , setSelectedProductActivated ] 	= useState( '' );

	const [ catSubCats, setCatSubCats ] =  useState( [] );
	const [ selectedCategory, setSelectedCategory ] =  useState( [] );

	const [ autoCompleteOptions, setAutoCompleteOptions ] = useState( [] );
	const [ autoCompleteInputValue, setAutoCompleteInputValue ] = useState( '' );
	const [ autoCompleteSelectedOption, setAutoCompleteSelectedOption ] = useState('');
	const autoCompleteOnSelect = ( data, option ) => {

		setAutoCompleteSelectedOption( option );
		setAutoCompleteInputValue( option.label );

		const product = allProducts.filter( e => e.productId == data )[ 0 ];
		setSelectedProduct ( product );
		setSelectedProductId ( product.productId );
		setSelectedProductName( product.productName );
		setSelectedProductImage( product.productImageUrl );
		setSelectedProductCategory( product.category );
		setSelectedProductSubCategory( product.subCategory );
		setSelectedProductColor( product.productColor );
		setSelectedProductDescription( product.productLineDescription );
		setSelectedProductActivated( product.productActived );

		setOpenDashboardModalProductsDetails( true );

	};
	const autoCompleteInputChange = ( data, option ) => {
		setAutoCompleteInputValue( data );
		setAutoCompleteSelectedOption( option ); // to remove selected option when user types  something wich doesn't match with any option
	};

	const { setProductQuantity, getProductQuantity } = useContext( SiteContext ); 
	
	const handleClickProduct = ( product ) => {
		setSelectedProduct ( product );
		setSelectedProductId ( product.productId );
		setSelectedProductName( product.productName );
		setSelectedProductImage( product.productImageUrl );
		setSelectedProductCategory( product.category );
		setSelectedProductSubCategory( product.subCategory );
		setSelectedProductColor( product.productColor );
		setSelectedProductDescription( product.productLineDescription );
		setSelectedProductActivated( product.productActived );
		
		setOpenDashboardModalProductsDetails( true );
	}

	// 
	useEffect( () => {
		// 
		const getAll  = async () => {
			
			const all = await getAllProducts();	
			// const allActive = all.filter( ( x, y ) => !x.productActivated ); 
			setAllProducts( all );
			setProductCount( all.length );
console.log( 'all[ 0 ]', all[ 0 ] )
			// Auto cmoplet
			const autoCompleteData = all.map( ( e ) => ( 
				{ 
					label: e.productName, 
					value: e.productId,
					description: e.productLineDescription
				} 
			) );
			setAutoCompleteOptions( autoCompleteData );

			// Categories
			const categoriesUniques = Array();
			
			const categories = all.map( ( e ) => { 
				const obj = { 
					category: e.category, 
					subCategory: e.subCategory,
				}
				if ( !categoriesUniques.some( f => f.category === obj.category && f.subCategory === obj.subCategory ) ) {
					categoriesUniques.push( obj );
				}
			})
// console.log( categoriesUniques );
			setCatSubCats( categoriesUniques );
		}
		getAll();

	}, [] );

	// 
	const [currentPage, setCurrentPage] = useState(0);
	const PER_PAGE = 50;
	const offset = currentPage * PER_PAGE;
	const currentPageData = allProducts
		.slice(offset, offset + PER_PAGE);
	const pageCount = Math.ceil(allProducts.length / PER_PAGE);

	function handlePageClick({ selected: selectedPage }) {
		setCurrentPage(selectedPage);
	}

	// Product edit Modals
	const [ openDashboardModalProductsDetails, setOpenDashboardModalProductsDetails ] = useState(false);
	const onOpenDashboardModalProductsDetails  		= () => setOpenDashboardModalProductsDetails(true);
	const onCloseOpenDashboardModalProductsDetails 	= async () => {
		setOpenDashboardModalProductsDetails(false)
	};

	// Product delete Modals
	const [ openDashboardModalProductsDelete, setOpenDashboardModalProductsDelete ] = useState(false);
	const onOpenDashboardModalProductsDelete  		= () => setOpenDashboardModalProductsDelete(true);
	const onCloseOpenDashboardModalProductsDelete 	= async () => {
		setOpenDashboardModalProductsDelete(false)
	};

	// Category delete
	const [ openDashboardModalCategoryDelete, setOpenDashboardModalCategoryDelete ] = useState(false);
	const onOpenDashboardModalCategoryDelete  		= () => setOpenDashboardModalCategoryDelete(true);
	const onCloseOpenDashboardModalCategoryDelete 	= async () => {
		setOpenDashboardModalCategoryDelete(false)
	};

	// Get product quantity
	const ProductQuantity = async ( params ) => {
	
		const rep = await getProductQuantity( params.params.productId );
		if( !rep.quantity ){
			return(
				<button>remove</button>
			)
		}
		else{
			return(
				<span>{rep.quantity}</span>
			)
		}
	}
	
	// Delete empty
	const handleDeleteProducts = () => {
		setOpenDashboardModalProductsDelete( true );
	}
	
	
	// build products 
	const BuildItems = () => {
		return (
			currentPageData.map( ( product, index ) => 
				
				<div key  = { index }  className="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-6 ">
	
					<tbody>
						<tr>
							<td>
								<a 
									onClick 	= { ( e ) => handleClickProduct( product ) }
									style		= {{ cursor: 'pointer' }}
								>
									<img 
										style={{ maxHeight: '80px', maxWidth: '25px'  }} 
										src={ 'https://www.237usa.com/images/products/' + product.productImageUrl } 
										width="50" 
									/>
								</a>
							</td>
							<td>
								<a 
									style	= {{ cursor: 'pointer' }}
									onClick 	= { ( e ) => handleClickProduct( product ) }
								>
									{ product.productName }
								</a>
							</td>
						</tr>
					</tbody>
				</div>
			)
		)
	}

	const handleChangeCategory = ( catSubcat ) => {
		const category 		= catSubcat.split( '--' )[0];
		const subCategory 	= catSubcat.split( '--' )[1];
		
		const obj = { 
			category: 		category, 
			subCategory: 	subCategory,
		}
		
		setSelectedCategory( obj );
		
		setOpenDashboardModalCategoryDelete( true );
	}

	const BuildCategories = () => {
		return (
			<Select
				defaultValue = "Vider une sous-categorie"
				style = {{ width: '300px' }}
				onChange = {(value) => {
					handleChangeCategory(value)
				}}
				options 		= {
					catSubCats.map( ( catSubCat, index ) => {		
						const el = catSubCat.category + '--' + catSubCat.subCategory;
						return { value: el, label: el }
					})
				}
			/>
		)
	}


	return (
		<>
			<Modal open={ openDashboardModalProductsDetails } onClose={ onCloseOpenDashboardModalProductsDetails } center>
				<DashboardModalProductsDetails params =
					{{
						selectedProduct:	selectedProduct,
						productName: 		selectedProductName,
						productId: 			selectedProductId,
						productImage: 		selectedProductImage,
						productCategory: 	selectedProductCategory,
						productSubCategory: selectedProductSubCategory,
						productColor: 		selectedProductColor,
						productDescription: selectedProductDescription,
						productActived: 	selectedProductActivated,
					}}
				/>
			</Modal>

			<Modal open={ openDashboardModalProductsDelete } onClose={ onCloseOpenDashboardModalProductsDelete } center>
				<DashboardModalProductsDelete params =
					{{
						allProducts:	allProducts,
					}}
				/>
			</Modal>

			<Modal open={ openDashboardModalCategoryDelete } onClose={ onCloseOpenDashboardModalCategoryDelete } center>
				<DashboardModalCategoryDelete params =
					{{
						allProducts:	allProducts,
						category:		selectedCategory,
					}}
				/>
			</Modal>
			
			<DashboardHeader />

			<div className="ps-layout--shop">
                <div className="ps-layout__right">
                    <div className="ps-block--shop-features">
                        <div className="ps-block__header">
                            <h3>Liste des Produits</h3>
                        </div>
                    </div>
                    <div className="ps-shopping ps-tab-root">
                        <div className="ps-shopping__header">
                            <p><strong>{ productCount }</strong> Produits trouvés &nbsp;| &nbsp; <button onClick={ e => handleDeleteProducts() }>Retirer les produits en rupture de stock</button>  &nbsp;| &nbsp; <BuildCategories/>  </p>
							
                            <div className="ps-shopping__actions">
								<AutoComplete
									value 		= { autoCompleteInputValue }
									options		= { autoCompleteOptions }
									autoFocus 	= { true }
									style		= { {width: 200 }}
									filterOption = {( autoCompleteInputValue, option ) =>
										// option.label.toUpperCase().indexOf( autoCompleteInputValue.toUpperCase() ) !== -1
										option.label.toUpperCase().indexOf( autoCompleteInputValue.toUpperCase() ) !== -1 || 
										option.description.toUpperCase().indexOf( autoCompleteInputValue.toUpperCase() ) !== -1
										
									}
									onSelect	= { autoCompleteOnSelect }
									onChange	= { autoCompleteInputChange }
									placeholder	= "Chercher un article"
								/>
                            </div>
                        </div>
                        <div className="ps-tabs">
                            <div className="ps-tab active" id="tab-1">
                                <div className="ps-shopping-product">
                                    <div className="row">
										<BuildItems /> 
                                    </div>
									<div className="ps-pagination">
									<ReactPaginate
										previousLabel={"← Précédent"}
										nextLabel={"Suivant →"}
										pageCount={pageCount}
										onPageChange={handlePageClick}
										containerClassName={"pagination"}
										previousLinkClassName={"pagination__link"}
										nextLinkClassName={"pagination__link"}
										disabledClassName={"pagination__link--disabled"}
										activeClassName={"pagination_active"}
									/>
                                </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

			<DashboardFooter />
		</>
	);
};

export default DashboardProductList;
