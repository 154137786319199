import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import Currency from './Currency';
import { StoreContext } from '../context/store';
import '../priceSliderOverrides.css';

const FilterBySizeButton = ( params ) => {

	// context custom hook
	const { sizeList, sizeFiltered } = useContext( StoreContext );

	// buttons color states
	const [ colors, setColors ] = useState( [] );

	// display all
	const handleClickAll = () => {
		sizeFiltered();
		setColors (	new Array( sizeList.length ).fill( false ) ) // deselect all
	}

	// Build sizes buttons
	const BuildSizes = () => {
		return (
			sizeList.map( ( size, index ) => 
				<Link 
					key 	= { index } 
					id		= { 'button-' + index }
					onClick	= { () => handleSizeClick( index ) }
					style	= { { backgroundColor: colors[ index ] } }
				>
					{ size }
				</Link>
			)
		)
	}
	
	// On size button click
	const handleSizeClick = ( index ) => {
		const size = sizeList[ index ];

		// update choosen button collor
		const updatedColors = colors.map( ( e, k ) => k != index ? e = 'white' : e = '#fcb800' )
		setColors( updatedColors );
		
		// displayed filtered products
		sizeFiltered( size );
	}
	
	// Update clicked button
	
	
	useEffect(() => {
		if( !colors.length ){
			setColors( new Array( sizeList.length ).fill( 'white' ) )
		}
	}, [ sizeList ] ); // Include cartItems as a dependency here

	return (
		<>
			<BuildSizes />
			<p><Link onClick={ handleClickAll }>Toutes les tailles</Link></p>
		</>
	);
};

export default FilterBySizeButton;
