import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import { StoreContext } from '../context/store';

const FilterByBrandCheckBoxes = ( params ) => {

	// context custom hook
	const { brandList, brandFiltered } 	= useContext( StoreContext );

	// multiple checkboxes states
	const [ brandsCheckedState, setBrandsCheckedState ] = useState( [] ); 

	// a checkbox is checked
	const handleChangeBrand = ( position ) => {

		// method to build the new array of checked state
		const updatedBrandsCheckedState = brandsCheckedState.map( ( item, index ) =>
			index === position ? !item : item
		);
		setBrandsCheckedState( updatedBrandsCheckedState );

		// Filter displayed data to show selected
		const selectedBrands = brandList.filter( ( brand, index ) => updatedBrandsCheckedState[ index ] === true  ) ;
		brandFiltered( selectedBrands );
	}

	// display all
	const handleClickAll = () => {
		brandFiltered();
		setBrandsCheckedState (	new Array( brandList.length ).fill( false ) ) // deselect all
	}
	
	// Build brands filter
	const BuildBrandsFilter = () => {

		return(
			brandList.map( ( brand, index ) => 
				<div>
					<input 
						key			= { index }
						id			= { 'brand-' + index }
						checked 	= { brandsCheckedState[ index ] }
						onChange	= { () => handleChangeBrand(index) } 
						value		= { brand } 
						type		= "checkbox" 
						name		= "brand"
						style		= {{ width:'16px', height:'16px', }}
					/>
					&nbsp;<label key = { 'label-' + index } htmlFor={ index }>{ brand }</label>
				</div>
			)
		)	
	}
	
	useEffect(() => {
		if( !brandsCheckedState.length )
			setBrandsCheckedState (	new Array( brandList.length ).fill( false )	
		); // [ false, false, ... ]
	}, [ brandList ] ); // Include cartItems as a dependency here


	return (
		<>
			<BuildBrandsFilter />
			<br/>
			<p><Link onClick={ handleClickAll }>Afficher toutes les marques</Link></p>
		</>
	);
};

export default FilterByBrandCheckBoxes;
