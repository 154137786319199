import React, { useState, useEffect, useContext } from "react";
import { CartContext } from '../context/cart';
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
} from '@ant-design/icons';
import { Button, Divider, notification, message, Space } from 'antd';

	
const ShopBtnAddToCart = ( params ) => {
	const cartItem				= params.params.cartItem;
	const productSize			= params.params.selectedSize;
	const quantity				= params.params.quantity;
	
	// Antd notifications
	notification.config({
		placement: 'bottomRight',
		top: 150,
	});

	const openNotification = ( message ) => {			// Success add to cart
		notification.success({
			message: `${message}`,
			duration: 3,
			description: "Ce produit a été ajouté à vote panier.",
			placement: 'topRight',
		});
	};
	const openNotificationErrorNoSize = ( message ) => {			// Error no size selected
		notification.error({
			message: `${message}`,
			duration: 3,
			description: 'Veuillez choisir une taille',
			placement: 'topRight',
		});
	};

	const openNotificationErrorSoldout = ( message ) => {			// Error no size selected
		notification.error({
			message: `${message}`,
			duration: 5,
			description: 'Ce produit a été sollicité par bon nombre. Il est en repture de stock.',
			placement: 'topRight',
		});
	};


	// Ajouter au panier
	const { addToCart } = useContext( CartContext );
	const handleClickAjouter = () => {
		if( quantity == 0 ){
			openNotificationErrorSoldout( '' );
			return;
		}
		if( !productSize ){
			openNotificationErrorNoSize( '' );
			return;
		}
		else{
			addToCart( cartItem );
			openNotification( 'Success' );

			// close the modal
			const closeBtn = window.document.getElementsByClassName( 'react-responsive-modal-closeButton' );
			if( closeBtn.length )
				window.document.getElementsByClassName( 'react-responsive-modal-closeButton' )[ 0 ].click();
	
		}
		

	}
	
	// Not display Go to product page button if we are on a product page
	const location = useLocation();
	const { pathname, search } = location;
	const btnDisplay = ( () => {
		if( pathname.includes( '/article' ) )
			return 'none'
		else
			return 'block'
	} )();
	
	// return the jsx code
	return (
		<>
			<div style={{ marginTop: '15px' }}>
				<div className="ps-product__btn-group">
					<a onClick={ handleClickAjouter } title="Ajouter" className="ps-btn" style={{color:'white'}}>
						<i className="icon-bag2"></i>&nbsp;Ajouter au panier
					</a>
				</div>
			</div>
			
			<div style={{ marginTop: '15px' }}>
				<a className="ps-btn ps-btn--black" href={ cartItem.product.path } style={{ color:'white', display: btnDisplay }}>Voir page du produit</a>
			</div>
			
		</>
	);
};

export default ShopBtnAddToCart;

