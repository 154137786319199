import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from 'react-router-dom';
import DashboardHeader from "./DashboardHeader"; 
import DashboardFooter from "./DashboardFooter"; 
import ReactPaginate from 'react-paginate';
import { AuthContext } from "../context/AuthProvider";
import { SiteContext } from '../context/site';


const DashboardProductGenerateSQL = () => {

	// 
	useEffect( () => {

	}, [] );

	return (
		<>
			
			<DashboardHeader />

			<div className="ps-layout--shop">
                <div className="ps-layout__right">
                    <div className="ps-block--shop-features">
                        <div className="ps-block__header">
                            <h3>Generer du SQL</h3>
                        </div>
                    </div>
                    <div className="ps-shopping ps-tab-root" >
                        Foo
                    </div>
                </div>
            </div>

			<DashboardFooter />
		</>
	);
};

export default DashboardProductGenerateSQL;
