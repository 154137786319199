import React, { useState, useEffect, useContext } from "react";
import { CartContext } from '../context/cart';
import { SiteContext } from '../context/site';
import { useNavigate } from 'react-router-dom';
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import Currency from "./Currency";
import { Link } from "react-router-dom";
import ShopItemQuantityCart from "./ShopItemQuantityCart";
import { Button, notification, message, Popconfirm } from 'antd';
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
} from '@ant-design/icons';

const Cart = () => {
	
	const { 
		cartItems, 
		getCartTotal, 
		removeFromCart, 
		clearCart, 
		getCartProductCount 
	} = useContext( CartContext );

	const { siteURL } 	= useContext( SiteContext );
	const serverPicturesConst = siteURL + '/images/products/';
	
	const products 	= cartItems;
	const total 	=  getCartTotal().toString().replace(/(?=(?:.{3})*$)/g, ' ').trim();
console.log( products )
	// Antd
	const confirmRemove = ( product )  => {
		removeFromCart( product );
		message.success( 'Un article a été supprimé de votre panier' );
	};
	const confirmRemoveAll = ()  => {
		clearCart();
		message.success( 'Votre panier a été vidé!' );
	};
	const cancel = ()  => {
	};
	
	const BuildProducts = () => {
		return (
			products.map( ( product, index ) => 
				<tr>
                    <td data-label="Product">
                        <div className="ps-product--cart">
                            <div className="ps-product__thumbnail" style={{display: 'block'}}>
								<Link to={ product.product.path }>
									<img src={ serverPicturesConst + product.product.picture } alt="" />
								</Link>
							</div>
                            <div className="ps-product__content">
								<Link to={ product.product.path }>
									{ product.product.name }
								</Link>
                                <p>
									Size: <strong>{ product.product.size }</strong>
								</p>
                            </div>
                        </div>
                    </td>
                    <td className="price" data-label="Price">
						{ product.product.price }<Currency />
					</td>
                    <td data-label="Quantity">
						<ShopItemQuantityCart params= {{ product }} />
                    </td>
                    <td data-label="Total">
						{ ( product.product.price.replace( /\s/g, '' ) * product.quantity ).toString().replace(/(?=(?:.{3})*$)/g, ' ').trim() } <Currency />
					</td>
                    <td data-label="Actions">
						<Popconfirm
							title="Supprimer un produit"
							description={ 'Voulez vous supprimer ' + product.product.name + ', taille ' + product.product.size + ' de votre panier?'}
							onConfirm={ () => confirmRemove( product ) } 
							onCancel={cancel}
							okText="Oui"
							cancelText="Non"
						>
							<a classNameName="ps-product__remove" style={{ cursor: 'pointer' }} >
								<i classNameName="icon-cross"></i>
							</a>
						</Popconfirm>
					</td>
                </tr>
			)
		)
	}
	
	const BuildBill = () => {
		return (
			products.map( ( product, index ) => 
				<li>
					<span className="ps-block__shop">
						<Link to={ product.product.path }> 
							{ product.product.name } × { product.quantity }
						</Link>
					</span>
					<span className="ps-block__shipping">
						Size: { product.product.size }
					</span>
					<span className="ps-block__estimate">
						<strong>Prix: </strong>
						{ ( product.product.price.replace( /\s/g, '' ) * product.quantity ).toString().replace(/(?=(?:.{3})*$)/g, ' ').trim() } <Currency />
					</span>
				</li>
			)
		)
	}
	
	return (
		<>
		<Header />
		<div classNameName="ps-page--product reverse">
        <div classNameName="container">
            <div classNameName="ps-page__container">

		<div className="ps-page--simple">
        
        <div className="ps-section--shopping ps-shopping-cart" style={{ paddingTop: '25px' }}>
            <div className="container">
                <div className="ps-section__header" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                    <h1>Votre Panier </h1>
                </div>
                <div className="ps-section__content">
                    <div className="table-responsive">
                        <table className="table ps-table--shopping-cart ps-table--responsive">
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'left' }} >Nom du produit</th>
                                    <th style={{ textAlign: 'left' }} >Prix</th>
                                    <th style={{ textAlign: 'left' }} >Quantité</th>
                                    <th style={{ textAlign: 'left' }} >Total</th>
                                    <th style={{ textAlign: 'left' }} ></th>
                                </tr>
                            </thead>
                            <tbody>
								<BuildProducts />
                            </tbody>
                        </table>
                    </div>
                    <div className="ps-section__cart-actions">
						<div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">
                            <div className="ps-block--shopping-total">
                                <div className="ps-block__header">
                                    <p>Total <span> { total }<Currency /></span></p>
                                </div>
                                <div className="ps-block__content">
                                    <ul className="ps-block__product">
										<BuildBill />
                                    </ul>
                                    <h3>Total <span>{ total }<Currency /></span></h3>
                                </div>
                            </div>
							<Link className="ps-btn ps-btn--fullwidth" to="/commander">Commander</Link>
                        </div>
					</div>
                </div>

            </div>
        </div>
    </div>
              
            </div>
			
        </div>
		<Newsletter />
    </div>
	<Footer />
		</>
	);
};

export default Cart;
