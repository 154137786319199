import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import Newsletter from "./Newsletter";
import ShopSelection from "./ShopSelection";
import ShopCategoriesList from "./ShopCategoriesList";
import ShopCategory from "./ShopCategory";
import Header from "./Header";
import Footer from "./Footer";
import Shops from "./Shops";

import overrideOwl from "../overrideOwl.css";

const Index = ( params ) => {
// console.log( params );
	return (
		<>
		<Header/>
			<div id="homepage-1">
        <div className="ps-home-banner ps-home-banner--1">
            <div className="ps-container">
                <div className="ps-section__left">
					<Link to="/categories/hommes/jeans-et-pantalons">
						<img src="img/testBan10.jpg" />
					</Link>
                </div>
                <div className="ps-section__right">
					<Link className="ps-collection" to="/categories/hommes/chaussures/article?type=category&typeName=hommes/chaussures&name=Calvin%20Klein&productId=49729fb4-d295-4ee9-b713-e352ffe75661">
						<img src="img/promotion01.jpg" alt="" />
					</Link>
					<Link to="/categories/femmes/chaussures/article?type=category&typeName=femmes/chaussures&name=Victoria%20K&productId=e67b5bac-717c-4263-b1a6-4fb684474bab" className="ps-collection" href="#">
						<img src="img/promotion02.jpg" alt="" />
					</Link>
				</div>
            </div>
        </div>
        <div className="ps-site-features">
            <div className="ps-container">
                <div className="ps-block--site-features">
                    <div className="ps-block__item">
                        <div className="ps-block__left"><i className="icon-rocket"></i></div>
                        <div className="ps-block__right">
                            <h4>Livraison Gratuite</h4>
                            <p>Pour des achats de plus de<br /> 30 000FCFA</p>
                        </div>
                    </div>
                    <div className="ps-block__item">
                        <div className="ps-block__left"><i className="icon-sync"></i></div>
                        <div className="ps-block__right">
                            <h4>Points de Recupération</h4>
                            <p>Récupération gratuite de vos achats pres de chez vous</p>
                        </div>
                    </div>
                    <div className="ps-block__item">
                        <div className="ps-block__left"><i className="icon-credit-card"></i></div>
                        <div className="ps-block__right">
                            <h4>Payments Simplifiés</h4>
                            <p>Payez à la livraison avec votre opérateur mobile favoris</p>
                        </div>
                    </div>
                    <div className="ps-block__item">
                        <div className="ps-block__left"><i className="icon-bubbles"></i></div>
                        <div className="ps-block__right">
                            <h4>Assistance 24/7</h4>
                            <p>Assistance via téléphone, WhatsApp et Email</p>
                        </div>
                    </div>
                    <div className="ps-block__item">
                        <div className="ps-block__left"><i className="icon-gift"></i></div>
                        <div className="ps-block__right">
                            <h4>Des idées de cadeau</h4>
                            <p>Recevez de l'inspiration avec nos newsletters</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="ps-product-list ps-clothings">
            <div className="ps-container">
                <ShopSelection params = {{
					name: 	'sac a dos',
					title: 	'Sac à dos, prix spécial rentrée',
				}} />
            </div>
        </div>
		<div className="ps-product-list ps-clothings">
            <div className="ps-container">
                <ShopSelection params= {{
					name: 	'nouveau produits',
					title: 	'Nouveautés',
				}} />
            </div>
        </div>
		<div className="container">
			<div className="ps-block--shop-features">
				<div className="ps-block__header">
					<h3>Shopping par categories</h3>
				</div>
				<div className="row">
					<ShopCategoriesList params={{ 
						categories: params.params.categories.val,
					}}/>
				</div>
			</div>
		</div>
		
		<div className="ps-product-list ps-clothings">
            <div className="ps-container">
				<ShopSelection params = {{
					name: 	'US Jeans',
					title: 	'Sélection de Jeans originaux US',
				}}/>
            </div>
        </div>
		
		<div className="container">
			<div className="ps-block--shop-features">
				<div className="ps-block__header">
					<h3>Boutiques</h3>
				</div>
				<div className="row">
					<Shops />
				</div>
			</div>
		</div>
                                
		<p>&nbsp;</p>
		<p>&nbsp;</p>
		<div className="ps-product-list ps-clothings">
            <div className="ps-container">
                <ShopCategory params={{ 
					displayedName: 'Chaussures pour Femmes',
					category: 		'femmes/chaussures',
					limit: 			10,
					path: 			'categories/femmes/chaussures',
				}}/>
            </div>
        </div>
		<div className="ps-product-list ps-clothings">
            <div className="ps-container">
                <ShopCategory params={{ 
					displayedName: 	'Chaussures / baskets pour Hommes',
					category: 		'hommes/chaussures',
					limit: 			10,
					path: 			'categories/hommes/chaussures',
				}}/>
            </div>
        </div>
		<div className="ps-product-list ps-clothings">
            <div className="ps-container">
                <ShopCategory params={{ 
					displayedName: 	'Sacs pour Femmes',
					category: 		'femmes/sacs',
					limit: 			10,
					path: 			'categories/femmes/sacs',
				}}/>
            </div>
        </div>
		<div className="ps-product-list ps-clothings">
            <div className="ps-container">
				<ShopCategory params={{ 
					displayedName: 	'Articles pour Maison',
					category: 		'maison/interieur',
					limit: 			10,
					path: 			'categories/maison/interieur',
				}}/>
            </div>
        </div>
		<div className="ps-product-list ps-clothings">
            <div className="ps-container">
                <ShopSelection params= {{
					name: 	'derniere chance',
					title: 	'Dernière chance, plus q\'une pièce',
				}} />
            </div>
        </div>
        <div className="ps-home-ads">
            <div className="ps-container">
				<div className="row" style={{ marginLeft: '1%' }}>Publicité</div>
                <div className="row">
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 "><a target='_blanc' className="ps-collection" href="https://www.booking.com/hotel/cm/la-principaute-douala-douala.fr.html"><img src="img\pubs\HotelLaPrincipaute\ban01.jpg" alt="" /></a>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 "><a target='_blanc' className="ps-collection" href="#" href="https://www.booking.com/hotel/cm/la-principaute-douala-douala.fr.html"><img src="img\pubs\HotelLaPrincipaute\HotelLaPrincipauté530x240.jpg" alt="" /></a>
                    </div>
                </div>
            </div>
        </div>
       <p>&nbsp;</p>
        
			<Newsletter />
		</div>
		<Footer />
		</>
	);
};

export default Index;
