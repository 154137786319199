import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import { StoreContext } from '../context/store';
import ShopSelection from "./ShopSelection";
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import SocialBar from "./SocialBar";


const SearchPage = () => {
	const { clearProductList, productList } 	= useContext( StoreContext );
	
	// clear products list
	useEffect( () => {
		if( productList.length ){
			clearProductList();
		}
	}, [ productList ] );
	return (
		<>
			<Header />
				<div class="ps-page--404">
						<div class="ps-section__content">
							<h3>Rehercher un produit</h3>
						</div>
				</div>
			<Newsletter />
			<Footer />
		</>
	);
};

export default SearchPage;
