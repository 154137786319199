import React, { useState, useEffect } from "react";
import SecuredPagesAuth from "./SecuredPagesAuth";
import { Link } from "react-router-dom";

const Header = () => {
	const handleClickAddProduct = () => {
		 window.document.location.href = '/dashboard/product/edit/dev';
	}
	return (
		<>
		<SecuredPagesAuth />
		<header className="header header--1" data-sticky="true">
			<p><b>Panel de Gestion</b></p>
		</header>
		<p>
			<Link to='/dashboard/product/list'>Liste des produits</Link>&nbsp;|&nbsp;
			<Link to='/dashboard/product/list/inactifs' >Liste des produits inactifs&nbsp;|&nbsp;</Link>
			<Link to='/dashboard/product/list/dev' >Liste des produits à ajouter&nbsp;|&nbsp;</Link>
			<Link onClick = { e => handleClickAddProduct( e ) } >Ajouter un produit</Link>
		</p>
		<p>&nbsp;</p>
		</>
	);
};

export default Header;
