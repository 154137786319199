import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';

import Shops from "./Shops";

import Newsletter from "./Newsletter";
import Header from "./Header";
import Footer from "./Footer";

const Shop = ( params ) => {

	const pageName = 'Boutiques'
	useEffect( () => {

	}, [] );
	
	
	return (
		<>
		
		<Header/>
		<div class="ps-page--single ps-page--vendor">
        <section class="ps-store-list">
            <div class="container">
                
                <div class="ps-section__wrapper">
                    
                    <div class="ps-section__right">
                                <div className="ps-shopping-product">
									<div className="ps-block--shop-features">
										<div className="ps-block__header">
											<h3>{ pageName }</h3>
										</div>
									</div>
									 <div className="ps-shopping ps-tab-root">
									
									<div className="row">
										<Shops />
									</div>
                                </div>
                       </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <Newsletter />
	<Footer />
		
		
		
		
		
	
		</>
	);
};

export default Shop;
