import React from "react";


const Badge = ( badge ) => {	// To do : deal with all badges cases with diffferent colors
// console.log( badge.badge )
	const BadgeTag = () => {
		const badgeContent = badge.badge;
		if( badgeContent )
			return <div className="ps-product__badge"> { badgeContent } </div>
		else
			return <span />
	}

	return ( 
		<> 
			<BadgeTag/> 
		</> 
	)
	
};

export default Badge;
