import PropTypes from 'prop-types'
import { createContext, useState, useEffect } from 'react';
import { Space, Spin } from 'antd';
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
	LoadingOutlined
} from '@ant-design/icons';

export const StoreContext = createContext()

export const StoreProvider = ({ children }) => {
	
	// spiner
	const [ spiner, setSpiner ] = useState( 'none' );
	
	// initializing product list
	const [ productList, setProductList ] = useState( localStorage.getItem( 'productList' ) ? JSON.parse( localStorage.getItem( 'productList' ) ) : [] );
	
	// initializing product list to not change
	const [ productListSave, setProductListSave ] = useState( localStorage.getItem( 'productListSave' ) ? JSON.parse( localStorage.getItem( 'productListSave' ) ) : [] );
	
	// initializing brands List
	const [ brandList, setBrandList ] = useState( [] );

	// initializing prices List
	const [ highestPrice, setHighestPrice ] = useState( [] );

	// initializing sizes List
	const [ sizeList, setSizeList ] = useState( [] );

	// initializing colors List
	const [ colorList, setColorList ] = useState( [] );
	
	// helper: products validation
	const productsValidation = async ( products ) => {
// console.log( 'products', products );
		// validate each product
		var valides = [];
		var nonValides = [];
		for( const product of products  ){
			if( product.removed !== null && product.removed === false )
				valides.push( product )
			else
				nonValides.push( product )
		}
console.log( 'nonValides', nonValides );
console.log( 'valides', valides );
		
		// return valides
		return valides;
	}

	// helper: remove duplicates
	const removeDuplicates = ( arr ) => {
		return arr.reduce( ( total, e ) => { if( !total.includes( e ) ){ total.push( e )}; return total; }, [] );	
	}

	// helper: Fetch data definition
	async function fetchData( url, data, method, spiner ) {
		if( spiner !== false )
			setSpiner( 'block' )
		
		const response = await fetch( url, {
			method: method, // *GET, POST, PUT, DELETE, etc.
			// mode: "no-cors", // no-cors, *cors, same-origin
			headers: {
				"Content-Type": "application/json",
				// 'Content-Type': 'application/x-www-form-urlencoded',
			},
			// body: JSON.stringify( data ), // body data type must match "Content-Type" header
		});
		setTimeout( setSpiner, 2000, 'none' );

		return response.json(); // parses JSON response into native JavaScript objects
	}
	
	// helper: categories names ajustments before query
	const catAjust = ( cat ) => {
		if( cat == 'hommes' )
			return 'homme'
		else if( cat == 'femmes' )
			return 'femme'
		else if( cat == 'soldes-chaussures' )
			return 'chaussure'

		
		else
			return cat;
	}

	// helper: sub categories names ajustments before query
	const subcatAjust = ( subCat ) => {		
		if( subCat == 'chaussures' )
			return 'chaussure'
		if( subCat == 't shirt et chemises' )
			return 't-shirt-shirt'
		if( subCat == 'jeans-et-pantalons' )
			return 'jeans-pants'
		if( subCat == 'boxer-et-shorts' )
			return 'boxer-shorts'
		if( subCat == 'soins' )
			return 'soin'
		if( subCat == 'accessoires' )
			return 'accessoire'
		if( subCat == 'rouge à lèvres' )
			return 'rouge-a-levres'
		if( subCat == 'vernie à ongles' )
			return 'vernie-a-ongles'
		if( subCat == 'vernie à ongles' )
			return 'vernie-a-ongles'
		if( subCat == 'téléphones' )
			return 'telephone'
		if( subCat == 'écouteurs-et-baffles' )
			return 'ecouteur'
		if( subCat == 'chargeurs' )
			return 'chargeur'
		if( subCat == 'ordinateurs' )
			return 'ordinateur'
		if( subCat == 'soldes-pour-femmes' )
			return 'femme'
		if( subCat == 'soldes-pour-hommes' )
			return 'homme'
		if( subCat == 'soldes-maison' )
			return 'maison' 
		if( subCat == 'soldes-electronique' )
			return 'electronique' 
		if( subCat == 'soldes-chaussures' )
			return 'chaussure' 
		if( subCat == 'sacs' )
			return 'sac'
		return subCat;
	}
	
	// helper: colors, take an array of colors text and return an array of colors text and code object
	const getColorCode = ( colors ) => {
		var colorName 		= '';
		var colorCode 		= '';
		var originalName 	= '';
// console.log( 'colors', colors );
		const colorsAndCode = colors.map( 
			color => {
				if( color == 'Blanc' ){
					colorName 			= 'Blanc';
					colorCode 			= '#fff';
					originalName 		= 'Blanc';
				}
				else if( color == 'Blanche' ){
					colorName 			= 'Blanc';
					colorCode 			= '#fff';
					originalName 		= 'Blanche';
				}
				else if( color == 'Noir' ){
					colorName 			= 'Noir';
					colorCode 			= '#000';
					originalName 		= 'Noir';
				}
				else if( color == 'Argent' ){
					colorName 			= 'Argent';
					colorCode 			= '#C0C0C0';
					originalName 		= 'Argent';
				}
				else if( color == 'Brun Caramel' ){
					colorName 			= 'Brun Caramel';
					colorCode 			= '#A4562D';
					originalName 		= 'Brun Caramel';
				}
				else if( color == 'Violet mate' ){
					colorName 			= 'Violet mate';
					colorCode 			= '#4C3449';
					originalName 		= 'Violet mate';
				}
				else if( color == 'Glacier' ){
					colorName 			= 'Glacier';
					colorCode 			= '#5F6981';
					originalName 		= 'Glacier';
				}
				else if( color == 'Or' ){
					colorName 			= 'Or';
					colorCode 			= '#F0D2AD';
					originalName 		= 'Or';
				}
				else if( color == 'Rouge' ){
					colorName 			= 'Rouge';
					colorCode 			= '#B14447';
					originalName 		= 'Rouge';
				}
				else if( color == 'Rose' ){
					colorName 			= 'Rose';
					colorCode 			= '#F7879A';
					originalName 		= 'Rose';
				}
				else if( color == 'Bleue' || color == 'Bleu'){
					colorName 			= 'Bleue';
					colorCode 			= '#154572';
					originalName 		= 'Bleue';
				}
				else if( color == 'Violet' ){
					colorName 			= 'Violet';
					colorCode 			= '#452574';
					originalName 		= 'Violet';
				}
				else if( color == 'Vert' || color == 'Verte' ){
					colorName 			= 'Vert';
					colorCode 			= '#659600';
					originalName 		= 'Vert';
				}
				else if( color == 'Coral Blue' ){
					colorName 			= 'Coral Blue';
					colorCode 			= '#63708E';
					originalName 		= 'Coral Blue';
				}
				else if( color == 'Abyss Blue' ){
					colorName 			= 'Abyss Blue';
					colorCode 			= '#2F3E56';
					originalName 		= 'Abyss Blue';
				}
				else if( color == 'Blue' ){
					colorName 			= 'Blue';
					colorCode 			= '#046BC8';
					originalName 		= 'Blue';
				}
				else if( color == 'Bleu Marine' ){
					colorName 			= 'Bleu Marine';
					colorCode 			= '#7587B5';
					originalName 		= 'Bleu Marine';
				}
				else if( color == 'Gris' ){
					colorName 			= 'Gris';
					colorCode 			= '#B0AEB0';
					originalName 		= 'Gris';
				}
				else if( color == 'Léopard' ){
					colorName 			= 'Léopard';
					colorCode 			= '#e5cf8c';
					originalName 		= 'Léopard';
				}
				else if( color == 'Marron' ){
					colorName 			= 'Marron';
					colorCode 			= '#D37452';
					originalName 		= 'Marron';
				}
				else if( color == 'PAPAYA' ){
					colorName 			= 'Papaya';
					colorCode 			= '#EF6A40';
					originalName 		= 'PAPAYA';
				}
				else if( color == 'Tan' ){
					colorName 			= 'Tan';
					colorCode 			= '#F5A856';
					originalName 		= 'Tan';
				}
				else if( color == 'Camouflage' ){
					colorName 			= 'Camouflage';
					colorCode 			= '#999E7E';
					originalName 		= 'Camouflage';
				} 
				else if( color == 'Camo' ){
					colorName 			= 'Camo';
					colorCode 			= '#999E7E';
					originalName 		= 'Camo';
				} 
				else if( color == 'Multi' ){
					colorName 			= 'Multi';
					colorCode 			= '#fff';
					originalName 		= 'Multi';
				}
				else{
					colorName 			=  color;
					colorCode 			= '#fff';
					originalName 		= color;
				}
// console.log( colorName );
				return ( 
					{
						colorName: 		colorName,
						colorCode:		colorCode,
						originalName: 	originalName
					}
				)
			}
		)
console.log( colorsAndCode );
		return colorsAndCode;
	}

	// Helper: randomize array
	const shuffle = ( array ) => {
		let currentIndex = array.length;

		// While there remain elements to shuffle...
		while (currentIndex != 0) {

			// Pick a remaining element...
			let randomIndex = Math.floor(Math.random() * currentIndex);
			currentIndex--;

			// And swap it with the current element.
			[array[currentIndex], array[randomIndex]] = [
			  array[randomIndex], array[currentIndex]];
		}
	}

	// List all Products
	const getAllProducts = async ( storeName ) => {
		const url  = 'https://allkakou.com/api/product/api/search-products?productSearchName=%20';
		const method = 'GET';
		
		const data = { 
		};
		
		const resp = await fetchData( url, data, method );
		
		return resp;
	}

	// Products from a store
	const getAStoreProducts = async ( storeName ) => {

		if( !storeName || storeName == null )
			return
		
		// clear
		clearProductList();

		var url  = '';
		if( storeName == 'reason' )
			url =  'https://allkakou.com/api/product/api/find-product-by-name?productName=reason';
		else if( storeName == 'levis' )
			url =  'https://allkakou.com/api/product/api/find-product-by-name?productName=Levi';
		else if( storeName == 'us-polo-assn' )
			url =  'https://allkakou.com/api/product/api/find-product-by-name?productName=U.S.%20Polo%20Assn';
	

		const data = { 
		};


		// Query
		const method 		= 'GET';
		var storeProducts 	= await fetchData( url, data, method );
		storeProducts 		= await productsValidation( storeProducts );
		// keep the products states in the locage storage
		localStorage.setItem( 'productList', JSON.stringify( storeProducts ) );
		
		// set products states
		setProductList( storeProducts );
		
		// keep a copy that will not be change by the app
		setProductListSave( storeProducts );	

		// return for the module  that need it
		return storeProducts;
	}

	// Products from a Category
	const getACategoryProducts = async ( categoryName ) => {
		
		// check
		if( !categoryName || categoryName == null )
			return
		
		clearProductList();
		// setProductList( [] );
		// localStorage.setItem( 'productList', JSON.stringify( [] ) );
		
		// prepare query
		const cat 		= catAjust( categoryName.split( '/' )[0] );
		const subCat	= subcatAjust( categoryName.split( '/' )[ categoryName.split( '/' ).length -1 ] );
		
		// Query
		var categoryApiURL = '';
		if( cat == 'soldes' )
			categoryApiURL = 'https://allkakou.com/api/product/api/find-cate-as-sale?category=' + subCat
		else
			categoryApiURL = 'https://allkakou.com/api/product/api/find-cate-sub?category=' + cat + '&subCategory=' + subCat + ''

		const data = { 
			category: cat,
			subCategory: subCat
		};

		const method = 'GET';
		var categoryProducts = await fetchData( categoryApiURL, data, method );

		// vaidate all products
		categoryProducts = await productsValidation( categoryProducts );

		// save the products states in the locage storage
		localStorage.setItem( 'productList', JSON.stringify( categoryProducts ) );

		// set products states
		setProductList( categoryProducts );

		// save a copy that will not be changed by the app
		setProductListSave( categoryProducts );	

		// return for the module wich need it
		return categoryProducts;
	}

	// Products from a Selection
	const getASelectionProducts = async ( selection ) => {
		if( !selection || selection == null )
			return

		clearProductList();
		// setProductList( [] );
		// localStorage.setItem( 'productList', JSON.stringify( [] ) );
		
		// 
		var selectionApiURL  = '';
		if( selection == 'sac a dos' )
			selectionApiURL  = 'https://allkakou.com/api/product/api/find-product-as-subSubCategory?subSubCategory=sac-new-rentree-soclaire';
		else if( selection == 'US Jeans' )
			selectionApiURL =  'https://allkakou.com/api/product/api/find-cate-sub?category=homme&subCategory=jeans-pants';
		else if( selection == 'derniere chance' )
			selectionApiURL =  'https://allkakou.com/api/product/api/all-products-unique';
		else if( selection == 'nouveau produits' )
			selectionApiURL =  'https://allkakou.com/api/product/api/all-products-new-item';
		
		
		const data = { 
		};

		const method = 'GET';

		var selectionProducts = await fetchData( selectionApiURL, data, method );

		// validate each product
		selectionProducts = await productsValidation( selectionProducts );
		
		if( selection == 'sac a dos' )	// Todo:
			shuffle( selectionProducts )
		
		// save the products states in the locale storage
		localStorage.setItem( 'productList', JSON.stringify( selectionProducts ) );
		
		// set products states
		setProductList( selectionProducts );
		
		// save a copy that will not be change by the app
		setProductListSave( selectionProducts );	
		
		// return for the module  that need it
		return selectionProducts;
	}

	// get a product by id
	const getProductById = async ( productId, spiner ) => {

		if( !productId || productId == null )
			return

		const data = { productId: productId };

		const productApiURL = 'https://allkakou.com/api/product/api/find-product-id?productId=' + productId;
		const method  = 'GET';
		const product = await fetchData( productApiURL, data, method, spiner );

		return product;
	}

	// List all products brands in the category or selection
	const getBrandList = () => {
		// list all brand
		const listAll = productListSave.map( e => e.brand ); // we extract from productListSave not to change product list
		// remove dupicates
		const list = removeDuplicates( listAll );
		setBrandList( list );
	}
	
	// Filter products by brand
	const brandFiltered = ( brands ) => {
		if( !brands || !brands.length )
			setProductList( productListSave ) // display all
		else
			setProductList( productListSave.filter( e => brands.includes( e.brand ) ) ) // display selected brands
	}

	// Get highest price in the category or selection
	const getHighestPrice = () => {
		const r = productListSave.reduce( ( total, e ) => { if( e.productPrice > total ) total = e.productPrice; return total; }, 0  ); //  from productListSave not to 	
		setHighestPrice( r );
	}

	// Filter by price
	const priceRange = ( priceArray ) => {
		
		setProductList( productListSave.filter( e => priceArray[0] <= e.productPrice && priceArray[1] >= e.productPrice ) )
		
	}

	// List all products sizes in the category or selection
	const getSizeList = () => {
		// list all sizes
		const listAll = productListSave.map( e => e.productSize[0].sizeName ); // we extract from productListSave not to change product list	
		// remove dupicates
		const list = removeDuplicates( listAll );	
		setSizeList( list );
	}

	// Filter products by size
	const sizeFiltered = ( size ) => {
		if( !size )
			setProductList( productListSave ) // display all
		else
			setProductList( productListSave.filter( e => e.productSize[0].sizeName == size ) ) // display selected brands
	}

	// List all products colors in the category or selection
	const getColorList = () => {
		// list all colors
		
		const listAll = productListSave.map( e => e.productColor ); // we extract from productListSave not to change product list	
		// remove dupicates
		const list = removeDuplicates( listAll )
		setColorList( list );
	}

	// Filter products by color
	const colorFiltered = ( color ) => {
		if( !color )
			setProductList( productListSave ) // display all
		else
			setProductList( productListSave.filter( e => e.productColor == color ) ) // display selected brands
	}

	// Reorder product list from A to Z
	const reorderByProductNameAZ = () => {
		const copyList 	= [ ...productList ];
		copyList.sort( ( a, b ) => {
			const nameA = a.productName.toUpperCase(); // ignore upper and lowercase
			const nameB = b.productName.toUpperCase(); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		} ) ;
		setProductList( copyList );
	}

	// Reorder product list from Z to A
	const reorderByProductNameZA = () => {
		const copyList 	= [ ...productList ];
		copyList.sort( ( a, b ) => {
			const nameA = a.productName.toUpperCase(); // ignore upper and lowercase
			const nameB = b.productName.toUpperCase(); // ignore upper and lowercase
			if (nameA > nameB) {
				return -1;
			}
			if (nameA < nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		} ) ;
		setProductList( copyList );
	}

	// Reorder product list from price lower to upper
	const reorderByPriceUp = () => {
		const copyList 	= [ ...productList ];
		function compareNumbers(a, b) {
			return a.productPrice - b.productPrice;
		}
		copyList.sort( compareNumbers );
		setProductList( copyList );
	}

	// Reorder product list from price upper to lower
	const reorderByPriceDown = ( type ) => {
		const copyList 	= [ ...productList ];
		function compareNumbers(a, b) {
			return b.productPrice - a.productPrice;
		}
		copyList.sort( compareNumbers );
		setProductList( copyList );
	}

	// Search a product
	const searchAProduct = async( productName ) => {
		// clear
		clearProductList();
		
		// Query
		const method	= 'GET';
		const url		= 'https://allkakou.com/api/product/api/find-product-by-name?productName=' + productName;
		const data		= '';
		const searchProducts = await fetchData( url, data, method );

		// save the products states in the locage storage
		localStorage.setItem( 'productList', JSON.stringify( searchProducts ) );
		
		// set products states
		setProductList( searchProducts );
		
		// save a copy that will not be change by the app
		setProductListSave( searchProducts );	
		
		return searchProducts;
	}

	// clear product List
	const clearProductList = () => {
		const selectionProducts = [];
		// save the products states in the locage storage
		localStorage.setItem( 'productList', JSON.stringify( selectionProducts ) );
		
		// set products states
		setProductList( selectionProducts );
		
		// save a copy that will not be change by the app
		setProductListSave( selectionProducts );
	}

	// Coupon verification
	const checkCoupon = async ( coupon ) => {
		const method  = 'GET';
		const url	= 'https://allkakou.com/api/promo/coupon?coupon=' + coupon;
		const data	= {};
		
		const resp = await fetchData( url, data, method );	
		if( resp.status != 'NOT_FOUND'  )
			return true;
		else
			return false;
	}

	// Set filters data when product list changes
	useEffect(() => {
		getBrandList();
		getHighestPrice();
		getSizeList();
		getColorList();
	}, [ productList ]); // Include cartItems as a dependency here

	// clear
	useEffect(() => {
		clearProductList();
	}, []); // Include cartItems as a dependency here
	

	return (
		<StoreContext.Provider
			value={{
				productList,
				clearProductList,
				brandFiltered,
				brandList,
				priceRange,
				highestPrice,
				sizeList,
				sizeFiltered,
				getColorCode,
				colorList,
				colorFiltered,
				reorderByProductNameAZ,
				reorderByProductNameZA,
				reorderByPriceUp,
				reorderByPriceDown,
				searchAProduct,
				getAStoreProducts,
				getACategoryProducts,
				getASelectionProducts,
				getProductById,
				checkCoupon,
				getAllProducts,
			}}
		>
			<Space
				style={{ display: spiner }}
			>
				<Spin
					indicator={
						<LoadingOutlined
							style={{
									display:		spiner,
									fontSize: 		30,
									color: 			'#fcb800'
								}}
							spin
						/>
					}
					fullscreen
					tip		= "chargement" 
					size	= "large"
				/>
				
			</Space>
		
			{children}
		
		</StoreContext.Provider>
	);
};

StoreProvider.propTypes = {
	children: PropTypes.node.isRequired,
}