import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { StoreContext } from '../context/store';

const SearchAProduct = ( ) => {


	// context custom hook
	const { searchAProduct } = useContext( StoreContext );
	
	// search value
	const [ searchValue, setSearchValue ] = useState( '' );
	
	// Handle change search input
	const handleSearchChange = ( event ) => {
		const value = event.target.value;
		setSearchValue( value );
	}

	// handle ssearch click button
	const navigate = useNavigate();
	const handleSearchClick = ( e ) => {
		e.preventDefault();
		const products = searchAProduct( searchValue );
		navigate( '/rechercher/' + searchValue );
	}



	return (
		<>
			<input 
				className	= "form-control" 
				type		= "text" placeholder="Je recherche ..." 
				id			= "input-search"
				onChange	= { handleSearchChange }
				value		= { searchValue }
			/>
			<button
				onClick		= { handleSearchClick }
			>Trouver
			</button>
		</>
	);
};

export default SearchAProduct;
