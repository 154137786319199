import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import { SiteContext } from "../context/site";
import '../modalOverrides.css';

import { Space, Spin, Button, notification, message, Popconfirm } from 'antd';
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
	LoadingOutlined
} from '@ant-design/icons';

const DashboardAddProductSizeModal = ( params ) => {

	var newDescription = {};
	
	// Set sizes
	const { size, setSize } = useContext( SiteContext );
	const { sizes, setSizes } = useContext( SiteContext );

	// Size label
	const [ label, setLabel ] = useState( '' );
	const handleChangeLabel = ( e ) => {	
		setLabel( e.target.value );
	}

	// Size value
	const [ quantity, setQuantity ] = useState( '' );
	const handleChangeQuantity = ( e ) => {	
		const number = e.target.value;
		if( isNaN( number ) ){
			message.error( 'Taper un chiffre' );
			return;
		}
		setQuantity( number );
	}
	// Add Size
	const handleClickSize = () => {
		// check if size already exist

		// Size validation
		const labelError = 'Taper le label de la taille';
		if( !label ){
			message.error( labelError );
			return;
		}

		// Quantity validaton
		const quantityError = 'Taper la quantité de la taille';

		if( !quantity ){
			message.error( quantityError );
			return;
		}

		// create
		const rd = Math.floor(Math.random() * 1000000000);
		const newSize = {
			label: label,
			quantity: quantity,
			uid: 'create_' + rd,
		}

		// set
		setSize( newSize );
		const conts = sizes;
		conts.push( newSize );
		setSizes( conts );

		message.success( 'Taille ajoutée' );

		window.document.getElementsByClassName( 'modalClose' )[0].click(); // Todo: react way
	}


	// useEffect(() => {
	// }, [ ] );

	return (
		<>
			<div className="bootstrap-modal">
				<div className="modal fade" id="sizeAddModal" tabindex="-1" role="dialog" aria-labelledby="sizeAddModalLabel" aria-hidden="true">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title" id="descriptionAddModalLabel">Ajouter une taille</h5>
								<button id="closeModal" type="button" className="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
							<div className="modal-body">
								<form>
									<div className="form-group">
										<label for="recipient-name" className="col-form-label">Label de la taille:</label>
										<input 
											value 		= { label }
											onChange 	= { e => handleChangeLabel( e ) }
											type 		= "text" 
											className		= "form-control" 
											id			= "recipient-name" 
										/>
									</div>
									<div className="form-group">
										<label for="recipient-name" className="col-form-label">Quantité:</label>
										<input 
											value 		= { quantity }
											onChange 	= { e => handleChangeQuantity( e ) }
											type 		= "text" 
											className		= "form-control" 
											id			= "recipient-name" 
										/>
									</div>
								</form>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-danger modalClose" data-dismiss="modal">Close</button>
								<button 
									type	= "button" 
									className	= "btn btn-success"
									onClick = { e => handleClickSize( e ) }
								>
									Save
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DashboardAddProductSizeModal;
