import React, { useState, useEffect, useContext } from "react";
import Currency from "./Currency";
import ImageGallery from "react-image-gallery";
import { CartContext } from '../context/cart';
import { Link, useNavigate } from "react-router-dom";
import ShopItemColors from "./ShopItemColors";
import { SiteContext } from '../context/site';
import { AuthContext } from '../context/AuthProvider';

import { Space, Spin, Button, notification, message, Popconfirm, Select, Radio, Flex, DatePicker } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
	LoadingOutlined
} from '@ant-design/icons';

const DashboardModalProductsDetails = ( item ) => {
	
	const selectedProduct = item.params.selectedProduct;
console.log( 'selectedProduct', selectedProduct );
	const productName	= item.params.productName;
	const productId		= item.params.productId;
	const productImage	= item.params.productImage;
	const productCategory		= item.params.productCategory;
	const productSubCategory	= item.params.productSubCategory;
	const productColor			= item.params.productColor;
	const productDescription 	= item.params.productDescription;
	// Tailles pluriel
	const TaillePluriel = () => {
		return(
			selectedProduct.productSize.length > 1 ? 's' : ''
		)
	}
	const { setProductQuantity, getProductQuantity, deactivateProduct, removeProduct, removeLocalProduct, productUpdate, productUpdateQuantity } = useContext( SiteContext ); 

	const [ quantity, setQuantity ] = useState( '' );
	const [ note, setNote ] = useState( '' );

	const [ displayDelete, setDisplayDelete ] = useState( 'none' );
	
	const { getUser } = useContext( AuthContext );
	const token = getUser().accessToken;

	const navigate = useNavigate();
	const handleClickEditerBtn = () => {
		navigate( '/dashboard/product/edit/?productId=' + productId );
	}

	const handleClickSaveBtn = async () => {
		if( quantity == '' ){
			message.error( 'Taper une quantité' );
			return;
		}

		const data = {
			productNote: 		note,
			productQuantity: 	quantity,
			productId: 			productId,
		}

		await setProductQuantity( data );

		const messageText = productId ? 'Produit mise à jour' : 'Produit ajoutée';
		message.success( messageText );

		window.document.getElementsByClassName( 'react-responsive-modal-closeButton' )[0].click();
	}
	
	// desactivate a product
	const handleClickUpdateSite = async () => {
		if( quantity == '' ){
			message.error( 'Tapez la quantité' );
			return
		}
		if( !note ){
			message.error( 'Tapez la (les) taille(s) [ taille - quantité, taille - quantité, ...  ]' );
			return
		}
		
		const sizes = note.split( ',' );
		const size	= sizes.map( size => ( 
			{ 
				sizeName: size.split( '-' )[0].replace(/\s/g, ''),  
				sizeQuantity: + size.split( '-' )[1]
			} 
		));
console.log( 'size', size );
		// selectedProduct.productSize 	= size;
		// selectedProduct.productQuantity = quantity;

		const data = {
		  productId: selectedProduct.productId,
		  productQuantity: + quantity,
		  productSize: size
		}
console.log( 'data', data );
		const rep = await productUpdateQuantity( data, token );
		const messageText = 'Le site a été mis à jour';
		message.success( messageText );
		
		window.document.getElementsByClassName( 'react-responsive-modal-closeButton' )[0].click();
	}

		
	// Update the site
	const handleClickDesactivateProductBtn = async ( productId ) => {
		const data = {
			category 		: productCategory,
			subCategory		: productSubCategory,
			productName		: productName,
			productColor	: productColor,
		}

		const rep = await deactivateProduct( data, token );
		const messageText = 'Produit désactivé';
		message.success( messageText );
		
		window.document.getElementsByClassName( 'react-responsive-modal-closeButton' )[0].click();
	}

	// remove a product
	const handleClickRemoveProductBtn = async ( productId ) => {
		const data = {
			category 		: productCategory,
			subCategory		: productSubCategory,
			productName		: productName,
			productColor	: productColor,
		}

		const rep = await removeProduct( data, token );

console.log( 'RemoveProduct', rep );

		// remove local 
		const rep02 = await removeLocalProduct( productId, token );
console.log( 'RemoveProduct local', rep02 );
		const messageText = 'Produit local supprimé';
		message.success( messageText );
		
		window.document.getElementsByClassName( 'react-responsive-modal-closeButton' )[0].click();
	}

	const handleChangeQuantity = ( e ) => {
		const quantity = e.target.value;
		if( isNaN( quantity ) )
			return

		setQuantity( quantity );
	}

	const handleChangeNote = ( e ) => {
		const note = e.target.value;
		setNote( note );
	}

	// site current product sizes
	
	const BuildSiteSizes = () => { return ( selectedProduct.productSize.map( ( size, index ) => size.sizeName + ' - ' + size.sizeQuantity + ( index != selectedProduct.productSize.length - 1 ? ', ' : '' )))}
	// get the project
	useEffect( () => {
		const getQuantity = async () => {
			const data = await getProductQuantity( productId );
			setQuantity( data.quantity );
			setNote( data.note );
			
			if( !data.quantity )
				setDisplayDelete( 'block' )
			else
				setDisplayDelete( 'none' )
		}
		getQuantity();
	}, [productId] );

	return (
		<>      
			<p>&nbsp;</p>
			<p>{ productName }</p>
			<p>{ productDescription }</p>
			<article className="ps-product--detail ps-product--fullwidth">
				<div className="ps-product__header">
					<img
						style 	= {{ maxHeight: '300px' }}
						src 	= { 'https://www.237usa.com/images/products/' + productImage } 
					/>
					<p>&nbsp;</p>
				</div>
				<div className = "ps-product__info" >
					<p> -------------------------------- </p>
					<p>Quantité</p>
						<input 
							type		= 'text' 
							value		= { quantity } 
							onChange	= {  e => handleChangeQuantity( e ) }
						/>
						<p>Tailles</p>
						<textarea 
							value		= { note }
							onChange	= {  e => handleChangeNote( e ) }
						>
						</textarea>
						<p>Taille<TaillePluriel/> actuelle<TaillePluriel/> sur le site:<br/>
							<BuildSiteSizes /> 
						</p>
						<p> -------------------------------- </p>
							<Button 
								success
								onClick={ e => handleClickEditerBtn( e ) }
							>
								<i className='fa fa-pencil' ></i>&nbsp; Editer 
							</Button>
						<Button 
							success
							onClick={ e => handleClickSaveBtn( e ) }
						>
							<i className='fa fa-save' ></i>&nbsp;Enregistrer
						</Button>
						<Popconfirm
							title		= 'Mettre le site à jour'
							description	= 'êtes-vous  sûr de vouloir mettre le site à jour?'
							onConfirm	= { handleClickUpdateSite }
							okText		= 'Oui'
							cancelText	= 'Non'
							
						>
							<Button 
								success
							>
								<i className='fa fa-refresh' ></i>&nbsp;Mettre le site à jour
							</Button>
						 </Popconfirm>
						<Popconfirm
							title		= 'Desactivation'
							description	= 'êtes-vous  sûr de vouloir desactiver cette article?'
							onConfirm	= { handleClickDesactivateProductBtn }
							okText		= 'Oui'
							cancelText	= 'Non'
							
						>
							<Button 
								warning
							>
								Desactiver le produit
							</Button>
						 </Popconfirm>
						<Popconfirm
							title		= 'Suppression'
							description	= 'êtes-vous  sûr de vouloir supprimer cette article?'
							onConfirm	= { handleClickRemoveProductBtn }
							okText		= 'Oui'
							cancelText	= 'Non'
						>
							<Button 
								style={{ backgroundColor: 'red', color: 'white' }}
							>
								Supprimer le produit
							</Button>
						 </Popconfirm>
				</div>
			</article>
			<p>&nbsp;</p>
		</>
	);
};

export default DashboardModalProductsDetails;
