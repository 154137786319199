import PropTypes from 'prop-types'
import { createContext, useState, useEffect } from 'react'

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	
	// user
	const [ user, setUser ] = useState( localStorage.getItem( 'user' ) ? JSON.parse( localStorage.getItem( 'user' ) ) : {} );
	
	// add the user
	const logIn = ( user ) => {
		setUser( user )
	}
	
	// get the user
	const getUser = () => {
		return user
	}
	
	// delete the user
	const logOut = () => {
		setUser( null );
	}
	
	// is authentificated
	const isAuthenticated = () => {
		return getUser() != null && getUser().accessToken != null ? true : false
	}
	
	// check password validation
	const isValidPassword = ( password ) => {
		const rg01 = /\d+/;
		const rg02 = /[a-z]+/;
		const rg03 = /[A-Z]+/;
		
		if( !rg01.test( password ) )
			return 1
		if( !rg02.test( password ) )
			return 2	
		if( !rg03.test( password ) )
			return 3
		if( password.length < 7 || password.length > 100 )
			return 4
		
		return true;
	}
	
	useEffect (() => {
		const data = localStorage.getItem( 'user' );
		if (data) {
			setUser( JSON.parse(data) );
		}
	}, []); // 

	useEffect (() => {
		localStorage.setItem( 'user', JSON.stringify( user ) );
	}, [user]); // 
	
	return (
		<AuthContext.Provider 
			value={{ 
				logIn, 
				getUser,
				logOut,
				isAuthenticated,
				isValidPassword
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired,
};