import React, { useState, useEffect } from "react";
import Shop from "./Shop";
import ShopSelections from "./ShopSelections";
import ShoppingPage from "./ShoppingPage";
import ShoppingStore from "./ShoppingStore";
import ShopCategories from "./ShopCategories";
import ShopLeftMenu from './ShopLeftMenu';
import Product from './Product';
import Index from "./Index";
import Cart from "./Cart";
import Login from "./Login";
import Order from "./Order";
import OrderThankYou from "./OrderThankYou";
import EmailValidation from "./EmailValidation";
import PasswordForget from "./PasswordForget";
import PasswordForgetValidation from "./PasswordForgetValidation";
import PasswordForgetRestore from "./PasswordForgetRestore";
import MySpace from './MySpace';
import MySpaceHistory from './MySpaceHistory';
import MySpaceChangePassword from './MySpaceChangePassword';
import MySpaceAdresse from './MySpaceAdresse';
import MySpaceAdresseCreate from './MySpaceAdresseCreate';
import BlogPage from './BlogPage';
import BlogPageOrder from './BlogPageOrder';
import BlogPageDelivery from './BlogPageDelivery';
import BlogPagePayment from './BlogPagePayment';
import ContactPage from './ContactPage';
import FaqPage from './FaqPage';
import AboutPage from './AboutPage';
import TermsPage from './TermsPage';
import PoliticPage from './PoliticPage';
import NotFoundPage from './NotFoundPage';
import SearchPage from './SearchPage';
import NewsletterThankYou from './NewsletterThankYou';
import NewsletterPage from './NewsletterPage';
import DashboardProductList from './DashboardProductList';
import DashboardProductListDev from './DashboardProductListDev';
import DashboardProductEdit from './DashboardProductEdit';
import DashboardProductEditDev from './DashboardProductEditDev';
import DashboardProductGenerateSQL from './DashboardProductGenerateSQL';


var allCategories = {};

allCategories[ 'val' ] = '1000';

const AllRoutes = {
	// get all categories
	getAllCategories: () =>
	{
		const allRoutes = AllRoutes.routes;
		var categories 	= Array();
		
		// get each categories and their routes
		for( var route01 of allRoutes ){
			var already 	= Array();
			var category01 = route01.element.props.params.category;
			if(	category01 == null || !category01 )
				continue;
			// if(	route01.element.props.params.categories== null )  // only categories
			// 	continue;
			
			var arr 	= Array();
			for( var route02 of allRoutes ){
				const category02 = route02.element.props.params.category;
				if(	category02 == null || !category02 )
					continue;

				var obj = {};
				
				if(	category02.includes( category01 ) ){
					const path02 	= route02.element.props.params.path;
					const title02 	= route02.element.props.params.pageName;
					const imageUrl 	= route02.element.props.params.imageUrl ? route02.element.props.params.imageUrl : 'img/categories/1.jpg';
					
					// if(	route02.element.props.params.categories == null ) // only categories
					//	continue;
					
					if( already.includes( path02 ) )
						continue;

					obj = {
						category : 	category02,
						path : 		path02,
						title : 	title02,
						imageUrl:	imageUrl,
					}
					arr.push( obj );
					already.push( path02 );
				}
			}

			if( arr.length )
				categories[ category01 ] = arr;

		}
		// 
// console.log( '---------wwwww categories' );
// console.log( categories );
		allCategories = categories;
		return categories;
	},
	// all cateories extracted from routes
	categories: '',
	
	// get all selections
	getAllSelections: () =>
	{
		const allRoutes = AllRoutes.routes;
		var selections = Array();
		// get each selection and their routes
		for( var route of allRoutes ){
			const selection = route.element.props.params.selection;
			if( selection != null && selection ){
				const selectionName = route.element.props.params.pageName;
				const path 			= route.element.props.params.path;
				const obj = {
					selection : selectionName,
					path : path
				}
				selections.push( obj );
			}
		}
			
		//Group array by Selections name
		//const result = Object.groupBy( selections, ({ selection }) => selection );
		const result = selections.reduce((x, y) => {
			(x[y.selection] = x[y.selection] || []).push(y);
            return x;
		}, {});
		return result;
	},
	routes: [
		{	// accueil
			element:  <Index 
							params={{ 
								pageName: 	'Accueil',
								path: 		'accueil',
								category:	'',
								categories:	allCategories,
							}}
						/>,
			path: 'accueil',
		},
		{	// accueil
			element:  <Index 
							params={{ 
								pageName: 	'Accueil',
								path: 		'',
								category:	'',
								categories:	allCategories,
							}}
						/>,
			path: '',
		},
		{	// boutiques
			element:  <Shop 
								params={{ 
									categories:	allCategories,
								}}
			/>,
			path: 'boutiques',
		},
		{	// selections
			element:  <ShopSelections 
								params={{ 
									pageName: 	'Nos Selections',
									path: 		'selections',
									categories:	allCategories,
								}}
			/>,
			path: 'selections',
		},
		{	// selection sac à dos
			element:  <ShoppingPage 
								params={{ 
									pageName: 	'Sac à Dos',
									path:		'selections/sac-a-dos',
									selection:	'sac a dos',
									categories:	allCategories,
								}}
			/>,
			path: 'selections/sac-a-dos',
		},
		{	// selection nouveauté
			element:  <ShoppingPage 
								params={{ 
									pageName: 	'Derniers Arrivages',
									path:		'selections/nouveau-produits',
									selection:	'nouveau produits',
									categories:	allCategories,
								}}
			/>,
			path: 'selections/nouveau-produits',
		},	
		{	// selection US Jeans
			element:  <ShoppingPage 
								params={{ 
									pageName: 	'US Jeans',
									path:		'selections/us-jeans',
									selection:	'US Jeans',
									categories:	allCategories,
								}}
			/>,
			path: 'selections/us-jeans',
		},
		{	// selection derniere chance
			element:  <ShoppingPage 
								params={{ 
									pageName: 	'Derniere chance - Il n\'en reste qu\'une pièce',
									path:		'selections/derniere-chance',
									selection:	'derniere chance',
									categories:	allCategories,
								}}
			/>,
			path: 'selections/derniere-chance',
		},
		{	// store | Suslo Couture
			element:  <ShoppingStore 
								params={{ 
									pageName: 		'Suslo Couture',
									storeName: 		'suslo-couture',
									path:			'boutiques/suslo-couture',
									description: 	'Suslo Couture est une marque américaine de vêtements de haute qualité extensibles pour hommes, coupe slim, qui fera la différence dans la façon dont vous vous habillez et augmentera votre sex-appeal. Suslo Couture a été fondé il y a douze ans par les frères Halabieh, Elie, Jack et Albert.',
									website: 		'www.suslocouture.com',
									categories:	allCategories,
									logoPath: 'img/vendor/store/1.png',
									backgroundImagePath: 'img/vendor/store/default_banner.jpg',
								}}
			/>,
			path: 'boutiques/suslo-couture',
		},
		{	// store | Levi Strauss
			element:  <ShoppingStore 
								params={{ 
									pageName: 		'Levi Strauss & Co',
									storeName: 		'levis',
									path:			'boutiques/levis',
									description: 	'Signature by Levi Strauss & Co. est une filiale de Levi Strauss & Co. qui se consacre à fournir des jeans de haute qualité à des prix abordables, créés pour les particuliers et les familles.',
									website: 		'www.levistrauss.com',
									categories:	allCategories,
									logoPath: 'img/vendor/store/2.png',
									backgroundImagePath: 'img/vendor/store/levis_banner01.jpg',
								}}
			/>,
			path: 'boutiques/levis',
		},
		{	// store | Reason
			element:  <ShoppingStore 
								params={{ 
									pageName: 		'Reason',
									storeName: 		'reason',
									path:			'boutiques/reason',
									description: 	'Reason est une marque américaine de vêtements et autres accessoires. Reason est une marque leader de vêtements décontractés pour hommes',
									website: 		'www.reasonclothing.com',
									categories:	allCategories,
									logoPath: 'img/vendor/store/3.png',
									backgroundImagePath: 'img/vendor/store/docker_banner01.jpg',
								}}
			/>,
			path: 'boutiques/reason',
		},
		{	// store | U.S. Polo Assn
			element:  <ShoppingStore 
								params={{ 
									pageName: 		'U.S. Polo Assn',
									storeName: 		'us-polo-assn',
									path:			'boutiques/us-polo-assn',
									description: 	'U.S. Polo Assn n\'est PAS AFFILIÉ À POLO RALPH LAUREN.Les produits de la marque U.S. Polo Assn sont authentiques et officialisé par la United States Polo Association, l\'organe directeur du sport du polo aux États-Unis depuis 1890.',
									website: 		'www.uspoloassn.com',
									categories:	allCategories,
									logoPath: 'img/vendor/store/4.png',
									backgroundImagePath: 'img/vendor/store/uspolo_banner01.jpg',
								}}
			/>,
			path: 'boutiques/us-polo-assn',
		},
		{	// categories
			element:  <ShopCategories 
								params={{ 
									pageName: 	'Nos Catégories',
									path:		'categories',
									categories:	allCategories,
								}}
			/>,
			path: 'categories',
		},		
		{	// categorie homme
			element:  <ShopCategories 
								params={{ 
									pageName: 	'Categories Hommes',
									path:		'categories/hommes',
									category:	'hommes',
									imageUrl:	'img/categories/categorie_homme.jpg',
									categories:	allCategories,
								}}
			/>,
			path: 'categories/hommes',
		},	
		{	// categorie femmes
			element:  <ShopCategories 
								params={{ 
									pageName: 	'Categories Femmes',
									path:		'categories/femmes',
									category:	'femmes',
									imageUrl:	'img/categories/categorie_femme.jpg',
									categories:	allCategories,
								}}
			/>,
			path: 'categories/femmes',
		},	
		// {	// categorie femmes | cosmetiques
			// element:  <ShopCategories 
								// params={{ 
									// pageName: 	'Cosmetiques',
									// path: 		'categories/femmes/cosmetiques',
									// category:	'femmes/cosmetiques',
									// categories:	allCategories,
								// }}
			// />,
			// path: 'categories/femmes/cosmetiques',
		// },		
		{	// categorie femmes | chaussures
			element:  <ShoppingPage 
								params={{ 
									pageName: 	'Chaussures pour Femmes',
									category: 	'femmes/chaussures',
									path:		'categories/femmes/chaussures',
									categories:	allCategories,
									imageUrl:	'img/categories/femmes_chaussures.jpg',
								}}
			/>,
			path: 'categories/femmes/chaussures',
		},	
		{	// categorie femmes | sacs
			element:  <ShoppingPage 
								params={{ 
									pageName: 	'Sacs pour Femmes',
									category: 	'femmes/sacs',
									path:		'categories/femmes/sacs',
									categories:	allCategories, 
									imageUrl:	'img/categories/femmes_sac.jpg',
								}}
			/>,
			path: 'categories/femmes/sacs',
		},	
		{	// categorie femmes | accessoires
			element:  <ShoppingPage 
								params={{ 
									pageName: 	'Accessoires pour Femmes',
									category: 	'femmes/accessoires',
									path:		'categories/femmes/accessoires',
									categories:	allCategories,
									imageUrl:	'img/categories/femmes_accessoires.jpg',
								}}
			/>,
			path: 'categories/femmes/accessoires',
		},	
		// {	// categorie femmes | cosmetiques | mascara
			// element:  <ShoppingPage 
								// params={{ 
									// pageName: 	'Mascara',
									// category: 	'femmes/cosmetiques/mascara',
									// path:		'categories/femmes/cosmetiques/mascara',
									// categories:	allCategories,
								// }}
			// />,
			// path: 'categories/femmes/cosmetiques/mascara',
		// },
		// {	// categorie femmes | cosmetiques | rouge à lèvres
			// element:  <ShoppingPage 
								// params={{ 
									// pageName: 	'Rouge à lèvres',
									// category: 	'femmes/cosmetiques/rouge à lèvres',
									// path:		'categories/femmes/cosmetiques/rouge-à-lèvres',
									// categories:	allCategories,
								// }}
			// />,
			// path: 'categories/femmes/cosmetiques/rouge-à-lèvres',
		// },
		// {	// categorie femmes | cosmetiques | vernie à ongles
			// element:  <ShoppingPage 
								// params={{ 
									// pageName: 	'Vernie à ongles',
									// category: 	'femmes/cosmetiques/vernie à ongles',
									// path:		'categories/femmes/cosmetiques/vernie-à-ongles',
									// categories:	allCategories,
								// }}
			// />,
			// path: 'categories/femmes/cosmetiques/vernie-à-ongles',
		// },
		// {	// categorie femmes | cosmetiques | parfums
			// element:  <ShoppingPage 
								// params={{ 
									// pageName: 	'Parfums',
									// category: 	'femmes/cosmetiques/parfums',
									// path:		'categories/femmes/cosmetiques/parfums',
									// categories:	allCategories,
								// }}
			// />,
			// path: 'categories/femmes/cosmetiques/parfums',
		// },
		{	// categorie maison
			element:  <ShopCategories 
								params={{ 
									pageName: 	'Categorie Maison',
									path: 		'categories/maison',
									category:	'maison',
									imageUrl:	'img/categories/categorie_maison.jpg',
									categories:	allCategories,
								}}
			/>,
			path: 'categories/maison',
		},
		{	// categorie electronique
			element:  <ShopCategories 
								params={{ 
									pageName: 	'Appareils Electroniques',
									path: 		'categories/electronique',
									category:	'electronique',
									imageUrl:	'img/categories/categorie_electronique.jpg',
									categories:	allCategories,
								}}
			/>,
			path: 'categories/electronique',
		},
		{	// categorie soldes
			element:  <ShopCategories 
								params={{ 
									pageName: 	'Toutes les Soldes',
									path: 		'categories/soldes',
									category:	'soldes',
									categories:	allCategories,
								}}
			/>,
			path: 'categories/soldes',
		},
		{	// categorie hommes | chaussures
			element:  <ShoppingPage 
								params={{ 
									pageName: 	'Chaussures pour Hommes',
									category: 	'hommes/chaussures',
									path:		'categories/hommes/chaussures',
									categories:	allCategories,
									imageUrl:	'img/categories/chaussures_pour_hommes.jpg',
								}}
			/>,
			path: 'categories/hommes/chaussures',
		},
		{	// categorie hommes | t-shirt et chemises
			element:  <ShoppingPage 
								params={{ 
									pageName: 	'T-shirt et Chemises',
									category: 	'hommes/t shirt et chemises',
									path:		'categories/hommes/t-shirt-et-chemises',
									categories:	allCategories,
									imageUrl:	'img/categories/t_shirt_et_chemises.jpg',
								}}
			/>,
			path: 'categories/hommes/t-shirt-et-chemises', 
		},
		{	// categorie hommes | jeans et pantalons
			element:  <ShoppingPage 
								params={{ 
									pageName: 	'Jeans et Pantalons',
									category: 	'hommes/jeans-et-pantalons',
									path:		'categories/hommes/jeans-et-pantalons',
									categories:	allCategories,
									imageUrl:	'img/categories/jeans_et_pantalons.jpg',
								}}
			/>,
			path: 'categories/hommes/jeans-et-pantalons',
		},
		{	// categorie hommes | Boxer et Shorts
			element:  <ShoppingPage 
								params={{ 
									pageName: 	'Boxer et Shorts',
									category: 	'hommes/boxer-et-shorts',
									path:		'categories/hommes/boxer-et-shorts',
									categories:	allCategories,
									imageUrl:	'img/categories/boxer_et_shorts.jpg',
								}}
			/>,
			path: 'categories/hommes/boxer-et-shorts',
		},
		{	// categorie hommes | soins
			element:  <ShoppingPage 
								params={{ 
									pageName: 	'Soins pour Hommes',
									category: 	'hommes/soins',
									path:		'categories/hommes/soins',
									categories:	allCategories,
									imageUrl:	'img/categories/soins_hommes.jpg',
								}}
			/>,
			path: 'categories/hommes/soins',
		},
		{	// categorie hommes | accessoires
			element:  <ShoppingPage 
								params={{ 
									pageName: 	'Accessoires',
									category: 	'hommes/accessoires',
									path:		'categories/hommes/accessoires',
									categories:	allCategories,
									imageUrl:	'img/categories/accessoires_hommes.jpg',
								}}
			/>,
			path: 'categories/hommes/accessoires',
		},
		{	// categorie electronique | téléphones
			element:  <ShoppingPage 
								params={{ 
									pageName: 	'Téléphones',
									category: 	'electronique/téléphones',
									path:		'categories/electronique/téléphones',
									categories:	allCategories,
									imageUrl:	'img/categories/electronique-telephones.jpg',
								}}
			/>,
			path: 'categories/electronique/téléphones',
		},
		{	// categorie electronique | écouteurs et baffles
			element:  <ShoppingPage 
								params={{ 
									pageName: 	'Ecouteurs et Baffles',
									category: 	'electronique/écouteurs-et-baffles',
									path:		'categories/electronique/écouteurs-et-baffles',
									categories:	allCategories,
									imageUrl:	'img/categories/ecouteur_baffles.jpg',
								}}
			/>,
			path: 'categories/electronique/écouteurs-et-baffles',
		},
		{	// categorie electronique | selfie
			element:  <ShoppingPage 
								params={{ 
									pageName: 	'Selfie',
									category: 	'electronique/selfie',
									path:		'categories/electronique/selfie',
									categories:	allCategories,
									imageUrl:	'img/categories/selfie.jpg',
								}}
			/>,
			path: 'categories/electronique/selfie',
		},
		{	// categorie electronique | chargeurs
			element:  <ShoppingPage 
								params={{ 
									pageName: 	'Chargeurs',
									category: 	'electronique/chargeurs',
									path:		'categories/electronique/chargeurs',
									categories:	allCategories,
									imageUrl:	'img/categories/chargeurs.jpg',
								}}
			/>,
			path: 'categories/electronique/chargeurs',
		},
		{	// categorie electronique | ordinateurs
			element:  <ShoppingPage 
								params={{ 
									pageName: 	'Ordinateurs',
									category: 	'electronique/ordinateurs',
									path:		'categories/electronique/ordinateurs',
									categories:	allCategories,
									imageUrl:	'img/categories/ordinateur.jpg',
								}}
			/>,
			path: 'categories/electronique/ordinateurs',
		},
		{	// categorie maison | interieur
			element: <ShoppingPage 
								params={{ 
									pageName: 	'Articles d\'interieur pour la maison',
									category: 	'maison/interieur',
									path:		'categories/maison/interieur',
									categories:	allCategories,
									imageUrl:	'img/categories/interieur.jpg',
								}}
			/>,
			path: 'categories/maison/interieur',
		},
		// {	// categorie maison | cuisine
			// element: <ShoppingPage 
								// params={{ 
									// pageName: 	'Articles pour votre cuisine',
									// category: 	'maison/cuisine',
									// path:		'categories/maison/cuisine',
									// categories:	allCategories,
								// }}
			// />,
			// path: 'categories/maison/cuisine',
		// },			
		{	// categorie soldes | femmes
			element: <ShoppingPage 
								params={{ 
									pageName: 	'Soldes pour Femmes',
									category: 	'soldes/soldes-pour-femmes',
									path:		'categories/soldes/soldes-pour-femmes',
									categories:	allCategories,
									imageUrl:	'img/categories/soldes_femmes.jpg',
								}}
			/>,
			path: 'categories/soldes/soldes-pour-femmes',
		},	
		{	// categorie soldes | hommes
			element: <ShoppingPage 
								params={{ 
									pageName: 	'Soldes pour Hommes',
									category: 	'soldes/soldes-pour-hommes',
									path:		'categories/soldes/soldes-pour-hommes',
									categories:	allCategories,
									imageUrl:	'img/categories/soldes_hommes.jpg',
								}}
			/>,
			path: 'categories/soldes/soldes-pour-hommes',
		},	
		{	// categorie soldes | chaussures
			element: <ShoppingPage 
								params={{ 
									pageName: 	'Soldes sur les Chaussures',
									category: 	'soldes/soldes-chaussures',
									path:		'categories/soldes/soldes-chaussures',
									categories:	allCategories,
									imageUrl:	'img/categories/soldes_chaussures.jpg',
								}}
			/>,
			path: 'categories/soldes/soldes-chaussures',
		},		
		{	// categorie soldes | electroniques
			element: <ShoppingPage 
								params={{ 
									pageName: 	'Soldes sur l\'Electronique',
									category: 	'soldes/soldes-electronique',
									path:		'categories/soldes/soldes-electronique',
									categories:	allCategories,
									imageUrl:	'img/categories/soldes_electroniques.jpg',
								}}
			/>,
			path: 'categories/soldes/soldes-electronique',
		},	
		{	// categorie soldes | maison
			element: <ShoppingPage 
								params={{ 
									pageName: 	'Soldes sur les articles de Maison',
									category: 	'soldes/soldes-maison',
									path:		'categories/soldes/soldes-maison',
									categories:	allCategories,
									imageUrl:	'img/categories/soldes_maison.jpg',
								}}
			/>,
			path: 'categories/soldes/soldes-maison',
		},
		{	// product | from categories
			element: <Product 
				params={{ 
					path:	'product',
				}}
			/>,
			path: '/categories/*',
		},
		{	// product | from selections
			element: <Product 
				params={{ 
					path:	'product',
				}}
			/>,
			path: '/selections/*',
		},
		{	// product | from  stores
			element: <Product 
				params={{ 
					path:	'product',
				}}
			/>,
			path: '/boutiques/*',
		},
		{	// product | from  search
			element: <Product 
				params={{ 
					path:	'/recherche/*',
				}}
			/>,
			path: '/recherche/*',
		},
		{	// Panier
			element: <Cart 
				params={{ 
					path:	'/panier',
				}}
			/>,
			path: '/panier',
		},
		{	// connexion / abonnement
			element: <Login 
				params={{ 
					path:	'/connexion',
				}}
			/>,
			path: '/connexion',
		},
		{	// Email, validation
			element: <EmailValidation 
				params={{ 
					path:	'/connexion/email-validation',
					email: '',
				}}
			/>,
			path: '/connexion/email-validation',
		},
		{	// Mot de passe oublie
			element: <PasswordForget 
				params={{ 
					path:	'/connexion/mot-de-passe-oublie',
					email: '',
				}}
			/>,
			path: '/connexion/mot-de-passe-oublie',
		},
		{	// Mot de passe oublie, validation du code
			element: <PasswordForgetValidation 
				params={{ 
					path: '/connexion/mot-de-passe-oublie/validation',
					email: '',
				}}
			/>,
			path: '/connexion/mot-de-passe-oublie/validation',
		},
		{	// Mot de passe oublie, recuperation du mot de passe
			element: <PasswordForgetRestore 
				params={{ 
					path:	'/connexion/mot-de-passe-oublie/restaurer',
					email: '',
				}}
			/>,
			path: '/connexion/mot-de-passe-oublie/restaurer',
		},
		{	// commander
			element: <Order 
				params={{ 
					path:	'/commander',
				}}
			/>,
			path: '/commander',
		},
		{	// commander merci
			element: <OrderThankYou 
				params={{ 
					path:	'/commander/merci',
				}}
			/>,
			path: '/commander/merci',
		},
		{	// my space
			element: <MySpace 
				params={{ 
					path:	'/mon-espace',
				}}
			/>,
			path: '/mon-espace',
		},
		{	// my space | historique
			element: <MySpaceHistory 
				params={{ 
					path:	'/mon-espace/historique',
				}}
			/>,
			path: '/mon-espace/historique',
		},
		{	// my space | changer de mot de passe
			element: <MySpaceChangePassword 
				params={{ 
					path:	'/mon-espace/changer-de-mot-de-passe',
				}}
			/>,
			path: '/mon-espace/changer-de-mot-de-passe',
		},
		{	// my space | mes adresses
			element: <MySpaceAdresse 
				params={{ 
					path:	'/mon-espace/mes-adresses',
				}}
			/>,
			path: '/mon-espace/mes-adresses',
		},
		{	// my space | mes adresses | creer
			element: <MySpaceAdresseCreate 
				params={{ 
					path:	'/mon-espace/mes-adresses/creer',
				}}
			/>,
			path: '/mon-espace/mes-adresses/creer',
		},
		{	// search 
			element: <SearchPage 
				params={{ 
					categories:	allCategories,
				}}
			/>,
			path: '/rechercher',
		},
		{	// search | search result
			element: <ShoppingPage 
				params={{ 
					categories:	allCategories,
				}}
			/>,
			path: '/rechercher/*',
		},
		{	// Blog page
			element: <BlogPage 
				params={{ 
					path:	'/blog',
				}}
			/>,
			path: '/blog',
		},
		{	// Blog | comment commander
			element: <BlogPageOrder 
				params={{ 
					path:	'/blog/comment-commander',
				}}
			/>,
			path: '/blog/comment-commander',
		},
		{	// Blog | comment recevoir
			element: <BlogPageDelivery 
				params={{ 
					path:	'/blog/comment-recevoir-ses-achats',
				}}
			/>,
			path: '/blog/comment-recevoir-ses-achats',
		},
		{	// Blog | comment payer
			element: <BlogPagePayment 
				params={{ 
					path:	'/blog/comment-payer',
				}}
			/>,
			path: '/blog/comment-payer', 
		},
		{	// Blog | contact page
			element: <ContactPage 
				params={{ 
					path:	'/contactez-nous',
				}}
			/>,
			path: '/contactez-nous',
		},
		{	// FAQ page
			element: <FaqPage 
				params={{ 
					path:	'/faq',
				}}
			/>,
			path: '/faq',
		},
		{	// About page
			element: <AboutPage 
				params={{ 
					path:	'/a-propos-de-nous',
				}}
			/>,
			path: '/a-propos-de-nous',
		},
		{	// Politique page
			element: <PoliticPage 
				params={{ 
					path:	'/confidentialite-politique',
				}}
			/>,
			path: '/confidentialite-politique',
		},
		{	// Terms page
			element: <TermsPage 
				params={{ 
					path:	'/term-conditions',
				}}
			/>,
			path: '/term-conditions',
		},
		{	// Not found page
			element: <NotFoundPage 
				params={{ 
					path:	'/*',
				}}
			/>,
			path: '/*',
		},
		{	// NewsLetter subscription | Thank you
			element: <NewsletterPage
				params={{ 
					path:	'/newsletter',
				}}
			/>,
			path: '/newsletter',
		},
		{	// NewsLetter subscription | Thank you
			element: <NewsletterThankYou 
				params={{ 
					path:	'/newsletter/merci',
				}}
			/>,
			path: '/newsletter/merci',
		},
		{	// Dashboard | product | list
			element: <DashboardProductList 
				params={{ 
					path:	'/dashboard/product/list',
				}}
			/>,
			path: '/dashboard/product/list',
		},
		{	// Dashboard | product | list | inactif 
			element: <DashboardProductListDev
				params={{ 
					path:	'/dashboard/product/list/dev',
				}}
			/>,
			path: '/dashboard/product/list/dev',
		},
		{	// Dashboard | product | edit
			element: <DashboardProductEditDev 
				params={{ 
					path: '/dashboard/product/edit/dev',
				}}
			/>,
			path: '/dashboard/product/edit/dev',
		},
		{	// Dashboard | product | generate SQL
			element: <DashboardProductGenerateSQL 
				params={{ 
					path: '/dashboard/product/generate/SQL',
				}}
			/>,
			path: '/dashboard/product/generate/SQL',
		},
	], 
}

allCategories[ 'val' ] = AllRoutes.getAllCategories();

export default AllRoutes;
