import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import { Form, Input, Select } from 'antd';

import { AuthContext } from "../context/AuthProvider";

import { Space, Spin, Button, notification, message, Popconfirm } from 'antd';
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
	LoadingOutlined
} from '@ant-design/icons';

import useDidMountEffect from './useDidMountEffect';

const EmailValidation = ( params ) => {
	const codeRegex = /^\d{5}$/;
		
	// waiting spin
	const [ validationSpin, setValidationSpin ] = useState( 'none' );
	
	// user email
	const { getUser }	= useContext( AuthContext );
	const email = getUser().email;

	const [ code, setCode ] = useState( '' );
	const [ codeError, setCodeError ] = useState( false );
	const handleChangeCode = ( e ) => {	
		setCode( e.target.value );
		// realtime validation for error messages
		if( !e.target.value ){
			setCodeError( 'Le code de validation doit avoir 5 chiffres.' );
		}
		else if( !codeRegex.test( e.target.value ) ){
			setCodeError( 'Le code de validation doit avoir 5 chiffres' );
		}
		else{
			setCodeError( false )
		}
	}

	// Get fetch definition
	async function getData( url ) {
		const response = await fetch( url, {
			method: "GET", // *GET, POST, PUT, DELETE, etc.
			// mode: "no-cors", // no-cors, *cors, same-origin
			headers: {
				"Content-Type": "application/json",
				// 'Content-Type': 'application/x-www-form-urlencoded',
			},
			// body: JSON.stringify( data ), // body data type must match "Content-Type" header
		});

		return response; // parses JSON response into native JavaScript objects
	}
	
	// Validation
	const navigate 	= useNavigate();
	const handleClickCodeVerification	 = ( event ) => {
		
		event.preventDefault();
		
		// spin
		setValidationSpin( 'block' );
		
		// reset all errors
		resetAllErrors();
		
		// code validation
		if( !codeRegex.test( code ) ){
			setValidationSpin( 'none' );
			message.error( 'Le code de validation doit avoir 5 chiffres' );
			return
		}
		
		const verificationURL = 'https://allkakou.com/api/authentication/confirmRegistration?codeRegistration=' + code +''
		
		const resp = getData( verificationURL ).then( 
			( data ) => {

				if( data.status !== 200 ){
					setValidationSpin( 'none' );
					message.error( 'Il n\'y a pas de compte associé à ce code.' );	
					setCodeError( 'Il n\'y a pas de compte associé à ce code.' );
					return;
				}
 
				// Redirect to login page
				message.success( 'Votre compte a été créé.' );	
				const gotoUrl	= '/connexion';
				navigate( gotoUrl );
			}
		);
	}
	
	// code resend
	const handleClickCodeNotReceived = ( event ) => {
		setValidationSpin( 'block' );
		const verificationURL = 'https://allkakou.com/api/authentication/generate-new-code?emailNewCode=' + email + '&typeCode=AccountRegistration';
		const resp = getData( verificationURL ).then( 
			( data ) => {
				console.log( '**** data', data ); // JSON data parsed by `data.json()` call
				message.info( 'Un nouveau code a été envoyé dans votre boite e-mail ' + email + '.' );
			}
		);
		setValidationSpin( 'none' );
	}
	
	const [form] = Form.useForm();
	
	// reset all the error messages
	const resetAllErrors = () => {
		setCodeError( false );
	}
	
	// run after the first render
	useDidMountEffect(() => {
        form.validateFields();
    },  [ codeError ] );
	
	useEffect(() => {
		setValidationSpin( 'none' );
	}, []);
	
	return (
		<>
			<Header />
			
			<div className="container">
				<Form 
					style 		= {{ paddingTop: '25px' }} 
					className 	= "ps-form--account ps-tab-root" 
					action 	= "" 
					method 	= "get"
					layout 	= "vertical"
					form	= {form}
				>
							<div>
								<div className="ps-form__content">
									<h3 className="">Validez votre Inscription</h3><br/>
									<h5>Entrez le code qui vous a été envoyé à votre boite e-mail {email}</h5>
									<div className="form-group">
										<Form.Item
											name  = "Email"
											rules={[
												{
													message: codeError,
													validator: ( value ) => {
														if ( codeError === false ) {
															return Promise.resolve();
														} 
														else {
															return Promise.reject( codeError );
														}
													}
												}
											]}
										>
											<Input 
												value={code} 
												onChange={handleChangeCode}
												className="form-control" 
												type="text" 
												placeholder="Code" 
											/>
										</Form.Item>
									</div>
									<p>&nbsp;</p>
									<div className="form-group submtit">
										<button 
											className="ps-btn ps-btn--fullwidth"
											onClick={handleClickCodeVerification}
										>
											<Space>
													<Spin
														indicator={
															<LoadingOutlined
																style={{
																	fontSize: 		20,
																	marginRight: 	'10px',
																	display:		validationSpin
																}}
																spin
															/>
														}
													/>
											</Space>
											Confirmer
										</button>
										<Link onClick={handleClickCodeNotReceived}>Je n'ai pas reçu de code.</Link>
									</div>
									<p>&nbsp;</p>
								</div>
							</div>
						
				</Form>
				</div>
				<Newsletter />
            
			<Footer />
		</>
	);
};

export default EmailValidation;
