import React from "react";

const SocialBarFoo = () => {
	return ( 
		<> 
			<div className="ps-post__social"><a className="facebook" href="https://web.facebook.com/profile.php?id=100089245475448"><i className="fa fa-facebook"></i></a><a className="twitter" href="#"><i className="fa fa-twitter"></i></a><a className="google" href="#"><i className="fa fa-google-plus"></i></a><a className="linkedin" href="#"><i className="fa fa-linkedin"></i></a><a className="pinterest" href="#"><i className="fa fa-pinterest"></i></a></div>
		</> 
	)
	
};
export default SocialBarFoo;
