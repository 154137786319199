import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import PasswordForgetValidation from "./PasswordForgetValidation";
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import { AuthContext } from "../context/AuthProvider";
import { Form, Input, Select } from 'antd';
import { Space, Spin, Button, notification, message, Popconfirm } from 'antd';
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
	LoadingOutlined
} from '@ant-design/icons';

import useDidMountEffect from './useDidMountEffect';

const PasswordForget = ( params ) => {
	
	// spin
	const [ passwordForgetSpin, setPasswordForgetSpin ] = useState( 'none' );

	// user email
	const regexEmailValidation = /^[a-zA-Z0-9. _-]+@[a-zA-Z0-9. -]+\.[a-zA-Z]{2,4}$/;
	const [ email, setEmail ] = useState( '' );
	const [ emailError, setEmailError ] = useState( false );
	const handleChangeEmail = ( e ) => {
		setEmail( e.target.value );
		// realtime validation for error messages
		if( !e.target.value ){
			setEmailError( 'Saisir une adresse email svp.' );
		}
		else if( !regexEmailValidation.test( e.target.value ) ) {
			setEmailError( 'Adresse email non valide.' );
		}
		else{
			setEmailError( false )
		}
	}

	// Get fetch definition
	async function getData( url ) {
		const response = await fetch( url, {
			method: "GET", // *GET, POST, PUT, DELETE, etc.
			// mode: "no-cors", // no-cors, *cors, same-origin
			headers: {
				"Content-Type": "application/json",
				// 'Content-Type': 'application/x-www-form-urlenemaild',
			},
			// body: JSON.stringify( data ), // body data type must match "Content-Type" header
		});

		if( response.status !== 200 ){
			return false
		}
		else{
			return response.json(); // parses JSON response into native JavaScript objects
		}
	}
	
	// Validation
	const { logIn }	= useContext( AuthContext );
	const navigate 	= useNavigate();
	const handleClickPasswordForget	 = ( event ) => {
		
		event.preventDefault();

		// spin
		setPasswordForgetSpin( 'block' );
		
		// reset all errors
		resetAllErrors();

		// validation registration email
		if( !regexEmailValidation.test( email ) ){
			message.error( 'Adresse e-mail invalide.' );
			setEmailError( 'Adresse e-mail invalide.' );
			setPasswordForgetSpin( 'none' );
			return
		}
		
		const passwordForgetURL = 'https://allkakou.com/api/authentication/request-password-up?email=' + email +''
		
		const resp = getData( passwordForgetURL ).then( 
			( data ) => {

				if( !data ){
					message.error( 'Il n\'y a pas de compte associé à cet email.' );
					setEmailError( 'Il n\'y a pas de compte associé à cet email.' );
					setPasswordForgetSpin( 'none' );
					return;
				}

				// Keep the user data. Note: user is not activated and can not acces secured pages
				logIn( data );

				// Redirect to password recovery confirm page
				const gotoUrl	= '/connexion/mot-de-passe-oublie/validation';
				navigate( gotoUrl );
			}
		);
	}

	const [form] = Form.useForm();
	
	// reset all the error messages
	const resetAllErrors = () => {
		setEmailError( false );
	}
	
	// run after the first render
	useDidMountEffect(() => {
        form.validateFields();
    },  [ emailError ] );
	
	useEffect(() => {
		setPasswordForgetSpin( 'none' );
	}, []);


	return (
		<>
			<Header />
				<div className="container">
				<div class="ps-section__header" style={{textAlign:'center', marginTop: '25px'}}>
                    <h3>Réinitialiser votre Mot de Passe</h3>
                </div>
		
					<Form 
						style 		= {{ paddingTop: '25px' }} 
						className 	= "ps-form--account ps-tab-root" 
						action 	= "" 
						method 	= "get"
						layout 	= "vertical"
						form	= {form}
					>
						<div className="ps-tabs">
							<div className="ps-tab active" id="sign-in">
								<div className="ps-form__content">
									<h5>Entrez votre email et nous vous enverrons un code pour réinitialiser votre mot de passe.</h5>
									<div className="form-group">
										<Form.Item
											name  = "Email"
											rules={[
												{
													message: emailError,
													validator: ( value ) => {
														if ( emailError === false ) {
															return Promise.resolve();
														} 
														else {
															return Promise.reject( emailError );
														}
													}
												}
											]}
										>
											<input 
												value={email} 
												onChange={handleChangeEmail}
												className="form-control" 
												type="text" 
												placeholder="E-mail" 
											/>
										</Form.Item>
									</div>
									<p>&nbsp;</p>
									<div className="form-group submtit">
										<button 
											className="ps-btn ps-btn--fullwidth"
											onClick={handleClickPasswordForget}
										>
											<Space>
												<Spin
													indicator={
														<LoadingOutlined
															style={{
																fontSize: 		20,
																marginRight: 	'10px',
																display:		passwordForgetSpin
															}}
															spin
														/>
													}
												/>
											</Space>
											Confirmer
										</button>
									</div>
									<p>&nbsp;</p>
								</div>
							</div>
						</div>
					</Form>
				</div>
				<Newsletter />
            
			<Footer />
		</>
	);
};

export default PasswordForget;
