import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import BlogMenu from './BlogMenu';
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import SocialBar from "./SocialBar";
const BlogPageDelivery = ( params ) => {

	
	return (
		<>
		<Header />
			<div className="ps-page--blog" style={{ marginTop: '25px' }}>
        <div className="container">
            <div className="ps-blog--sidebar">
                <div className="ps-blog__left">
                    <div className="ps-post--detail sidebar">
                        <div className="ps-post__header">
                            <h1>Livraison et Recupération des achats</h1>
                            <p>17 Décembre 2023 / par Jane, 237usa.com / dans <Link to='/blog'>Comment ça marche</Link></p>
                        </div>
                        <div className="ps-post__content">
                            <h4>Sur la page Commander, vous êtes invité à choisir une des deux méthodes d'acquisition de vos achats: la livraison ou la récupération.</h4>
                            <strong>La livraison</strong> est payante, à partir de 1000 FCfa. Un courtier 237usa vous apportera votre colis à l'adresse fournie.
							<strong>La récupération</strong> est gratuite. Vous passerez prendre votre colis dans le point de récupération que vous aurez choisi pendant la commande.
                        </div>
                        <div className="ps-post__footer">
                            <SocialBar />
                        </div>
                    </div>
                </div>
                <div className="ps-blog__right">
                    <BlogMenu />
                </div>
            </div>
        </div>
    </div>
	<Newsletter />
	<Footer />
		</>
	);
};

export default BlogPageDelivery;
