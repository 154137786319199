import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import BlogMenu from './BlogMenu';
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import SocialBar from "./SocialBar";
const BlogPagePayment = ( params ) => {

	
	return (
		<>
		<Header />
			<div className="ps-page--blog" style={{ marginTop: '25px' }}>
        <div className="container">
            <div className="ps-blog--sidebar">
                <div className="ps-blog__left">
                    <div className="ps-post--detail sidebar">
                        <div className="ps-post__header">
                            <h1>Comment payer sa commande</h1>
                            <p>17 Décembre 2023 / par Jane, 237usa.com / dans <Link to='/blog'>Comment ça marche</Link></p>
                        </div>
                        <div className="ps-post__content">
                            <h4>Nous acceptons le paiement à la livraison.</h4>
                            <p>Lors de votre commande, vous êtes invité à fournir un numéro de paiement mobile. C'est se numéro que vous utiliserez pour payer votre commande. Vous pouvez le faire juste apres avoir passé votre commande. Vous pouvez aussi payer à la livraison.</p>
                        </div>
                        <div className="ps-post__footer">
                            <SocialBar />
                        </div>
                    </div>
                </div>
                <div className="ps-blog__right">
                    <BlogMenu />
                </div>
            </div>
        </div>
    </div>
	<Newsletter />
	<Footer />
		</>
	);
};

export default BlogPagePayment;
