import React, { useState, useEffect, useContext } from "react";
import { CartContext } from '../context/cart';
import { useNavigate, Link } from 'react-router-dom';
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import Currency from "./Currency";
import ShopItemQuantityCart from "./ShopItemQuantityCart";
import MySpaceMenu from "./MySpaceMenu";
import image01 from '../images/vendor-1.png';
import image02 from '../images/vendor-2.png';
import image03 from '../images/vendor-3.png';
import moment from 'moment/min/moment-with-locales';
import Moment from 'react-moment';
import { Button, notification, message, Popconfirm } from 'antd';
import { AuthContext } from "../context/AuthProvider";
import { Modal } from 'react-responsive-modal';
import MySpaceCommandsModal from "./MySpaceCommandsModal";
import { SiteContext } from "../context/site";

import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
} from '@ant-design/icons';

const MySpace = () => {
	
	const { getUserHistory }   = useContext( SiteContext ); // used in use effect
	
	// Sets the moment instance to use.
	Moment.globalMoment = moment;
	// Set the locale for every react-moment instance to French.
	Moment.globalLocale = 'fr';
	
	// user info
	const { getUser }	= useContext( AuthContext );
	var email 		= 'testeur@testeur.com';
	var firstName	= 'testeur';
	var lastName	= 'testeur';
	if( getUser() != null ){
		email 		= getUser().email;
		firstName  	= getUser().firstName;
		lastName 	= getUser().lastName;
	}
	const name = firstName + ' ' + lastName;
	
	// Get fetch definition
	async function getData( url, data, method ) {
		const response = await fetch( url, {
			method: method, // *GET, POST, PUT, DELETE, etc.
			// mode: "no-cors", // no-cors, *cors, same-origin
			headers: {
				"Content-Type": "application/json",
				// 'Content-Type': 'application/x-www-form-urlencoded',
			},
			// body: JSON.stringify( data ), // body data type must match "Content-Type" header
		});
		
		if( response.status !== 200 ){
			return false
		}
		else{
			return response.json(); // parses JSON response into native JavaScript objects
		}
		
	}
	
	// Get history
	// const commandsHistory = getUserHistory( email );
	
	// const commandsHistory = [
    // {
        // "orderId": "3d910e4b-8153-4ebd-bfd5-1332ec0f5f14",
        // "someTotalOrder": 7000,
        // "someTotalOrderReal": 6000,
        // "orderPhoneNumber": "698781674",
        // "orderCode": "34784798",
        // "personEmail": "yaosoft@hotmail.com",
        // "userPaymentPhoneNumber": null,
        // "userOrderAddress": "Japoma\nCocotiers\nImmeuble Bafana II",
        // "deliveryTypePrice": 1000,
        // "createdDate": "2024-03-26T04:01:58.553+00:00",
        // "updatedDate": "2024-05-26T04:01:58.553+00:00",
        // "person": {
            // "personId": "8983ac76-08fe-45f5-a7c8-41f810982042",
            // "email": "yaosoft@hotmail.com",
            // "firstName": "TCHUISSI",
            // "lastName": "Yaovi",
            // "userName": null,
            // "photos": null,
            // "gender": null,
            // "phoneNumber": "698781674",
            // "createdDate": "2022-12-08T15:50:30.346+00:00",
            // "updatedDate": "2022-12-08T15:50:30.346+00:00",
            // "roles": [
                // {
                    // "roleId": "96091a8e-231b-4e5f-b890-889d49649df4",
                    // "name": "ROLE_USER",
                    // "description": "Un user peut-etre un client",
                    // "createdDate": "2022-10-03T00:43:50.722+00:00",
                    // "updatedDate": "2022-10-03T00:43:50.722+00:00"
                // }
            // ],
            // "enable": true,
            // "tokenEnabled": false,
            // "newsLetter": true
        // },
        // "products": [
            // {
                // "productId": "d5b14a63-5cfa-4a09-bb20-05d5fb67c9a8",
                // "productName": "Time and Tru",
                // "productLineDescription": "Chaussures plates blanche à point pour femmes.",
                // "productSizeOrder": "40",
                // "productSearch": "time and tru time and tru point femme chaussure string",
                // "brand": "Time and Tru",
                // "productColor": "Point",
                // "currentProductSelected": null,
                // "productImageUrl": "Time-and-Tru-Women-Feather-Flats-BlkWht1.png",
                // "productNewImageUrl": "string",
                // "productPriceBuy": 5000,
                // "productPriceBenefit": 5000,
                // "productPrice": 6000,
                // "productPriceSale": 10000,
                // "productPriceTotalSell": 6000,
                // "productTax": 0,
                // "productQuantity": 4,
                // "initialProductQuantity": 5,
                // "productQuantitySell": 1,
                // "productDescription": [
                    // {
                        // "descName": "Cuir PU haut de gamme."
                    // },
                    // {
                        // "descName": "Style à enfiler."
                    // },
                    // {
                        // "descName": "Bout rond"
                    // },
                    // {
                        // "descName": "Essuyer."
                    // },
                    // {
                        // "descName": "Pays d'importation:USA"
                    // }
                // ],
                // "productSize": [
                    // {
                        // "sizeName": "42",
                        // "sizeQuantity": 3
                    // },
                    // {
                        // "sizeName": "41",
                        // "sizeQuantity": 0
                    // },
                    // {
                        // "sizeName": "40",
                        // "sizeQuantity": 1
                    // }
                // ],
                // "productImage": [
                    // {
                        // "imageName": "Time-and-Tru-Women-Feather-Flats-BlkWht1.png"
                    // },
                    // {
                        // "imageName": "Time-and-Tru-Women-Feather-Flats-BlkWht2.jpeg"
                    // },
                    // {
                        // "imageName": "Time-and-Tru-Women-Feather-Flats-BlkWht3.jpeg"
                    // },
                    // {
                        // "imageName": "Time-and-Tru-Women-Feather-Flats-BlkWht5.jpeg"
                    // }
                // ],
                // "category": "femme",
                // "subCategory": "chaussure",
                // "subSubCategory": "string",
                // "createdDate": "2022-12-24T16:53:07.785+00:00",
                // "updatedDate": "2022-12-24T16:53:07.785+00:00",
                // "productActived": true,
                // "unique": false,
                // "removed": false,
                // "newProduct": false
            // }
        // ],
        // "recupLocation": null,
        // "orderStatus": {
            // "orderStatusName": "PAYER",
            // "createdDate": "2024-05-26T04:01:58.553+00:00",
            // "updatedDate": "2024-05-26T04:01:58.553+00:00"
        // },
        // "discount": null
    // },
    // {
        // "orderId": "aaf36758-6e7a-402b-a1e0-0f997d999178",
        // "someTotalOrder": 14000,
        // "someTotalOrderReal": 14000,
        // "orderPhoneNumber": "698781674",
        // "orderCode": "77444594",
        // "personEmail": "yaosoft@hotmail.com",
        // "userPaymentPhoneNumber": null,
        // "userOrderAddress": null,
        // "deliveryTypePrice": 0,
        // "createdDate": "2024-05-26T03:56:27.725+00:00",
        // "updatedDate": "2024-05-26T03:56:27.725+00:00",
        // "person": {
            // "personId": "8983ac76-08fe-45f5-a7c8-41f810982042",
            // "email": "yaosoft@hotmail.com",
            // "firstName": "TCHUISSI",
            // "lastName": "Yaovi",
            // "userName": null,
            // "photos": null,
            // "gender": null,
            // "phoneNumber": "698781674",
            // "createdDate": "2022-12-08T15:50:30.346+00:00",
            // "updatedDate": "2022-12-08T15:50:30.346+00:00",
            // "roles": [
                // {
                    // "roleId": "96091a8e-231b-4e5f-b890-889d49649df4",
                    // "name": "ROLE_USER",
                    // "description": "Un user peut-etre un client",
                    // "createdDate": "2022-10-03T00:43:50.722+00:00",
                    // "updatedDate": "2022-10-03T00:43:50.722+00:00"
                // }
            // ],
            // "enable": true,
            // "tokenEnabled": false,
            // "newsLetter": true
        // },
        // "products": [
            // {
                // "productId": "a89d8268-6f40-49c2-92d1-bad82701c7b0",
                // "productName": "NORTHLIGHT",
                // "productLineDescription": "Enseigne murale néon en forme de lèvres de 26.6cm.",
                // "productSizeOrder": "26.6cm",
                // "productSearch": "northlight northlight rose maison interieur string",
                // "brand": "NORTHLIGHT",
                // "productColor": "Rose",
                // "currentProductSelected": null,
                // "productImageUrl": "NORTHLIGHT-10-5in-Lips-Led.jpg",
                // "productNewImageUrl": "string",
                // "productPriceBuy": 2750,
                // "productPriceBenefit": 1250,
                // "productPrice": 1500,
                // "productPriceSale": 4000,
                // "productPriceTotalSell": 3000,
                // "productTax": 0,
                // "productQuantity": 2,
                // "initialProductQuantity": 2,
                // "productQuantitySell": 2,
                // "productDescription": [
                    // {
                        // "descName": "Enseigne murale néon en forme de lèvres de 26.6cm."
                    // }
                // ],
                // "productSize": [
                    // {
                        // "sizeName": "26.6cm",
                        // "sizeQuantity": 2
                    // }
                // ],
                // "productImage": [
                    // {
                        // "imageName": "NORTHLIGHT-10-5in-Lips-Led.jpg"
                    // }
                // ],
                // "category": "maison",
                // "subCategory": "interieur",
                // "subSubCategory": "string",
                // "createdDate": "2023-02-05T11:51:14.548+00:00",
                // "updatedDate": "2023-02-05T11:51:14.548+00:00",
                // "productActived": true,
                // "unique": false,
                // "removed": false,
                // "newProduct": false
            // },
            // {
                // "productId": "067eb45b-bb62-46f3-b432-e341325c7c8a",
                // "productName": "Victoria K",
                // "productLineDescription": "Ballerines compensées médaillon carré doré noir pour femmes.",
                // "productSizeOrder": "43",
                // "productSearch": "victoria k victoria k chameau femme chaussure string",
                // "brand": "Victoria K",
                // "productColor": "Chameau",
                // "currentProductSelected": null,
                // "productImageUrl": "Victoria-Square-Gold-Medallion1.png",
                // "productNewImageUrl": "string",
                // "productPriceBuy": 11000,
                // "productPriceBenefit": 5000,
                // "productPrice": 11000,
                // "productPriceSale": 16000,
                // "productPriceTotalSell": 11000,
                // "productTax": 0,
                // "productQuantity": 3,
                // "initialProductQuantity": 3,
                // "productQuantitySell": 1,
                // "productDescription": [
                    // {
                        // "descName": "Une paire de ballerines compensées  Victoria K pour femmes avec un médaillon carré doré noir à la mode sur le devant, offre une combinaison de confort et de style."
                    // },
                    // {
                        // "descName": "La semelle en caoutchouc ajoute de la stabilité. Ce look élégant et chic à la mode se porte bien à tout moment de la journée ou d'une soirée en ville."
                    // },
                    // {
                        // "descName": "Une élégante paire de ballerines Victoria K ajoute du style à votre garde-robe de tous les jours."
                    // },
                    // {
                        // "descName": "Style à enfiler."
                    // },
                    // {
                        // "descName": "Carré doré à la mode noir."
                    // },
                    // {
                        // "descName": "Clous dorés dans les coins du médaillon."
                    // },
                    // {
                        // "descName": "Matériau texturé conçu."
                    // },
                    // {
                        // "descName": "Semelle 100% caoutchouc."
                    // },
                    // {
                        // "descName": "Semelle confort."
                    // },
                    // {
                        // "descName": "Utilisez un chiffon doux pour nettoyer."
                    // },
                    // {
                        // "descName": "Pays d'importation:USA."
                    // }
                // ],
                // "productSize": [
                    // {
                        // "sizeName": "40.5",
                        // "sizeQuantity": 1
                    // },
                    // {
                        // "sizeName": "41",
                        // "sizeQuantity": 1
                    // },
                    // {
                        // "sizeName": "43",
                        // "sizeQuantity": 1
                    // }
                // ],
                // "productImage": [
                    // {
                        // "imageName": "Victoria-Square-Gold-Medallion1.png"
                    // },
                    // {
                        // "imageName": "Victoria-Square-Gold-Medallion2.png"
                    // },
                    // {
                        // "imageName": "Victoria-Square-Gold-Medallion3.png"
                    // },
                    // {
                        // "imageName": "Victoria-Square-Gold-Medallion4.png"
                    // },
                    // {
                        // "imageName": "Victoria-Square-Gold-Medallion5.png"
                    // },
                    // {
                        // "imageName": "Victoria-Square-Gold-Medallion6.png"
                    // }
                // ],
                // "category": "femme",
                // "subCategory": "chaussure",
                // "subSubCategory": "string",
                // "createdDate": "2023-01-06T14:50:41.249+00:00",
                // "updatedDate": "2023-01-06T14:50:41.249+00:00",
                // "productActived": true,
                // "unique": false,
                // "removed": false,
                // "newProduct": false
            // }
        // ],
        // "recupLocation": {
            // "recupLocationId": null,
            // "location": "New Bell",
            // "city": "Douala",
            // "partners": [
                // {
                    // "partnerAddress": "Ngangue, New Bell, Douala",
                    // "partnerDescription": [
                        // "Situé pas loin du petit marché Ngangue. Le Salon de Coiffure chez Guy est partenaire de 237usa.com.",
                        // "Le salon fournit les services suivants: Coupe de cheveux régulière, Coupe de cheveux + Barbe, Rasage à la serviette chaude, Garniture de barbe, Les sourcils, Fondus and Shampooing.",
                        // "Parmi les installations de cet établissement figurent une connexion Wi-Fi gratuite pour les clients",
                        // "L'établissement est entierement climatisé pour la grande satisfaction de ses clients."
                    // ],
                    // "partnerFirstName": "Guy",
                    // "partnerLastName": "Rene",
                    // "partnerAlias": "Salon de Coiffure - Chez Guy",
                    // "partnerPhoneNumber": "699972941",
                    // "partnerAvatar": "photo-salon-coiffure-chez-guy.png",
                    // "partnerPhoto": "avatar-salon-coiffure-chez-guy.jpeg",
                    // "partnerLocationImage": "map-salon-coiffure-chez-guy.png",
                    // "partnerGoogleMapLink": "https://www.google.com/maps/place/4%C2%B001'24.1%22N+9%C2%B042'36.4%22E/@4.023777,9.708612,18z/data=!4m5!3m4!1s0x0:0x83f7ec02a771ac2c!8m2!3d4.02336!4d9.7101",
                    // "dayOfWeek": "Lundi-Samedi",
                    // "timeOfWeek": "9h-21h",
                    // "partnerCapacity": 30,
                    // "pertnerOffer": {
                        // "pertnerOfferName": "Promotion",
                        // "partnerOfferImages": [
                            // {
                                // "partnerOfferImageName": "salon-coiffure-chez-guy1.jpeg"
                            // },
                            // {
                                // "partnerOfferImageName": "salon-coiffure-chez-guy2.jpeg"
                            // },
                            // {
                                // "partnerOfferImageName": "salon-coiffure-chez-guy3.jpeg"
                            // },
                            // {
                                // "partnerOfferImageName": "salon-coiffure-chez-guy4.jpeg"
                            // },
                            // {
                                // "partnerOfferImageName": "salon-coiffure-chez-guy5.jpeg"
                            // }
                        // ]
                    // },
                    // "partnerActived": true
                // }
            // ]
        // },
        // "orderStatus": {
            // "orderStatusName": "NON-PAYER",
            // "createdDate": "2024-05-26T03:56:27.725+00:00", 
            // "updatedDate": "2024-05-26T03:56:27.725+00:00"
        // },
        // "discount": null
    // }
// ]

	
	// get a order info and open the modal
	const[ order, setOrder ] = useState( {} );
	
	const[ userHistory, setUserHistory ] = useState( [] );
	const handleClickHistory = ( orderId ) => {
		// get command data
		setOrder( userHistory.filter( e => e.orderId == orderId )[0] );
		onOpenCommandsModal();
	}

	// CommandsModal state
	const [ openCommandsModal, setOpenCommandsModal ] = useState(false);
	const onOpenCommandsModal  = () => setOpenCommandsModal(true);
	const onCloseCommandsModal = () => setOpenCommandsModal(false);

	
	const BuildHistory = () => {
console.log( 'userHistory', userHistory )
		return (
			userHistory.map(
				( history, key ) =>
					<tr key={key}>
						<td>
							<Link onClick={ e => handleClickHistory( history.orderId ) } title='Voir'><i className="icon-eye"></i> &nbsp;{ history.orderCode }</Link>
						</td>
						<td>
							<Moment locale='fr' format='DD MMM yyyy, hh:mm' >{ history.createdDate }</Moment> 
							<p></p>
						</td>
						<td>
							{ history.orderPhoneNumber }
						</td>
						<td>
							{ history.orderStatus.orderStatusName == 'NON-PAYER'
							?
								<span style={{ color: 'red' }}>&nbsp;Non payé</span>
							:
								<span style={{ color: 'green' }}><i className="icon-check"></i>&nbsp;Payé</span>
							}
						</td>
						<td>
							{ history.someTotalOrder.toString().replace(/(?=(?:.{3})*$)/g, ' ').trim() }<Currency />
						</td>
					</tr>
				
			)
		)
	}
	

	useEffect( () => {
		// set the products state
		const getUserHistoryFn = async () => {
			const history = await getUserHistory( email );
console.log( 'history', history );
			setUserHistory ( history );
		}
		if( !userHistory.length )
			getUserHistoryFn();  // uncomment to get production data
		
	}, [ ] );

	return (
		<>
		<Header />
		
		<Modal open={ openCommandsModal } onClose={ onCloseCommandsModal } center>
			<MySpaceCommandsModal params = { order } />
		</Modal>
		
		<main class="ps-page--my-account">
        <section class="ps-section--account">
            <div class="container">
                <div class="row" style={{ marginLeft: '0px' }}>
                    <div class="col-lg-4">
                        <div class="ps-section__left">
                            <MySpaceMenu />
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="ps-section__right">
                            <div class="container">
                <div class="ps-section__header">
					<h3>Historique de vos Commandes</h3>
                    <p>Retrouvez toutes vos commandes et les details de vos transaction.</p>
                </div>
                <div class="ps-section__content">
                    <div class="row">
                        <div class="table-responsive" style={{ width: '65%' }}>
                                        <table class="table" >
                                            <thead>
                                                <tr>
                                                    <th>Commandes</th>
                                                    <th>Date</th>
                                                    <th>Paiement</th>
                                                    <th>Status</th>
													<th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <BuildHistory />
                                            </tbody>
                                        </table>
                                    </div>
                        
                    </div>
                </div>
            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
		<Newsletter />
    </main>
	<Footer />
		</>
	);
};

export default MySpace;
