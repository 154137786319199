import React, { useState, useEffect, useContext } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ShoppingPageItemSimple from "./ShoppingPageItemSimple";
import { StoreContext } from '../context/store';


const ShopSelectionVerticale = ( selection ) => {	
	const selectionName = selection.selection.name;
	const title 		= selection.selection.title;
	const maxItems 		= selection.selection.maxItems;
	const path			= selection.selection.path;
	
	// custom hook to get data
	const { getASelectionProducts } = useContext( StoreContext );
	const [ selections, setSelections ] = useState( [] );

	// set product badge
	const getBadge = ( productPrice, productPriceSale ) => {
		const rep = ( ( productPriceSale - productPrice )/productPriceSale ) * 100;
		
		if( isNaN( rep ) || rep == 'Infinity' || rep == '-Infinity' )
			return ''
		
		return	'-' + Math.trunc( rep ) + '%'
	}
	
	const BuildItems = () => {
		return (
			selections.map(( product, index ) => 
			index < maxItems
			&&
				<ShoppingPageItemSimple 
					key  = { index } 
					item = {{
						name: 			product.productName, 
						price: 			product.productPrice.toString().replace(/(?=(?:.{3})*$)/g, ' '),
						priceOld:		product.productPriceSale ? product.productPriceSale.toString().replace(/(?=(?:.{3})*$)/g, ' ') : '',
						description: 	product.productLineDescription, 
						picture: 		product.productImageUrl ? product.productImageUrl : product.productImage[0],
						pictures: 		product.productImage,
						badge:			getBadge( product.productPrice, product.productPriceSale ),
						brand:			product.brand,
						productDescription: product.productDescription,
						productId: 		product.productId,
						productColor: 	product.productColor,
						productSize:	product.productSize,
						selection:		selectionName,
						category:		null,
						categories: 	null,
						categoryPath:	null,
					}}
				/>
			)
		)
	}
	
	//
	useEffect( () => {
		// set the products state
		const getProducts = async () => {
			const selection = selectionName.toLowerCase();
			const selectionProducts = await getASelectionProducts( selection );
			setSelections ( selectionProducts );
		}
		getProducts();
	}, [ selection.selection.title ] );
	
	return (
		<>
			<h3>{ title }</h3>
		    <div className="widget__content">
				<BuildItems props = { maxItems }
				/> 
            </div>
		</>
	);
};

export default ShopSelectionVerticale;