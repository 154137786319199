import React from "react";
import { Link } from "react-router-dom";
import AllRoutes from "./AllRoutes";
import ShopMobileMenu from "./ShopMobileMenu";
const Footer = () => {
	const categories = AllRoutes.getAllCategories();

	const CatLinks = ( category ) => {
		
		return(
			category.params.map( ( cat, index ) =>
				<Link key={ index } to={ '/' + cat.path } >{ cat.title }</Link>
			)
		)
	}

	const FooterMenu = () =>{
		const cats = Object.values( categories ); 
 
		return(
			cats.map(( cat, index ) =>
				!cat[ 0 ].category.includes( '/' ) 
				&&
					<p key={ index }>
						<strong>
							{ cat[ 0 ].category }
						</strong>
						<CatLinks params={ cat } />
					</p>
				
			)
		)
	}

	return (
		<>
		<footer className="ps-footer">
        <div className="ps-container">
            <div className="ps-footer__widgets">
                <aside className="widget widget_footer widget_contact-us">
                    <h4 className="widget-title">Contactez-nous</h4>
                    <div className="widget_content">
                        <p>Appelez nous 24/7</p>
                        <h5>+237 699 97 29 41</h5>
						<a href="mailto:info@237usa.com">info@237usa.com</a>
                        <p>Immeuble Bafana II, <br/>Japoma Douala <br/>Cameroun <br /></p>
                        <ul className="ps-list--social">
                            <li><a className="facebook" href="https://web.facebook.com/search/top?q=237usa.com"><i className="fa fa-facebook"></i></a></li>
                            <li><a className="twitter" href="#"><i className="fa fa-twitter"></i></a></li>
                            <li><a className="google-plus" href="#"><i className="fa fa-google-plus"></i></a></li>
                            <li><a className="instagram" href="#"><i className="fa fa-instagram"></i></a></li>
                        </ul>
                    </div>
                </aside>
                <aside className="widget widget_footer">
                    <h4 className="widget-title">Acces rapide</h4>
                    <ul className="ps-list--link">
                        <li><Link to="/confidentialite-politique">Confidentialité & Politique</Link></li>
                        <li><Link to="/term-conditions">Termes & conditions</Link></li>
                        <li><Link to="/blog/comment-recevoir-ses-achats">Livraison</Link></li>
                        <li><Link to="/blog/comment-payer">Paiement</Link></li>
                        <li><Link to="/faq">FAQs</Link></li>
                    </ul>
                </aside>
                <aside className="widget widget_footer">
                    <h4 className="widget-title">Entreprise</h4>
                    <ul className="ps-list--link">
                        <li><Link to="/a-propos-de-nous">À propos de nous</Link></li>
                        <li><Link to="/contactez-nous">Partenaires</Link></li>
                        <li><Link to="/contactez-nous">Carriere</Link></li>
                        <li><Link to="/contactez-nous">Contact</Link></li>
                    </ul>
                </aside>
                <aside className="widget widget_footer">
                    <h4 className="widget-title">Bussiness</h4>
                    <ul className="ps-list--link">
                        <li><Link to="/mon-espace">Mon compte</Link></li>
                        <li><Link to="/connexion">S'abonner</Link></li>
                    </ul>
                </aside>
            </div>
            <div className="ps-footer__links">
				<FooterMenu />
            </div>
            <div className="ps-footer__copyright">
                <p>&copy; 2024 237USA.com Tous Droits Réservés</p>
                <p><span>Paiements sécurisés avec:</span><Link to="/blog/comment-payer" title='Orange Money'><img src="img/payment-method/OrangeMoney.png" alt="" style={{width: '50px'}} /></Link><Link to="/blog/comment-payer" title='MTN Mobile Money'><img src="img/payment-method/MTNMobileMoney.png" alt="" style={{width: '50px'}}  /></Link></p>
            </div>
        </div>
    </footer>
    <div id="back2top"><i className="icon icon-arrow-up"></i></div>

	<p>&nbsp;</p>
	<p>&nbsp;</p>
    
    <ShopMobileMenu params={categories}/>
    <div id="loader-wrapper">
        <div className="loader-section section-left"></div>
        <div className="loader-section section-right"></div>
    </div>
		</>
	);
};

export default Footer;
