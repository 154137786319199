import React, { useState, useEffect, useContext } from "react";
import { CartContext } from '../context/cart';
import { useNavigate } from 'react-router-dom';
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import Currency from "./Currency";
import { Link } from "react-router-dom";
import ShopItemQuantityCart from "./ShopItemQuantityCart";
import MySpaceMenu from "./MySpaceMenu";
import image01 from '../images/vendor-1.png';
import image02 from '../images/vendor-2.png';
import image03 from '../images/vendor-3.png';

import { Button, notification, message, Popconfirm } from 'antd';
import { AuthContext } from "../context/AuthProvider";
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
} from '@ant-design/icons';

const MySpace = () => {
	

	
	return (
		<>
		<Header />
		<main class="ps-page--my-account">
        <section class="ps-section--account">
            <div class="container">
                <div class="row" style={{ marginLeft: '0px' }}>
                    <div class="col-lg-4">
                        <div class="ps-section__left">
                            <MySpaceMenu />
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="ps-section__right">
                            <div class="container">
                <div class="ps-section__header">
					<h3>Bienvenue! </h3>
                    <p>Procurez-vous des produits uniques made in USA au meilleur prix!</p>
                </div>
                <div class="ps-section__content">
                    <div class="row">
                        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">
                            <div class="ps-block--icon-box-2">
                                <div class="ps-block__thumbnail"><img src={image03} alt="" /></div>
                                <div class="ps-block__content">
                                    <h4>Assistance 24/7</h4>
                                    <div class="ps-block__desc" data-mh="about-desc">
                                        <p>Pour toutes vos questions</p>
										<p>Appelez nous 24/7<br/>
										+237 699 97 29 41<br/>
										info@237usa.com
										</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
		<Newsletter />
    </main>
	<Footer />
		</>
	);
};

export default MySpace;
