import React, { useState, useEffect, useContext } from "react";
import Rater from 'react-rater';
import 'react-rater/lib/react-rater.css';
import '../gallerieOverride.css';
import '../productOverrides.css';
import { useNavigate, Link } from 'react-router-dom';
import ImageGallery from "react-image-gallery";
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import ProductStickyHeader from "./ProductStickyHeader";
import ShopItemColors from "./ShopItemColors";
import Currency from "./Currency";
import { useLocation } from "react-router-dom";
import ShopItemSize from "./ShopItemSize";
import ShopCategory from "./ShopCategory";
import ShopSelection from "./ShopSelection";
import ShopStore from "./ShopStore";
import ShopASearch from "./ShopASearch";
import ShopSelectionVerticale from "./ShopSelectionVerticale";
import { SiteContext } from '../context/site';
import { StoreContext } from '../context/store';
import SocialBar from "./SocialBar";

import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
} from '@ant-design/icons';
import { Button, Divider, notification, Space } from 'antd';

const Product = ( params ) => {

	window.scrollTo( 0, 0 );

	const [ selectedProduct, setSelectedProduct ] 	= useState( [] );
	
	const { getProductById } = useContext( StoreContext );

	// set product badge
	const getBadge = ( productPrice, productPriceSale ) => {
		const rep = ( ( productPriceSale - productPrice )/productPriceSale ) * 100;
		
		if( isNaN( rep ) || rep == 'Infinity' || rep == '-Infinity' )
			return ''
		
		return	'-' + Math.trunc( rep ) + '%'
	}
	
	const searchParams = new URLSearchParams( window.location.search );
	// 
	
// console.log( 'selectedProduct', selectedProduct );

	const id = searchParams.get( 'productId' );
	const [ productId, setProductId ]	= useState( id );
	const name 				= selectedProduct.productName ? selectedProduct.productName : '';
	const price 			= selectedProduct.productPrice ? selectedProduct.productPrice.toString().replace(/(?=(?:.{3})*$)/g, ' ') : '';
	const colors 			= selectedProduct.productColor ? selectedProduct.productColor : '';
	const price_old 		= selectedProduct.productPriceSale ? selectedProduct.productPriceSale.toString().replace(/(?=(?:.{3})*$)/g, ' ') : '';
	const description 		= selectedProduct.productLineDescription ? selectedProduct.productLineDescription : '';
	const badge 			= selectedProduct.productPrice ? getBadge( selectedProduct.productPrice, selectedProduct.productPriceSale ): '';
	// const category 			= selectedProduct.category? selectedProduct.category : '';
	const brand 			= selectedProduct.brand ? selectedProduct.brand : '';
	const picture 			= selectedProduct.productImageUrl ? selectedProduct.productImageUrl : '';
	const pictures 			= selectedProduct.productImage ? selectedProduct.productImage : '';
	const descriptions		= selectedProduct.productDescription? selectedProduct.productDescription : [];
	const sizesList			= selectedProduct.productSize ? selectedProduct.productSize : [];
	// const categoryName		= selectedProduct.categoryName;
	// const currentPath		= selectedProduct.currentPath;
	// const selectionName		= selectedProduct.selectionName;
	const initialQuantity	= selectedProduct.initialQuantity ? selectedProduct.initialQuantity : '';
	const stockQuantity		= selectedProduct.productQuantity ? selectedProduct.productQuantity : '';
	const type				= searchParams.get( 'type' );
	const typeName			= searchParams.get( 'typeName' );

	const productSizesTab	= searchParams.getAll( 'productSize' );

	const { siteURL } 	= useContext( SiteContext );

	// Handle click category btn
	const navigate = useNavigate();
	// const handleClickCategory = () =>{ // category or selection
		// navigate ( '/' + currentPath, {
			// params: {
				// selection:		'',
				// categoryName:	categoryName,
				// currentPath:	currentPath,
			// }
		// });
		// // navigate(0);
	// }


	// const sizesListTab =  specifications.map( ( specification ) => specification.sizeName );


	// Build Description's lines
	const BuildDescriptions = () => {
		return(
			descriptions.map( ( description, index ) =>
				<li key = { index }>
					{ description.descName }
				</li>
			)
		)
	}

	// Build specification
	const sizesListTab 		= sizesList.map( ( specification ) => specification.sizeName );
	const sizesListString 	= sizesListTab.join( ', ' );
	
	const BuildSpecifications = () => {
		return(
		<>
			<tr>
                <td>Taille</td>
				<td>{ sizesListString }</td>
            </tr>
			<tr>
                <td>Couleur</td>
				<td>{ colors }</td>
            </tr>
		</>
		)
	}

	// Build productSizes arrays
	const productSizes 	= Array();
	const itemSizes 	= Array();

	for( var size of sizesList ){
		// for Ant design radiobox
		const obj01 = {};
		obj01[ 'label' ] 		= size.sizeName;
		obj01[ 'value' ] 		= size.sizeName;
		productSizes.push( obj01 );
		// to retrieve the quantity for selected size
		const obj02 = {};
		obj02[ 'label' ] 		= size.sizeName;
		obj02[ 'value' ] 		= size.sizeQuantity;
		itemSizes.push( obj02 );	
	}

	// Antd
	const [ api, contextHolder ] = notification.useNotification();	
	const openNotification = ( message ) => {			// add to cart success
		notification.success({
			message: `${message}`,
			duration: 3,
			description: "Ce produit a été ajouté à vote panier.",
			placement: 'topRight',
			zIndex: 9999
		});
	};
	const openNotificationError = ( message ) => {		// add to cart error
		notification.error({
			message: `${message}`,
			duration: 3,
			description: "Vous devez choisir une taille.",
			placement: 'topRight',
			zIndex: 9999
		});
	};
		
	// product 
	const imageGalerie = () => {
		const arr = Array();
		for( const picture of pictures ){
			const obj = {};
			obj[ 'original' ] 	= siteURL + '/images/products/' + picture.imageName;
			obj[ 'thumbnail' ] 	= siteURL + '/images/products/' + picture.imageName;
			
			arr.push( obj );		
		}
		
		return arr;
	}	
	const imageGaleriePictures = imageGalerie();
	
	// Rater	
	const BuildRater01 = () => {
		return ( 
			<
				Rater total		={5} 
				rating			={1} 
				interactive		={false}
			/> 
		)
	}	
	
	// Rater - user	
	const [ rating02, setRating02 ] = useState( 0 );
	const BuildRater02 = () => {
		return ( 
			<
				Rater total		= { 5 } 
				rating			= { rating02 } 
				interactive		= { true }
				onRate 			= { ( rating  )=> setRating02( rating.rating ) }
			/> 
		)
	}	

	// A cart item
	// const location 	= useLocation();
	// const path 		= location.pathname;
	const path = window.location.href;
	const product =	{
		id: 			productId,
		name: 			name,
		price: 			price,
		picture: 		picture,
		size: 			'',
		productSizes:	productSizes,
		itemSizes:		itemSizes,
		path:			path,
		stockQuantity:	stockQuantity
	};

	const cartItem = {
		product:	product,
		quantity:	'', 	
	}
	
	//
	useEffect( () => {
		// set the products state
		const getAProduct = async () => {
			const selectedProduct = await getProductById( id, true ); // fetch with spiner
			setSelectedProduct( selectedProduct[ 0 ] );
			
// console.log( 'selectedProduct', selectedProduct );	
		}
		getAProduct( id );
	
	}, [ id ] );
	
	// Update page title
	useEffect ( () => {
		window.document.title = capitalizeFirstLetter( typeName ) + ' ' +  capitalizeFirstLetter( name ) + ' - ' + description;
	}, [name] );
	
	// helper
	function capitalizeFirstLetter( string ) {
		if( !string || string === null )
			return
		
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	return (
		<>
		<Header />
		<ProductStickyHeader 
			params = {{ 
				name: 		name,
				category: 	typeName,
				picture:	picture,
				price:		price,
				price_old:	price_old,
				cartItem:	cartItem,
			}}
		/>
		
		<div class="ps-page--product">
        <div class="ps-container">
            <div class="ps-page__container">
                <div class="ps-page__left">
                    <div class="ps-product--detail ps-product--fullwidth">
                        <div class="ps-product__header">
                            <div class="ps-product__thumbnail" data-vertical="true">
                                <ImageGallery items={imageGaleriePictures} />
                            </div>
                            <div class="ps-product__info">
                                <h1>{ capitalizeFirstLetter( name ) }</h1>
                                <div class="ps-product__meta">
                                    <p>Marque: { capitalizeFirstLetter( brand ) }</p>
                                </div>
									<h4 class="ps-product__price">{price}<Currency /> { price_old && <> - <span style={{ textDecoration: 'line-through' }}>{ price_old }<Currency /></span></> }</h4>
                                <div class="ps-product__desc">
                                    <p>{ capitalizeFirstLetter( type ) }:&nbsp;<b>{ typeName.toUpperCase() }</b></p>
                                    <ul class="ps-list--dot">
										{ description }
										<br/>
                                        <BuildDescriptions />
                                    </ul>
                                </div>
                                <div class="ps-product__variations">
                                    <figure>
                                        <ShopItemColors params = { colors } />
                                    </figure>
                                </div>
                                <div class="ps-product__shopping">
                                    <figure>
                                        <ShopItemSize productSizes =
											{{
												cartItem: 	cartItem
											}}
										/>
                                    </figure>
                                </div>
                                <div class="ps-product__specification"><Link class="report" to="/contactez-nous">Signaler ce produit</Link>
                                    <p><strong>SKU:</strong> { product.id }</p>
                                    <p class="categories"><i className='fa fa-phone'></i> <a href='tel:+237699972941' title='Appelez-nous'>+ 237 699 97 29 41</a></p>
                                </div>
                                <div class="ps-product__sharing">
									<p >Suivez-nous</p>
									<SocialBar />
								</div>
                            </div>
                        </div>
                        <div class="ps-product__content ps-tab-root">
                            <h3 className="ps-product__heading">Specifications</h3>
                    <div className="table-responsive">
                        <table className="table table-bordered ps-table ps-table--specification">
                            <tbody>
								<BuildSpecifications />
								<p>&nbsp;</p>
                            </tbody>
                        </table>
                    </div>
                    
                    
                        </div>
                    </div>
                </div>
                <div class="ps-page__right">
                    <aside class="widget widget_product widget_features">
                        <p><i className="icon-network"></i> Livraison dans tout le pays</p>
                        <p><i className="icon-3d-rotate"></i> Livraison gratuite à partir de 30 000F </p>
                        <p><i className="icon-receipt"></i> Paiement à la livraison possible.</p>
                        <p><i className="icon-credit-card"></i> Paiement Mobile Money</p>
                    </aside>
                    <aside class="widget widget_sell-on-site">
                        <i class="icon-store"></i>&nbsp;Vous voulez proposer un point de récupération?<Link to="/contactez-nous"> contactez-nous!</Link>
                    </aside>
                    <aside class="widget widget_ads"><Link to="/boutiques"><img src="img/ads/product-ads.gif" alt="" /></Link></aside>
                    <aside class="widget widget_same-brand">
                        <ShopSelectionVerticale selection= {{
							name: 	'derniere chance',
							title: 	'Dernière chance',
							maxItems: 1,
						}} />
                    </aside>
                </div>
            </div>
            <div class="ps-section--default ps-customer-bought">
                { type == 'category'
								&&
									<ShopCategory params = 
										{{	
											category: 			typeName,
											displayedName: 		'Dans la même categorie',
											limit:				10,
										}}
									/>
				}
				{ type == 'selection'
								&&
									<ShopSelection params = 
										{{	
											name: 		typeName,
											title: 		'Dans la même sélection',
										}}
									/>
				}
				{ type == 'store'
								&&
									<ShopStore params = 
										{{	
											name: 		typeName,
											title: 		'Dans la même boutique',
										}}
									/>
				}
				{ type == 'recherche'
								&&
									<ShopASearch params = 
										{{	
											name: 		typeName,
											title: 		'Dans la même recherche',
										}}
									/>
				}
            </div>
        </div>
    </div>
    <Newsletter />
    <Footer />
    
		</>
	);
};

export default Product;
