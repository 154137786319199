import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import { AuthContext } from "../context/AuthProvider";
import { SiteContext } from "../context/site";
import imageLivraison from '../images/milestone-3.png';

const OrderThankYou = () => {
	const { getReferrer } = useContext( SiteContext );
	const path	= getReferrer();

	const navigate = useNavigate();
	if( path != '/commander' )
		navigate( '/accueil' )


	const { getUser }	= useContext( AuthContext );	
	const email = getUser().email;
	const firstName = getUser().firstName;
	const lastName = getUser().lastName;
	const name  = firstName + ' ' + lastName;


	
	const handleClickVoirHistoric = () => {
		
	}
	return (
		<>
			<Header />
				<main className="ps-page--my-account">
       
					<section className="ps-section--account">
						<div className="container">
							<div className="ps-block--payment-success">
								<h3>Commande reçue !</h3>
								<p>Merci d'avoir fait vos achats chez nous.</p>
							</div>
							<div>
								{firstName}, nous avons reçu votre commande. 
								<br/>Nous vous avons envoyé un e-mail de confirmation à votre adresse e-mail: <b>{email}</b>.
								<br/>Vous pouvez payer dès maintenant ou à la reception de votre marchandise. 
								<br/><br/>Utilisez le numéro de téléphone associé à votre commande pour nous payer à l'un des numéros suivant:<br/><br/>
								<b>MTN: 650 08 87 90 </b><br/>
								<b>Orange: 656 96 95 14</b>
								<br/>
								<br/>
								<Link to='/mon-espace/historique'>Voir vos commandes</Link> | <Link to='/accueil'>Continuer votre shopping</Link>
								<br/>
								<br/>
								<img src = {imageLivraison} />
							</div>
						</div>
					</section>
				</main>
				<Newsletter />
			<Footer />
		</>
	);
};

export default OrderThankYou;
