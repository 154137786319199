import React, { useState, useEffect, useContext } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ShopCategoryItem from './ShopCategoryItem.js';
import { StoreContext } from '../context/store';
import { useNavigate, useLocation, Link } from 'react-router-dom';


const ShopCategoriesList = ( params ) => {

	const location = useLocation();
	const { pathname, search } = location;
	const currentLink 		= pathname.replace(/^\//, '');
	
	const categoriesObjArr 	= Object.values( params.params.categories ) ;
	const categories 		= Object.values( categoriesObjArr ) ;
// console.log( 'categories', categories );	
	// buil a category sub categories
	const BuildCategories = ( params ) => {
		var addValue = 1;
		const subCategories = params.subCategories;
		return (
			subCategories.map(( subCategory, index ) => 
				(
					subCategory.path != currentLink 
					?
						<>
							<ShopCategoryItem 
								key  = { index } 
								item = {{
									category: 	subCategory.category,
									path: 		subCategory.path,
									categories: categories,
									imageSrc: 	subCategory.imageUrl,
								}}
							/>
						</>
					:
					''
				)
			)
		)
	}

	// get all root Categories
	const checkIfSubcatRegex = /\//;
	const rootCategoriesAll = categories.reduce( ( total, subcategories ) =>  { total.push( subcategories.filter( subcategory => !checkIfSubcatRegex.test( subcategory.category ) ) ); return total; } , [] ); 	
	var rootCategories = rootCategoriesAll.filter( category => category.length ).map( elt => elt[ 0 ] ); 

	// remove some categories
	const categoriesToNotDisplay = [ 'soldes' ];
	const rep = rootCategories.filter( category => !categoriesToNotDisplay.includes( category.category ) );
	rootCategories = rep;

	// check if this link is a root categories link ( not a sub category )
	const checkIfSubcatPathRegex = /(.+\/)+/;
	const checkIfSubcatPath = checkIfSubcatPathRegex.test( currentLink );

	// B the category with it subcategories
	const BuildItems = () => {
		
		return (
			checkIfSubcatPath
			?
				categories.map(( subCategories, index ) => 
					(
						subCategories[0].path == currentLink
						&& 
						<BuildCategories 
							key  			= { index }
							subCategories 	= { subCategories }
						/>
					)
				)
			:
				rootCategories.map(( subCategories, index ) => 
					(
						<BuildCategories 
							key  			= { index }
							subCategories 	= { [ subCategories ] }
						/>
					)
				)
		)
	}	
 

	// setTotal
	const pageName 		= params.params.pageName;
	const path			= params.params.path;
	const category		= params.params.category;
	
	// clear products list
	useEffect( () => {

	}, [ ] );


	return (
		<>

                                <div className="row">
                                    <BuildItems /> 
                                </div>
                                
		</>
	);
};

export default ShopCategoriesList;
