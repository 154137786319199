import React, { useState, useEffect, useContext } from "react";
import ShopItemSize from "./ShopItemSize";
import Currency from "./Currency";
import ImageGallery from "react-image-gallery";
import { CartContext } from '../context/cart';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";

const ProductModalAddToCart = ( items ) => {
	const cartItem	 		= items.params.cartItem;

	useEffect(() => {
	}, []);

	return (
		<>        
				<p>&nbsp;</p>
                <article className="ps-product--detail ps-product--fullwidth">
                    <div className="ps-product__header">
                        <ShopItemSize productSizes =
							{{
								cartItem: cartItem
							}}
						/>
                    </div>
                </article>
				<p>&nbsp;</p>
		</>
	);
};

export default ProductModalAddToCart;
