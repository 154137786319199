import React, { useState, useEffect, useContext,  } from "react";
import { CartContext } from '../context/cart';
import { useNavigate, useLocation } from 'react-router-dom';
import Currency from "./Currency";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthProvider";
import "../mySpaceMenuActive.css";

const MySpaceMenu = () => {

	const navigate = useNavigate();
	
	const location 			= useLocation();
	const currentLink 		= location.pathname;
	const [ pageLink, setPageLink ] = useState( currentLink )

	const deactivateAll = () => {
		const menuElts = window.document.getElementsByClassName( 'menuElt' );
		[ ...menuElts ].map( ( e ) => e.classList.remove( 'mySpaceMenuActive' ) );
	}

	const handleClickMenu = ( pageURL ) => {
		setPageLink( pageURL )
		navigate( pageURL );
	}

	// user info
	const { getUser, logOut, isAuthenticated }	= useContext( AuthContext );
	var email 		= 'testeur@testeur.com';
	var firstName	= 'testeur';
	var lastName	= 'testeur';
	const [ authenticated, setAuthenticated ] = useState( isAuthenticated() );
	
	if( getUser() != null ){
		email 		= getUser().email;
		firstName  	= getUser().firstName;
		lastName 	= getUser().lastName;
	}
	
	const handleClickDeconnexion = () => {
		logOut();
		setAuthenticated( false );
		const fallback	= '/connexion';
		navigate( fallback );
	}
	
	const name = firstName + ' ' + lastName;
	

	useEffect(() => {
		deactivateAll();
		window.document.getElementById( pageLink ).classList.add( 'mySpaceMenuActive' );
	}, [ pageLink ]);
	
	return (
		<>
		    <aside class="ps-widget--account-dashboard">
                <div class="ps-widget__header"><img src="img/users/3.jpg" alt="" />
                    <figure>
                        <figcaption>Hello</figcaption>
                        <p><a href="#">{name}</a></p>
                    </figure>
                </div>
                <div className="ps-widget__content">
				<ul>
					<li>
						<a 
							onClick= { (e) => handleClickMenu(  e.target.id ) } 
							id='/mon-espace' 
							className='menuElt active'
							style={{ cursor: 'pointer' }}
						>
							<i className="icon-home"></i> 
							Bienvenue
						</a>
					</li>
					<li>
						<a 
							onClick= { (e) => handleClickMenu(  e.target.id ) } 
							id='/mon-espace/historique' 
							className='menuElt'
							style={{ cursor: 'pointer' }}
						>
							<i className="icon-papers"></i> 
							Historique de vos Commandes
						</a>
					</li>
					<li>
						<a
							onClick= { (e) => handleClickMenu( e.target.id ) } 
							id='/mon-espace/changer-de-mot-de-passe' 
							className='menuElt'
							style={{ cursor: 'pointer' }}
						>
							<i className="icon-user"></i> 
							Changer votre Mot de Passe
						</a>
					</li>
					<li>
						<a
							onClick= { (e) => handleClickMenu( e.target.id ) } 
							id='/mon-espace/mes-adresses' 
							className='menuElt'
							style={{ cursor: 'pointer' }}
						>
							<i className="fa fa-arrow-circle-right"></i> 
							Mes adresses
						</a>
					</li>
					<li>
						<a
							onClick= { (e) => handleClickDeconnexion() } 
							id='/mon-espace/deconnexion' 
							className='menuElt'
							style={{ cursor: 'pointer' }}
						>
							<i className="icon-unlock"></i> 
							Déconnexion
						</a>
					</li>
				</ul>
			<br/>
            </div>
            </aside>
		
			
		</>
	);
};

export default MySpaceMenu;
