import PropTypes from 'prop-types'
import { createContext, useState, useEffect } from 'react'

export const CartContext = createContext()

export const CartProvider = ({ children }) => {
	
	// cart items
	const [cartItems, setCartItems] = useState(localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [])
	
	// check if an item exist in the cart
	const isItemInCart = ( item ) => {

		const res = cartItems.find( ( cartItem ) => 
			cartItem.product.id		== item.product.id &&
			cartItem.product.size	== item.product.size
		);
		return res;
	}

	const addToCart = ( item ) => {
		if( isItemInCart( item ) ){
			setCartItems(
				cartItems.map( ( cartItem ) =>
					cartItem.product &&
					cartItem.product.id === item.product.id
					? 
					{ ...cartItem, quantity: item.quantity }
					: cartItem
				)
			);
		} else {
			setCartItems( [...cartItems, { ...item, quantity: item.quantity } ] );
		}
	};

	const removeFromCart = ( item ) => {
		if( isItemInCart( item ) ){
			setCartItems( cartItems.filter( ( cartItem ) => 
				!( 
					cartItem.product.id		== item.product.id &&
					cartItem.product.size	== item.product.size 
				)
			));		
		}
	};

	const clearCart = () => {
		setCartItems([]);
	};

	const getCartTotal = () => {
		const rep = cartItems.reduce( ( total, cartItem ) => total + cartItem.product.price.replace( /\s/g, '' ) * cartItem.quantity, 0 );
		return rep;
	};

	const getCartProductCount = () => {

		const rep = cartItems.reduce( ( total, cartItem ) => total + cartItem.quantity, 0 );
		return rep;
		
	};

	useEffect(() => {
		const data = localStorage.getItem( 'cartItems' );
		if (data) {
			setCartItems(JSON.parse(data));
		}
	}, []);

	useEffect(() => {
		localStorage.setItem( 'cartItems', JSON.stringify( cartItems ) );
	}, [cartItems]); // Include cartItems as a dependency here

	return (
		<CartContext.Provider
			value={{
				isItemInCart,
				cartItems,
				addToCart,
				removeFromCart,
				clearCart,
				getCartTotal,
				getCartProductCount,
			}}
		>
			{children}
		</CartContext.Provider>
	);
};

CartProvider.propTypes = {
	children: PropTypes.node.isRequired,
};