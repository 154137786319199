import PropTypes from 'prop-types'
import { createContext, useState, useEffect } from 'react'
import { Space, Spin } from 'antd';
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
	LoadingOutlined
} from '@ant-design/icons';
export const SiteContext = createContext();

export const SiteProvider = ({ children }) => {

	// prod adresse
	const prod_serverPrefix = 'https://allkakou.com/api';

	// server adresse
	const adresseAPI_serverPrefix = 'https://237usa.diamta.com/public/index.php/api';

	// local adresse
	const adresseAPI_localPrefix = 'http://localhost/237usa/237usa_backend/public/index.php/api'; 

	const currentServerPrefix = adresseAPI_serverPrefix; // adresseAPI_localPrefix

	const [ filePath, setFilePath ] = useState( currentServerPrefix.split( 'index.php' )[ 0 ] );

	// spiner
	const [ spiner, setSpiner ] = useState( 'none' );

	// helper: Fetch data definition
	async function fetchData( url, data, method, token, contentType ) {

		setSpiner( 'block' );
		const response = await fetch( url, {
			method: method, // *GET, POST, PUT, DELETE, etc.
			// mode: "no-cors", // no-cors, *cors, same-origin
			headers: {
				"Content-Type": contentType ? contentType : "application/json",
				"Authorization" : token ? `Bearer ${token}` : '',
				// 'Content-Type': 'application/x-www-form-urlencoded',
			},
			...( ( method == 'POST' || method == 'PUT' ) && { body: JSON.stringify( data ), } )
		});
		setTimeout( setSpiner, 2000, 'none' );
		if( response.status != 200 )
			return false;
		
		if( response.status == 200 ){

			if( method == 'GET') {
				 const isJson = response.headers.get('content-type')?.includes('application/json');
				 const data = isJson ? await response.json() : response;
				return data // parses JSON response into native JavaScript objects
			}
			else if( method == 'POST' || method == 'PUT' ){
				return true;
			}
			else{
				return false;
			}
		}
	}

	// post a product with files 
	const postProject = async ( token, dataObj, file, files, url ) => {
		const formData = new FormData();
console.log( '+++++ file: ', file );
		// Append files
		files.forEach( ( file ) => {
		 	formData.append( 'productImages', file )
		});

		// formData.append( 'productImages', JSON.stringify( files ) )

		formData.append( 'productImageUrl', file )

		// Append data
		//for ( var key in dataObj ) 
		//	formData.append(key, dataObj[key]);
		
		formData.append('dto', JSON.stringify( dataObj ) );

for (var key of formData.entries()) {
	console.log( 'key0 --> ' + key[0] + ', key1 --> ' + key[1]);
}
		// You can use any AJAX library you like
		const resp = await await fetch( url, {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			// mode: "no-cors", // no-cors, *cors, same-origin
			headers: {
				"Content-Type": 'multipart/form-data', //contentType ? contentType : "application/json",
				"Authorization" : token ? `Bearer ${token}` : '',
			},
			body: formData,
		});

		return resp.json();
	};

	// user
	const [ ref, setRef ] = useState( localStorage.getItem('ref') ? localStorage.getItem( 'ref' ) : '' )
	
	// set user referrer before redirection to login page
	const setReferrer = async( url ) => {
		await setRef( url );	// 
		await localStorage.setItem( 'ref', url );
		
		return url;
	}

	// get user referrer
	const getReferrer = () => {	
		return ref;
	}
	
	// get site url
	const siteURL = 'https://www.237usa.com';
	
	// Newsletter subscription
	const newletterSubscription = async ( email ) => {
		const url  = 'https://allkakou.com/api/news/news-lettter';
		const data = {
			email: 		email,
			category: 	'client'
		}
		const method   = 'POST';
		const response = await fetchData( url, data, method );
		
		return response;
	}
	
	// Partners for retrieval
	const partnersForRetrieval = async () => {
		const method  	= 'GET';
		const url		= 'https://allkakou.com/api/recup/all-recup';
		const data		= {};
		
		const partners = await fetchData( url, data, method );
		
		return partners
	}
	
	// Get a user history
	const getUserHistory = async ( email ) => {
		const url 		= 'https://allkakou.com/api/order/order?personEmail=' + email;
		const method 	= 'GET';
		const data 		= { personEmail: email };

		const userHistory = await fetchData( url, data, method );
		return userHistory;
	}
	
	// Create a user adresses
	const createUserAdresse = async ( data ) => { 
		const url 		= currentServerPrefix + '/create/adresse'; 
		const method 	= 'POST';

		const resp 		= await fetchData( url, data, method );
		return resp;
	}

	// Create a user adresses
	const updateUserAdresse = async ( data ) => { 
		const url 		= currentServerPrefix + '/update/adresse'; 
		const method 	= 'POST';

		const resp 		= await fetchData( url, data, method );
		return resp;
	}

	// Delete a user adresse
	const deleteUserAdresse = async ( data ) => { 
		const url 		= currentServerPrefix + '/delete/adresse'; 
		const method 	= 'POST';

		const resp 		= await fetchData( url, data, method );
		return resp;
	}

	// Get villes
	const getVilles = async () => { 
		const url 		= currentServerPrefix + '/list/villes/'; ; 
		const method 	= 'GET';
		const data 		= {};
		const resp 		= await fetchData( url, data, method );
		return resp;
	}

	// Get a ville's quartiers
	const getVilleQuartiers = async ( villeId ) => { 
		const url 		= currentServerPrefix + '/list/quartiers/ville/?villeId=' + villeId; 
		const method 	= 'GET';
		const data 		= {};

		const resp 		= await fetchData( url, data, method );
		return resp;
	}

	// Get a user's adresses
	const getUserAdresses = async ( userId ) => { 

		const url 		= currentServerPrefix + '/list/user/adresses/?userId=' + userId; 
		const method 	= 'GET';
		const data 		= {};

		const resp 		= await fetchData( url, data, method );
		return resp;
	}

	// Send order
	const sendOrder = async ( data ) => {
		const method 	= 'POST';
		const url		= 'https://allkakou.com/api/order/orders';
		
		const response = await fetchData( url, data, method );
		
		return response;
	}

	// set a product quantity
	const setProductQuantity = async ( data ) => {
		const method  	= 'POST';
		const url		= currentServerPrefix + '/stock/product/quantity';
		
		const resp = await fetchData( url, data, method );	
		if( resp.status != 'NOT_FOUND' )
			return true;
		else
			return false;
	}

	// set a product quantity
	const getProductQuantity = async ( productId ) => {
		const method  	= 'GET';
		const url		= currentServerPrefix + '/stock/get/product/?productId=' + productId; 
		const data 		= {};

		const resp 		= await fetchData( url, data, method );
		return resp;
	}

	// get soldout products
	const getSoldoutProduct = async () => {
		const method  	= 'GET';
		const url		= currentServerPrefix + '/stock/soldout'; 
		const data 		= {};

		const resp 		= await fetchData( url, data, method );
		return resp;
	}

	// deactivate product quantity
	const deactivateProduct = async ( data, token ) => {
		const method  	= 'GET';
		const url		= 'https://allkakou.com/api/product/disable-product/?category=' + data.category + '&subCategory=' +  data.subCategory + '&productName=' + data.productName + '&productColor=' + data.productColor;
		const resp 		= await fetchData( url, data, method, token );
		return resp;
	}

	// remove product removeProduct
	const removeProduct = async ( data, token ) => {
		const method  	= 'GET';
		const url		= 'https://allkakou.com/api/product/remove-product?category=' + data.category + '&subCategory=' +  data.subCategory + '&productName=' + data.productName + '&productColor=' + data.productColor;
		const resp 		= await fetchData( url, data, method, token );
		return resp;
	}

	// delete product in local data baase
	const removeLocalProduct = async ( productId, token ) => {
		const method  	= 'GET';
		const url		= currentServerPrefix + '/stock/product/delete/?productId=' + productId;
		const data 		= {};
		const resp 		= await fetchData( url, data, method, token );
		
		return resp;
	}

	// product description
	const [ description, setDescription ] 		= useState( {} );
	const [ descriptions, setDescriptions ] 	= useState( [] );

	// product quantity
	const [ size, setSize ] 	= useState( {} );
	const [ sizes, setSizes ] 	= useState( [] );

	// product save
	const productSave = async ( data, type, token ) => {
		const url = type == 'edit' ? 'https://allkakou.com/api/product/put-product' : 'https://allkakou.com/api/product/add-product';
		const method = type == 'edit' ? 'PUT' : 'POST';

		const resp 		= await fetchData( url, data, method, token );
		return resp;
	}

	// product save / development
	const productSaveDev = async ( data, fileList ) => {
		const url 		= currentServerPrefix + '/product/save';
		const formData 	= new FormData();
		const method  	= 'POST';
		// Append files

		fileList.forEach( ( file ) => {
			formData.append( 'files[]', file.originFileObj )
		});

		// Append data
		for ( var key in data ) 
			formData.append(key, data[key]);

		// Post
		// const resp = await fetchData( url, formData, method )
		setSpiner( 'block' );
		const resp = await fetch( url, {
			method: 'POST',
			body: formData,
		})
		setTimeout( setSpiner, 2000, 'none' );
		return resp;
	};

	// send mail
	const sendEmail = async ( html ) => {
		const method  	= 'GET';
		const url		= currentServerPrefix + '/email/send/?html=' + html; 
		const data 		= {};

		const resp 		= await fetchData( url, data, method );
		return resp;
	}
	
	// get category
	const getCategories = async () => {
		const method  	= 'GET';
		const url		= 'https://allkakou.com/api/category/list-category';
		const data 		= {};

		const resp 		= await fetchData( url, data, method );
		return resp;
	}
	
	// get sub category
	const getSubCategories = async ( categoryId ) => {
		const method  	= 'GET';
		const url		= 'https://allkakou.com/api/category/list-sub-category?categoryId=' + categoryId;
		const data 		= {};

		const resp 		= await fetchData( url, data, method );
		return resp;
	}

	// Get a product by id
	const productGetDev = async ( productId ) => {
		const method  	= 'GET';
		const url		= currentServerPrefix + '/product/get/?productId=' + productId; 
		const data 		= {};

		const resp 		= await fetchData( url, data, method );
		return resp;
	}

	// List products
	const productListDev = async () => {
		const method  	= 'GET';
		const url		= currentServerPrefix + '/product/list'; 
		const data 		= {};

		const resp 		= await fetchData( url, data, method );
		return resp;
	}

	const productGetDescriptions = async ( productId ) => {

		const method  	= 'GET';
		const url		= currentServerPrefix + '/product/descriptions/get/?productId=' + productId; 
		const data 		= {};

		const resp 		= await fetchData( url, data, method );
		return resp;
	}

	const productGetSizes = async ( productId ) => {
		const method  	= 'GET';
		const url		= currentServerPrefix + '/product/sizes/get/?productId=' + productId; 
		const data 		= {};

		const resp 		= await fetchData( url, data, method );
		return resp;
	}

	const productGetImages = async ( productId ) => {
		const method  	= 'GET';
		const url		= currentServerPrefix + '/product/images/get/?productId=' + productId; 
		const data 		= {};

		const resp 		= await fetchData( url, data, method );
		return resp;
	}

	const productRemove = async ( productId ) => {
		const method  	= 'GET';
		const url		= currentServerPrefix + '/product/remove/?productId=' + productId; 
		const data 		= {};

		const resp 		= await fetchData( url, data, method );
		return resp;
	}

	const productUpdateQuantity = async ( data, token ) => {
		const method  	= 'POST';
		const url		= prod_serverPrefix + '/product/update-quantity/'; 

		const resp 		= await fetchData( url, data, method, token );
		return resp;
	}

	// get last update
	const getLastUpdate = async ( productId ) => {
		const method  	= 'GET';
		const url		= adresseAPI_serverPrefix + '/last/update/?productId=' + productId; 
		const data 		= {};

		const resp 		= await fetchData( url, data, method );
		return resp;
	}

	// Product create
	const productCreate 	= async ( token, data, file, files ) => {
console.log( 'token', token );
		const url  = prod_serverPrefix + '/product/add-product/';
		const resp = await postProject( token, data, file, files, url ); 

		return resp;
	}

	// Product update
	const productUpdate = async ( token, data ) => {
		const method  	= 'PUT';
		const url		= prod_serverPrefix + '/product/put-product/'; 

		const resp 		= await fetchData( url, data, method, token );
		return resp;
	}
	
	// get image's blob
	const getImageBlob = async( imageURL ) => {
		const imageURLTest = 'https://237usa.diamta.com/public/uploads/products/test.jpg';
		const imageToConvert = await fetch( imageURLTest );
		const blob = await imageToConvert.blob();
		const objectURL = URL.createObjectURL(blob);
console.log( 'type', imageToConvert );

		// return objectURL;

		const filename = imageURLTest.split( '/' )[ imageURL.split( '/' ).length - 1 ];
		return new File( [blob], filename , {
			type: "image/jpeg",
		});
	}
	
	// blob to base64
	const  blobToBase64 = (blob) => {
		return new Promise((resolve, _) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.readAsDataURL(blob);
		});
	}


	useEffect( () => {
// alert( '--- referrer: ' + ref );
		// localStorage.setItem( 'ref', ref );
// console.log( 'ref: ', ref );	
	}, [ref] ); // Include site as a dependency here

	return (	
	
		<SiteContext.Provider 
			value={{ 
				setReferrer,
				getReferrer,
				siteURL,
				newletterSubscription,
				partnersForRetrieval,
				getUserHistory,
				getUserAdresses,
				getVilleQuartiers,
				getVilles,
				createUserAdresse,
				updateUserAdresse,
				deleteUserAdresse, 
				sendOrder,
				setProductQuantity,
				getProductQuantity,
				deactivateProduct,
				getSoldoutProduct,
				removeProduct,
				removeLocalProduct,
				description,
				setDescription,
				descriptions,
				setDescriptions,
				size,
				setSize,
				sizes,
				setSizes,
				productSave,
				sendEmail,
				getCategories,
				getSubCategories,
				productSaveDev,
				productGetDev,
				productListDev,
				filePath,
				productGetDescriptions,
				productGetSizes,
				productGetImages,
				productRemove,
				productUpdate,
				productUpdateQuantity,
				getLastUpdate,
				productCreate,
				productUpdate,
				getImageBlob,
				blobToBase64,
			}}
		>
		
			<Space
				style={{ display: spiner }}
			>
				<Spin
					indicator={
						<LoadingOutlined
							style={{
									display:		spiner,
									fontSize: 		100,
									color: 			'#fcb800'
								}}
							spin
						/>
					}
					fullscreen
					tip		= "chargement" 
					size	= "large"
			/>
			
		</Space>

		{children}

		</SiteContext.Provider>

	);
	
	
	
};

SiteProvider.propTypes = {
	children: PropTypes.node.isRequired,
};