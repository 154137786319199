import React, { useState, useEffect, useContext } from "react";
import { CartContext } from '../context/cart';
import { SiteContext } from '../context/site';
import { useNavigate, useLocation } from 'react-router-dom';
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import Currency from "./Currency";
import { Link } from "react-router-dom";

import MySpaceMenu from "./MySpaceMenu";
import image01 from '../images/vendor-1.png';
import image02 from '../images/vendor-2.png';
import image03 from '../images/vendor-3.png';
import { AuthContext } from "../context/AuthProvider";

import { Form, Input, Select } from 'antd';

import { Space, Spin, Button, notification, message, Popconfirm } from 'antd';
import {
	RadiusBottomleftOutlined,
	RadiusBottomrightOutlined,
	RadiusUpleftOutlined,
	RadiusUprightOutlined,
	LoadingOutlined
} from '@ant-design/icons';

import useDidMountEffect from './useDidMountEffect';

const MySpaceChangePassword = () => {
	
	// spin
	const [ passwordChangeSpin, setPasswordChangeSpin ] = useState( 'none' );
	
	// user info
	const { getUser, isValidPassword }	= useContext( AuthContext );

	var email 		= 'testeur@testeur.com';
	var firstName	= 'testeur';
	var lastName	= 'testeur';
	if( getUser() != null ){
		email 		= getUser().email;
		firstName  	= getUser().firstName;
		lastName 	= getUser().lastName;
	}
	const name = firstName + ' ' + lastName;
	
	// mot de passe Actuel
	const [ motDePasseActuel, setMotDePasseActuel ] = useState( '' );
	const [ motDePasseActuelError, setMotDePasseActuelError ] = useState( false );
	const handleChangeMotDePasseActuel = ( e ) => {	
		setMotDePasseActuel( e.target.value );
		if( !e.target.value ){
			setMotDePasseActuelError( 'Saisir votre mot de passe actuel.' );
		}
		else{
			setMotDePasseActuelError( false )
		}
	}
	
	// mot de passe
	const [ motDePasse, setMotDePasse ] = useState( '' );
	const [ motDePasseError, setMotDePasseError ] = useState( false );
	const handleChangeMotDePasse = ( e ) => {	
		setMotDePasse( e.target.value );
		
		// validate password
		if( !e.target.value ){
			setMotDePasseError( 'Saisir le nouveau mot de passe actuel.' );
		}
		else if( isValidPassword( e.target.value ) !== true ) {
			setMotDePasseError( 'Votre mot de passe doit avoir 6 à 100 caractères, lettres majuscules et minuscules et au moins un chiffre.' );
		}
		else{
			setMotDePasseError( false )
		}
	}

	// confirmer mot de passe
	const [ confirmerMotDePasse, setConfirmerMotDePasse ] = useState( '' );
	const [ confirmerMotDePasseError, setConfirmerMotDePasseError ] = useState( false );
	const handleChangeConfirmerMotDePasse = ( e ) => {	
		setConfirmerMotDePasse( e.target.value );
		if( motDePasse != e.target.value ) {
			setConfirmerMotDePasseError( 'Les mots de passe doivent être identiques.' );
		}
		else{
			setConfirmerMotDePasseError( false )
		}
	}

	// post data definition
	async function postData( url, data, method ) {
		const response = await fetch( url, {
			method: "POST", // *GET, POST, PUT, DELETE, etc.
			// mode: "no-cors", // no-cors, *cors, same-origin
			headers: {
				"Content-Type": "application/json",
				// 'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: JSON.stringify( data ), // body data type must match "Content-Type" header
		});
		
		if( response.status !== 200 ){
			return false
		}
		else{
			return true; // parses JSON response into native JavaScript objects
		}
		
	}
	
	// const regexPasswordValidation = /(\d+[a-zA-Z!\*\-\+&éàçè\'\(\)ç]*){6,100}/
	
	// post data definition
	async function postData( url, data, method ) {
		const response = await fetch( url, {
			method: "POST", // *GET, POST, PUT, DELETE, etc.
			// mode: "no-cors", // no-cors, *cors, same-origin
			headers: {
				"Content-Type": "application/json",
				// 'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: JSON.stringify( data ), // body data type must match "Content-Type" header
		});
		
		if( response.status !== 200 ){
			return false
		}
		else{
			return true; // parses JSON response into native JavaScript objects
		}
		
	}
	
	//
	const { setReferrer } 	= useContext( SiteContext );
	const location 			= useLocation();
	const currentPage 		= '/mon-espace'; // location.pathname;
	const navigate 			= useNavigate();

	const handleClickPasswordModifier = ( event ) => {
		setPasswordChangeSpin( 'block' );
		event.preventDefault();
		// validate current password
		if( !motDePasseActuel ){
			setPasswordChangeSpin( 'none' );
			message.error( 'Saisir votre mot de passe actuel.' );
			setMotDePasseActuelError( 'Saisir votre mot de passe actuel.' );
			return
		}
		// validate password
		if( isValidPassword( motDePasse ) !== true ) {
			setPasswordChangeSpin( 'none' );
			message.error( 'Votre mot de passe doit avoir 6 à 100 caractères, lettres majuscules et minuscules et au moins un chiffre.' );
			setMotDePasseError( 'Votre mot de passe doit avoir 6 à 100 caractères, lettres majuscules et minuscules et au moins un chiffre.' );
			return
		}
		// validate password repeat
		if( motDePasse != confirmerMotDePasse ) {
			setPasswordChangeSpin( 'none' );
			message.error( 'Les mots de passe doivent être identiques.' );
			setConfirmerMotDePasseError( 'Les mots de passe doivent être identiques.' );
			return
		}
		
		// restore the password
		const passwordRecoverApiURL = 'https://allkakou.com/api/authentication/update-pass';
		const passwordRecoverData = {
			email: 			email,
			password: 		motDePasseActuel,
			newPassword: 	motDePasse
		};

		const method = 'POST';
		
		const resp = postData( passwordRecoverApiURL, passwordRecoverData, method ).then( 
			( data ) => {
				
				if( !data ){
					setPasswordChangeSpin( 'none' );
					message.error( 'Vérifier votre connexion internet svp.' );	
					return;
				}
				
				setPasswordChangeSpin( 'none' );
				message.success( 'Votre mot de passe a été modifié.' );
				
				// Login redirect
				setReferrer ( currentPage ); // I'll come back!
				const gotoUrl = '/connexion';
				navigate( gotoUrl );
			}
		);
	}
	
		
	const [form] = Form.useForm();
	
	// run after the first render
	useDidMountEffect(() => {
        form.validateFields();
    },  [ motDePasseError ] );
	useDidMountEffect(() => {
        form.validateFields();
    },  [ confirmerMotDePasseError ] );
	useDidMountEffect(() => {
        form.validateFields();
    },  [ motDePasseActuelError ] );

	useEffect(() => {
		setPasswordChangeSpin( 'none' );
	}, []);
	
	return (
		<>
		<Header />
		<main className="ps-page--my-account">
        <section className="ps-section--account">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="ps-section__left">
                            <MySpaceMenu />
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="ps-section__right">
                            <div className="container">
                <div className="ps-section__header">
					<h3>Changer votre Mot de Passe </h3>
                    <p>Choissisez un nouveau mot de passe pour votre compte</p>
                
                <Form 
					style 		= {{ paddingTop: '25px' }} 
					className 	= "ps-tab-root" 
					action 	= "" 
					method 	= "get"
					layout 	= "vertical"
					form	= {form}
				>
				<div className="ps-section__content">
                    <div className="row" style={{ marginLeft: '0px' }}>
                        <div className="ps-form__content" >
                            <div className="form-group">
								<Form.Item
									name  = "motDePasseActuel"
									rules={[
										{
											message: motDePasseActuelError,
											validator: ( value ) => {
												if ( motDePasseActuelError === false ) {
													return Promise.resolve();
												} 
												else {
													return Promise.reject( motDePasseActuelError );
												}
											}
										}
									]}
								>
									<input 
										value={motDePasseActuel} 
										onChange={handleChangeMotDePasseActuel}
										className="form-control" 
										type="password" 
										placeholder="Mot de passe Actuel" 
									/>
								</Form.Item >
                                </div>
								<div className="form-group">
								<Form.Item
									name  = "motDePasse"
									rules={[
										{
											message: motDePasseError,
											validator: ( value ) => {
												if ( motDePasseError === false ) {
													return Promise.resolve();
												} 
												else {
													return Promise.reject( motDePasseError );
												}
											}
										}
									]}
								>
									<input 
										value={motDePasse} 
										onChange={handleChangeMotDePasse}
										className="form-control" 
										type="password" 
										placeholder="Nouveau Mot de Passe" 
									/>
								</Form.Item>
                                </div>
                                <div className="form-group form-forgot">
								<Form.Item
									name  = "validationMotDePasse"
									rules={[
										{
											message: confirmerMotDePasseError,
											validator: ( value ) => {
												if ( confirmerMotDePasseError === false ) {
													return Promise.resolve();
												} 
												else {
													return Promise.reject( confirmerMotDePasseError );
												}
											}
										}
									]}
								>
                                    <Input 
										value={confirmerMotDePasse}
										className="form-control" 
										type="password" 
										placeholder="Confirmer le Mot de passe" 
										onChange={handleChangeConfirmerMotDePasse}
									/>
								</Form.Item >
                                </div>
                                <div className="form-group submtit">
                                    <button 
										className	= "ps-btn ps-btn--black"
										onClick		= {handleClickPasswordModifier}
										style		= {{ color: 'white' }}
									>
									<Space>
											<Spin
												indicator={
													<LoadingOutlined
														style={{
															fontSize: 		20,
															marginRight: 	'10px',
															display:		passwordChangeSpin
														}}
														spin
													/>
												}
											/>
										</Space>
										Modifier le Mot de Passe
									</button>
                                </div>
                            </div>
                        
                    </div>
                </div>
				</Form>
				</div>
            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
		<Newsletter />
    </main>
	<Footer />
		</>
	);
};

export default MySpaceChangePassword;
