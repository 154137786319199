import React, { useState, useEffect, useContext } from "react";
import Newsletter from "./Newsletter";
import ShopLeftMenu from "./ShopLeftMenu";
import ShoppingPageItemSimple from './ShoppingPageItemSimple';
import Header from "./Header"; 
import Footer from "./Footer"; 
import Filters from "./Filters";
import ReactPaginate from 'react-paginate';
import { StoreContext } from '../context/store';
import "../bannerOverride.css";
		
const ShoppingStore = ( params ) => {

	const pageName				= params.params.pageName;
	const categoryPath			= params.params.path;
	const categories			= params.params.categories.val;	// all categories to build the left menu
	const storeName 			= params.params.storeName;
	const storeDescription 		= params.params.description;
	const storeWebsite			= params.params.website;
	const logoPath				= params.params.logoPath ? params.params.logoPath : 'img/vendor/store/0.png';
	const backgroundImagePath 	= params.params.backgroundImagePath ? params.params.backgroundImagePath : 'img/vendor/store/default_banner.jpg';
	
	// custom hook to get data
	const { getAStoreProducts, productList } = useContext( StoreContext )
	const [ storeProducts, setStoreProducts ] = useState( [] );

	// loading message
	const [ loadingMessage, setLoadingMessage ] = useState( 'veuillez patienter ...' )
	const [ displayLoadingMessage, setDisplayLoadingMessage ] = useState( 'none' );
	
	// set product badge
	const getBadge = ( productPrice, productPriceSale ) => {
		const rep = ( ( productPriceSale - productPrice )/productPriceSale ) * 100;
		
		if( isNaN( rep ) || rep == 'Infinity' || rep == '-Infinity' )
			return ''
		
		return	'-' + Math.trunc( rep ) + '%'
	}
	
	// paginate
	const [currentPage, setCurrentPage] = useState(0);
	const PER_PAGE = 24;
	const offset = currentPage * PER_PAGE;

// console.log( 'storeProducts', storeProducts );

	const currentPageData = productList
		.slice( offset, offset + PER_PAGE );
	const pageCount = Math.ceil( productList.length / PER_PAGE );
	
	function handlePageClick({ selected: selectedPage }) {
		setCurrentPage(selectedPage);
	}

// console.log( 'currentPageData', currentPageData );

	// build products 
	const BuildItems = () => {
		return (
			currentPageData.map( ( product, index ) => 
				<div key  = { index }  className="col-lg-4 col-md-4 col-sm-6 col-6 ">
					<ShoppingPageItemSimple 
						key  = { index } 
						item = {{
							name: 			product.productName, 
							price: 			product.productPrice.toString().replace(/(?=(?:.{3})*$)/g, ' '),
							priceOld:		product.productPriceSale ? product.productPriceSale.toString().replace(/(?=(?:.{3})*$)/g, ' ') : '',
							description: 	product.productLineDescription, 
							picture: 		product.productImageUrl ? product.productImageUrl : product.productImage[0],
							pictures: 		product.productImage,
							badge:			getBadge( product.productPrice, product.productPriceSale ),
							brand:			product.brand,
							productDescription: product.productDescription,
							productId: 		product.productId,
							productColor: 	product.productColor,
							productSize:	product.productSize,
							selection:		'',
							category:		'',
							store:			storeName,
							categories: 	categories,
							categoryPath:	categoryPath,
						}}
					/>
				</div>
			)
		)
	}
	
	//
	
	useEffect( () => {
		// get the products
		const getProducts = async () => {
			setDisplayLoadingMessage( 'block' );
			await getAStoreProducts( storeName );
			setDisplayLoadingMessage( 'none' );
		}
		getProducts();
		
	}, [ storeName ] );
	
	
	return (
		<>
		<Header/>
		<div class="ps-page--single ps-page--vendor">
        <section class="ps-store-list">
            <div class="container">
                <aside className="ps-block--store-banner">
							<div 
								className		= "ps-block__content bg--cover" 
								style			= {{ 
													backgroundImage: 'url("' + backgroundImagePath + '")'
								}}
							>
								<h3>{ pageName }</h3>
							</div>
							<div className="ps-block__user">
								<div className="ps-block__user-avatar">
									<img src={ logoPath } alt="" />
								</div>
								<div className="ps-block__user-content">
									<p>{ storeDescription }</p>
									<p><a target="_blanc" href = { 'https://' + storeWebsite }>{ storeWebsite }</a></p>
								</div>
						</div>
				</aside>
				
                <div class="ps-section__wrapper">
                    <div class="ps-section__left">
                        < ShopLeftMenu params = { categories } />
                    </div>
                    <div class="ps-section__right">
                                <div className="ps-shopping-product">
									<div className="ps-block--shop-features">
										<div className="ps-block__header">
											<h3>{ pageName }</h3>
										</div>
									</div>
									 <div className="ps-shopping ps-tab-root">
									<div className="ps-shopping__header">
										<p><strong>{ productList.length }</strong> Produits trouvés </p>
										<div className="ps-shopping__actions">
											<Filters />
										</div>
									</div>
									<div className="row" style={{ display: displayLoadingMessage, marginTop: '2%', paddingLeft: '45%' }}>
										<span>{ loadingMessage }</span>
									</div>
									<div className="row">
										<BuildItems /> 
									</div>
                                </div>
                                <div className="ps-pagination">
									<ReactPaginate
										previousLabel={"← Précédent"}
										nextLabel={"Suivant →"}
										pageCount={pageCount}
										onPageChange={handlePageClick}
										containerClassName={"pagination"}
										previousLinkClassName={"pagination__link"}
										nextLinkClassName={"pagination__link"}
										disabledClassName={"pagination__link--disabled"}
										activeClassName={"pagination_active"}
									/>
                                </div>
                       </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <Newsletter />
	<Footer />
		</>
	);
};

export default ShoppingStore;
