import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link, useLocation  } from 'react-router-dom';
import Newsletter from "./Newsletter";
import Header from "./Header"; 
import Footer from "./Footer"; 
import SocialBar from "./SocialBar";
import "../politicOverrides.css"

const TermsPage = ( params ) => {
	
	return (
		<>
			<Header />
			<div className="ps-blog--sidebar">
                <div className="ps-blog__left">
                    <div className="ps-post--detail sidebar">
                        <div className="ps-post__content">
                            <h1>Termes & Conditions</h1>
                        </div>
                        <div className="ps-post__content">
                            <h4>Communication et confidentialité.</h4>
                            <p>En effectuant un achat en ligne sur www.237 USA.com, vous acceptez automatiquement les communications de 237 USA et vous vous inscrivez automatiquement en tant que membre, confirmant ainsi que vous acceptez que la réception de communications par e-mail soit une condition de participation continue. En outre, vous acceptez que 237 USA puisse vous envoyer des courriers et des communications par e-mail concernant votre compte et d'autres promotions. Si vous choisissez d'interrompre les communications par e-mail, vous pouvez le faire en contactant le service clientèle ou en vous désinscrivant via le lien "désabonnement" sur tous les 237 USA. Cependant, vous acceptez que l'interruption de la communication par e-mail puisse entraîner la perte des futures offres en tant que membre, car le courrier électronique est la principale méthode d'émission des offres des membres autre que l'offre pour les nouveaux membres. Pour plus de détails sur l'inscription à 237 USA , voir ci-dessous. Les informations personnelles des membres seront conservées conformément à la politique de confidentialité de 237 USA, disponible à l'adresse : <Link to='/term-conditions'>Termes & Conditions</Link> Afin de protéger l'intégrité des membres, nous filtrons régulièrement les activités liées aux comptes de nos membres et vous informerons à l'aide de l'adresse e-mail que vous avez fournie en lien avec votre compte si nous remarquons une activité suspecte.</p>
                        </div>
						<div className="ps-post__content">
                            <h4>Annuler votre adhésion.</h4>
                            <p>Vous pouvez annuler votre adhésion en tant que membre à tout moment en écrivant par e-mail au service client à info@237 USA.com. 237 USA se réserve le droit d'annuler votre adhésion à sa seule discrétion.</p>
                        </div>
						<div className="ps-post__content">
                            <h4>Propriété de la propriété intellectuelle.</h4>
                            <p>Toutes les marques de commerce, marques de service et noms commerciaux sur ce site, y compris, sans s'y limiter, le logo ,la bande de signature, tout nom de marque, sont la propriété de 237 USA. Aucune utilisation de ceux-ci ne peut être faite sans préalable, autorisation écrite de 237 USA. Les produits, logos, dessins, publicités et images décrits et/ou affichés sur ce Site peuvent faire l'objet d'autres droits de propriété intellectuelle réservés par 237 USA ou d'autres tiers. Aucune licence n'est concédée sur ces droits de propriété intellectuelle.</p>
                        </div>
						<div className="ps-post__content">
                            <h4>Informations sur nos produits.</h4>
                            <p>237 USA ne peut garantir que les produits et la sélection apparaissant sur ce site seront disponibles, soit par l'intermédiaire de ce site. Toutes les descriptions de produits, les spécifications, les couleurs et les prix peuvent être modifiés par 237 USA à tout moment sans préavis. Les prix affichés sur le Site sont indiqués en fcfa et ne sont valables et effectifs qu'au Cameroun. Les spécifications et paramètres techniques particuliers de votre ordinateur et son affichage des couleurs des produits proposés sur ce Site peuvent avoir un impact sur votre avis ou votre perception des produits.</p>
                        </div>
						<div className="ps-post__content">
                            <h4>Achats.</h4>
                            <p>Les produits proposés à la vente et les services disponibles sur le Site sont destinés à un usage personnel uniquement. Nous nous réservons le droit de limiter la quantité de produits achetés et de refuser ou d'annuler toute commande qui, à notre seule discrétion, violerait l'une de nos pratiques commerciales, lois ou politiques applicables.</p>
                        </div>
						<div className="ps-post__content">
                            <h4>Exactitude des informations.</h4>
                            <p>Les informations présentées sur ce Site ont été compilées par 237usa à partir de sources internes et externes. Aucune représentation n'est faite ou garantie donnée quant à l'exhaustivité ou l'exactitude de ces informations. Ce site peut contenir des erreurs typographiques, des informations incomplètes ou obsolètes, y compris, mais sans s'y limiter, des informations sur les prix. 237usa se réserve le droit d'apporter des modifications aux informations de ce site, ou aux services qui y sont décrits, ou de mettre à jour ces informations à tout moment et sans préavis (y compris après que vous avez soumis votre commande), mais 237usa ne s'engage pas à corriger ou mettre à jour ces informations.Si des informations sur les prix sont inexactes, 237usa se réserve le droit d'annuler tout achat effectué sur la foi d'un prix inexact.</p>
                        </div>
						<div className="ps-post__content">
                            <h4>Indemnité.</h4>
                            <p>Vous acceptez de défendre, d'indemniser et d'exonérer 237usa de toute réclamation, dommage, coût et dépense, y compris les honoraires d'avocat, découlant de ou liés à votre utilisation de ce site, votre accès ou votre incapacité à accéder à ce site, ou de votre confiance dans toute information contenue dans ce site.</p>
                        </div>
                    </div>
                </div>
            </div>
			<Newsletter />
			<Footer />
		</>
	);
};

export default TermsPage;
